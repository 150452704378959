import React, { useEffect, useState } from "react";
//axios
import axios from "axios";
//react-redux

import { useDispatch, useSelector } from "react-redux";
import {
    getPersonById,
    getPracticeById,
    lastSearchedResult,
    openPerson,
    openPractice,
    search,
    updatePerson,
    updatePersonByID,
    updatePractice,
    uniQualificationSearch,
} from "../actions/userActions";
//practice model
import personModel from "../constants/personModel";
import {
    areas2,
    areas,
    salaryBand,
    expHorses,
    species,
    positions,
    dropDownEventsRegistration,
    dropDownEventsCongresses,
    wp_countries,
} from "../constants/constants";
//react-bootstrap
import {
    Accordion,
    Button,
    Card,
    Col,
    Collapse,
    Container,
    Form,
    FormControl,
    Image,
    ListGroup,
    Row,
    Tab,
    Table,
    Tabs,
    Dropdown,
    DropdownButton,
    InputGroup,
} from "react-bootstrap";
//perfect-scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
//react-select
import Select from "react-select";
//react-spinner
import { css } from "@emotion/react";
import BeatLoader from "react-spinners/BeatLoader";
//search-icon
import searchIcon from "../assets/icons/search-icon.svg";
import cancelIcon from "../assets/icons/cancel-icon.svg";
import addressIcon from "../assets/icons/map-pin.svg";

import Swal from "sweetalert2";
import addIcon from "../assets/icons/add.svg";
import MaskedInput from "react-text-mask";
import InputMask from "react-input-mask";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//sweet alert
import swal from "sweetalert";
import useOnclickOutside from "react-cool-onclickoutside";
import Axios from "../adapters/xhr";

//SSO INTEREST CONSTANT
import {
    beefCattle,
    bird,
    cats,
    cattle,
    dairyCattle,
    dogs,
    dogs2,
    general,
    horses,
    lawInfo,
    others,
    pig,
    poultry,
    practiceManagement,
    rabbits,
    reptiles,
    rodents,
    sheep,
    specialisms,
    specialisms2,
    zooWildlifeFish,
} from "../constants/PersonSSOInterest";

// validator
import {
    suffix_update_save,
    save_without_audit,
    save_with_audit,
    moveToLocumValidation,
} from "../helpers/validation";
import ErrorMessage from "./ErrorMessage";
import dayjs from "dayjs";
import { confirmationAlert, swAlert } from "../helpers/alert";
import { selectStyle } from "../styling/select-style";
import { handleDate } from "../helpers/helpers";

var utc = require("dayjs/plugin/utc"); // dependent on utc plugin
dayjs.extend(utc);

const Person = (props) => {
    var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //usestate-hooks
    const [genderr, setGender] = useState("");
    const [open, setOpen] = useState(true);
    const [key, setKey] = useState("details");
    let [personInput, setPersonInput] = useState(personModel);
    const [postcode, setPostcode] = useState("");
    const [postcodeMenuShow, setPostCodeMenuShow] = useState(false);
    const [address, setAddress] = useState({});
    const [data, setData] = useState({});
    const [bar, openBar] = useState(false);
    const [Keyword, setKeyword] = useState("");
    const [uniKeyword, setUniKeyword] = useState("");
    const [data2, setData2] = useState({});
    const [error, setError] = useState({});
    const [selectedSalaryBand, setSalaryBand] = useState(null);
    const [selectedLocation, setLocation] = useState(null);
    const [selectedPmLocation, setPmLocation] = useState(null);
    const [selectedPmOutside, setPmOutside] = useState(null);
    const [seletedEquineExperience, setSeletedEquineExperience] = useState([]);
    const [positionType, setPositionType] = useState([]);
    const [speciesType, setSpeciesType] = useState([]);
    const [slider, setSlider] = useState(false);
    const [s_request_type, set_request_type] = useState({
        request_type: "",
        request_date: "",
        abcName: "",
        abcAnswer: "",
    });
    const [auditError, setAuditError] = useState([]);
    const [openMenu, setOpenMenu] = useState(false);
    //react-redux
    const dispatch = useDispatch();
    const { personId } = useSelector((state) => state.openPersonReducer);
    const { personGet } = useSelector((state) => state.getPersonReducer);
    const { loadingUpdatePerson, personUpdate, errorUpdatePeron } = useSelector(
        (state) => state.updatePersonReducer
    );
    const { lastSearchResult } = useSelector(
        (state) => state.lastSearchedResultReducer
    );
    const { searchPracticeResult2 } = useSelector(
        (state) => state.searchReducer
    );
    const { loading, uni_data } = useSelector((state) => state.uniSearch);
    const [uniBar, setUniBar] = useState(false);
    const [placeValue, setPlaceValue] = useState({
        curr_date: "{{curren date}}",
    });
    const [SASrole, setSASrole] = useState([1, 2, 3]);
    const [selectedEventsRegistration, setEventsRegistration] = useState(null);
    const [selectedEventsAttended, setEventsAttended] = useState(null);
    const [selectedEventsCongresses, setEventsCongresses] = useState(null);
    const [duplicateIDs, setDuplicateIDs] = useState(null);
    const [enableSSOField, setEnableSSOField] = useState(true);
    const [disableSpecies, setDisableSpecies] = useState(false);
    const [tempSpecies, setTempSpecies] = useState("");
    const [selectedCountryResidence, setCountryResidence] = useState(null);
    useEffect(() => {
        dispatch(getPersonById(personId));
    }, [dispatch, personId]);

    useEffect(() => {
        set_request_type((prev) => ({ ...prev, abcName: "", abcAnswer: "" }));
    }, [s_request_type.request_type]);

    useEffect(() => {
        let current = new Date();
        let today_date = `${current.getFullYear()}-${(
            "0" +
            (current.getMonth() + 1)
        ).slice(-2)}-${("0" + current.getDate()).slice(-2)}`;
        //console.log(today_date)
        if (personInput.pm_interested === "Yes") {
            setPersonInput((prev) => ({
                ...prev,
                pm_interest_date: today_date,
            }));
        } else {
            setPersonInput((prev) => ({ ...prev, pm_interest_date: "" }));
        }
    }, [personInput.pm_interested]);

    useEffect(() => {
        if (
            personInput.v_svt_request === "Written" ||
            personInput.v_svt_request === "C Written"
        ) {
            setPersonInput((prev) => ({
                ...prev,
                v_svt_abc_name: "",
                v_svt_abc_answer: "",
            }));
        }
        if (
            personInput.v_svbj_request === "Written" ||
            personInput.v_svbj_request === "C Written"
        ) {
            setPersonInput((prev) => ({
                ...prev,
                v_svbj_abc_name: "",
                v_svbj_abc_answer: "",
            }));
        }
        if (
            personInput.v_svnt_request === "Written" ||
            personInput.v_svnt_request === "C Written"
        ) {
            setPersonInput((prev) => ({
                ...prev,
                v_svnt_abc_name: "",
                v_svnt_abc_answer: "",
            }));
        }
    }, [
        personInput.v_svt_request,
        personInput.v_svbj_request,
        personInput.v_svnt_request,
    ]);

    // useEffect(() => {
    //
    //     if (personInput.v_svt === 'No' && personInput.practice_id !==2) {
    //         setPersonInput(prev => ({ ...prev,v_svt_request :'Phone',v_svt_request_date:'', v_svt_abc_name: '',v_svt_abc_answer: '',v_svte: 'No', v_svtl: 'No' }))
    //     }
    //     if (personInput.v_svnt === 'No' && personInput.practice_id !==2) {
    //         setPersonInput(prev => ({ ...prev,v_svnt_request :'Phone',v_svnt_request_date:'' ,v_svnt_abc_name: '', v_svnt_abc_answer: '' }))
    //     }
    //     if (personInput.v_svbj === 'No' && personInput.practice_id !==2) {
    //         setPersonInput(prev => ({ ...prev,v_svbj_request :'Phone',v_svbj_request_date:'', v_svbj_abc_name: '', v_svbj_abc_answer: '' }))
    //     }
    //
    // }, [personInput.v_svt, personInput.v_svbj, personInput.v_svnt])

    useEffect(() => {
        if (personInput.v_sevbd === "No" && personInput.practice_id !== 2) {
            setPersonInput((prev) => ({
                ...prev,
                v_sevbd_request: "Phone",
                v_sevbd_request_date: "",
            }));
        }
        if (personInput.v_vnews_d === "No" && personInput.practice_id !== 2) {
            setPersonInput((prev) => ({
                ...prev,
                v_vnews_d_request: "Phone",
                v_vnews_d_request_date: "",
            }));
        }
        if (personInput.v_vjnw === "No" && personInput.practice_id !== 2) {
            setPersonInput((prev) => ({
                ...prev,
                v_vjnw_request: "Phone",
                v_vjnw_request_date: "",
            }));
        }
        if (
            personInput.v_vt_jobs_marketing === "No" &&
            personInput.practice_id !== 2
        ) {
            setPersonInput((prev) => ({
                ...prev,
                v_vt_jobs_marketing_request: "Phone",
                v_vt_jobs_marketing_request_date: "",
            }));
        }
        if (personInput.v_s3rd === "No" && personInput.practice_id !== 2) {
            setPersonInput((prev) => ({
                ...prev,
                v_s3rd_request: "Phone",
                v_s3rd_request_date: "",
            }));
        }
        if (personInput.v_vnews === "No" && personInput.practice_id !== 2) {
            setPersonInput((prev) => ({
                ...prev,
                v_vnews_request: "Phone",
                v_vnews_request_date: "",
            }));
        }
        if (personInput.v_vjnm === "No" && personInput.practice_id !== 2) {
            setPersonInput((prev) => ({
                ...prev,
                v_vjnm_request: "Phone",
                v_vjnm_request_date: "",
            }));
        }
        if (
            personInput.vn_monthly_newsletter === "No" &&
            personInput.practice_id !== 2
        ) {
            setPersonInput((prev) => ({
                ...prev,
                vn_monthly_newsletter_request: "Phone",
                vn_monthly_newsletter_request_date: "",
            }));
        }
    }, [
        personInput.v_sevbd,
        personInput.v_vnews_d,
        personInput.v_vjnw,
        personInput.v_vt_jobs_marketing,
        personInput.v_s3rd,
        personInput.v_vnews,
        personInput.v_vjnm,
        personInput.vn_monthly_newsletter,
    ]);

    // useEffect(() => {
    //    if( !re.test(personInput.v_email) ){
    //        setPersonInput(prev => ({...prev, v_sevbd: 'No', v_sevbd_request: 'Phone', v_sevbd_request_date: ''}))
    //        setPersonInput(prev => ({...prev, v_vnews_d: 'No', v_vnews_d_request: 'Phone', v_vnews_d_request_date: ''}))
    //        setPersonInput(prev => ({...prev, v_vjnw: 'No', v_vjnw_request: 'Phone', v_vjnw_request_date: ''}))
    //        setPersonInput(prev => ({...prev, v_vt_jobs_marketing: 'No', v_vt_jobs_marketing_request: 'Phone', v_vt_jobs_marketing_request_date: ''}))
    //        setPersonInput(prev => ({...prev, v_s3rd: 'No', v_s3rd_request: 'Phone', v_s3rd_request_date: ''}))
    //        setPersonInput(prev => ({...prev, v_vnews: 'No', v_vnews_request: 'Phone', v_vnews_request_date: ''}))
    //        setPersonInput(prev => ({...prev, v_vjnm: 'No', v_vjnm_request: 'Phone', v_vjnm_request_date: ''}))
    //        setPersonInput(prev => ({...prev, vn_monthly_newsletter: 'No', vn_monthly_newsletter_request: 'Phone', vn_monthly_newsletter_request_date: ''}))
    //    }
    //
    // }, [personInput.v_email, personInput.v_work_email, personInput.sso_email])

    useEffect(() => {
        if (personGet) {
            setDuplicateIDs(
                personGet.data.duplicateIDs == null
                    ? ""
                    : personGet.data.duplicateIDs
            );
            populatePerson(personGet.data);
            setEnableSSOField(true);
            setTempSpecies("");
            setKeyword("");
        }
    }, [personGet]);

    useEffect(() => {
        if (lastSearchResult) {
            populatePerson(lastSearchResult.data.vet);
            setDuplicateIDs(
                lastSearchResult.data.duplicateIDs == null
                    ? ""
                    : lastSearchResult.data.duplicateIDs
            );
            setKeyword("");
        }
    }, [lastSearchResult]);

    useEffect(() => {
        if (searchPracticeResult2) {
            setData2({
                practices: searchPracticeResult2
                    ? searchPracticeResult2.data.practices
                    : [],
            });
            openBar(true);
        }
    }, [searchPracticeResult2]);

    useEffect(() => {
        if (Keyword.length >= 3) dispatch(search(Keyword, "pers"));
        else openBar(false);
    }, [dispatch, Keyword]);

    useEffect(() => {
        if (uniKeyword.length >= 2) {
            dispatch(uniQualificationSearch(uniKeyword));
            setUniBar(true);
        } else {
            setUniBar(false);
        }
    }, [dispatch, uniKeyword]);

    useEffect(() => {
        if (
            personInput.practice_data.office &&
            personInput.practice_data.office.split(" ")[0] === "Industry"
        ) {
            setKey("subscriptions");
        }
    }, [personInput.practice_data.office]);

    useEffect(() => {
        setDisableSpecies(false);

        let v_job_types =
            personInput.v_job_types.length > 0
                ? personInput.v_job_types.split("")
                : [];
        let CX = v_job_types.includes("C") || v_job_types.includes("X");

        if (personInput.v_species.length !== 0) {
            setTempSpecies(personInput.v_species);
        }

        // IF ONLY C X ARE AVAILABLE IN JOB TYPES THEN ENABLED THE SPECIES
        if (personInput.v_job_types.length === 0) {
            setPersonInput((prev) => ({ ...prev, v_species: "" }));
            setDisableSpecies(true);
        } else if (!CX) {
            setPersonInput((prev) => ({ ...prev, v_species: "" }));
            setDisableSpecies(true);
        } else if (CX) {
            if (tempSpecies.length !== 0) {
                setDisableSpecies(false);
                setPersonInput((prev) => ({ ...prev, v_species: tempSpecies }));
            }
        } else {
            setDisableSpecies(false);
        }
    }, [personInput.v_job_types]);

    // useEffect(() => {
    //     if(personInput.v_email.length > 0){
    //        setPersonInput(prev => ({ ...prev, v_email_opt_in: 'Yes' }))
    //    } else{
    //        setPersonInput(prev => ({ ...prev, v_email_opt_in: 'No' }))
    //    }
    // }, [personInput.v_email]);

    const populatePerson = (person) => {
        resetPostcodeLookUpField();
        let temp = {};
        let sal = [];
        let area = [];
        let area2 = [];
        let pm_area = [];
        let pm_outside_area = [];
        let exp = [];
        let sp = [];
        let pos = [];
        let reg = [];
        let att = [];
        let cong = [];
        if (person) {
            Object.entries(person).forEach(([key, value]) => {
                if (value === null) {
                    temp[key] = "";
                } else {
                    temp[key] = value;
                }
            });

            setPersonInput(temp);

            temp.sm_salary_band
                ? temp.sm_salary_band.split(",").forEach((value) => {
                      sal.push(
                          salaryBand.find((row) => {
                              return row.value == value;
                          })
                      );
                  })
                : (sal = []);

            temp.areas
                ? temp.areas.split(",").forEach((value) => {
                      area.push(
                          areas.find((row) => {
                              return row.value == value;
                          })
                      );
                  })
                : (area = []);

            temp.pm_areas
                ? temp.pm_areas.split(",").forEach((value) => {
                      pm_area.push(
                          areas.find((row) => {
                              return row.value == value;
                          })
                      );
                  })
                : (pm_area = []);

            temp.areas2
                ? temp.areas2.split(",").forEach((value) => {
                      area2.push(
                          areas2.find((row) => {
                              return row.value == value;
                          })
                      );
                  })
                : (area2 = []);

            temp.pm_outside_areas
                ? temp.pm_outside_areas.split(",").forEach((value) => {
                      pm_outside_area.push(
                          areas2.find((row) => {
                              return row.value == value;
                          })
                      );
                  })
                : (pm_outside_area = []);

            temp.main_experience
                ? temp.main_experience.split(",").forEach((value) => {
                      exp.push(
                          expHorses.find((row) => {
                              return row.value == value;
                          })
                      );
                  })
                : (exp = []);

            temp.position
                ? temp.position.split(",").forEach((value) => {
                      pos.push(
                          positions.find((row) => {
                              return row.value == value;
                          })
                      );
                  })
                : (temp.position = []);

            temp.species_type
                ? temp.species_type.split(",").forEach((value) => {
                      sp.push(
                          species.find((row) => {
                              return row.value == value;
                          })
                      );
                  })
                : (temp.species_type = []);

            temp.v_registration
                ? temp.v_registration.split(",").forEach((value) => {
                      reg.push(
                          dropDownEventsRegistration.find((row) => {
                              return row.value == value;
                          })
                      );
                  })
                : (temp.v_registration = []);

            temp.v_attended
                ? temp.v_attended.split(",").forEach((value) => {
                      att.push(
                          dropDownEventsRegistration.find((row) => {
                              return row.value == value;
                          })
                      );
                  })
                : (temp.v_attended = []);

            temp.v_congresses
                ? temp.v_congresses.split(",").forEach((value) => {
                      cong.push(
                          dropDownEventsCongresses.find((row) => {
                              return row.value == value;
                          })
                      );
                  })
                : (temp.v_congresses = []);

            setSalaryBand(sal);
            setLocation(area);
            setPmLocation(pm_area);
            setPmOutside(pm_outside_area);
            setSeletedEquineExperience(exp);
            setPositionType(pos);
            setSpeciesType(sp);
            setEventsRegistration(reg);
            setEventsAttended(att);
            setEventsCongresses(cong);
            setCountryResidence({ label: temp.residence });
        }
    };

    //search stuff
    const handleKeywordChange = (e) => {
        e.preventDefault();
        const { value } = e.target;

        setKeyword(value);
    };

    const handleUniKeywordChange = (e) => {
        const { value } = e.target;
        setPersonInput((prev) => ({ ...prev, u_uni: value }));
        setUniKeyword(value);
    };

    const setDeletePm = () => {
        Swal.fire({
            title: "Are you sure to perform this action?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
        }).then((result) => {
            if (result.isConfirmed) {
                let practice_id = personInput.practice_id;
                let v_sdm = personInput.v_sdm;
                let v_s3rd = personInput.v_s3rd;
                let v_s3rd_request = personInput.v_s3rd_request;
                let v_s3rd_request_date = personInput.v_s3rd_request_date;
                let v_job_types = personInput.v_job_types;
                let v_job_types_o = personInput.v_job_types_o;
                let v_species = personInput.v_species;
                let ind_type = personInput.ind_type;

                let pm_name = personInput.pm_name;
                let pm_email = personInput.pm_email;
                let pm_phone = personInput.pm_phone;
                let pm_university = personInput.pm_university;
                let pm_next_role = personInput.pm_next_role;
                let pm_postcode = personInput.pm_postcode;
                let pm_address_1 = personInput.pm_address_1;
                let pm_address_2 = personInput.pm_address_2;
                let pm_address_3 = personInput.pm_address_3;
                let pm_distance = personInput.pm_distance;
                let pm_town = personInput.pm_town;
                let pm_county = personInput.pm_county;
                let pm_relocate = personInput.pm_relocate;
                let pm_working_outside = personInput.pm_working_outside;
                let pm_looking_for_work = personInput.pm_looking_for_work;
                let pm_ideal_rota = personInput.pm_ideal_rota;
                let pm_clinical_interest = personInput.pm_clinical_interest;
                let pm_important_next_role = personInput.pm_important_next_role;
                let pm_skill_develop = personInput.pm_skill_develop;
                let pm_salary_band = personInput.pm_salary_band;
                let pm_work_in_uk = personInput.pm_work_in_uk;
                let pm_cv = personInput.pm_cv;
                let pm_year_rcvs = personInput.pm_year_rcvs;
                let pm_areas = personInput.pm_areas;
                let pm_outside_areas = personInput.pm_outside_areas;
                let pm_profile = personInput.pm_profile;
                let pm_profile_created_date =
                    personInput.pm_profile_created_date;
                let pm_profile_modified = personInput.pm_profile_modified;
                let pm_job_type = personInput.pm_job_type;
                let pm_year_qualification = personInput.pm_year_qualification;
                let pm_ideal_role = personInput.pm_ideal_role;
                let pm_country = personInput.pm_country;

                pm_name = null;
                pm_email = null;
                pm_phone = null;
                pm_university = null;
                pm_next_role = null;
                pm_postcode = null;
                pm_address_1 = null;
                pm_address_2 = null;
                pm_address_3 = null;
                pm_distance = null;
                pm_town = null;
                pm_county = null;
                pm_relocate = null;
                pm_working_outside = null;
                pm_looking_for_work = null;
                pm_ideal_rota = null;
                pm_clinical_interest = null;
                pm_important_next_role = null;
                pm_skill_develop = null;
                pm_salary_band = null;
                pm_work_in_uk = null;
                pm_cv = null;
                pm_year_rcvs = null;
                pm_areas = null;
                pm_outside_areas = null;
                pm_profile = "No";
                pm_profile_created_date = null;
                pm_profile_modified = null;
                pm_job_type = null;
                pm_year_qualification = null;
                pm_ideal_role = null;
                pm_country = null;

                dispatch(
                    updatePerson(
                        personInput.id,
                        {
                            practice_id: practice_id,
                            v_sdm: v_sdm,
                            v_s3rd: v_s3rd,
                            v_s3rd_request: v_s3rd_request,
                            v_s3rd_request_date: v_s3rd_request_date,
                            v_job_types: v_job_types,
                            v_job_types_o: v_job_types_o,
                            v_species: v_species,
                            ind_type: ind_type,

                            pm_name: pm_name,
                            pm_email: pm_email,
                            pm_phone: pm_phone,
                            pm_university: pm_university,
                            pm_next_role: pm_next_role,
                            pm_postcode: pm_postcode,
                            pm_address_1: pm_address_1,
                            pm_address_2: pm_address_2,
                            pm_address_3: pm_address_3,
                            pm_distance: pm_distance,
                            pm_town: pm_town,
                            pm_county: pm_county,
                            pm_relocate: pm_relocate,
                            pm_working_outside: pm_working_outside,
                            pm_looking_for_work: pm_looking_for_work,
                            pm_ideal_rota: pm_ideal_rota,
                            pm_clinical_interest: pm_clinical_interest,
                            pm_important_next_role: pm_important_next_role,
                            pm_skill_develop: pm_skill_develop,
                            pm_salary_band: pm_salary_band,
                            pm_work_in_uk: pm_work_in_uk,
                            pm_cv: pm_cv,
                            pm_year_rcvs: pm_year_rcvs,
                            pm_areas: pm_areas,
                            pm_outside_areas: pm_outside_areas,
                            pm_profile: pm_profile,
                            pm_profile_created_date: pm_profile_created_date,
                            pm_profile_modified: pm_profile_modified,
                            pm_job_type: pm_job_type,
                            pm_year_qualification: pm_year_qualification,
                            pm_ideal_role: pm_ideal_role,
                            pm_country: pm_country,
                        },
                        null
                    )
                );
                setKeyword("");
            }
        });
    };

    const handleSetPracticeId = (id, office) => {
        // setPersonInput(prevState => ({
        //   ...prevState,
        //   practice_id: String(id)
        // }))
        let v_sdm = personInput.v_sdm;
        let v_s3rd = personInput.v_s3rd;
        let v_s3rd_request = personInput.v_s3rd_request;
        let v_s3rd_request_date = personInput.v_s3rd_request_date;
        let v_job_types = personInput.v_job_types;
        let v_job_types_o = personInput.v_job_types_o;
        let v_species = personInput.v_species;
        let ind_type = personInput.ind_type;
        let pm_soft_opt_in = personInput.pm_soft_opt_in;
        let pm_soft_opt_in_date = personInput.pm_soft_opt_in_date;

        if (office.split(" ")[0] === "Industry") {
            v_sdm = "No";
            v_s3rd = "No";
            v_s3rd_request = "Phone";
            v_s3rd_request_date = null;
            v_job_types = null;
            //  v_job_types_o = null;
            v_species = null;
            pm_soft_opt_in_date = null;
            pm_soft_opt_in = "No";
        } else {
            ind_type = "None";
            v_sdm = "Yes";
            if (v_job_types_o !== "") {
                v_job_types = v_job_types;
            }
        }
        if (
            personInput.practice_id == 4 ||
            personInput.practice_id == 9000 ||
            personInput.practice_id == 10000
        ) {
            if (id == 2 || id == 3) {
                v_sdm = "No";
            } else if (office.split(" ")[0] === "Industry") {
                v_sdm = "No";
                v_s3rd = "No";
                v_job_types = null;
                v_species = null;
            } else {
                v_sdm = "Yes";
            }
        }

        openBar(false);
        dispatch(
            updatePerson(
                personInput.id,
                {
                    practice_id: id,
                    v_sdm: v_sdm,
                    v_s3rd: v_s3rd,
                    v_s3rd_request: v_s3rd_request,
                    v_s3rd_request_date: v_s3rd_request_date,
                    v_job_types: v_job_types,
                    v_job_types_o: v_job_types_o,
                    v_species: v_species,
                    ind_type: ind_type,
                    pm_soft_opt_in: pm_soft_opt_in,
                    pm_soft_opt_in_date: pm_soft_opt_in_date,
                    request_coming_from: "branch",
                },
                id
            )
        );
        setKeyword("");
    };

    //address finder
    const handlePostcodeChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        // setPostCodeMenuShow(true)
        setPostcode(value);
    };

    const handlePostcodeSubmit = async () => {
        const api_key = "htcb_PTDuEiH1jU-6sz0vA24263";
        const { data } = await axios.get(
            `https://api.getAddress.io/find/${postcode}?api-key=${api_key}&expand=true&sort=true`
        );

        const arr = [];
        data.addresses.forEach((curr, ind) => {
            arr.push({
                display: curr.formatted_address.join(" "),
                address1: curr.line_1,
                address2:
                    curr.line_2 === "" &&
                    curr.country !== "Isle of Man" &&
                    curr.country !== "Channel Islands"
                        ? curr.locality
                        : curr.line_2,
                address3: curr.line_3,
                town:
                    curr.country === "Isle of Man" ||
                    curr.country === "Channel Islands"
                        ? curr.locality
                        : curr.town_or_city,
                county:
                    curr.town_or_city === "Isle of Man" ||
                    curr.country === "Channel Islands"
                        ? curr.town_or_city
                        : curr.county,
                country:
                    curr.country === "Isle of Man" ? "England" : curr.country,
                postcode: data.postcode,
            });
        });
        setData({ arr });
        setPostCodeMenuShow(true);
    };

    const handleSetAddress = (data) => {
        setPostcode("");
        setPersonInput((prevState) => ({
            ...prevState,
            v_address_1: data.address1,
            v_address_2: data.address2,
            v_address_3: data.address3,
            v_town: data.town,
            v_county: data.county,
            v_postcode: data.postcode,
            v_country: data.country,
        }));
        setData({});
    };

    const handleSetCountry = (data) => {
        setPersonInput((prev) => ({ ...prev, u_country: data }));
        setUniBar(false);
    };

    const setPrimaryPma = () => {
        setPersonInput((prev) => ({ ...prev, primary_pma: null }));
    };

    const setSecondaryPma = () => {
        setPersonInput((prev) => ({ ...prev, secondary_pma: null }));
    };

    const setUnlinkSSOEmailAdd = () => {
        setPersonInput((prev) => ({ ...prev, sso_email: "" }));
    };

    const handleSetUni = (data) => {
        setPersonInput((prev) => ({ ...prev, u_uni: data }));
    };

    const handleCountryChange = (selectedOption) => {

        setCountryResidence(selectedOption);

        if (!props.showPracticeMessage) {
            props.practiceMessageFunc(true);
        }
    };

    const createInitials = (e) => {
        if (!props.showPracticeMessage) {
            props.practiceMessageFunc(true);
        }
        const { name, value } = e.target;

        //personModel[name] = value;
        setPersonInput((prevState) => ({
            ...prevState,
            [name]: value,
        }));

        let initials = [];
        let initialsMiddlename = [];
        let initialsForename = [];

        personInput.middlenames
            .toLowerCase()
            .split(" ")
            .map(function (val, i) {
                if (val.includes("-"))
                    initialsMiddlename[i] = val
                        .split("-")
                        .map((word) => word.charAt(0).toUpperCase())
                        .join("-");
                else initialsMiddlename[i] = val.charAt(0).toUpperCase();
            });

        personInput.forename
            .toLowerCase()
            .split(" ")
            .map(function (val, i) {
                if (val.includes("-"))
                    initialsForename[i] = val
                        .split("-")
                        .map((word) => word.charAt(0).toUpperCase())
                        .join("-");
                else initialsForename[i] = val.charAt(0).toUpperCase();
            });

        // combine the forename with the middle name
        initials =
            initialsForename.join(" ") + " " + initialsMiddlename.join(" ");

        // remove the white spaces with single space
        initials = initials.replace(/\s+/g, " ");

        // remove the white spaces that is surround (-) sign
        initials = initials.replace(/\s+(?=[-])|(?<=-)\s*/g, "");

        setPersonInput((prevState) => ({
            ...prevState,
            initials: initials.trim(),
        }));
    };

    // update person input
    const handlePersonChange = (e) => {
        if (!props.showPracticeMessage) {
            props.practiceMessageFunc(true);
        }
        const { name, value } = e.target;

        if (value == "Mr") {
            setGender("Male");
            setPersonInput((prevState) => ({
                ...prevState,
                gender: "Male",
            }));
        } else if (["Mrs", "Ms", "Miss"].includes(value)) {
            setGender("Female");
            setPersonInput((prevState) => ({
                ...prevState,
                gender: "Female",
            }));
        } /* else {
            setGender('Other')
        } */

        if (name === "v_job_types") {
            let v_job_types =
                personInput.v_job_types.length > 0
                    ? personInput.v_job_types.split("")
                    : [];

            if (v_job_types.find((curr) => curr === value)) {
                let str = v_job_types.filter((curr) => curr !== value).join("");
                setPersonInput((prevState) => ({
                    ...prevState,
                    v_job_types: str,
                }));
            } else {
                v_job_types.push(value);
                let str = v_job_types.length > 0 ? v_job_types.join("") : "";
                setPersonInput((prevState) => ({
                    ...prevState,
                    v_job_types: str,
                }));
            }
        } else if (name === "v_species") {
            let v_species =
                personInput.v_species.length > 0
                    ? personInput.v_species.split("")
                    : [];

            if (v_species.find((curr) => curr === value)) {
                let str = v_species.filter((curr) => curr != value).join("");
                setPersonInput((prevState) => ({
                    ...prevState,
                    v_species: str,
                }));
                setTempSpecies(str);
            } else {
                v_species.push(value);
                let str = v_species.length > 0 ? v_species.join("") : "";
                setPersonInput((prevState) => ({
                    ...prevState,
                    v_species: str,
                }));
                setTempSpecies(str);
            }
        } else if (name === "pm_looking_for_work") {
            let pm_looking_for_work =
                personInput.pm_looking_for_work.length > 0
                    ? personInput.pm_looking_for_work.split(",")
                    : [];

            if (pm_looking_for_work.find((curr) => curr === value)) {
                let str = pm_looking_for_work
                    .filter((curr) => curr != value)
                    .join();

                setPersonInput((prevState) => ({
                    ...prevState,
                    pm_looking_for_work: str,
                }));
            } else {
                pm_looking_for_work.push(value);
                let str =
                    pm_looking_for_work.length > 0
                        ? pm_looking_for_work.join()
                        : "";
                setPersonInput((prevState) => ({
                    ...prevState,
                    pm_looking_for_work: str,
                }));
            }
        } else if (name === "pm_salary_band") {
            let pm_salary_band =
                personInput.pm_salary_band.length > 0
                    ? personInput.pm_salary_band.split(",")
                    : [];

            if (pm_salary_band.find((curr) => curr === value)) {
                let str = pm_salary_band.filter((curr) => curr != value).join();

                setPersonInput((prevState) => ({
                    ...prevState,
                    pm_salary_band: str,
                }));
            } else {
                pm_salary_band.push(value);
                let str =
                    pm_salary_band.length > 0 ? pm_salary_band.join() : "";
                setPersonInput((prevState) => ({
                    ...prevState,
                    pm_salary_band: str,
                }));
            }
        } else if (name === "pm_next_role") {
            let pm_next_role =
                personInput.pm_next_role.length > 0
                    ? personInput.pm_next_role.split(",")
                    : [];

            if (pm_next_role.find((curr) => curr === value)) {
                let str = pm_next_role.filter((curr) => curr != value).join();

                setPersonInput((prevState) => ({
                    ...prevState,
                    pm_next_role: str,
                }));
            } else {
                pm_next_role.push(value);
                let str = pm_next_role.length > 0 ? pm_next_role.join() : "";
                setPersonInput((prevState) => ({
                    ...prevState,
                    pm_next_role: str,
                }));
            }
        } else if (name === "contact_method") {
            let contact_method =
                personInput.contact_method.length > 0
                    ? personInput.contact_method.split(",")
                    : [];

            if (contact_method.find((curr) => curr === value)) {
                let str = contact_method.filter((curr) => curr != value).join();

                setPersonInput((prevState) => ({
                    ...prevState,
                    contact_method: str,
                }));
            } else {
                contact_method.push(value);
                let str =
                    contact_method.length > 0 ? contact_method.join() : "";
                setPersonInput((prevState) => ({
                    ...prevState,
                    contact_method: str,
                }));
            }
        } else if (name === "v_email") {
            if (personInput.v_email != "" && value === "") {
                setPersonInput((prevState) => ({
                    ...prevState,
                    v_email: "",
                    // v_sevbd: "No",
                    // v_vnews_d: "No",
                    // v_vt_jobs_marketing: "No",
                    // v_s3rd: "No",
                    // v_vnews: "No",
                    // v_vjnm: "No",
                    // vn_monthly_newsletter: "No"
                }));
            } else {
                if (re.test(value)) {
                    setPersonInput((prevState) => ({
                        ...prevState,
                        v_email: value,
                    }));
                } else {
                    setPersonInput((prevState) => ({
                        ...prevState,
                        v_email: value,
                        v_sevbd: "No",
                        v_vnews_d: "No",
                        v_vt_jobs_marketing: "No",
                        v_s3rd: "No",
                        v_vnews: "No",
                        v_vjnm: "No",
                        vn_monthly_newsletter: "No",
                    }));
                }
            }
        } else if (name === "pms") {
            let str =
                personInput.pms.length > 0 ? personInput.pms.split(",") : [];

            if (str.find((curr) => curr === value)) {
                let str2 = str.filter((curr) => curr != value).join();

                setPersonInput((prevState) => ({
                    ...prevState,
                    pms: str2,
                }));
            }
            //  } else if(name == "ind_vt_start" ){
            //      personInput.ind_vt_start = value
            //      setPersonInput(prevState => ({
            //          ...prevState,
            //          ind_vt_start: value
            //      }))
            //  }
            else {
                str.push(value);
                let str2 = str.length > 0 ? str.join() : "";
                setPersonInput((prevState) => ({
                    ...prevState,
                    pms: str2,
                }));
            }
        } else if (name === "v_interests") {
            let v_interests =
                personInput.v_interests.length > 0
                    ? personInput.v_interests.split(",")
                    : [];

            if (v_interests.find((curr) => curr === value)) {
                let str = v_interests
                    .filter((curr) => curr !== value)
                    .join(",");
                setPersonInput((prevState) => ({
                    ...prevState,
                    v_interests: str,
                }));
            } else {
                v_interests.push(value);
                let str = v_interests.length > 0 ? v_interests.join(",") : "";
                setPersonInput((prevState) => ({
                    ...prevState,
                    v_interests: str,
                }));
            }
        } else {
            setPersonInput((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
        if (
            name === "v_svt" &&
            value === "No" &&
            personInput.practice_id !== 2
        ) {
            setPersonInput((prev) => ({
                ...prev,
                v_svt_request: "Phone",
                v_svt_request_date: "",
                v_svt_abc_name: "",
                v_svt_abc_answer: "",
                v_svte: "No",
                v_svtl: "No",
            }));
        }
        if (
            name === "v_svnt" &&
            value === "No" &&
            personInput.practice_id !== 2
        ) {
            setPersonInput((prev) => ({
                ...prev,
                v_svnt_request: "Phone",
                v_svnt_request_date: "",
                v_svnt_abc_name: "",
                v_svnt_abc_answer: "",
            }));
        }
        if (
            name === "v_svbj" &&
            value === "No" &&
            personInput.practice_id !== 2
        ) {
            setPersonInput((prev) => ({
                ...prev,
                v_svbj_request: "Phone",
                v_svbj_request_date: "",
                v_svbj_abc_name: "",
                v_svbj_abc_answer: "",
            }));
        }

        // Digital Editions
        if (
            name === "v_svt_digi" &&
            value === "No" &&
            personInput.practice_id !== 2
        ) {
            setPersonInput((prev) => ({
                ...prev,
                v_svt_digi_request: "Phone",
                v_svt_digi_request_date: "",
                v_svt_digi_abc_name: "",
                v_svt_digi_abc_answer: "",
                v_svte_digi: "No",
                v_svtl_digi: "No",
            }));
        }
        if (
            name === "v_svnt_digi" &&
            value === "No" &&
            personInput.practice_id !== 2
        ) {
            setPersonInput((prev) => ({
                ...prev,
                v_svnt_digi_request: "Phone",
                v_svnt_digi_request_date: "",
                v_svnt_digi_abc_name: "",
                v_svnt_digi_abc_answer: "",
            }));
        }
        if (
            name === "v_svbj_digi" &&
            value === "No" &&
            personInput.practice_id !== 2
        ) {
            setPersonInput((prev) => ({
                ...prev,
                v_svbj_digi_request: "Phone",
                v_svbj_digi_request_date: "",
                v_svbj_digi_abc_name: "",
                v_svbj_digi_abc_answer: "",
            }));
        }
    };

    const handlePersonSubmit = (e, text) => {
        e.preventDefault();

        let data = [];

        selectedSalaryBand
            ? selectedSalaryBand.map((row) => {
                  data.push(row.value);
              })
            : (selectedSalaryBand = []);
        let location = [];
        selectedLocation
            ? selectedLocation.map((row) => {
                  location.push(row.value);
              })
            : (selectedLocation = []);

        let pm_location = [];

        selectedPmLocation
            ? selectedPmLocation.map((row) => {
                  pm_location.push(row.value);
              })
            : (selectedPmLocation = []);

        let pm_location_outside = [];

        selectedPmOutside
            ? selectedPmOutside.map((row) => {
                  pm_location_outside.push(row.value);
              })
            : (selectedPmOutside = []);

        let equineData = [];
        seletedEquineExperience
            ? seletedEquineExperience.map((row) => {
                  equineData.push(row.value);
              })
            : (seletedEquineExperience = []);
        let positionData = [];
        // positionType ? positionType.map((row) => {
        //     positionData.push(row.value)
        // }) : positionType = []
        let spData = [];
        speciesType
            ? speciesType.map((row) => {
                  spData.push(row.value);
              })
            : (speciesType = []);
        let eventsReg = [];
        selectedEventsRegistration
            ? selectedEventsRegistration.map((row) => {
                  eventsReg.push(row.value);
              })
            : (selectedEventsRegistration = []);
        let eventsAtt = [];
        selectedEventsAttended
            ? selectedEventsAttended.map((row) => {
                  eventsAtt.push(row.value);
              })
            : (selectedEventsAttended = []);
        let eventsCongresses = [];
        selectedEventsCongresses
            ? selectedEventsCongresses.map((row) => {
                  eventsCongresses.push(row.value);
              })
            : (selectedEventsCongresses = []);
        personInput = {
            ...personInput,
            sm_salary_band: data.join(","),
            areas: location.join(","),
            pm_areas: pm_location.join(","),
            pm_outside_areas: pm_location_outside.join(","),
            main_experience: equineData.join(","),
            position: positionData.join(","),
            species_type: spData.join(","),
            v_registration: eventsReg.join(","),
            v_attended: eventsAtt.join(","),
            v_congresses: eventsCongresses.join(","),
            residence: selectedCountryResidence.value,
        };

        if (
            personInput.v_svt === "Yes" &&
            personInput.v_svt_request.length === 0
        ) {
            personInput.v_svt_request = "Phone";
        }
        if (
            personInput.v_svnt === "Yes" &&
            personInput.v_svnt_request.length === 0
        ) {
            personInput.v_svnt_request = "Phone";
        }
        if (
            personInput.v_svbj === "Yes" &&
            personInput.v_svbj_request.length === 0
        ) {
            personInput.v_svbj_request = "Phone";
        }

        personInput.initials = document.getElementById("initials-id").value;
        if (
            (personGet
                ? personGet.data.v_email != null
                : lastSearchResult.data.vet.v_email != null) &&
            personInput.v_email == "" &&
            (personInput.v_sevbd !== "No" ||
                personInput.v_vnews_d !== "No" ||
                personInput.v_vjnw !== "No" ||
                personInput.v_vt_jobs_marketing !== "No" ||
                personInput.v_s3rd !== "No" ||
                personInput.v_vnews !== "No" ||
                personInput.v_vjnm !== "No" ||
                personInput.vn_monthly_newsletter !== "No")
        ) {
            // setPersonInput(prevState => ({
            //     ...prevState,
            //     v_sevbd: "No",
            //     v_vnews_d: "No",
            //     v_vjnw: "No",
            //     v_vt_jobs_marketing: "No",
            //     v_s3rd: "No",
            //     v_vnews: "No",
            //     v_vjnm: "No",
            //     vn_monthly_newsletter: "No"
            // }))

            let temp = personInput;
            temp.v_sevbd = "No";
            temp.v_vnews_d = "No";
            temp.v_vjnw = "No";
            temp.v_vt_jobs_marketing = "No";
            temp.v_s3rd = "No";
            temp.v_vnews = "No";
            temp.v_vjnm = "No";
            temp.vn_monthly_newsletter = "No";
            temp.v_email_opt_in = "No";

            let error = {};

            error = suffix_update_save(personInput);

            setError(error);
            for (var key in personInput) {
                if (personInput[key] === "") {
                    personInput[key] = null;
                }
            }
            personInput.id
                ? dispatch(updatePerson(personInput.id, temp, null, text))
                : dispatch(
                      updatePerson(lastSearchResult.data.vet.id, temp, text)
                  );
            //swal("As there is no email address, all emails subscriptions have been set to No");
        } else {
            // if(personInput.v_email == ""){
            //     personInput.v_email_opt_in = "No"
            // }
            let error = {};
            error = suffix_update_save(personInput);

            setError(error);
            if (Object.entries(error).length === 0) {
                for (var key in personInput) {
                    if (personInput[key] === "") {
                        personInput[key] = null;
                    }
                }
                personInput.id
                    ? dispatch(
                          updatePerson(personInput.id, personInput, null, text)
                      )
                    : dispatch(
                          updatePerson(
                              lastSearchResult.data.vet.id,
                              personInput,
                              null,
                              text
                          )
                      );
            } else {
                swAlert(
                    "Please Enter the Qualifications/Suffix value",
                    "error"
                );
            }
        }
    };

    const handleDuplicateDelete = (id) => {
        Swal.fire({
            title: "Are you sure to perform this action?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
        }).then((result) => {
            if (result.isConfirmed) {
                let payload = { id: id, vet_id: personInput.id };
                Axios.post(`/delete/user/from/sso`, payload).then((res) => {
                    if (res.status === 200) {
                        swAlert(res.message);
                        dispatch(lastSearchedResult());
                    }
                });
            }
        });
    };

    const handleAuditChange = (e) => {
        const { name, value } = e.target;
        set_request_type((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    //For closing the Audit Modal by clicking outside
    const ref = useOnclickOutside(() => {
        setOpenMenu(false);
        setUniBar(false);
        setPostCodeMenuShow(false);
        setAuditError([]);
    });

    //This the Modal for On Clicking Actions dropdown button
    const showModal = (event, action) => {
        let payload = {
            action: action,
        };
        let locumerror = moveToLocumValidation(personInput);
        setError(locumerror);
        if (action === "locum")
            setPersonInput((prev) => ({ ...prev, v_pma: "Home" }));

        if (action === "locum" && Object.keys(locumerror).length > 0) {
            swAlert("Enter Full home address", "error");
        } else {
            Swal.fire({
                title: "Are you sure to perform this action?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.isConfirmed) {
                    Axios.post(`/vet/move/practice/${personInput.id}`, payload)
                        .then((res) => {
                            Swal.fire({
                                position: "center",
                                icon: "success",
                                title: `${res.data.message}`,
                                showConfirmButton: false,
                                timer: 1500,
                            }).then(() => {
                                dispatch(lastSearchedResult());
                                props.resetState();
                            });
                        })
                        .catch((err) => {
                            Swal.fire({
                                position: "center",
                                icon: "error",
                                title: `${err.response.data.message}`,
                                showConfirmButton: false,
                                timer: 3000,
                            }).then(() => {
                                props.resetState();
                            });
                        });
                }
            });
        }
    };

    //This is the Modal for Save with Audit
    const showAuditModal = (e) => {
        swal("Do you want to update request date(s)?", {
            buttons: {
                notSave: {
                    text: "Save without Audits",
                    className: "theme-btn-secondary min-w-175 my-2",
                    value: "Save withOut Audit",
                },
                save: {
                    text: "Save with Audits",
                    className: "theme-btn-outline-secondary min-w-175 my-2",
                    value: "save with Audits",
                },
            },
        }).then((value) => {
            let errorMesg = "";
            switch (value) {
                case "save with Audits":
                    setSlider(true);
                    setOpenMenu(!openMenu);
                    set_request_type({
                        request_type: "",
                        request_date: "",
                        abcName: "",
                        abcAnswer: "",
                    });

                    break;
                case "Save withOut Audit":
                    let error = save_without_audit(personInput);
                    setError(error);
                    if (Object.entries(error).length > 0) {
                        Swal.fire({
                            position: "center",
                            icon: "error",
                            title: error.text,
                            showConfirmButton: false,
                            timer: 3000,
                        });
                    } else {
                        handlePersonSubmit(e, "Save without Audits");
                        props.practiceMessageFunc(false);
                        props.resetState();
                    }
                    break;
            }
            resetPostcodeLookUpField();
        });
    };

    const resetPostcodeLookUpField = () => {
        setPostcode("");
    };

    //multiselect
    const onSelect = (selectedList, selectedItem) => {};
    const onRemove = (selectedList, removedItem) => {};

    const formatString = (item) => {
        var desired = item.replace(/&#13;|&#10;|&nbsp|;/gi, "");
        return desired;
    };

    const handleAuditSubscription = (e) => {
        e.preventDefault();
        let auditValidation = saveWithAuditValidation();

        if (personInput.v_svt === "Yes") {
            personInput.v_svt_request = s_request_type.request_type;
            personInput.v_svt_request_date = s_request_type.request_date;
            personInput.v_svt_abc_name = s_request_type.abcName;
            personInput.v_svt_abc_answer = s_request_type.abcAnswer;
        } else {
            personInput.v_svt_request = "Phone";
            personInput.v_svt_request_date = "";
            personInput.v_svt_abc_name = "";
            personInput.v_svt_abc_answer = "";
        }
        if (personInput.v_svnt === "Yes") {
            personInput.v_svnt_request = s_request_type.request_type;
            personInput.v_svnt_request_date = s_request_type.request_date;
            personInput.v_svnt_abc_name = s_request_type.abcName;
            personInput.v_svnt_abc_answer = s_request_type.abcAnswer;
        } else {
            personInput.v_svnt_request = "Phone";
            personInput.v_svnt_request_date = "";
            personInput.v_svnt_abc_name = "";
            personInput.v_svnt_abc_answer = "";
        }
        if (personInput.v_svbj === "Yes") {
            personInput.v_svbj_request = s_request_type.request_type;
            personInput.v_svbj_request_date = s_request_type.request_date;
            personInput.v_svbj_abc_name = s_request_type.abcName;
            personInput.v_svbj_abc_answer = s_request_type.abcAnswer;
        } else {
            personInput.v_svbj_request = "Phone";
            personInput.v_svbj_request_date = "";
            personInput.v_svbj_abc_name = "";
            personInput.v_svbj_abc_answer = "";
        }

        let error = save_with_audit(personInput);
        if (Object.keys(error).length > 0 || auditValidation.length > 0) {
            Swal.fire({
                position: "center",
                icon: "error",
                title: error.text,
                showConfirmButton: false,
                timer: 2000,
            });
            setAuditError(auditValidation);
        } else {
            handlePersonSubmit(e, "Save with Audits");
            props.practiceMessageFunc(false);
            props.resetState(personInput);
            setSlider(false);
        }
    };

    const handleAuditSubscriptionDigi = (e) => {
        e.preventDefault();
        let auditValidation = saveWithAuditValidation();

        if (personInput.v_svt_digi === "Yes") {
            personInput.v_svt_digi_request = s_request_type.request_type;
            personInput.v_svt_digi_request_date = s_request_type.request_date;
            personInput.v_svt_digi_abc_name = s_request_type.abcName;
            personInput.v_svt_digi_abc_answer = s_request_type.abcAnswer;
        } else {
            personInput.v_svt_digi_request = "Phone";
            personInput.v_svt_digi_request_date = "";
            personInput.v_svt_digi_abc_name = "";
            personInput.v_svt_digi_abc_answer = "";
        }
        if (personInput.v_svnt_digi === "Yes") {
            personInput.v_svnt_digi_request = s_request_type.request_type;
            personInput.v_svnt_digi_request_date = s_request_type.request_date;
            personInput.v_svnt_digi_abc_name = s_request_type.abcName;
            personInput.v_svnt_digi_abc_answer = s_request_type.abcAnswer;
        } else {
            personInput.v_svnt_digi_request = "Phone";
            personInput.v_svnt_digi_request_date = "";
            personInput.v_svnt_digi_abc_name = "";
            personInput.v_svnt_digi_abc_answer = "";
        }
        if (personInput.v_svbj_digi === "Yes") {
            personInput.v_svbj_digi_request = s_request_type.request_type;
            personInput.v_svbj_digi_request_date = s_request_type.request_date;
            personInput.v_svbj_digi_abc_name = s_request_type.abcName;
            personInput.v_svbj_digi_abc_answer = s_request_type.abcAnswer;
        } else {
            personInput.v_svbj_digi_request = "Phone";
            personInput.v_svbj_digi_request_date = "";
            personInput.v_svbj_digi_abc_name = "";
            personInput.v_svbj_digi_abc_answer = "";
        }

        let error = save_with_audit(personInput);
        if (Object.keys(error).length > 0 || auditValidation.length > 0) {
            Swal.fire({
                position: "center",
                icon: "error",
                title: error.text,
                showConfirmButton: false,
                timer: 2000,
            });
            setAuditError(auditValidation);
        } else {
            handlePersonSubmit(e, "Save with Audits");
            props.practiceMessageFunc(false);
            props.resetState(personInput);
            setSlider(false);
        }
    };

    const handleAuditSubscriptionOnline = (e) => {
        let auditValidation = saveWithAuditValidation();
        e.preventDefault();
        if (personInput.v_sevbd === "Yes") {
            personInput.v_sevbd_request = s_request_type.request_type;
            personInput.v_sevbd_request_date = s_request_type.request_date;
        }
        if (personInput.v_vnews_d === "Yes") {
            personInput.v_vnews_d_request = s_request_type.request_type;
            personInput.v_vnews_d_request_date = s_request_type.request_date;
        }
        if (personInput.v_vjnw === "Yes") {
            personInput.v_vjnw_request = s_request_type.request_type;
            personInput.v_vjnw_request_date = s_request_type.request_date;
        }
        if (personInput.v_vt_jobs_marketing === "Yes") {
            personInput.v_vt_jobs_marketing_request =
                s_request_type.request_type;
            personInput.v_vt_jobs_marketing_request_date =
                s_request_type.request_date;
        }
        if (personInput.v_s3rd === "Yes") {
            personInput.v_s3rd_request = s_request_type.request_type;
            personInput.v_s3rd_request_date = s_request_type.request_date;
        }
        if (personInput.v_vnews === "Yes") {
            personInput.v_vnews_request = s_request_type.request_type;
            personInput.v_vnews_request_date = s_request_type.request_date;
        }
        if (personInput.v_vjnm === "Yes") {
            personInput.v_vjnm_request = s_request_type.request_type;
            personInput.v_vjnm_request_date = s_request_type.request_date;
        }
        if (personInput.vn_monthly_newsletter === "Yes") {
            personInput.vn_monthly_newsletter_request =
                s_request_type.request_type;
            personInput.vn_monthly_newsletter_request_date =
                s_request_type.request_date;
        }

        let error = save_with_audit(personInput);
        setError(error);
        if (Object.keys(error).length > 0 || auditValidation.length > 0) {
            Swal.fire({
                position: "center",
                icon: "error",
                title: error.text,
                showConfirmButton: false,
                timer: 2000,
            });
            setAuditError(auditValidation);
        } else {
            setPersonInput({ ...personInput });
            handlePersonSubmit(e, "Save with Audits");
            props.practiceMessageFunc(false);
            props.resetState(personInput);
            setSlider(false);
        }
    };
    const handleAuditSubscriptionAll = (e) => {
        e.preventDefault();
        let auditValidation = saveWithAuditValidation();

        if (personInput.v_sevbd === "Yes") {
            personInput.v_sevbd_request = s_request_type.request_type;
            personInput.v_sevbd_request_date = s_request_type.request_date;
        }
        if (personInput.v_vnews_d === "Yes") {
            personInput.v_vnews_d_request = s_request_type.request_type;
            personInput.v_vnews_d_request_date = s_request_type.request_date;
        }
        if (personInput.v_vjnw === "Yes") {
            personInput.v_vjnw_request = s_request_type.request_type;
            personInput.v_vjnw_request_date = s_request_type.request_date;
        }
        if (personInput.v_vt_jobs_marketing === "Yes") {
            personInput.v_vt_jobs_marketing_request =
                s_request_type.request_type;
            personInput.v_vt_jobs_marketing_request_date =
                s_request_type.request_date;
        }
        if (personInput.v_s3rd === "Yes") {
            personInput.v_s3rd_request = s_request_type.request_type;
            personInput.v_s3rd_request_date = s_request_type.request_date;
        }
        if (personInput.v_vnews === "Yes") {
            personInput.v_vnews_request = s_request_type.request_type;
            personInput.v_vnews_request_date = s_request_type.request_date;
        }
        if (personInput.v_vjnm === "Yes") {
            personInput.v_vjnm_request = s_request_type.request_type;
            personInput.v_vjnm_request_date = s_request_type.request_date;
        }
        if (personInput.vn_monthly_newsletter === "Yes") {
            personInput.vn_monthly_newsletter_request =
                s_request_type.request_type;
            personInput.vn_monthly_newsletter_request_date =
                s_request_type.request_date;
        }
        if (personInput.v_svt === "Yes") {
            personInput.v_svt_request = s_request_type.request_type;
            personInput.v_svt_request_date = s_request_type.request_date;
            personInput.v_svt_abc_name = s_request_type.abcName;
            personInput.v_svt_abc_answer = s_request_type.abcAnswer;
        } else {
            personInput.v_svt_request = "Phone";
            personInput.v_svt_request_date = "";
            personInput.v_svt_abc_name = "";
            personInput.v_svt_abc_answer = "";
        }
        if (personInput.v_svnt === "Yes") {
            personInput.v_svnt_request = s_request_type.request_type;
            personInput.v_svnt_request_date = s_request_type.request_date;
            personInput.v_svnt_abc_name = s_request_type.abcName;
            personInput.v_svnt_abc_answer = s_request_type.abcAnswer;
        } else {
            personInput.v_svnt_request = "Phone";
            personInput.v_svnt_request_date = "";
            personInput.v_svnt_abc_name = "";
            personInput.v_svnt_abc_answer = "";
        }
        if (personInput.v_svbj === "Yes") {
            personInput.v_svbj_request = s_request_type.request_type;
            personInput.v_svbj_request_date = s_request_type.request_date;
            personInput.v_svbj_abc_name = s_request_type.abcName;
            personInput.v_svbj_abc_answer = s_request_type.abcAnswer;
        } else {
            personInput.v_svbj_request = "Phone";
            personInput.v_svbj_request_date = "";
            personInput.v_svbj_abc_name = "";
            personInput.v_svbj_abc_answer = "";
        }
        if (personInput.v_svt_digi === "Yes") {
            personInput.v_svt_digi_request = s_request_type.request_type;
            personInput.v_svt_digi_request_date = s_request_type.request_date;
            personInput.v_svt_digi_abc_name = s_request_type.abcName;
            personInput.v_svt_digi_abc_answer = s_request_type.abcAnswer;
        } else {
            personInput.v_svt_digi_request = "Phone";
            personInput.v_svt_digi_request_date = "";
            personInput.v_svt_digi_abc_name = "";
            personInput.v_svt_digi_abc_answer = "";
        }
        if (personInput.v_svnt_digi === "Yes") {
            personInput.v_svnt_digi_request = s_request_type.request_type;
            personInput.v_svnt_digi_request_date = s_request_type.request_date;
            personInput.v_svnt_digi_abc_name = s_request_type.abcName;
            personInput.v_svnt_digi_abc_answer = s_request_type.abcAnswer;
        } else {
            personInput.v_svnt_digi_request = "Phone";
            personInput.v_svnt_digi_request_date = "";
            personInput.v_svnt_digi_abc_name = "";
            personInput.v_svnt_digi_abc_answer = "";
        }
        if (personInput.v_svbj_digi === "Yes") {
            personInput.v_svbj_digi_request = s_request_type.request_type;
            personInput.v_svbj_digi_request_date = s_request_type.request_date;
            personInput.v_svbj_digi_abc_name = s_request_type.abcName;
            personInput.v_svbj_digi_abc_answer = s_request_type.abcAnswer;
        } else {
            personInput.v_svbj_digi_request = "Phone";
            personInput.v_svbj_digi_request_date = "";
            personInput.v_svbj_digi_abc_name = "";
            personInput.v_svbj_digi_abc_answer = "";
        }
        let error = save_with_audit(personInput);
        if (Object.keys(error).length > 0 || auditValidation.length > 0) {
            if (typeof error.text == "undefined") {
                error.text = "Fill all required fields.";
            }
            Swal.fire({
                position: "center",
                icon: "error",
                title: error.text,
                showConfirmButton: false,
                timer: 2000,
            });
            setAuditError(auditValidation);
        } else {
            handlePersonSubmit(e, "Save with Audits");
            props.practiceMessageFunc(false);
            props.resetState(personInput);
            setSlider(false);
        }
    };

    const messageOnClick = () => {
        if (
            localStorage.getItem("user_role") != 5 &&
            props.userMe_role != 5 &&
            props.showPersonMessage
        ) {
            Swal.fire({
                title: "Message",
                text: "Changes has been made on practice window. Dont forget to save changes if needed.",
                confirmButtonText: "Understood",
            });
            props.showPersonMsgFunc(false);
        }
    };

    /* const handlePlaceholder = (text) => {
        let updated_text = (personInput.v_notes.length === 0) ? '{{' + text + '}}' : personInput.v_notes.trim() + ' {{' + text + '}}';
        setPersonInput({ v_notes: updated_text })
    } */

    const handleUpdateNotes = () => {
        confirmationAlert("Do you want to update notes?", "question").then(
            async (result) => {
                if (result.isConfirmed) {
                    let payload = {
                        practice_id: personInput.practice_id,
                        v_notes: personInput.v_notes,
                    };
                    dispatch(
                        updatePersonByID(
                            personInput.id,
                            payload,
                            "Succesfully updated"
                        )
                    );
                }
            }
        );
    };

    const saveWithAuditValidation = () => {
        let error = [];
        if (s_request_type.request_type.length === 0) {
            s_request_type.request_type = "Phone";
        }
        //console.log(s_request_type.request_type)
        if (
            s_request_type.request_type !== "Written" &&
            s_request_type.request_type !== "C Written"
        ) {
            if (s_request_type.request_date.length === 0) {
                error.push({ name: "request_date", error: "Required" });
            }
            if (s_request_type.abcAnswer.length === 0) {
                error.push({ name: "abcAnswer", error: "Required" });
            }
            if (s_request_type.abcName.length === 0) {
                error.push({ name: "abcName", error: "Required" });
            }
        }
        return error;
    };

    return (
        <div className="PersonModule">
            {openMenu && (
                <div ref={ref}>
                    <Collapse in={slider == true}>
                        <div id="addPractice" onClick={messageOnClick}>
                            <Container>
                                <h3 className="my-3">Update audit dates</h3>
                                <Form>
                                    <Form.Group>
                                        <Form.Label>
                                            Select request type
                                        </Form.Label>
                                        <Form.Control
                                            name="request_type"
                                            as="select"
                                            className="form-select"
                                            value={s_request_type.request_type}
                                            onChange={handleAuditChange}
                                        >
                                            <option value="Phone">Phone</option>
                                            <option value="Written">
                                                Written
                                            </option>
                                            <option value="Electronic">
                                                Electronic
                                            </option>
                                            <option value="Website">
                                                Website
                                            </option>
                                            <option value="C Phone">
                                                C Phone
                                            </option>
                                            <option value="C Written">
                                                C Written
                                            </option>
                                            <option value="C Electronic">
                                                C Electronic
                                            </option>
                                            <option value="C Website">
                                                C Website
                                            </option>
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Request date</Form.Label>
                                        <Form.Control
                                            className={
                                                !s_request_type.request_date &&
                                                auditError.find(
                                                    (curr) =>
                                                        curr.name ===
                                                        "request_date"
                                                )
                                                    ? "invalid date-icon"
                                                    : "date-icon"
                                            }
                                            type="date"
                                            name="request_date"
                                            value={s_request_type.request_date}
                                            max="2999-12-31"
                                            onChange={handleAuditChange}
                                        />
                                    </Form.Group>

                                    {s_request_type.request_type !==
                                        "Written" &&
                                        s_request_type.request_type !==
                                            "C Written" && (
                                            <>
                                                <Form.Group>
                                                    <Form.Label>
                                                        ABC Name
                                                    </Form.Label>
                                                    <Form.Control
                                                        className={
                                                            !s_request_type.abcName &&
                                                            auditError.find(
                                                                (curr) =>
                                                                    curr.name ===
                                                                    "abcName"
                                                            ) &&
                                                            "invalid"
                                                        }
                                                        type="text"
                                                        name="abcName"
                                                        value={
                                                            s_request_type.abcName
                                                        }
                                                        onChange={
                                                            handleAuditChange
                                                        }
                                                    />
                                                </Form.Group>

                                                <Form.Group>
                                                    <Form.Label>
                                                        ABC Answer
                                                    </Form.Label>
                                                    <Form.Control
                                                        className={
                                                            !s_request_type.abcAnswer &&
                                                            auditError.find(
                                                                (curr) =>
                                                                    curr.name ===
                                                                    "abcAnswer"
                                                            ) &&
                                                            "invalid"
                                                        }
                                                        type="text"
                                                        name="abcAnswer"
                                                        value={
                                                            s_request_type.abcAnswer
                                                        }
                                                        onChange={
                                                            handleAuditChange
                                                        }
                                                    />
                                                </Form.Group>
                                            </>
                                        )}

                                    <Form.Group className="my-3">
                                        <div className="d-block mb-3">
                                            <Button
                                                className="btn theme-btn-outline-primary w-100 me-1 mb-2"
                                                type="submit"
                                                onClick={
                                                    handleAuditSubscriptionOnline
                                                }
                                            >
                                                Update Online
                                            </Button>
                                            <Button
                                                className="btn theme-btn-outline-primary w-100 ms-1 mb-2"
                                                type="submit"
                                                onClick={
                                                    handleAuditSubscriptionDigi
                                                }
                                            >
                                                Update Digital editions
                                            </Button>
                                            <Button
                                                className="btn theme-btn-outline-primary w-100 ms-1 mb-2"
                                                type="submit"
                                                onClick={
                                                    handleAuditSubscription
                                                }
                                            >
                                                Update Pubs
                                            </Button>
                                        </div>
                                        <Button
                                            className="theme-btn-secondary w-100"
                                            type="submit"
                                            onClick={handleAuditSubscriptionAll}
                                        >
                                            Update All
                                        </Button>
                                    </Form.Group>
                                </Form>
                            </Container>
                        </div>
                    </Collapse>
                </div>
            )}

            <Card
                className={
                    personInput.practice_data.office &&
                    personInput.practice_data.office.split(" ")[0] ===
                        "Industry"
                        ? "custom-card person-card indus-person"
                        : "custom-card person-card"
                }
                onClick={messageOnClick}
            >
                <Card.Header className="d-flex justify-content-between align-items-center min-h-50">
                    {personGet ? (
                        <span className="card-header-title">
                            {personInput.id} | {personInput.forename}{" "}
                            {personInput.surname} | {personInput.updatedby} |{" "}
                            {dayjs(personInput.date).format("DD/MM/YYYY")} |{" "}
                            {dayjs(personInput.record_created).format(
                                "DD/MM/YYYY"
                            )}
                        </span>
                    ) : lastSearchResult ? (
                        <span className="card-header-title">
                            {personInput.id} | {personInput.forename}{" "}
                            {personInput.surname} | {personInput.updatedby} |{" "}
                            {dayjs(personInput.date).format("DD/MM/YYYY")} |{" "}
                            {dayjs(personInput.record_created).format(
                                "DD/MM/YYYY"
                            )}
                        </span>
                    ) : (
                        ""
                    )}
                    {(props.userMe_role == 1 ||
                        props.userMe_role == 2 ||
                        props.userMe_role == 3) && (
                        <Button
                            size="sm"
                            className="theme-btn-white"
                            onClick={(e) => {
                                showAuditModal(e);
                            }}
                        >
                            Save Changes
                            <BeatLoader
                                color="#ffffff"
                                loading={loadingUpdatePerson === true}
                                css=""
                                size={5}
                            />
                        </Button>
                    )}
                </Card.Header>
                <Card.Body className="pt-0">
                    {(props.userMe_role == 1 ||
                        props.userMe_role == 2 ||
                        props.userMe_role == 3) && (
                        <Row className="light-grey">
                            <Col lg={12}>
                                <Row className="py-2 justify-content-between align-items-center gx-2">
                                    <Col lg={6}>
                                        <Row className="align-items-center">
                                            <Col lg={8}>
                                                <div className="find-address practice-find-address">
                                                    <span
                                                        className="address-icon"
                                                        onClick={
                                                            handlePostcodeSubmit
                                                        }
                                                    >
                                                        <Image
                                                            src={addressIcon}
                                                        />
                                                        search
                                                    </span>
                                                    <Form.Control
                                                        name="postcode"
                                                        type="text"
                                                        className="mb-0"
                                                        size="sm"
                                                        value={postcode}
                                                        placeholder="Enter your Postcode"
                                                        autoComplete="nope"
                                                        onChange={
                                                            handlePostcodeChange
                                                        }
                                                    />
                                                    {postcodeMenuShow &&
                                                        data.hasOwnProperty(
                                                            "arr"
                                                        ) &&
                                                        Array.from(data.arr)
                                                            .length > 0 && (
                                                            <div
                                                                ref={ref}
                                                                className="address-dropdown"
                                                            >
                                                                <PerfectScrollbar>
                                                                    {Array.from(
                                                                        data.arr
                                                                    ).map(
                                                                        (
                                                                            curr,
                                                                            index
                                                                        ) => (
                                                                            <p
                                                                                key={
                                                                                    index
                                                                                }
                                                                                onClick={() => {
                                                                                    handleSetAddress(
                                                                                        curr
                                                                                    );
                                                                                }}
                                                                            >
                                                                                {
                                                                                    curr.display
                                                                                }
                                                                            </p>
                                                                        )
                                                                    )}
                                                                </PerfectScrollbar>
                                                            </div>
                                                        )}
                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <Form.Label className="me-3">
                                                        PMA
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="v_pma"
                                                        type="text"
                                                        as="select"
                                                        className="form-select max-w-110  mb-0"
                                                        value={
                                                            personInput.v_pma
                                                        }
                                                        onChange={
                                                            handlePersonChange
                                                        }
                                                    >
                                                        <option value="Work">
                                                            Work
                                                        </option>
                                                        <option value="Home">
                                                            Home
                                                        </option>
                                                    </Form.Control>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="d-flex justify-content-end">
                                            <div className="">
                                                <button
                                                    type="button"
                                                    className={`theme-btn-white btn btn-primary me-2 ${
                                                        personInput.practice_id ==
                                                        1
                                                            ? "burgundy-color"
                                                            : ""
                                                    }`}
                                                    onClick={(event) =>
                                                        showModal(
                                                            event,
                                                            "locum"
                                                        )
                                                    }
                                                >
                                                    Locum
                                                </button>
                                                <button
                                                    type="button"
                                                    className={`theme-btn-white btn btn-primary me-2 ${
                                                        personInput.practice_id ==
                                                        3
                                                            ? "burgundy-color"
                                                            : ""
                                                    }`}
                                                    onClick={(event) =>
                                                        showModal(
                                                            event,
                                                            "retired"
                                                        )
                                                    }
                                                >
                                                    Retired
                                                </button>
                                                <button
                                                    type="button"
                                                    className={`theme-btn-white btn btn-primary me-2 ${
                                                        personInput.practice_id ==
                                                        2
                                                            ? "burgundy-color"
                                                            : ""
                                                    }`}
                                                    onClick={(event) =>
                                                        showModal(
                                                            event,
                                                            "pigeon"
                                                        )
                                                    }
                                                >
                                                    Pigeonhole
                                                </button>
                                                <button
                                                    type="button"
                                                    className="theme-btn-white btn btn-primary"
                                                    onClick={(event) =>
                                                        showModal(
                                                            event,
                                                            "delete"
                                                        )
                                                    }
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    )}
                    <Row className="">
                        <div className="alice-blue-bg pt-3 pb-2">
                            <Collapse in={open}>
                                <Form>
                                    {personInput.practice_data.office &&
                                    personInput.practice_data.office.split(
                                        " "
                                    )[0] !== "Industry" ? (
                                        <Row className="align-items gx-2">
                                            <Col lg={3}>
                                                <Form.Label>
                                                    Address line 1
                                                </Form.Label>
                                                <Form.Control
                                                    name="v_address_1"
                                                    type="text"
                                                    value={
                                                        personInput.v_address_1
                                                    }
                                                    onChange={
                                                        handlePersonChange
                                                    }
                                                    className={
                                                        !personInput.v_address_1 &&
                                                        error.address
                                                            ? "invalid"
                                                            : ""
                                                    }
                                                />

                                                <Form.Label>
                                                    Address line 2
                                                </Form.Label>
                                                <Form.Control
                                                    name="v_address_2"
                                                    type="text"
                                                    value={
                                                        personInput.v_address_2
                                                    }
                                                    onChange={
                                                        handlePersonChange
                                                    }
                                                />

                                                <Form.Label>
                                                    Address line 3
                                                </Form.Label>
                                                <Form.Control
                                                    name="v_address_3"
                                                    type="text"
                                                    value={
                                                        personInput.v_address_3
                                                    }
                                                    onChange={
                                                        handlePersonChange
                                                    }
                                                />

                                                <Form.Label>
                                                    Town / City
                                                </Form.Label>
                                                <Form.Control
                                                    name="v_town"
                                                    type="text"
                                                    value={personInput.v_town.toUpperCase()}
                                                    onChange={
                                                        handlePersonChange
                                                    }
                                                    className={
                                                        !personInput.v_town &&
                                                        error.town
                                                            ? "invalid"
                                                            : ""
                                                    }
                                                />

                                                <Form.Label>County</Form.Label>
                                                <Form.Control
                                                    name="v_county"
                                                    type="text"
                                                    value={
                                                        personInput.v_county
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                        personInput.v_county.slice(
                                                            1
                                                        )
                                                    }
                                                    onChange={
                                                        handlePersonChange
                                                    }
                                                    className={
                                                        !personInput.v_county &&
                                                        error.county
                                                            ? "invalid"
                                                            : ""
                                                    }
                                                />

                                                <Form.Label>
                                                    Postcode
                                                </Form.Label>
                                                <Form.Control
                                                    name="v_postcode"
                                                    type="text"
                                                    value={personInput.v_postcode.toUpperCase()}
                                                    onChange={
                                                        handlePersonChange
                                                    }
                                                    className={
                                                        !personInput.v_postcode &&
                                                        error.postcode
                                                            ? "invalid"
                                                            : ""
                                                    }
                                                />

                                                <Form.Label>Country</Form.Label>
                                                <Form.Control
                                                    name="v_country"
                                                    type="text"
                                                    value={
                                                        personInput.v_country
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                        personInput.v_country.slice(
                                                            1
                                                        )
                                                    }
                                                    onChange={
                                                        handlePersonChange
                                                    }
                                                />
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Label>Title</Form.Label>
                                                <Form.Control
                                                    name="title"
                                                    type="text"
                                                    as="select"
                                                    className="form-select"
                                                    value={personInput.title}
                                                    onChange={
                                                        handlePersonChange
                                                    }
                                                >
                                                    <option value="Dr">
                                                        Dr
                                                    </option>
                                                    <option value="Mr">
                                                        Mr
                                                    </option>
                                                    <option value="Mrs">
                                                        Mrs
                                                    </option>
                                                    <option value="Ms">
                                                        Ms
                                                    </option>
                                                    <option value="Miss">
                                                        Miss
                                                    </option>
                                                    <option value="Mx">
                                                        Mx
                                                    </option>
                                                    <option value="Prof">
                                                        Prof
                                                    </option>
                                                    <option value="Major">
                                                        Major
                                                    </option>
                                                    <option value="Sir">
                                                        Sir
                                                    </option>
                                                    <option value="Brig">
                                                        Brig
                                                    </option>
                                                    <option value="Capt">
                                                        Capt
                                                    </option>
                                                    <option value="The">
                                                        The
                                                    </option>
                                                </Form.Control>
                                                <Form.Label>
                                                    Initials
                                                </Form.Label>
                                                <Form.Control
                                                    name="initials"
                                                    id="initials-id"
                                                    type="text"
                                                    value={personInput.initials}
                                                    onChange={
                                                        handlePersonChange
                                                    }
                                                    readOnly={true}
                                                />
                                                <Form.Label>
                                                    Forename
                                                </Form.Label>
                                                <Form.Control
                                                    name="forename"
                                                    type="text"
                                                    value={personInput.forename}
                                                    onChange={createInitials}
                                                />
                                                <Form.Label>
                                                    Middle names
                                                </Form.Label>
                                                <Form.Control
                                                    name="middlenames"
                                                    type="text"
                                                    value={
                                                        personInput.middlenames
                                                    }
                                                    onChange={createInitials}
                                                />
                                                <Form.Label>Surname</Form.Label>
                                                <Form.Control
                                                    name="surname"
                                                    type="text"
                                                    value={personInput.surname}
                                                    onChange={
                                                        handlePersonChange
                                                    }
                                                />
                                                <Form.Label>Maiden</Form.Label>
                                                <Form.Control
                                                    name="maiden"
                                                    type="text"
                                                    value={personInput.maiden}
                                                    onChange={
                                                        handlePersonChange
                                                    }
                                                />
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Label>Gender</Form.Label>
                                                <Form.Control
                                                    name="gender"
                                                    as="select"
                                                    className="form-select"
                                                    id="gender-select"
                                                    value={personInput.gender}
                                                    onChange={
                                                        handlePersonChange
                                                    }
                                                >
                                                    {[
                                                        "Dr",
                                                        "Mr",
                                                        "Prof",
                                                        "Major",
                                                        "Sir",
                                                        "Brig",
                                                        "Capt",
                                                        "The",
                                                    ].includes(
                                                        personInput.title
                                                    ) && (
                                                        <option value="Male">
                                                            Male
                                                        </option>
                                                    )}
                                                    {[
                                                        "Dr",
                                                        "Mrs",
                                                        "Miss",
                                                        "Ms",
                                                        "Prof",
                                                        "Major",
                                                        "Sir",
                                                        "Brig",
                                                        "Capt",
                                                        "The",
                                                    ].includes(
                                                        personInput.title
                                                    ) && (
                                                        <option value="Female">
                                                            Female
                                                        </option>
                                                    )}
                                                </Form.Control>
                                                <Form.Label>
                                                    Working hours
                                                </Form.Label>
                                                <Form.Control
                                                    name="working_hours"
                                                    type="text"
                                                    value={
                                                        personInput.working_hours
                                                    }
                                                    onChange={
                                                        handlePersonChange
                                                    }
                                                />
                                                {/* <Form.Label>PMA</Form.Label>
                                                <Form.Control
                                                    name="v_pma"
                                                    type="text"
                                                    as="select"
                                                    className="form-select"
                                                    value={personInput.v_pma}
                                                    onChange={handlePersonChange}
                                                >
                                                    <option value="Work">Work</option>
                                                    <option value="Home">Home</option>
                                                </Form.Control> */}

                                                <Form.Label>
                                                    Direct mail
                                                </Form.Label>
                                                <Form.Control
                                                    name="v_sdm"
                                                    type="text"
                                                    className="form-select"
                                                    as="select"
                                                    value={personInput.v_sdm}
                                                    onChange={
                                                        handlePersonChange
                                                    }
                                                >
                                                    {personInput.practice_data
                                                        .id !== 3 && (
                                                        <option value="Yes">
                                                            Yes
                                                        </option>
                                                    )}
                                                    <option value="No">
                                                        No
                                                    </option>
                                                </Form.Control>
                                                <Form.Label>Mob.</Form.Label>
                                                <InputMask
                                                    mask={[
                                                        "+",
                                                        "4",
                                                        "4",
                                                        /[1-9]/,
                                                        /[0-9]/,
                                                        /[0-9]/,
                                                        /[0-9]/,
                                                        " ",
                                                        /[0-9]/,
                                                        /[0-9]/,
                                                        /[0-9]/,
                                                        " ",
                                                        /[0-9]/,
                                                        /[0-9]/,
                                                        /[0-9]/,
                                                    ]}
                                                    value={personInput.v_tel}
                                                    name="v_tel"
                                                    className="form-control"
                                                    onChange={
                                                        handlePersonChange
                                                    }
                                                />
                                                <Form.Label>
                                                    Country residence
                                                </Form.Label>
                                                <Select
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    name="residence"
                                                    styles={selectStyle}
                                                    options={wp_countries}
                                                    value={
                                                        selectedCountryResidence
                                                    }
                                                    onChange={
                                                        handleCountryChange
                                                    }
                                                />
                                            </Col>

                                            <Col lg={3}>
                                                <Form.Label>
                                                    Primary email
                                                </Form.Label>
                                                <Form.Control
                                                    name="v_email"
                                                    type="text"
                                                    value={personInput.v_email}
                                                    onChange={
                                                        handlePersonChange
                                                    }
                                                />
                                                <Row>
                                                    <Col lg={3}>
                                                        <Button
                                                            className="btn btn-danger btn-sm"
                                                            id="button-addon2"
                                                            onClick={() => {
                                                                setPrimaryPma();
                                                            }}
                                                        >
                                                            Null
                                                        </Button>
                                                    </Col>
                                                    <Col lg={5}>
                                                        <Form.Group controlID="primary_personal">
                                                            <Form.Check
                                                                inline
                                                                id="primary_personal"
                                                                name="primary_pma"
                                                                type="radio"
                                                                label="Personal"
                                                                value={
                                                                    "Personal"
                                                                }
                                                                checked={
                                                                    personInput.primary_pma ==
                                                                    "Personal"
                                                                }
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <Form.Group controlID="primary_work">
                                                            <Form.Check
                                                                inline
                                                                id="primary_work"
                                                                name="primary_pma"
                                                                type="radio"
                                                                label="Work"
                                                                value={"Work"}
                                                                checked={
                                                                    personInput.primary_pma ==
                                                                    "Work"
                                                                }
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Form.Label>
                                                    Secondary email
                                                </Form.Label>
                                                <Form.Control
                                                    name="v_work_email"
                                                    type="text"
                                                    value={
                                                        personInput.v_work_email
                                                    }
                                                    onChange={
                                                        handlePersonChange
                                                    }
                                                />
                                                <Row>
                                                    <Col lg={3}>
                                                        <Button
                                                            className="btn btn-danger btn-sm"
                                                            id="button-addon2"
                                                            onClick={() => {
                                                                setSecondaryPma();
                                                            }}
                                                        >
                                                            Null
                                                        </Button>
                                                    </Col>
                                                    <Col lg={5}>
                                                        <Form.Group controlID="secondary_personal">
                                                            <Form.Check
                                                                inline
                                                                id="secondary_personal"
                                                                name="secondary_pma"
                                                                type="radio"
                                                                label="Personal"
                                                                value={
                                                                    "Personal"
                                                                }
                                                                checked={
                                                                    personInput.secondary_pma ==
                                                                    "Personal"
                                                                }
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <Form.Group controlID="secondary_work">
                                                            <Form.Check
                                                                inline
                                                                id="secondary_work"
                                                                name="secondary_pma"
                                                                type="radio"
                                                                label="Work"
                                                                value={"Work"}
                                                                checked={
                                                                    personInput.secondary_pma ==
                                                                    "Work"
                                                                }
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Form.Label>
                                                    SSO email
                                                </Form.Label>
                                                <InputGroup>
                                                    <FormControl
                                                        name="sso_email"
                                                        readOnly={
                                                            true
                                                        }
                                                        type="text"
                                                        value={
                                                            personInput.sso_email
                                                        }
                                                        // onChange={
                                                        //     handlePersonChange
                                                        // }
                                                        aria-describedby="basic-addon2"
                                                    />
                                                    {/*<Button*/}
                                                    {/*    className="btn btn-danger btn-sm"*/}
                                                    {/*    id="button-addon2"*/}
                                                    {/*    onClick={() => {*/}
                                                    {/*        setEnableSSOField(*/}
                                                    {/*            false*/}
                                                    {/*        );*/}
                                                    {/*    }}*/}
                                                    {/*    style={{*/}
                                                    {/*        marginTop: -6,*/}
                                                    {/*    }}*/}
                                                    {/*>*/}
                                                    {/*    Change*/}
                                                    {/*</Button>*/}
                                                </InputGroup>

                                                <Row>
                                                    <Col lg={3}>
                                                        <Button
                                                            className="btn btn-danger btn-sm"
                                                            id="button-addon2"
                                                            onClick={() => {
                                                                setUnlinkSSOEmailAdd();
                                                            }}
                                                        >
                                                            Unlink
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={6}>
                                                        <Form.Label>
                                                            SSO
                                                        </Form.Label>
                                                        <Form.Control
                                                            disabled={true}
                                                            name="v_sso"
                                                            as="select"
                                                            className="form-select mb-0"
                                                        >
                                                            <option
                                                                defaultValue
                                                                value={
                                                                    personInput.v_sso
                                                                }
                                                                style={{
                                                                    display:
                                                                        "none",
                                                                }}
                                                            >
                                                                {" "}
                                                                {personInput.v_sso ==
                                                                ""
                                                                    ? "No"
                                                                    : personInput.v_sso}{" "}
                                                            </option>

                                                            <option value="Yes">
                                                                Yes
                                                            </option>
                                                            <option value="No">
                                                                No
                                                            </option>
                                                            <option value="Duplicate">
                                                                Duplicate
                                                            </option>
                                                        </Form.Control>
                                                        <Form.Label>
                                                            Email opt in
                                                        </Form.Label>
                                                        <Form.Control
                                                            name="v_email_opt_in"
                                                            as="select"
                                                            className="form-select mb-0"
                                                            value={
                                                                personInput.v_email_opt_in
                                                            }
                                                            onChange={
                                                                handlePersonChange
                                                            }
                                                        >
                                                            <option value="Yes">
                                                                Yes
                                                            </option>
                                                            <option value="No">
                                                                No
                                                            </option>
                                                        </Form.Control>
                                                    </Col>
                                                    <Col lg={6}>
                                                        {/*<Form.Label>Skills match</Form.Label>*/}
                                                        {/*<Form.Control*/}
                                                        {/*    name="sm_profile"*/}
                                                        {/*    as="select"*/}
                                                        {/*    className="form-select"*/}
                                                        {/*    value={personInput.sm_profile}*/}
                                                        {/*    onChange={handlePersonChange}*/}
                                                        {/*>*/}
                                                        {/*    <option value="Completed">Completed</option>*/}
                                                        {/*    <option value="Snap">Snap</option>*/}
                                                        {/*    <option value="No">No</option>*/}
                                                        {/*</Form.Control>*/}

                                                        <Form.Label>
                                                            People Match
                                                        </Form.Label>
                                                        <Form.Control
                                                            name="pm_profile"
                                                            as="select"
                                                            className="form-select"
                                                            value={
                                                                personInput.pm_profile
                                                            }
                                                            onChange={
                                                                handlePersonChange
                                                            }
                                                        >
                                                            <option value="Yes">
                                                                Yes
                                                            </option>
                                                            <option value="No">
                                                                No
                                                            </option>
                                                        </Form.Control>
                                                        <Form.Label>
                                                            SMS
                                                        </Form.Label>
                                                        <Form.Control
                                                            onChange={
                                                                handlePersonChange
                                                            }
                                                            value={
                                                                personInput.v_sms
                                                            }
                                                            name="v_sms"
                                                            as="select"
                                                            className="form-select mb-0"
                                                        >
                                                            {/* <option defaultValue value={personInput.v_sms} style={{ display: 'none' }}> {personInput.v_sms == '' ? 'No' : personInput.v_sms} </option> */}

                                                            <option value="Yes">
                                                                Yes
                                                            </option>
                                                            <option value="No">
                                                                No
                                                            </option>
                                                        </Form.Control>
                                                    </Col>
                                                </Row>
                                                {duplicateIDs
                                                    ? duplicateIDs.map(
                                                          (val, i) => (
                                                              <>
                                                                  {" "}
                                                                  <Form.Label>
                                                                      Duplicate
                                                                      IDs
                                                                  </Form.Label>
                                                                  <InputGroup>
                                                                      <FormControl
                                                                          readOnly={
                                                                              true
                                                                          }
                                                                          type="text"
                                                                          value={
                                                                              "ID: " +
                                                                              val.id +
                                                                              (val.profile_updated
                                                                                  ? " " +
                                                                                    dayjs(
                                                                                        val.profile_updated
                                                                                    ).format(
                                                                                        "DD/MM/YYYY"
                                                                                    )
                                                                                  : "")
                                                                          }
                                                                          aria-describedby="basic-addon2"
                                                                      />
                                                                      <Button
                                                                          className="btn btn-danger btn-sm"
                                                                          id="button-addon2"
                                                                          onClick={() =>
                                                                              handleDuplicateDelete(
                                                                                  val.id
                                                                              )
                                                                          }
                                                                          style={{
                                                                              marginTop:
                                                                                  -6,
                                                                          }}
                                                                      >
                                                                          Delete
                                                                      </Button>
                                                                  </InputGroup>{" "}
                                                              </>
                                                          )
                                                      )
                                                    : ""}
                                            </Col>
                                        </Row>
                                    ) : (
                                        <Row className="align-items gx-2">
                                            <Col lg={3}>
                                                <Form.Label>
                                                    Address line 1
                                                </Form.Label>
                                                <Form.Control
                                                    name="v_address_1"
                                                    type="text"
                                                    value={
                                                        personInput.v_address_1
                                                    }
                                                    onChange={
                                                        handlePersonChange
                                                    }
                                                />

                                                <Form.Label>
                                                    Address line 2
                                                </Form.Label>
                                                <Form.Control
                                                    name="v_address_2"
                                                    type="text"
                                                    value={
                                                        personInput.v_address_2
                                                    }
                                                    onChange={
                                                        handlePersonChange
                                                    }
                                                />
                                                <Form.Label>
                                                    Address line 3
                                                </Form.Label>
                                                <Form.Control
                                                    name="v_address_3"
                                                    type="text"
                                                    value={
                                                        personInput.v_address_3
                                                    }
                                                    onChange={
                                                        handlePersonChange
                                                    }
                                                />
                                                <Form.Label>
                                                    Town / City
                                                </Form.Label>
                                                <Form.Control
                                                    name="v_town"
                                                    type="text"
                                                    value={personInput.v_town.toUpperCase()}
                                                    onChange={
                                                        handlePersonChange
                                                    }
                                                />

                                                <Form.Label>County</Form.Label>
                                                <Form.Control
                                                    name="v_county"
                                                    type="text"
                                                    value={
                                                        personInput.v_county
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                        personInput.v_county.slice(
                                                            1
                                                        )
                                                    }
                                                    onChange={
                                                        handlePersonChange
                                                    }
                                                />

                                                <Form.Label>
                                                    Postcode
                                                </Form.Label>
                                                <Form.Control
                                                    name="v_postcode"
                                                    type="text"
                                                    value={personInput.v_postcode.toUpperCase()}
                                                    onChange={
                                                        handlePersonChange
                                                    }
                                                />

                                                <Form.Label>Country</Form.Label>
                                                <Form.Control
                                                    name="v_country"
                                                    type="text"
                                                    value={
                                                        personInput.v_country
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                        personInput.v_country.slice(
                                                            1
                                                        )
                                                    }
                                                    onChange={
                                                        handlePersonChange
                                                    }
                                                />
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Label>Title</Form.Label>
                                                <Form.Control
                                                    name="title"
                                                    type="text"
                                                    as="select"
                                                    className="form-select"
                                                    value={personInput.title}
                                                    onChange={
                                                        handlePersonChange
                                                    }
                                                >
                                                    <option value="Dr">
                                                        Dr
                                                    </option>
                                                    <option value="Mr">
                                                        Mr
                                                    </option>
                                                    <option value="Mrs">
                                                        Mrs
                                                    </option>
                                                    <option value="Ms">
                                                        Ms
                                                    </option>
                                                    <option value="Miss">
                                                        Miss
                                                    </option>
                                                    <option value="Prof">
                                                        Prof
                                                    </option>
                                                    <option value="Major">
                                                        Major
                                                    </option>
                                                    <option value="Sir">
                                                        Sir
                                                    </option>
                                                    <option value="Brig">
                                                        Brig
                                                    </option>
                                                    <option value="Capt">
                                                        Capt
                                                    </option>
                                                    <option value="The">
                                                        The
                                                    </option>
                                                </Form.Control>
                                                <Form.Label>
                                                    Initials
                                                </Form.Label>
                                                <Form.Control
                                                    name="initials"
                                                    type="text"
                                                    id="initials-id"
                                                    onChange={
                                                        handlePersonChange
                                                    }
                                                    value={personInput.initials}
                                                    readOnly={true}
                                                />
                                                <Form.Label>
                                                    Forename
                                                </Form.Label>
                                                <Form.Control
                                                    name="forename"
                                                    type="text"
                                                    value={personInput.forename}
                                                    onChange={
                                                        handlePersonChange
                                                    }
                                                />
                                                <Form.Label>
                                                    Middle names
                                                </Form.Label>
                                                <Form.Control
                                                    name="middlenames"
                                                    type="text"
                                                    value={
                                                        personInput.middlenames
                                                    }
                                                    onChange={
                                                        handlePersonChange
                                                    }
                                                />
                                                <Form.Label>Surname</Form.Label>
                                                <Form.Control
                                                    name="surname"
                                                    type="text"
                                                    value={personInput.surname}
                                                    onChange={
                                                        handlePersonChange
                                                    }
                                                />

                                                <Form.Label>Maiden</Form.Label>
                                                <Form.Control
                                                    name="maiden"
                                                    type="text"
                                                    value={personInput.maiden}
                                                    onChange={
                                                        handlePersonChange
                                                    }
                                                />
                                                <Form.Label>Gender</Form.Label>
                                                <Form.Control
                                                    name="gender"
                                                    as="select"
                                                    className="form-select"
                                                    value={personInput.gender}
                                                    onChange={
                                                        handlePersonChange
                                                    }
                                                >
                                                    <option value="Male">
                                                        Male
                                                    </option>
                                                    <option value="Female">
                                                        Female
                                                    </option>
                                                </Form.Control>
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Label>
                                                    Other Job Type
                                                </Form.Label>
                                                <Form.Control
                                                    name="v_job_types_o"
                                                    type="text"
                                                    placeholder=""
                                                    value={
                                                        personInput.v_job_types_o
                                                    }
                                                    onChange={
                                                        handlePersonChange
                                                    }
                                                />
                                                <Form.Label>Suffix</Form.Label>
                                                <Form.Control
                                                    name="u_suffix"
                                                    type="text"
                                                    value={personInput.u_suffix}
                                                    onChange={
                                                        handlePersonChange
                                                    }
                                                />
                                                <Form.Label>
                                                    Certificates and diplomas
                                                </Form.Label>
                                                <Form.Control
                                                    name="u_cert"
                                                    type="text"
                                                    value={personInput.u_cert}
                                                    onChange={
                                                        handlePersonChange
                                                    }
                                                />
                                                <Form.Label>
                                                    RCVS Number
                                                </Form.Label>
                                                <Form.Control
                                                    name="v_rcvsnum"
                                                    type="text"
                                                    value={
                                                        personInput.v_rcvsnum
                                                    }
                                                    onChange={
                                                        handlePersonChange
                                                    }
                                                />
                                                <Form.Label>
                                                    Telephone
                                                </Form.Label>
                                                <InputMask
                                                    mask={[
                                                        "+",
                                                        /[1-9]/,
                                                        /[0-9]/,
                                                        /[0-9]/,
                                                        /[0-9]/,
                                                        /[0-9]/,
                                                        /[0-9]/,
                                                        " ",
                                                        /[0-9]/,
                                                        /[0-9]/,
                                                        /[0-9]/,
                                                        " ",
                                                        /[0-9]/,
                                                        /[0-9]/,
                                                        /[0-9]/,
                                                    ]}
                                                    value={personInput.v_tel}
                                                    name="v_tel"
                                                    className="form-control"
                                                    onChange={
                                                        handlePersonChange
                                                    }
                                                    onKeyUp={handlePersonChange}
                                                />

                                                <Form.Label>
                                                    Country residence
                                                </Form.Label>
                                                <Select
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    name="residence"
                                                    styles={selectStyle}
                                                    options={wp_countries}
                                                    value={
                                                        selectedCountryResidence
                                                    }
                                                    onChange={
                                                        handleCountryChange
                                                    }
                                                />
                                                <Form.Label>
                                                    Sub Type
                                                </Form.Label>
                                                <Form.Control
                                                    name="ind_type"
                                                    as="select"
                                                    className="form-select"
                                                    value={personInput.ind_type}
                                                    onChange={
                                                        handlePersonChange
                                                    }
                                                >
                                                    <option value="FOC - UK">
                                                        FOC - UK
                                                    </option>
                                                    <option value="FOC - OS">
                                                        FOC - OS
                                                    </option>
                                                    <option value="Full - UK">
                                                        Full - UK
                                                    </option>
                                                    <option value="Full - OS">
                                                        Full - OS
                                                    </option>
                                                    <option value="Lapsed">
                                                        Lapsed
                                                    </option>
                                                    <option value="None">
                                                        None
                                                    </option>
                                                </Form.Control>
                                                <Form.Label>Agent</Form.Label>
                                                <Form.Control
                                                    name="ind_agent"
                                                    type="text"
                                                    value={
                                                        personInput.ind_agent
                                                    }
                                                    onChange={
                                                        handlePersonChange
                                                    }
                                                />
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Label>
                                                    Primary Email
                                                </Form.Label>
                                                <Form.Control
                                                    name="v_email"
                                                    type="text"
                                                    value={personInput.v_email}
                                                    onChange={
                                                        handlePersonChange
                                                    }
                                                />
                                                <Row>
                                                    <Col lg={3}>
                                                        <Button
                                                            className="btn btn-danger btn-sm"
                                                            id="button-addon2"
                                                            onClick={() => {
                                                                setPrimaryPma();
                                                            }}
                                                        >
                                                            Null
                                                        </Button>
                                                    </Col>
                                                    <Col lg={5}>
                                                        <Form.Group controlID="primary_personal">
                                                            <Form.Check
                                                                inline
                                                                id="primary_personal"
                                                                name="primary_pma"
                                                                type="radio"
                                                                label="Personal"
                                                                value={
                                                                    "Personal"
                                                                }
                                                                checked={
                                                                    personInput.primary_pma ==
                                                                    "Personal"
                                                                }
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <Form.Group controlID="primary_work">
                                                            <Form.Check
                                                                inline
                                                                id="primary_work"
                                                                name="primary_pma"
                                                                type="radio"
                                                                label="Work"
                                                                value={"Work"}
                                                                checked={
                                                                    personInput.primary_pma ==
                                                                    "Work"
                                                                }
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Form.Label>
                                                    Secondary Email
                                                </Form.Label>
                                                <Form.Control
                                                    name="v_work_email"
                                                    type="text"
                                                    value={
                                                        personInput.v_work_email
                                                    }
                                                    onChange={
                                                        handlePersonChange
                                                    }
                                                />
                                                <Row>
                                                    <Col lg={3}>
                                                        <Button
                                                            className="btn btn-danger btn-sm"
                                                            id="button-addon2"
                                                            onClick={() => {
                                                                setSecondaryPma();
                                                            }}
                                                        >
                                                            Null
                                                        </Button>
                                                    </Col>
                                                    <Col lg={5}>
                                                        <Form.Group controlID="secondary_personal">
                                                            <Form.Check
                                                                inline
                                                                id="secondary_personal"
                                                                name="secondary_pma"
                                                                type="radio"
                                                                label="Personal"
                                                                value={
                                                                    "Personal"
                                                                }
                                                                checked={
                                                                    personInput.secondary_pma ==
                                                                    "Personal"
                                                                }
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <Form.Group controlID="secondary_work">
                                                            <Form.Check
                                                                inline
                                                                id="secondary_work"
                                                                name="secondary_pma"
                                                                type="radio"
                                                                label="Work"
                                                                value={"Work"}
                                                                checked={
                                                                    personInput.secondary_pma ==
                                                                    "Work"
                                                                }
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Form.Label>
                                                    SSO Email
                                                </Form.Label>
                                                <InputGroup>
                                                    <FormControl
                                                        name="sso_email"
                                                        readOnly={
                                                            true
                                                        }
                                                        type="text"
                                                        value={
                                                            personInput.sso_email
                                                        }
                                                        // onChange={
                                                        //     handlePersonChange
                                                        // }
                                                        aria-describedby="basic-addon2"
                                                    />
                                                    {/*<Button*/}
                                                    {/*    className="btn btn-danger btn-sm"*/}
                                                    {/*    id="button-addon2"*/}
                                                    {/*    onClick={() => {*/}
                                                    {/*        setEnableSSOField(*/}
                                                    {/*            false*/}
                                                    {/*        );*/}
                                                    {/*    }}*/}
                                                    {/*    style={{*/}
                                                    {/*        marginTop: -6,*/}
                                                    {/*    }}*/}
                                                    {/*>*/}
                                                    {/*    Change*/}
                                                    {/*</Button>*/}
                                                </InputGroup>
                                                <Row>
                                                    <Col lg={3}>
                                                        <Button
                                                            className="btn btn-danger btn-sm"
                                                            id="button-addon2"
                                                            onClick={() => {
                                                                setUnlinkSSOEmailAdd();
                                                            }}
                                                        >
                                                            Unlink
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={6}>
                                                        <Form.Label>
                                                            SSO
                                                        </Form.Label>
                                                        <Form.Control
                                                            disabled={true}
                                                            name="v_sso"
                                                            as="select"
                                                            className="form-select mb-0"
                                                        >
                                                            <option
                                                                defaultValue
                                                                value={
                                                                    personInput.v_sso
                                                                }
                                                                style={{
                                                                    display:
                                                                        "none",
                                                                }}
                                                            >
                                                                {" "}
                                                                {personInput.v_sso ==
                                                                ""
                                                                    ? "No"
                                                                    : personInput.v_sso}{" "}
                                                            </option>
                                                            <option value="Yes">
                                                                Yes
                                                            </option>
                                                            <option value="No">
                                                                No
                                                            </option>
                                                            <option value="Duplicate">
                                                                Duplicate
                                                            </option>
                                                        </Form.Control>
                                                        <Form.Label>
                                                            Email opt in
                                                        </Form.Label>
                                                        <Form.Control
                                                            name="v_email_opt_in"
                                                            as="select"
                                                            className="form-select mb-0"
                                                            value={
                                                                personInput.v_email_opt_in
                                                            }
                                                            onChange={
                                                                handlePersonChange
                                                            }
                                                        >
                                                            <option value="Yes">
                                                                Yes
                                                            </option>
                                                            <option value="No">
                                                                No
                                                            </option>
                                                        </Form.Control>
                                                    </Col>
                                                    <Col lg={6}>
                                                        {/*<Form.Label>Skills match</Form.Label>*/}
                                                        {/*<Form.Control*/}
                                                        {/*    name="sm_profile"*/}
                                                        {/*    as="select"*/}
                                                        {/*    className="form-select"*/}
                                                        {/*    value={personInput.sm_profile}*/}
                                                        {/*    onChange={handlePersonChange}*/}
                                                        {/*>*/}
                                                        {/*    <option value="Completed">Completed</option>*/}
                                                        {/*    <option value="Snap">Snap</option>*/}
                                                        {/*    <option value="No">No</option>*/}
                                                        {/*</Form.Control>*/}
                                                        <Form.Label>
                                                            People Match
                                                        </Form.Label>
                                                        <Form.Control
                                                            name="pm_profile"
                                                            as="select"
                                                            className="form-select"
                                                            value={
                                                                personInput.pm_profile
                                                            }
                                                            onChange={
                                                                handlePersonChange
                                                            }
                                                        >
                                                            <option value="Yes">
                                                                Yes
                                                            </option>
                                                            <option value="No">
                                                                No
                                                            </option>
                                                        </Form.Control>
                                                        <Form.Label>
                                                            SMS
                                                        </Form.Label>
                                                        <Form.Control
                                                            onChange={
                                                                handlePersonChange
                                                            }
                                                            value={
                                                                personInput.v_sms
                                                            }
                                                            name="v_sms"
                                                            as="select"
                                                            className="form-select mb-0"
                                                        >
                                                            {/* <option defaultValue value={personInput.v_sms} style={{ display: 'none' }}> {personInput.v_sms == '' ? 'No' : personInput.v_sms} </option> */}
                                                            <option value="Yes">
                                                                Yes
                                                            </option>
                                                            <option value="No">
                                                                No
                                                            </option>
                                                        </Form.Control>
                                                    </Col>
                                                </Row>
                                                {duplicateIDs
                                                    ? duplicateIDs.map(
                                                          (val, i) => (
                                                              <>
                                                                  {" "}
                                                                  <Form.Label>
                                                                      Duplicate
                                                                      IDs
                                                                  </Form.Label>
                                                                  <InputGroup>
                                                                      <FormControl
                                                                          readOnly={
                                                                              true
                                                                          }
                                                                          type="text"
                                                                          value={
                                                                              "ID: " +
                                                                              val.id +
                                                                              (val.profile_updated
                                                                                  ? " " +
                                                                                    dayjs(
                                                                                        val.profile_updated
                                                                                    ).format(
                                                                                        "DD/MM/YYYY"
                                                                                    )
                                                                                  : "")
                                                                          }
                                                                          aria-describedby="basic-addon2"
                                                                      />
                                                                      <Button
                                                                          className="btn btn-danger btn-sm"
                                                                          id="button-addon2"
                                                                          onClick={() =>
                                                                              handleDuplicateDelete(
                                                                                  val.id
                                                                              )
                                                                          }
                                                                          style={{
                                                                              marginTop:
                                                                                  -6,
                                                                          }}
                                                                      >
                                                                          Delete
                                                                      </Button>
                                                                  </InputGroup>{" "}
                                                              </>
                                                          )
                                                      )
                                                    : ""}
                                            </Col>
                                        </Row>
                                    )}
                                </Form>
                            </Collapse>
                            <div className="hide d-flex justify-content-center mt-2">
                                <a
                                    onClick={() => {
                                        setOpen(!open);
                                    }}
                                    className="hide-btn"
                                >
                                    <span
                                        className={
                                            open === true
                                                ? "arrow hide"
                                                : "arrow show"
                                        }
                                    ></span>
                                    {open ? "Hide" : "Show"}
                                </a>
                            </div>
                        </div>
                    </Row>
                    <Row>
                        <Col lg={12} className="p-0 position-relative">
                            <Tabs
                                id="controlled-tab"
                                activeKey={key}
                                className="person-tabs row-px-2"
                                onSelect={(k) => setKey(k)}
                            >
                                {personInput.practice_data.office &&
                                    personInput.practice_data.office.split(
                                        " "
                                    )[0] !== "Industry" && (
                                        <Tab eventKey="details" title="DETAILS">
                                            <Tabs className="secondary-tabs row-px-2">
                                                <Tab
                                                    eventKey="job"
                                                    title="Job / Species"
                                                    className="row-px-2"
                                                >
                                                    <Row className="job-species gx-2">
                                                        <Col lg={6}>
                                                            <ListGroup className="job-types">
                                                                <span className="list-group-header">
                                                                    Job types
                                                                </span>
                                                                <Form>
                                                                    <Row className="gx-0">
                                                                        <Col
                                                                            md={
                                                                                6
                                                                            }
                                                                        >
                                                                            <ListGroup.Item>
                                                                                <Form.Group controlId="z-seniorpartner">
                                                                                    <Form.Check
                                                                                        name="v_job_types"
                                                                                        type="checkbox"
                                                                                        label="(Z) Senior Partner"
                                                                                        value="Z"
                                                                                        checked={
                                                                                            personInput.v_job_types &&
                                                                                            personInput.v_job_types
                                                                                                .split(
                                                                                                    ""
                                                                                                )
                                                                                                .indexOf(
                                                                                                    "Z"
                                                                                                ) !=
                                                                                                -1
                                                                                        }
                                                                                        disabled={
                                                                                            personInput.v_job_types &&
                                                                                            personInput.v_job_types
                                                                                                .split(
                                                                                                    ""
                                                                                                )
                                                                                                .find(
                                                                                                    (
                                                                                                        curr
                                                                                                    ) =>
                                                                                                        curr ===
                                                                                                        ""
                                                                                                )
                                                                                        }
                                                                                        onChange={
                                                                                            handlePersonChange
                                                                                        }
                                                                                    />
                                                                                </Form.Group>
                                                                            </ListGroup.Item>
                                                                            <ListGroup.Item>
                                                                                <Form.Group controlId="a-buyer">
                                                                                    <Form.Check
                                                                                        name="v_job_types"
                                                                                        type="checkbox"
                                                                                        label="(A) Buyer"
                                                                                        value="A"
                                                                                        checked={
                                                                                            personInput.v_job_types &&
                                                                                            personInput.v_job_types
                                                                                                .split(
                                                                                                    ""
                                                                                                )
                                                                                                .indexOf(
                                                                                                    "A"
                                                                                                ) !=
                                                                                                -1
                                                                                        }
                                                                                        disabled={
                                                                                            personInput.v_job_types &&
                                                                                            personInput.v_job_types
                                                                                                .split(
                                                                                                    ""
                                                                                                )
                                                                                                .find(
                                                                                                    (
                                                                                                        curr
                                                                                                    ) =>
                                                                                                        curr ===
                                                                                                        ""
                                                                                                )
                                                                                        }
                                                                                        onChange={
                                                                                            handlePersonChange
                                                                                        }
                                                                                    />
                                                                                </Form.Group>
                                                                            </ListGroup.Item>
                                                                            <ListGroup.Item>
                                                                                <Form.Group controlId="b-partner">
                                                                                    <Form.Check
                                                                                        name="v_job_types"
                                                                                        type="checkbox"
                                                                                        label="(B) Partner"
                                                                                        value="B"
                                                                                        checked={
                                                                                            personInput.v_job_types &&
                                                                                            personInput.v_job_types
                                                                                                .split(
                                                                                                    ""
                                                                                                )
                                                                                                .indexOf(
                                                                                                    "B"
                                                                                                ) !=
                                                                                                -1
                                                                                        }
                                                                                        disabled={
                                                                                            personInput.v_job_types &&
                                                                                            personInput.v_job_types
                                                                                                .split(
                                                                                                    ""
                                                                                                )
                                                                                                .find(
                                                                                                    (
                                                                                                        curr
                                                                                                    ) =>
                                                                                                        curr ===
                                                                                                        ""
                                                                                                )
                                                                                        }
                                                                                        onChange={
                                                                                            handlePersonChange
                                                                                        }
                                                                                    />
                                                                                </Form.Group>
                                                                            </ListGroup.Item>
                                                                            <ListGroup.Item>
                                                                                <Form.Group controlID="c-Veterinary-Surgeon">
                                                                                    <Form.Check
                                                                                        id="v_job_types_Veterinary_Surgeon"
                                                                                        name="v_job_types"
                                                                                        type="checkbox"
                                                                                        label="(C) Veterinary Surgeon"
                                                                                        value="C"
                                                                                        checked={
                                                                                            personInput.v_job_types &&
                                                                                            personInput.v_job_types
                                                                                                .split(
                                                                                                    ""
                                                                                                )
                                                                                                .indexOf(
                                                                                                    "C"
                                                                                                ) !=
                                                                                                -1
                                                                                        }
                                                                                        disabled={
                                                                                            personInput.v_job_types &&
                                                                                            personInput.v_job_types
                                                                                                .split(
                                                                                                    ""
                                                                                                )
                                                                                                .find(
                                                                                                    (
                                                                                                        curr
                                                                                                    ) =>
                                                                                                        curr ===
                                                                                                            "S" ||
                                                                                                        curr ===
                                                                                                            "E" ||
                                                                                                        curr ===
                                                                                                            "D" ||
                                                                                                        curr ===
                                                                                                            "G" ||
                                                                                                        curr ===
                                                                                                            "R" ||
                                                                                                        curr ===
                                                                                                            "X" ||
                                                                                                        curr ===
                                                                                                            "Y"
                                                                                                )
                                                                                        }
                                                                                        onChange={
                                                                                            handlePersonChange
                                                                                        }
                                                                                    />
                                                                                </Form.Group>
                                                                            </ListGroup.Item>
                                                                            <ListGroup.Item>
                                                                                <Form.Group controlID="v_job_types_Locum_Veterinary_Surgeon">
                                                                                    <Form.Check
                                                                                        id="v_job_types_Locum_Veterinary_Surgeon"
                                                                                        name="v_job_types"
                                                                                        type="checkbox"
                                                                                        label="(X) Locum Veterinary Surgeon"
                                                                                        value="X"
                                                                                        checked={
                                                                                            personInput.v_job_types &&
                                                                                            personInput.v_job_types
                                                                                                .split(
                                                                                                    ""
                                                                                                )
                                                                                                .indexOf(
                                                                                                    "X"
                                                                                                ) !=
                                                                                                -1
                                                                                        }
                                                                                        disabled={
                                                                                            personInput.v_job_types &&
                                                                                            personInput.v_job_types
                                                                                                .split(
                                                                                                    ""
                                                                                                )
                                                                                                .find(
                                                                                                    (
                                                                                                        curr
                                                                                                    ) =>
                                                                                                        curr ===
                                                                                                            "Y" ||
                                                                                                        curr ===
                                                                                                            "C" ||
                                                                                                        curr ===
                                                                                                            "S" ||
                                                                                                        curr ===
                                                                                                            "E" ||
                                                                                                        curr ===
                                                                                                            "N" ||
                                                                                                        curr ===
                                                                                                            "G" ||
                                                                                                        curr ===
                                                                                                            "D" ||
                                                                                                        curr ===
                                                                                                            "R"
                                                                                                )
                                                                                        }
                                                                                        onChange={
                                                                                            handlePersonChange
                                                                                        }
                                                                                    />
                                                                                </Form.Group>
                                                                            </ListGroup.Item>
                                                                            <ListGroup.Item>
                                                                                <Form.Group controlID="v_job_types_Student_Vet">
                                                                                    <Form.Check
                                                                                        id="v_job_types_Student_Vet"
                                                                                        name="v_job_types"
                                                                                        type="checkbox"
                                                                                        label="(S) Student Vet"
                                                                                        value="S"
                                                                                        checked={
                                                                                            personInput.v_job_types &&
                                                                                            personInput.v_job_types
                                                                                                .split(
                                                                                                    ""
                                                                                                )
                                                                                                .indexOf(
                                                                                                    "S"
                                                                                                ) !=
                                                                                                -1
                                                                                        }
                                                                                        disabled={
                                                                                            personInput.v_job_types &&
                                                                                            personInput.v_job_types
                                                                                                .split(
                                                                                                    ""
                                                                                                )
                                                                                                .find(
                                                                                                    (
                                                                                                        curr
                                                                                                    ) =>
                                                                                                        curr ===
                                                                                                            "C" ||
                                                                                                        curr ===
                                                                                                            "E" ||
                                                                                                        curr ===
                                                                                                            "D" ||
                                                                                                        curr ===
                                                                                                            "G" ||
                                                                                                        curr ===
                                                                                                            "R" ||
                                                                                                        curr ===
                                                                                                            "X" ||
                                                                                                        curr ===
                                                                                                            "Y"
                                                                                                )
                                                                                        }
                                                                                        onChange={
                                                                                            handlePersonChange
                                                                                        }
                                                                                    />
                                                                                </Form.Group>
                                                                            </ListGroup.Item>
                                                                            <ListGroup.Item>
                                                                                <Form.Group controlID="v_job_types_Head_Nurse">
                                                                                    <Form.Check
                                                                                        id="v_job_types_Head_Nurse"
                                                                                        name="v_job_types"
                                                                                        type="checkbox"
                                                                                        label="(E) Head Nurse"
                                                                                        value="E"
                                                                                        checked={
                                                                                            personInput.v_job_types &&
                                                                                            personInput.v_job_types
                                                                                                .split(
                                                                                                    ""
                                                                                                )
                                                                                                .indexOf(
                                                                                                    "E"
                                                                                                ) !=
                                                                                                -1
                                                                                        }
                                                                                        disabled={
                                                                                            personInput.v_job_types &&
                                                                                            personInput.v_job_types
                                                                                                .split(
                                                                                                    ""
                                                                                                )
                                                                                                .find(
                                                                                                    (
                                                                                                        curr
                                                                                                    ) =>
                                                                                                        curr ===
                                                                                                            "C" ||
                                                                                                        curr ===
                                                                                                            "S" ||
                                                                                                        curr ===
                                                                                                            "D" ||
                                                                                                        curr ===
                                                                                                            "G" ||
                                                                                                        curr ===
                                                                                                            "R" ||
                                                                                                        curr ===
                                                                                                            "X" ||
                                                                                                        curr ===
                                                                                                            "Y"
                                                                                                )
                                                                                        }
                                                                                        onChange={
                                                                                            handlePersonChange
                                                                                        }
                                                                                    />
                                                                                </Form.Group>
                                                                            </ListGroup.Item>
                                                                            <ListGroup.Item>
                                                                                <Form.Group controlID="v_job_types_Nurse">
                                                                                    <Form.Check
                                                                                        id="v_job_types_Nurse"
                                                                                        name="v_job_types"
                                                                                        type="checkbox"
                                                                                        label="(D) Nurse"
                                                                                        value="D"
                                                                                        checked={
                                                                                            personInput.v_job_types &&
                                                                                            personInput.v_job_types
                                                                                                .split(
                                                                                                    ""
                                                                                                )
                                                                                                .indexOf(
                                                                                                    "D"
                                                                                                ) !=
                                                                                                -1
                                                                                        }
                                                                                        disabled={
                                                                                            personInput.v_job_types &&
                                                                                            personInput.v_job_types
                                                                                                .split(
                                                                                                    ""
                                                                                                )
                                                                                                .find(
                                                                                                    (
                                                                                                        curr
                                                                                                    ) =>
                                                                                                        curr ===
                                                                                                            "C" ||
                                                                                                        curr ===
                                                                                                            "S" ||
                                                                                                        curr ===
                                                                                                            "E" ||
                                                                                                        curr ===
                                                                                                            "G" ||
                                                                                                        curr ===
                                                                                                            "R" ||
                                                                                                        curr ===
                                                                                                            "X" ||
                                                                                                        curr ===
                                                                                                            "Y"
                                                                                                )
                                                                                        }
                                                                                        onChange={
                                                                                            handlePersonChange
                                                                                        }
                                                                                    />
                                                                                </Form.Group>
                                                                            </ListGroup.Item>
                                                                        </Col>
                                                                        <Col
                                                                            md={
                                                                                6
                                                                            }
                                                                        >
                                                                            <ListGroup.Item>
                                                                                <Form.Group controlID="v_job_types_Locum_Nurse">
                                                                                    <Form.Check
                                                                                        id="v_job_types_Locum_Nurse"
                                                                                        name="v_job_types"
                                                                                        type="checkbox"
                                                                                        label="(Y) Locum Nurse"
                                                                                        value="Y"
                                                                                        checked={
                                                                                            personInput.v_job_types &&
                                                                                            personInput.v_job_types
                                                                                                .split(
                                                                                                    ""
                                                                                                )
                                                                                                .indexOf(
                                                                                                    "Y"
                                                                                                ) !=
                                                                                                -1
                                                                                        }
                                                                                        disabled={
                                                                                            personInput.v_job_types &&
                                                                                            personInput.v_job_types
                                                                                                .split(
                                                                                                    ""
                                                                                                )
                                                                                                .find(
                                                                                                    (
                                                                                                        curr
                                                                                                    ) =>
                                                                                                        curr ===
                                                                                                            "C" ||
                                                                                                        curr ===
                                                                                                            "X" ||
                                                                                                        curr ===
                                                                                                            "S" ||
                                                                                                        curr ===
                                                                                                            "E" ||
                                                                                                        curr ===
                                                                                                            "D" ||
                                                                                                        curr ===
                                                                                                            "G" ||
                                                                                                        curr ===
                                                                                                            "R"
                                                                                                )
                                                                                        }
                                                                                        onChange={
                                                                                            handlePersonChange
                                                                                        }
                                                                                    />
                                                                                </Form.Group>
                                                                            </ListGroup.Item>
                                                                            <ListGroup.Item>
                                                                                <Form.Group controlID="v_job_types_Trainee_Nurse">
                                                                                    <Form.Check
                                                                                        id="v_job_types_Trainee_Nurse"
                                                                                        name="v_job_types"
                                                                                        type="checkbox"
                                                                                        label="(G) Student Veterinary Nurse"
                                                                                        value="G"
                                                                                        checked={
                                                                                            personInput.v_job_types &&
                                                                                            personInput.v_job_types
                                                                                                .split(
                                                                                                    ""
                                                                                                )
                                                                                                .indexOf(
                                                                                                    "G"
                                                                                                ) !=
                                                                                                -1
                                                                                        }
                                                                                        disabled={
                                                                                            personInput.v_job_types &&
                                                                                            personInput.v_job_types
                                                                                                .split(
                                                                                                    ""
                                                                                                )
                                                                                                .find(
                                                                                                    (
                                                                                                        curr
                                                                                                    ) =>
                                                                                                        curr ===
                                                                                                            "C" ||
                                                                                                        curr ===
                                                                                                            "S" ||
                                                                                                        curr ===
                                                                                                            "E" ||
                                                                                                        curr ===
                                                                                                            "D" ||
                                                                                                        curr ===
                                                                                                            "R" ||
                                                                                                        curr ===
                                                                                                            "X" ||
                                                                                                        curr ===
                                                                                                            "Y"
                                                                                                )
                                                                                        }
                                                                                        onChange={
                                                                                            handlePersonChange
                                                                                        }
                                                                                    />
                                                                                </Form.Group>
                                                                            </ListGroup.Item>
                                                                            <ListGroup.Item>
                                                                                <Form.Group controlID="c-Veterinary-Surgeon">
                                                                                    <Form.Check
                                                                                        id="v_job_types_VNA"
                                                                                        name="v_job_types"
                                                                                        type="checkbox"
                                                                                        label="(R) VNA"
                                                                                        value="R"
                                                                                        checked={
                                                                                            personInput.v_job_types &&
                                                                                            personInput.v_job_types
                                                                                                .split(
                                                                                                    ""
                                                                                                )
                                                                                                .indexOf(
                                                                                                    "R"
                                                                                                ) !=
                                                                                                -1
                                                                                        }
                                                                                        disabled={
                                                                                            personInput.v_job_types &&
                                                                                            personInput.v_job_types
                                                                                                .split(
                                                                                                    ""
                                                                                                )
                                                                                                .find(
                                                                                                    (
                                                                                                        curr
                                                                                                    ) =>
                                                                                                        curr ===
                                                                                                            "C" ||
                                                                                                        curr ===
                                                                                                            "S" ||
                                                                                                        curr ===
                                                                                                            "E" ||
                                                                                                        curr ===
                                                                                                            "D" ||
                                                                                                        curr ===
                                                                                                            "G" ||
                                                                                                        curr ===
                                                                                                            "X" ||
                                                                                                        curr ===
                                                                                                            "Y"
                                                                                                )
                                                                                        }
                                                                                        onChange={
                                                                                            handlePersonChange
                                                                                        }
                                                                                    />
                                                                                </Form.Group>
                                                                            </ListGroup.Item>
                                                                            <ListGroup.Item>
                                                                                <Form.Group controlID="v_job_types_Practice_Manager">
                                                                                    <Form.Check
                                                                                        id="v_job_types_Practice_Manager"
                                                                                        name="v_job_types"
                                                                                        type="checkbox"
                                                                                        label="(P) Practice Manager"
                                                                                        value="P"
                                                                                        checked={
                                                                                            personInput.v_job_types &&
                                                                                            personInput.v_job_types
                                                                                                .split(
                                                                                                    ""
                                                                                                )
                                                                                                .indexOf(
                                                                                                    "P"
                                                                                                ) !=
                                                                                                -1
                                                                                        }
                                                                                        disabled={
                                                                                            personInput.v_job_types &&
                                                                                            personInput.v_job_types
                                                                                                .split(
                                                                                                    ""
                                                                                                )
                                                                                                .find(
                                                                                                    (
                                                                                                        curr
                                                                                                    ) =>
                                                                                                        curr ===
                                                                                                        ""
                                                                                                )
                                                                                        }
                                                                                        onChange={
                                                                                            handlePersonChange
                                                                                        }
                                                                                    />
                                                                                </Form.Group>
                                                                            </ListGroup.Item>
                                                                            <ListGroup.Item>
                                                                                <Form.Group controlID="v_job_types_Admin">
                                                                                    <Form.Check
                                                                                        id="v_job_types_Admin"
                                                                                        name="v_job_types"
                                                                                        type="checkbox"
                                                                                        label="(F) Admin"
                                                                                        value="F"
                                                                                        checked={
                                                                                            personInput.v_job_types &&
                                                                                            personInput.v_job_types
                                                                                                .split(
                                                                                                    ""
                                                                                                )
                                                                                                .indexOf(
                                                                                                    "F"
                                                                                                ) !=
                                                                                                -1
                                                                                        }
                                                                                        disabled={
                                                                                            personInput.v_job_types &&
                                                                                            personInput.v_job_types
                                                                                                .split(
                                                                                                    ""
                                                                                                )
                                                                                                .find(
                                                                                                    (
                                                                                                        curr
                                                                                                    ) =>
                                                                                                        curr ===
                                                                                                        ""
                                                                                                )
                                                                                        }
                                                                                        onChange={
                                                                                            handlePersonChange
                                                                                        }
                                                                                    />
                                                                                </Form.Group>
                                                                            </ListGroup.Item>
                                                                            <ListGroup.Item>
                                                                                <Form.Group controlID="v_job_types_Reception">
                                                                                    <Form.Check
                                                                                        id="v_job_types_Reception"
                                                                                        name="v_job_types"
                                                                                        type="checkbox"
                                                                                        label="(H) Reception"
                                                                                        value="H"
                                                                                        checked={
                                                                                            personInput.v_job_types &&
                                                                                            personInput.v_job_types
                                                                                                .split(
                                                                                                    ""
                                                                                                )
                                                                                                .indexOf(
                                                                                                    "H"
                                                                                                ) !=
                                                                                                -1
                                                                                        }
                                                                                        disabled={
                                                                                            personInput.v_job_types &&
                                                                                            personInput.v_job_types
                                                                                                .split(
                                                                                                    ""
                                                                                                )
                                                                                                .find(
                                                                                                    (
                                                                                                        curr
                                                                                                    ) =>
                                                                                                        curr ===
                                                                                                        ""
                                                                                                )
                                                                                        }
                                                                                        onChange={
                                                                                            handlePersonChange
                                                                                        }
                                                                                    />
                                                                                </Form.Group>
                                                                            </ListGroup.Item>
                                                                            <ListGroup.Item>
                                                                                <Form.Group controlID="v_job_types_Recruiter">
                                                                                    <Form.Check
                                                                                        id="v_job_types_Recruiter"
                                                                                        name="v_job_types"
                                                                                        type="checkbox"
                                                                                        label="(J) Recruiter"
                                                                                        value="J"
                                                                                        checked={
                                                                                            personInput.v_job_types &&
                                                                                            personInput.v_job_types
                                                                                                .split(
                                                                                                    ""
                                                                                                )
                                                                                                .indexOf(
                                                                                                    "J"
                                                                                                ) !=
                                                                                                -1
                                                                                        }
                                                                                        disabled={
                                                                                            personInput.v_job_types &&
                                                                                            personInput.v_job_types
                                                                                                .split(
                                                                                                    ""
                                                                                                )
                                                                                                .find(
                                                                                                    (
                                                                                                        curr
                                                                                                    ) =>
                                                                                                        curr ===
                                                                                                        ""
                                                                                                )
                                                                                        }
                                                                                        onChange={
                                                                                            handlePersonChange
                                                                                        }
                                                                                    />
                                                                                </Form.Group>
                                                                            </ListGroup.Item>
                                                                            <ListGroup.Item className="rounded-0">
                                                                                <Form.Group controlID="v_job_types_o">
                                                                                    <Form.Control
                                                                                        id="v_job_types_o"
                                                                                        name="v_job_types_o"
                                                                                        type="text"
                                                                                        className="mb-0"
                                                                                        placeholder="Other"
                                                                                        value={
                                                                                            personInput.v_job_types_o
                                                                                        }
                                                                                        onChange={
                                                                                            handlePersonChange
                                                                                        }
                                                                                    />
                                                                                </Form.Group>
                                                                            </ListGroup.Item>
                                                                        </Col>
                                                                    </Row>
                                                                </Form>
                                                            </ListGroup>
                                                        </Col>
                                                        <Col lg={6}>
                                                            <ListGroup className="species">
                                                                <span className="list-group-header">
                                                                    Species
                                                                </span>
                                                                <Form>
                                                                    <Row className="gx-0">
                                                                        <Col
                                                                            md={
                                                                                6
                                                                            }
                                                                        >
                                                                            <ListGroup.Item>
                                                                                <Form.Group controlID="v_species_Dairy_cattle">
                                                                                    <Form.Check
                                                                                        id="v_species_Dairy_cattle"
                                                                                        name="v_species"
                                                                                        type="checkbox"
                                                                                        label="(D) Dairy cattle"
                                                                                        value="D"
                                                                                        checked={
                                                                                            personInput.v_species &&
                                                                                            personInput.v_species
                                                                                                .split(
                                                                                                    ""
                                                                                                )
                                                                                                .indexOf(
                                                                                                    "D"
                                                                                                ) !=
                                                                                                -1
                                                                                        }
                                                                                        onChange={
                                                                                            handlePersonChange
                                                                                        }
                                                                                        disabled={
                                                                                            disableSpecies
                                                                                        }
                                                                                    />
                                                                                </Form.Group>
                                                                            </ListGroup.Item>
                                                                            <ListGroup.Item>
                                                                                <Form.Group controlID="v_species_Beef_cattle">
                                                                                    <Form.Check
                                                                                        id="v_species_Beef_cattle"
                                                                                        name="v_species"
                                                                                        type="checkbox"
                                                                                        label="(B) Beef cattle"
                                                                                        value="B"
                                                                                        checked={
                                                                                            personInput.v_species &&
                                                                                            personInput.v_species
                                                                                                .split(
                                                                                                    ""
                                                                                                )
                                                                                                .indexOf(
                                                                                                    "B"
                                                                                                ) !=
                                                                                                -1
                                                                                        }
                                                                                        onChange={
                                                                                            handlePersonChange
                                                                                        }
                                                                                        disabled={
                                                                                            disableSpecies
                                                                                        }
                                                                                    />
                                                                                </Form.Group>
                                                                            </ListGroup.Item>
                                                                            <ListGroup.Item>
                                                                                <Form.Group controlID="v_species_Poultry">
                                                                                    <Form.Check
                                                                                        id="v_species_Poultry"
                                                                                        name="v_species"
                                                                                        type="checkbox"
                                                                                        label="(T) Poultry"
                                                                                        value="T"
                                                                                        checked={
                                                                                            personInput.v_species &&
                                                                                            personInput.v_species
                                                                                                .split(
                                                                                                    ""
                                                                                                )
                                                                                                .indexOf(
                                                                                                    "T"
                                                                                                ) !=
                                                                                                -1
                                                                                        }
                                                                                        onChange={
                                                                                            handlePersonChange
                                                                                        }
                                                                                        disabled={
                                                                                            disableSpecies
                                                                                        }
                                                                                    />
                                                                                </Form.Group>
                                                                            </ListGroup.Item>
                                                                            <ListGroup.Item>
                                                                                <Form.Group controlID="v_species_Sheep_goats">
                                                                                    <Form.Check
                                                                                        id="v_species_Sheep_goats"
                                                                                        name="v_species"
                                                                                        type="checkbox"
                                                                                        label="(S) Sheep, goats"
                                                                                        value="S"
                                                                                        checked={
                                                                                            personInput.v_species &&
                                                                                            personInput.v_species
                                                                                                .split(
                                                                                                    ""
                                                                                                )
                                                                                                .indexOf(
                                                                                                    "S"
                                                                                                ) !=
                                                                                                -1
                                                                                        }
                                                                                        onChange={
                                                                                            handlePersonChange
                                                                                        }
                                                                                        disabled={
                                                                                            disableSpecies
                                                                                        }
                                                                                    />
                                                                                </Form.Group>
                                                                            </ListGroup.Item>
                                                                            <ListGroup.Item>
                                                                                <Form.Group controlID="v_species_Birds">
                                                                                    <Form.Check
                                                                                        id="v_species_Birds"
                                                                                        name="v_species"
                                                                                        type="checkbox"
                                                                                        label="(G) Birds"
                                                                                        value="G"
                                                                                        checked={
                                                                                            personInput.v_species &&
                                                                                            personInput.v_species
                                                                                                .split(
                                                                                                    ""
                                                                                                )
                                                                                                .indexOf(
                                                                                                    "G"
                                                                                                ) !=
                                                                                                -1
                                                                                        }
                                                                                        onChange={
                                                                                            handlePersonChange
                                                                                        }
                                                                                        disabled={
                                                                                            disableSpecies
                                                                                        }
                                                                                    />
                                                                                </Form.Group>
                                                                            </ListGroup.Item>
                                                                            <ListGroup.Item>
                                                                                <Form.Group controlID="v_job_types_Dogs">
                                                                                    <Form.Check
                                                                                        id="v_job_types_Dogs"
                                                                                        name="v_species"
                                                                                        type="checkbox"
                                                                                        label="(C) Dogs"
                                                                                        value="C"
                                                                                        checked={
                                                                                            personInput.v_species &&
                                                                                            personInput.v_species
                                                                                                .split(
                                                                                                    ""
                                                                                                )
                                                                                                .indexOf(
                                                                                                    "C"
                                                                                                ) !=
                                                                                                -1
                                                                                        }
                                                                                        onChange={
                                                                                            handlePersonChange
                                                                                        }
                                                                                        disabled={
                                                                                            disableSpecies
                                                                                        }
                                                                                    />
                                                                                </Form.Group>
                                                                            </ListGroup.Item>
                                                                            <ListGroup.Item>
                                                                                <Form.Group controlID="v_species_Cats">
                                                                                    <Form.Check
                                                                                        id="v_species_Cats"
                                                                                        name="v_species"
                                                                                        type="checkbox"
                                                                                        label="(F) Cats"
                                                                                        value="F"
                                                                                        checked={
                                                                                            personInput.v_species &&
                                                                                            personInput.v_species
                                                                                                .split(
                                                                                                    ""
                                                                                                )
                                                                                                .indexOf(
                                                                                                    "F"
                                                                                                ) !=
                                                                                                -1
                                                                                        }
                                                                                        onChange={
                                                                                            handlePersonChange
                                                                                        }
                                                                                        disabled={
                                                                                            disableSpecies
                                                                                        }
                                                                                    />
                                                                                </Form.Group>
                                                                            </ListGroup.Item>
                                                                        </Col>
                                                                        <Col
                                                                            md={
                                                                                6
                                                                            }
                                                                        >
                                                                            <ListGroup.Item>
                                                                                <Form.Group controlID="v_species_Pigs">
                                                                                    <Form.Check
                                                                                        id="v_species_Pigs"
                                                                                        name="v_species"
                                                                                        type="checkbox"
                                                                                        label="(P) Pigs"
                                                                                        value="P"
                                                                                        checked={
                                                                                            personInput.v_species &&
                                                                                            personInput.v_species
                                                                                                .split(
                                                                                                    ""
                                                                                                )
                                                                                                .indexOf(
                                                                                                    "P"
                                                                                                ) !=
                                                                                                -1
                                                                                        }
                                                                                        onChange={
                                                                                            handlePersonChange
                                                                                        }
                                                                                        disabled={
                                                                                            disableSpecies
                                                                                        }
                                                                                    />
                                                                                </Form.Group>
                                                                            </ListGroup.Item>
                                                                            <ListGroup.Item>
                                                                                <Form.Group controlID="v_job_types_Reception">
                                                                                    <Form.Check
                                                                                        id="v_species_Horses"
                                                                                        name="v_species"
                                                                                        type="checkbox"
                                                                                        label="(H) Horses"
                                                                                        value="H"
                                                                                        checked={
                                                                                            personInput.v_species &&
                                                                                            personInput.v_species
                                                                                                .split(
                                                                                                    ""
                                                                                                )
                                                                                                .indexOf(
                                                                                                    "H"
                                                                                                ) !=
                                                                                                -1
                                                                                        }
                                                                                        onChange={
                                                                                            handlePersonChange
                                                                                        }
                                                                                        disabled={
                                                                                            disableSpecies
                                                                                        }
                                                                                    />
                                                                                </Form.Group>
                                                                            </ListGroup.Item>
                                                                            <ListGroup.Item>
                                                                                <Form.Group controlID="v_species_Reptiles">
                                                                                    <Form.Check
                                                                                        id="v_species_Reptiles"
                                                                                        name="v_species"
                                                                                        type="checkbox"
                                                                                        label="(X) Reptiles"
                                                                                        value="X"
                                                                                        checked={
                                                                                            personInput.v_species &&
                                                                                            personInput.v_species
                                                                                                .split(
                                                                                                    ""
                                                                                                )
                                                                                                .indexOf(
                                                                                                    "X"
                                                                                                ) !=
                                                                                                -1
                                                                                        }
                                                                                        onChange={
                                                                                            handlePersonChange
                                                                                        }
                                                                                        disabled={
                                                                                            disableSpecies
                                                                                        }
                                                                                    />
                                                                                </Form.Group>
                                                                            </ListGroup.Item>
                                                                            <ListGroup.Item>
                                                                                <Form.Group controlID="v_species_Rodents">
                                                                                    <Form.Check
                                                                                        id="v_species_Rodents"
                                                                                        name="v_species"
                                                                                        type="checkbox"
                                                                                        label="(E) Rodents"
                                                                                        value="E"
                                                                                        checked={
                                                                                            personInput.v_species &&
                                                                                            personInput.v_species
                                                                                                .split(
                                                                                                    ""
                                                                                                )
                                                                                                .indexOf(
                                                                                                    "E"
                                                                                                ) !=
                                                                                                -1
                                                                                        }
                                                                                        onChange={
                                                                                            handlePersonChange
                                                                                        }
                                                                                        disabled={
                                                                                            disableSpecies
                                                                                        }
                                                                                    />
                                                                                </Form.Group>
                                                                            </ListGroup.Item>
                                                                            <ListGroup.Item>
                                                                                <Form.Group controlID="v_species_Zoo_wildlife_fish">
                                                                                    <Form.Check
                                                                                        id="v_species_Zoo_wildlife_fish"
                                                                                        name="v_species"
                                                                                        type="checkbox"
                                                                                        label="(Z) Zoo, wildlife, fish"
                                                                                        value="Z"
                                                                                        checked={
                                                                                            personInput.v_species &&
                                                                                            personInput.v_species
                                                                                                .split(
                                                                                                    ""
                                                                                                )
                                                                                                .indexOf(
                                                                                                    "Z"
                                                                                                ) !=
                                                                                                -1
                                                                                        }
                                                                                        onChange={
                                                                                            handlePersonChange
                                                                                        }
                                                                                        disabled={
                                                                                            disableSpecies
                                                                                        }
                                                                                    />
                                                                                </Form.Group>
                                                                            </ListGroup.Item>
                                                                            <ListGroup.Item>
                                                                                <Form.Group controlID="v_species_Rabbits">
                                                                                    <Form.Check
                                                                                        id="v_species_Rabbits"
                                                                                        name="v_species"
                                                                                        type="checkbox"
                                                                                        label="(R) Rabbits"
                                                                                        value="R"
                                                                                        checked={
                                                                                            personInput.v_species &&
                                                                                            personInput.v_species
                                                                                                .split(
                                                                                                    ""
                                                                                                )
                                                                                                .indexOf(
                                                                                                    "R"
                                                                                                ) !=
                                                                                                -1
                                                                                        }
                                                                                        onChange={
                                                                                            handlePersonChange
                                                                                        }
                                                                                        disabled={
                                                                                            disableSpecies
                                                                                        }
                                                                                    />
                                                                                </Form.Group>
                                                                            </ListGroup.Item>
                                                                            <ListGroup.Item>
                                                                                <Form.Group controlID="v_species_o">
                                                                                    <Form.Control
                                                                                        id="v_species_o"
                                                                                        name="v_species_o"
                                                                                        type="text"
                                                                                        className="mb-0"
                                                                                        placeholder="Other"
                                                                                        value={
                                                                                            personInput.v_species_o
                                                                                        }
                                                                                        onChange={
                                                                                            handlePersonChange
                                                                                        }
                                                                                    />
                                                                                </Form.Group>
                                                                            </ListGroup.Item>
                                                                        </Col>
                                                                    </Row>
                                                                </Form>
                                                            </ListGroup>
                                                        </Col>
                                                    </Row>
                                                </Tab>
                                                <Tab
                                                    eventKey="qualifications"
                                                    title="Qualifications"
                                                    className="row-px-2"
                                                >
                                                    <Row className="gx-2">
                                                        <Col lg={3}>
                                                            <Form.Label>
                                                                Suffix
                                                                {/* <span className="jazzberryJam-text ms-1">*</span> */}
                                                            </Form.Label>
                                                            <Form.Control
                                                                className={
                                                                    !personInput.u_suffix &&
                                                                    error.Suffixname
                                                                        ? "invalid"
                                                                        : ""
                                                                }
                                                                name="u_suffix"
                                                                type="text"
                                                                value={
                                                                    personInput.u_suffix
                                                                }
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                            {!personInput.u_suffix && (
                                                                <ErrorMessage
                                                                    error={
                                                                        error.u_suffix
                                                                    }
                                                                />
                                                            )}
                                                        </Col>
                                                        <Col lg={3}>
                                                            <Form.Label>
                                                                Certs & Dips
                                                            </Form.Label>
                                                            <Form.Control
                                                                name="u_cert"
                                                                type="text"
                                                                value={
                                                                    personInput.u_cert
                                                                }
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Col>

                                                        <Col lg={3}>
                                                            <Form.Label>
                                                                Uni Year
                                                            </Form.Label>
                                                            <Form.Control
                                                                name="u_year"
                                                                as="select"
                                                                className="form-select"
                                                                value={
                                                                    personInput.u_year
                                                                }
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            >
                                                                <option value="">
                                                                    Select year
                                                                </option>
                                                                {Array.from(
                                                                    {
                                                                        length: 91,
                                                                    },
                                                                    (_, i) =>
                                                                        2030 - i
                                                                ).map(
                                                                    (
                                                                        curr,
                                                                        ind
                                                                    ) => (
                                                                        <option
                                                                            key={
                                                                                ind
                                                                            }
                                                                            value={
                                                                                curr
                                                                            }
                                                                        >
                                                                            {
                                                                                curr
                                                                            }
                                                                        </option>
                                                                    )
                                                                )}
                                                            </Form.Control>
                                                        </Col>
                                                        <Col lg={3}>
                                                            <Form.Label>
                                                                University
                                                            </Form.Label>
                                                            <div
                                                                className="position-relative university-dropdown"
                                                                ref={ref}
                                                            >
                                                                <Form.Control
                                                                    name="u_uni"
                                                                    type="text"
                                                                    size="sm"
                                                                    value={
                                                                        personInput.u_uni
                                                                    }
                                                                    placeholder=""
                                                                    autoComplete="nope"
                                                                    onChange={
                                                                        handleUniKeywordChange
                                                                    }
                                                                />
                                                                {uni_data &&
                                                                    uniBar &&
                                                                    uni_data
                                                                        .data
                                                                        .length >
                                                                        0 && (
                                                                        <div className="address-dropdown">
                                                                            <PerfectScrollbar>
                                                                                {uni_data.data.map(
                                                                                    (
                                                                                        curr,
                                                                                        index
                                                                                    ) => (
                                                                                        <p
                                                                                            key={
                                                                                                index
                                                                                            }
                                                                                            onClick={() => {
                                                                                                handleSetUni(
                                                                                                    curr.uni
                                                                                                );
                                                                                                handleSetCountry(
                                                                                                    curr.country
                                                                                                );
                                                                                                setUniBar(
                                                                                                    false
                                                                                                );
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                curr.uni
                                                                                            }
                                                                                            ,{" "}
                                                                                            {
                                                                                                curr.country
                                                                                            }
                                                                                        </p>
                                                                                    )
                                                                                )}
                                                                            </PerfectScrollbar>
                                                                        </div>
                                                                    )}
                                                            </div>
                                                        </Col>

                                                        <Col lg={3}>
                                                            <Form.Label>
                                                                Country
                                                            </Form.Label>
                                                            <Form.Control
                                                                name="u_country"
                                                                type="text"
                                                                value={
                                                                    personInput.u_country
                                                                }
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Col>

                                                        <Col lg={3}>
                                                            <Form.Label>
                                                                RCVS Number
                                                            </Form.Label>
                                                            <Form.Control
                                                                name="v_rcvsnum"
                                                                type="text"
                                                                value={
                                                                    personInput.v_rcvsnum
                                                                }
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Col>

                                                        <Col lg={3}>
                                                            <Form.Label>
                                                                VMG
                                                            </Form.Label>
                                                            <Form.Control
                                                                name="v_vpma"
                                                                as="select"
                                                                className="form-select"
                                                                value={
                                                                    personInput.v_vpma
                                                                }
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            >
                                                                <option>
                                                                    Yes
                                                                </option>
                                                                <option>
                                                                    No
                                                                </option>
                                                            </Form.Control>
                                                        </Col>
                                                    </Row>

                                                    {/* <div className="custom-table">
                                                    <Table className="table-curved">
                                                        <thead>
                                                            <tr>
                                                                <th>Qualification</th>
                                                                <th>Details</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Suffix</td>
                                                                <td>
                                                                    <Form.Label>
                                                                        Suffix<span className="jazzberryJam-text ms-1">*</span>
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        className={(personInput.u_suffix && !error.name) ? "" : "invalid"}
                                                                        name="u_suffix"
                                                                        type="text"
                                                                        value={personInput.u_suffix}
                                                                        onChange={handlePersonChange}
                                                                    />
                                                                    {!personInput.u_suffix && <ErrorMessage error={error.u_suffix} />}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Certs & Dips</td>
                                                                <td>
                                                                    <Form.Control
                                                                        name="u_cert"
                                                                        type="text"
                                                                        value={personInput.u_cert}
                                                                        onChange={handlePersonChange}
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Uni Year</td>
                                                                <td>
                                                                    <Form.Control
                                                                        name="u_year"
                                                                        as="select"
                                                                        className="form-select"
                                                                        value={personInput.u_year}
                                                                        onChange={handlePersonChange}
                                                                    >
                                                                        <option value="">Select year</option>
                                                                        {Array.from({ length: 91 }, (_, i) => 2030 - i).map((curr, ind) => (
                                                                            <option key={ind} value={curr}>{curr}</option>
                                                                        ))
                                                                        }
                                                                    </Form.Control>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>University</td>
                                                                <td>
                                                                    <Form.Control
                                                                        name="u_uni"
                                                                        type="text"
                                                                        value={personInput.u_uni}
                                                                        onChange={handlePersonChange}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <div className="position-relative university-dropdown" ref={ref}>
                                                                        <Form.Control
                                                                            name="u_uni"
                                                                            type="text"
                                                                            size="sm"
                                                                            value={personInput.u_uni}
                                                                            placeholder=""
                                                                            autoComplete="nope"
                                                                            onChange={handleUniKeywordChange}

                                                                        />
                                                                        {uni_data && uniBar && uni_data.data.length > 0 &&
                                                                            <div className="address-dropdown">
                                                                                <PerfectScrollbar>
                                                                                    {uni_data.data.map((curr, index) =>
                                                                                        <p key={index} onClick={() => { handleSetUni(curr.uni); handleSetCountry(curr.country); setUniBar(false); }}>{curr.uni}, {curr.country}</p>
                                                                                    )}
                                                                                </PerfectScrollbar>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Country</td>
                                                                <td>
                                                                    <Form.Control
                                                                        name="u_country"
                                                                        type="text"
                                                                        value={personInput.u_country}
                                                                        onChange={handlePersonChange}
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>RCVS Number</td>
                                                                <td>
                                                                    <Form.Control
                                                                        name="v_rcvsnum"
                                                                        type="text"
                                                                        value={personInput.v_rcvsnum}
                                                                        onChange={handlePersonChange}
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>VMG</td>
                                                                <td>
                                                                    <Form.Control
                                                                        name="v_vpma"
                                                                        as="select"
                                                                        className="form-select"
                                                                        value={personInput.v_vpma}
                                                                        onChange={handlePersonChange}
                                                                    >
                                                                        <option>Yes</option>
                                                                        <option>No</option>
                                                                    </Form.Control>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </div> */}
                                                </Tab>
                                                <Tab
                                                    eventKey="panels"
                                                    title="Panels"
                                                    className="row-px-2"
                                                >
                                                    <Row className="gx-2">
                                                        <Col lg={4}>
                                                            <Form.Label>
                                                                Vet Panel
                                                            </Form.Label>
                                                            <Form.Control
                                                                name="v_vet_panel"
                                                                type="text"
                                                                className="form-select"
                                                                as="select"
                                                                value={
                                                                    personInput.v_vet_panel
                                                                }
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            >
                                                                <option value="Yes">
                                                                    Yes
                                                                </option>
                                                                <option value="No">
                                                                    No
                                                                </option>
                                                            </Form.Control>
                                                            {/*{!personInput.u_suffix && <ErrorMessage error={error.u_suffix} />}*/}
                                                        </Col>
                                                        <Col lg={4}>
                                                            <Form.Label>
                                                                VN Panel
                                                            </Form.Label>
                                                            <Form.Control
                                                                name="v_vn_panel"
                                                                type="text"
                                                                className="form-select"
                                                                as="select"
                                                                value={
                                                                    personInput.v_vn_panel
                                                                }
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            >
                                                                <option value="Yes">
                                                                    Yes
                                                                </option>
                                                                <option value="No">
                                                                    No
                                                                </option>
                                                            </Form.Control>
                                                        </Col>

                                                        <Col lg={4}>
                                                            <Form.Label>
                                                                Practice Manager
                                                                Panel
                                                            </Form.Label>
                                                            <Form.Control
                                                                name="v_practice_manager_panel"
                                                                type="text"
                                                                className="form-select"
                                                                as="select"
                                                                value={
                                                                    personInput.v_practice_manager_panel
                                                                }
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            >
                                                                <option value="Yes">
                                                                    Yes
                                                                </option>
                                                                <option value="No">
                                                                    No
                                                                </option>
                                                            </Form.Control>
                                                        </Col>
                                                    </Row>

                                                    {/* <div className="custom-table">
                                                    <Table className="table-curved">
                                                        <thead>
                                                            <tr>
                                                                <th>Qualification</th>
                                                                <th>Details</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Suffix</td>
                                                                <td>
                                                                    <Form.Label>
                                                                        Suffix<span className="jazzberryJam-text ms-1">*</span>
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        className={(personInput.u_suffix && !error.name) ? "" : "invalid"}
                                                                        name="u_suffix"
                                                                        type="text"
                                                                        value={personInput.u_suffix}
                                                                        onChange={handlePersonChange}
                                                                    />
                                                                    {!personInput.u_suffix && <ErrorMessage error={error.u_suffix} />}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Certs & Dips</td>
                                                                <td>
                                                                    <Form.Control
                                                                        name="u_cert"
                                                                        type="text"
                                                                        value={personInput.u_cert}
                                                                        onChange={handlePersonChange}
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Uni Year</td>
                                                                <td>
                                                                    <Form.Control
                                                                        name="u_year"
                                                                        as="select"
                                                                        className="form-select"
                                                                        value={personInput.u_year}
                                                                        onChange={handlePersonChange}
                                                                    >
                                                                        <option value="">Select year</option>
                                                                        {Array.from({ length: 91 }, (_, i) => 2030 - i).map((curr, ind) => (
                                                                            <option key={ind} value={curr}>{curr}</option>
                                                                        ))
                                                                        }
                                                                    </Form.Control>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>University</td>
                                                                <td>
                                                                    <Form.Control
                                                                        name="u_uni"
                                                                        type="text"
                                                                        value={personInput.u_uni}
                                                                        onChange={handlePersonChange}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <div className="position-relative university-dropdown" ref={ref}>
                                                                        <Form.Control
                                                                            name="u_uni"
                                                                            type="text"
                                                                            size="sm"
                                                                            value={personInput.u_uni}
                                                                            placeholder=""
                                                                            autoComplete="nope"
                                                                            onChange={handleUniKeywordChange}

                                                                        />
                                                                        {uni_data && uniBar && uni_data.data.length > 0 &&
                                                                            <div className="address-dropdown">
                                                                                <PerfectScrollbar>
                                                                                    {uni_data.data.map((curr, index) =>
                                                                                        <p key={index} onClick={() => { handleSetUni(curr.uni); handleSetCountry(curr.country); setUniBar(false); }}>{curr.uni}, {curr.country}</p>
                                                                                    )}
                                                                                </PerfectScrollbar>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Country</td>
                                                                <td>
                                                                    <Form.Control
                                                                        name="u_country"
                                                                        type="text"
                                                                        value={personInput.u_country}
                                                                        onChange={handlePersonChange}
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>RCVS Number</td>
                                                                <td>
                                                                    <Form.Control
                                                                        name="v_rcvsnum"
                                                                        type="text"
                                                                        value={personInput.v_rcvsnum}
                                                                        onChange={handlePersonChange}
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>VMG</td>
                                                                <td>
                                                                    <Form.Control
                                                                        name="v_vpma"
                                                                        as="select"
                                                                        className="form-select"
                                                                        value={personInput.v_vpma}
                                                                        onChange={handlePersonChange}
                                                                    >
                                                                        <option>Yes</option>
                                                                        <option>No</option>
                                                                    </Form.Control>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </div> */}
                                                </Tab>
                                                {/* <Tab eventKey="address" title="Home address" className="row-px-2"> */}
                                                {/* <Row className="gx-2">
                                                    <Col lg={3}>
                                                        <Form.Label>Find your address</Form.Label>
                                                        <div className="find-address">
                                                        <span className="address-icon" onClick={handlePostcodeSubmit} ><Image src={addressIcon} />search</span>                                                            <Form.Control
                                                                name="postcode"
                                                                type="text"
                                                                size="sm"
                                                                placeholder="Enter your Postcode"
                                                                autoComplete="nope"
                                                                value={postcode}
                                                                onChange={handlePostcodeChange}
                                                            />
                                                            {postcodeMenuShow && data.hasOwnProperty("arr") && Array.from(data.arr).length > 0 &&
                                                                <div ref={ref} className="address-dropdown">
                                                                    <PerfectScrollbar>
                                                                        {Array.from(data.arr).map((curr, index) =>
                                                                            <p key={index} onClick={() => { handleSetAddress(curr) }}>{curr.display}</p>
                                                                        )}
                                                                    </PerfectScrollbar>
                                                                </div>
                                                            }
                                                        </div>
                                                    </Col>
                                                    <Col lg={3}>
                                                        <Form.Label>Address line 1</Form.Label>
                                                        <Form.Control
                                                            name="v_address_1"
                                                            type="text"
                                                            value={personInput.v_address_1}
                                                            onChange={handlePersonChange}
                                                            className={(!personInput.v_address_1 && error.address) ? 'invalid' : ''}
                                                        />
                                                    </Col>

                                                    <Col lg={3}>
                                                        <Form.Label>Address line 2</Form.Label>
                                                        <Form.Control
                                                            name="v_address_2"
                                                            type="text"
                                                            value={personInput.v_address_2}
                                                            onChange={handlePersonChange}
                                                        />
                                                    </Col>

                                                    <Col lg={3}>
                                                        <Form.Label>Address line 3</Form.Label>
                                                        <Form.Control
                                                            name="v_address_3"
                                                            type="text"
                                                            value={personInput.v_address_3}
                                                            onChange={handlePersonChange}
                                                        />
                                                    </Col>

                                                    <Col lg={3}>
                                                        <Form.Label>Town / City</Form.Label>
                                                        <Form.Control
                                                            name="v_town"
                                                            type="text"
                                                            value={personInput.v_town}
                                                            onChange={handlePersonChange}
                                                            className={(!personInput.v_town && error.town) ? 'invalid' : ''}
                                                        />
                                                    </Col>

                                                    <Col lg={3}>
                                                        <Form.Label>County</Form.Label>
                                                        <Form.Control
                                                            name="v_county"
                                                            type="text"
                                                            value={personInput.v_county}
                                                            onChange={handlePersonChange}
                                                            className={(!personInput.v_county && error.county) ? 'invalid' : ''}
                                                        />
                                                    </Col>


                                                    <Col lg={3}>
                                                        <Form.Label>Postcode</Form.Label>
                                                        <Form.Control
                                                            name="v_postcode"
                                                            type="text"
                                                            value={personInput.v_postcode}
                                                            onChange={handlePersonChange}
                                                            className={(!personInput.v_postcode && error.postcode) ? 'invalid' : ''}
                                                        />
                                                    </Col>

                                                    <Col lg={3}>
                                                        <Form.Label>Country</Form.Label>
                                                        <Form.Control
                                                            name="v_country"
                                                            type="text"
                                                            value={personInput.v_country}
                                                            onChange={handlePersonChange}
                                                        />
                                                    </Col>

                                                    <Col lg={3}>
                                                        <Form.Label>Primary email</Form.Label>
                                                        <Form.Control
                                                            name="v_email"
                                                            type="text"
                                                            value={personInput.v_email}
                                                            onChange={handlePersonChange}
                                                        />
                                                    </Col>

                                                    <Col lg={3}>
                                                        <Form.Label>Secondary email</Form.Label>
                                                        <Form.Control
                                                            name="v_work_email"
                                                            type="text"
                                                            value={personInput.v_work_email}
                                                            onChange={handlePersonChange}
                                                        />
                                                    </Col>

                                                    <Col lg={3}>
                                                        <Form.Label>SSO email</Form.Label>
                                                        <Form.Control
                                                           disabled
                                                            value={personInput.sso_email}

                                                        />
                                                    </Col>

                                                    <Col lg={3}>
                                                        <Form.Label>Mob.</Form.Label>
                                                        <MaskedInput
                                                            mask={['+', /[1-9]/, /[1-9]/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ']}
                                                            guide={false}
                                                            value={personInput.v_tel}
                                                            name="v_tel"
                                                            className="form-control"
                                                            onChange={handlePersonChange}
                                                        />
                                                    </Col>
                                                </Row> */}
                                                {/* <div className="custom-table">
                                                    <Table className="table-curved">
                                                        <thead>
                                                            <tr>
                                                                <th>Home Address</th>
                                                                <th>Details</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Find your address</td>
                                                                <td>
                                                                    <div className="find-address">
                                                                        <span className="search-icon"><Image src={searchIcon} onClick={handlePostcodeSubmit} /><span onClick={() => { setPostCodeMenuShow(true) }} className="search-arrow"></span></span>
                                                                        <span className="cancel-icon"><Image src={cancelIcon} /></span>
                                                                        <Form.Control
                                                                            name="postcode"
                                                                            type="text"
                                                                            size="sm"
                                                                            placeholder="Enter your Postcode"
                                                                            autoComplete="nope"
                                                                            onChange={handlePostcodeChange}
                                                                        />
                                                                        {postcodeMenuShow && data.hasOwnProperty("arr") && Array.from(data.arr).length > 0 &&
                                                                            <div ref={ref} className="address-dropdown">
                                                                                <PerfectScrollbar>
                                                                                    {Array.from(data.arr).map((curr, index) =>
                                                                                        <p key={index} onClick={() => { handleSetAddress(curr) }}>{curr.display}</p>
                                                                                    )}
                                                                                </PerfectScrollbar>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Address line 1</td>
                                                                <td>
                                                                    <Form.Control
                                                                        name="v_address_1"
                                                                        type="text"
                                                                        value={personInput.v_address_1}
                                                                        onChange={handlePersonChange}
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Address line 2</td>
                                                                <td>
                                                                    <Form.Control
                                                                        name="v_address_2"
                                                                        type="text"
                                                                        value={personInput.v_address_2}
                                                                        onChange={handlePersonChange}
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Address line 3</td>
                                                                <td>
                                                                    <Form.Control
                                                                        name="v_address_3"
                                                                        type="text"
                                                                        value={personInput.v_address_3}
                                                                        onChange={handlePersonChange}
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Town / City</td>
                                                                <td>
                                                                    <Form.Control
                                                                        name="v_town"
                                                                        type="text"
                                                                        value={personInput.v_town}
                                                                        onChange={handlePersonChange}
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>County</td>
                                                                <td>
                                                                    <Form.Control
                                                                        name="v_county"
                                                                        type="text"
                                                                        value={personInput.v_county}
                                                                        onChange={handlePersonChange}
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Postcode</td>
                                                                <td>
                                                                    <Form.Control
                                                                        name="v_postcode"
                                                                        type="text"
                                                                        value={personInput.v_postcode}
                                                                        onChange={handlePersonChange}
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Country</td>
                                                                <td>
                                                                    <Form.Control
                                                                        name="v_country"
                                                                        type="text"
                                                                        value={personInput.v_country}
                                                                        onChange={handlePersonChange}
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Primary email</td>
                                                                <td>
                                                                    <Form.Control
                                                                        name="v_email"
                                                                        type="text"
                                                                        value={personInput.v_email}
                                                                        onChange={handlePersonChange}
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Secondary email</td>
                                                                <td>
                                                                    <Form.Control
                                                                        name="v_work_email"
                                                                        type="text"
                                                                        value={personInput.v_work_email}
                                                                        onChange={handlePersonChange}
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>SSO Email</td>
                                                                <td>
                                                                    <Form.Control
                                                                        name=""
                                                                        type="text"
                                                                        // value={personInput.v_country}
                                                                        onChange={handlePersonChange}
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Mob.</td>
                                                                <td>
                                                                    <Form.Control
                                                            name="v_tel"
                                                            type="text"
                                                            value={personInput.v_tel}
                                                            onChange={handlePersonChange}
                                                        />
                                                                    <MaskedInput
                                                                        mask={['+', /[1-9]/, /[1-9]/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ']}
                                                                        guide={false}
                                                                        value={personInput.v_tel}
                                                                        name="v_tel"
                                                                        className="form-control"
                                                                        onChange={handlePersonChange}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </div> */}
                                                {/* </Tab> */}
                                            </Tabs>
                                        </Tab>
                                    )}

                                <Tab
                                    eventKey="subscriptions"
                                    title="SUBSCRIPTIONS"
                                >
                                    <Tabs className="secondary-tabs row-px-2">
                                        <Tab
                                            eventKey="subscriptions-sec"
                                            title="Subscriptions"
                                            className="row-px-2 subscriptions-sec"
                                        >
                                            <Row>
                                                <Col lg={12}>
                                                    <div className="custom-table">
                                                        <Table className="table-curved">
                                                            <thead>
                                                                {personInput
                                                                    .practice_data
                                                                    .office &&
                                                                personInput.practice_data.office.split(
                                                                    " "
                                                                )[0] !==
                                                                    "Industry" ? (
                                                                    <tr>
                                                                        <th>
                                                                            Publications
                                                                        </th>
                                                                        <th>
                                                                            Request
                                                                            Type
                                                                        </th>
                                                                        <th width="1%">
                                                                            Request
                                                                            date
                                                                        </th>
                                                                        <th>
                                                                            ABC
                                                                            name
                                                                        </th>
                                                                        <th>
                                                                            ABC
                                                                            answer
                                                                        </th>
                                                                        <th width="1%">
                                                                            Livestock
                                                                        </th>
                                                                        <th width="1%">
                                                                            Equine
                                                                        </th>
                                                                    </tr>
                                                                ) : (
                                                                    <tr>
                                                                        <th>
                                                                            Publications
                                                                        </th>
                                                                        <th width="1%">
                                                                            Start
                                                                            Date
                                                                        </th>
                                                                        <th width="1%">
                                                                            End
                                                                            date
                                                                        </th>
                                                                        <th>
                                                                            Cost
                                                                        </th>
                                                                        {/* <th>ABC answer</th> */}
                                                                        <th width="1%">
                                                                            Livestock
                                                                        </th>
                                                                        <th width="1%">
                                                                            Equine
                                                                        </th>
                                                                    </tr>
                                                                )}
                                                            </thead>
                                                            {personInput
                                                                .practice_data
                                                                .office &&
                                                            personInput.practice_data.office.split(
                                                                " "
                                                            )[0] !==
                                                                "Industry" ? (
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="publications-col">
                                                                            <div className="d-flex align-items-center justify-content-between">
                                                                                <span className="white-space-pre me-3">
                                                                                    Vet
                                                                                    Times
                                                                                </span>
                                                                                <Form.Control
                                                                                    name="v_svt"
                                                                                    as="select"
                                                                                    className="form-select"
                                                                                    value={
                                                                                        personInput.v_svt
                                                                                    }
                                                                                    onChange={
                                                                                        handlePersonChange
                                                                                    }
                                                                                >
                                                                                    <option value="Yes">
                                                                                        Yes
                                                                                    </option>
                                                                                    <option value="No">
                                                                                        No
                                                                                    </option>
                                                                                    <option value="CBP">
                                                                                        CBP
                                                                                    </option>
                                                                                </Form.Control>
                                                                            </div>
                                                                        </td>
                                                                        <td className="request-type-col">
                                                                            <Form.Control
                                                                                name="v_svt_request"
                                                                                as="select"
                                                                                className="form-select"
                                                                                value={
                                                                                    personInput.v_svt_request
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            >
                                                                                <option
                                                                                    value="Phone"
                                                                                    defaultValue=""
                                                                                >
                                                                                    Phone
                                                                                </option>
                                                                                <option value="Written">
                                                                                    Written
                                                                                </option>
                                                                                <option value="Electronic">
                                                                                    Electronic
                                                                                </option>
                                                                                <option value="Website">
                                                                                    Website
                                                                                </option>
                                                                                <option value="C Phone">
                                                                                    C
                                                                                    Phone
                                                                                </option>
                                                                                <option value="C Written">
                                                                                    C
                                                                                    Written
                                                                                </option>
                                                                                <option value="C Electronic">
                                                                                    C
                                                                                    Electronic
                                                                                </option>
                                                                                <option value="C Website">
                                                                                    C
                                                                                    Website
                                                                                </option>
                                                                            </Form.Control>
                                                                        </td>
                                                                        <td>
                                                                            <Form.Control
                                                                                name="v_svt_request_date"
                                                                                placeholderText="dd/mm/yyyy"
                                                                                type="date"
                                                                                max="2999-12-31"
                                                                                value={
                                                                                    personInput.v_svt_request_date
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            ></Form.Control>
                                                                        </td>
                                                                        <td>
                                                                            <Form.Control
                                                                                name="v_svt_abc_name"
                                                                                type="text"
                                                                                value={
                                                                                    personInput.v_svt_abc_name
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <Form.Control
                                                                                name="v_svt_abc_answer"
                                                                                type="text"
                                                                                value={
                                                                                    personInput.v_svt_abc_answer
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            />
                                                                        </td>
                                                                        <td className="livestock-col">
                                                                            <Form.Control
                                                                                name="v_svtl"
                                                                                as="select"
                                                                                className="form-select"
                                                                                value={
                                                                                    personInput.v_svtl
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                                disabled={
                                                                                    personInput.v_svt ===
                                                                                    "No"
                                                                                }
                                                                            >
                                                                                <option value="Yes">
                                                                                    Yes
                                                                                </option>
                                                                                <option
                                                                                    value="No"
                                                                                    defaultValue=""
                                                                                >
                                                                                    No
                                                                                </option>
                                                                                <option value="CPB">
                                                                                    CPB
                                                                                </option>
                                                                            </Form.Control>
                                                                        </td>
                                                                        <td className="equine-col">
                                                                            <Form.Control
                                                                                name="v_svte"
                                                                                as="select"
                                                                                className="form-select"
                                                                                value={
                                                                                    personInput.v_svte
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                                disabled={
                                                                                    personInput.v_svt ===
                                                                                    "No"
                                                                                }
                                                                            >
                                                                                <option value="Yes">
                                                                                    Yes
                                                                                </option>
                                                                                <option
                                                                                    value="No"
                                                                                    defaultValue=""
                                                                                >
                                                                                    No
                                                                                </option>
                                                                                <option value="CPB">
                                                                                    CPB
                                                                                </option>
                                                                            </Form.Control>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="publications-col">
                                                                            <div className="d-flex align-items-center  justify-content-between">
                                                                                <span className="white-space-pre me-3">
                                                                                    VN
                                                                                    Times
                                                                                </span>

                                                                                <Form.Control
                                                                                    name="v_svnt"
                                                                                    as="select"
                                                                                    className="form-select"
                                                                                    value={
                                                                                        personInput.v_svnt
                                                                                    }
                                                                                    onChange={
                                                                                        handlePersonChange
                                                                                    }
                                                                                >
                                                                                    <option value="Yes">
                                                                                        Yes
                                                                                    </option>
                                                                                    <option
                                                                                        value="No"
                                                                                        defaultValue=""
                                                                                    >
                                                                                        No
                                                                                    </option>
                                                                                    <option value="CBP">
                                                                                        CBP
                                                                                    </option>
                                                                                </Form.Control>
                                                                            </div>
                                                                        </td>
                                                                        <td className="request-type-col">
                                                                            <Form.Control
                                                                                name="v_svnt_request"
                                                                                as="select"
                                                                                className="form-select"
                                                                                value={
                                                                                    personInput.v_svnt_request
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            >
                                                                                <option value="Phone">
                                                                                    Phone
                                                                                </option>
                                                                                <option value="Written">
                                                                                    Written
                                                                                </option>
                                                                                <option value="Electronic">
                                                                                    Electronic
                                                                                </option>
                                                                                <option value="Website">
                                                                                    Website
                                                                                </option>
                                                                                <option value="C Phone">
                                                                                    C
                                                                                    Phone
                                                                                </option>
                                                                                <option value="C Written">
                                                                                    C
                                                                                    Written
                                                                                </option>
                                                                                <option value="C Electronic">
                                                                                    C
                                                                                    Electronic
                                                                                </option>
                                                                                <option value="C Website">
                                                                                    C
                                                                                    Website
                                                                                </option>
                                                                            </Form.Control>
                                                                        </td>
                                                                        <td>
                                                                            <Form.Control
                                                                                name="v_svnt_request_date"
                                                                                placeholderText="dd/mm/yyyy"
                                                                                type="date"
                                                                                max="2999-12-31"
                                                                                value={
                                                                                    personInput.v_svnt_request_date
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            ></Form.Control>
                                                                        </td>
                                                                        <td>
                                                                            <Form.Control
                                                                                name="v_svnt_abc_name"
                                                                                type="text"
                                                                                value={
                                                                                    personInput.v_svnt_abc_name
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <Form.Control
                                                                                name="v_svnt_abc_answer"
                                                                                type="text"
                                                                                value={
                                                                                    personInput.v_svnt_abc_answer
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            />
                                                                        </td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="publications-col">
                                                                            <div className="d-flex align-items-center justify-content-between">
                                                                                <span className="white-space-pre me-3">
                                                                                    VBJ
                                                                                </span>
                                                                                <Form.Control
                                                                                    name="v_svbj"
                                                                                    as="select"
                                                                                    className="form-select"
                                                                                    value={
                                                                                        personInput.v_svbj
                                                                                    }
                                                                                    onChange={
                                                                                        handlePersonChange
                                                                                    }
                                                                                >
                                                                                    <option value="Yes">
                                                                                        Yes
                                                                                    </option>
                                                                                    <option
                                                                                        value="No"
                                                                                        defaultValue=""
                                                                                    >
                                                                                        No
                                                                                    </option>
                                                                                    <option value="CBP">
                                                                                        CBP
                                                                                    </option>
                                                                                </Form.Control>
                                                                            </div>
                                                                        </td>
                                                                        <td className="request-type-col">
                                                                            <Form.Control
                                                                                name="v_svbj_request"
                                                                                as="select"
                                                                                className="form-select"
                                                                                value={
                                                                                    personInput.v_svbj_request
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            >
                                                                                <option value="Phone">
                                                                                    Phone
                                                                                </option>
                                                                                <option value="Written">
                                                                                    Written
                                                                                </option>
                                                                                <option value="Electronic">
                                                                                    Electronic
                                                                                </option>
                                                                                <option value="Website">
                                                                                    Website
                                                                                </option>
                                                                                <option value="C Phone">
                                                                                    C
                                                                                    Phone
                                                                                </option>
                                                                                <option value="C Written">
                                                                                    C
                                                                                    Written
                                                                                </option>
                                                                                <option value="C Electronic">
                                                                                    C
                                                                                    Electronic
                                                                                </option>
                                                                                <option value="C Website">
                                                                                    C
                                                                                    Website
                                                                                </option>
                                                                            </Form.Control>
                                                                        </td>
                                                                        <td>
                                                                            <Form.Control
                                                                                name="v_svbj_request_date"
                                                                                placeholderText="dd/mm/yyyy"
                                                                                type="date"
                                                                                max="2999-12-31"
                                                                                value={
                                                                                    personInput.v_svbj_request_date
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            ></Form.Control>
                                                                        </td>
                                                                        <td>
                                                                            <Form.Control
                                                                                name="v_svbj_abc_name"
                                                                                type="text"
                                                                                value={
                                                                                    personInput.v_svbj_abc_name
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <Form.Control
                                                                                name="v_svbj_abc_answer"
                                                                                type="text"
                                                                                value={
                                                                                    personInput.v_svbj_abc_answer
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            />
                                                                        </td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                </tbody>
                                                            ) : (
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="publications-col">
                                                                            <div className="d-flex align-items-center justify-content-between">
                                                                                <span className="white-space-pre me-3">
                                                                                    Vet
                                                                                    Times
                                                                                </span>
                                                                                <Form.Control
                                                                                    name="v_svt"
                                                                                    as="select"
                                                                                    className="form-select"
                                                                                    value={
                                                                                        personInput.v_svt
                                                                                    }
                                                                                    onChange={
                                                                                        handlePersonChange
                                                                                    }
                                                                                >
                                                                                    <option value="Yes">
                                                                                        Yes
                                                                                    </option>
                                                                                    <option
                                                                                        value="No"
                                                                                        defaultValue=""
                                                                                    >
                                                                                        No
                                                                                    </option>
                                                                                    <option value="CBP">
                                                                                        CBP
                                                                                    </option>
                                                                                </Form.Control>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <Form.Control
                                                                                name="ind_vt_start"
                                                                                placeholderText="dd/mm/yyyy"
                                                                                type="date"
                                                                                max="2999-12-31"
                                                                                value={
                                                                                    personInput.ind_vt_start
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            ></Form.Control>
                                                                        </td>
                                                                        <td>
                                                                            <Form.Control
                                                                                name="ind_vt_end"
                                                                                placeholderText="dd/mm/yyyy"
                                                                                type="date"
                                                                                max="2999-12-31"
                                                                                value={
                                                                                    personInput.ind_vt_end
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            ></Form.Control>
                                                                        </td>
                                                                        <td>
                                                                            <Form.Control
                                                                                name="ind_vt_cost"
                                                                                type="number"
                                                                                value={
                                                                                    personInput.ind_vt_cost
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            />
                                                                        </td>

                                                                        <td className="livestock-col">
                                                                            <Form.Control
                                                                                name="v_svtl"
                                                                                as="select"
                                                                                className="form-select"
                                                                                value={
                                                                                    personInput.v_svtl
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            >
                                                                                <option value="Yes">
                                                                                    Yes
                                                                                </option>
                                                                                <option
                                                                                    value="No"
                                                                                    defaultValue=""
                                                                                >
                                                                                    No
                                                                                </option>
                                                                                <option value="CPB">
                                                                                    CPB
                                                                                </option>
                                                                            </Form.Control>
                                                                        </td>
                                                                        <td className="equine-col">
                                                                            <Form.Control
                                                                                name="v_svte"
                                                                                as="select"
                                                                                className="form-select"
                                                                                value={
                                                                                    personInput.v_svte
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            >
                                                                                <option value="Yes">
                                                                                    Yes
                                                                                </option>
                                                                                <option
                                                                                    value="No"
                                                                                    defaultValue=""
                                                                                >
                                                                                    No
                                                                                </option>
                                                                                <option value="CPB">
                                                                                    CPB
                                                                                </option>
                                                                            </Form.Control>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="publications-col">
                                                                            <div className="d-flex align-items-center  justify-content-between">
                                                                                <span className="white-space-pre me-3">
                                                                                    VN
                                                                                    Times
                                                                                </span>

                                                                                <Form.Control
                                                                                    name="v_svnt"
                                                                                    as="select"
                                                                                    className="form-select"
                                                                                    value={
                                                                                        personInput.v_svnt
                                                                                    }
                                                                                    onChange={
                                                                                        handlePersonChange
                                                                                    }
                                                                                >
                                                                                    <option value="Yes">
                                                                                        Yes
                                                                                    </option>
                                                                                    <option
                                                                                        value="No"
                                                                                        defaultValue=""
                                                                                    >
                                                                                        No
                                                                                    </option>
                                                                                    <option value="CBP">
                                                                                        CBP
                                                                                    </option>
                                                                                </Form.Control>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <Form.Control
                                                                                name="ind_vn_start"
                                                                                placeholderText="dd/mm/yyyy"
                                                                                type="date"
                                                                                max="2999-12-31"
                                                                                value={
                                                                                    personInput.ind_vn_start
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            ></Form.Control>
                                                                        </td>
                                                                        <td>
                                                                            <Form.Control
                                                                                name="ind_vn_end"
                                                                                placeholderText="dd/mm/yyyy"
                                                                                type="date"
                                                                                max="2999-12-31"
                                                                                value={
                                                                                    personInput.ind_vn_end
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            ></Form.Control>
                                                                        </td>
                                                                        <td>
                                                                            <Form.Control
                                                                                name="ind_vn_cost"
                                                                                type="number"
                                                                                value={
                                                                                    personInput.ind_vn_cost
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            />
                                                                        </td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="publications-col">
                                                                            <div className="d-flex align-items-center justify-content-between">
                                                                                <span className="white-space-pre me-3">
                                                                                    VBJ
                                                                                </span>
                                                                                <Form.Control
                                                                                    name="v_svbj"
                                                                                    as="select"
                                                                                    className="form-select"
                                                                                    value={
                                                                                        personInput.v_svbj
                                                                                    }
                                                                                    onChange={
                                                                                        handlePersonChange
                                                                                    }
                                                                                >
                                                                                    <option value="Yes">
                                                                                        Yes
                                                                                    </option>
                                                                                    <option
                                                                                        value="No"
                                                                                        defaultValue=""
                                                                                    >
                                                                                        No
                                                                                    </option>
                                                                                    <option value="CBP">
                                                                                        CBP
                                                                                    </option>
                                                                                </Form.Control>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <Form.Control
                                                                                name="ind_vbj_start"
                                                                                placeholderText="dd/mm/yyyy"
                                                                                type="date"
                                                                                max="2999-12-31"
                                                                                value={
                                                                                    personInput.ind_vbj_start
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            ></Form.Control>
                                                                        </td>
                                                                        <td>
                                                                            <Form.Control
                                                                                name="ind_vbj_end"
                                                                                placeholderText="dd/mm/yyyy"
                                                                                type="date"
                                                                                max="2999-12-31"
                                                                                value={
                                                                                    personInput.ind_vbj_end
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            ></Form.Control>
                                                                        </td>
                                                                        <td>
                                                                            <Form.Control
                                                                                name="ind_vbj_cost"
                                                                                type="number"
                                                                                value={
                                                                                    personInput.ind_vbj_cost
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            />
                                                                        </td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                </tbody>
                                                            )}
                                                        </Table>
                                                    </div>
                                                </Col>
                                            </Row>

                                            {/*Digital editions*/}
                                            <Row>
                                                <Col lg={12}>
                                                    <div className="custom-table mt-3">
                                                        <Table className="table-curved">
                                                            <thead>
                                                                {personInput
                                                                    .practice_data
                                                                    .office &&
                                                                personInput.practice_data.office.split(
                                                                    " "
                                                                )[0] !==
                                                                    "Industry" ? (
                                                                    <tr>
                                                                        <th>
                                                                            Digital
                                                                            editions
                                                                        </th>
                                                                        <th>
                                                                            Request
                                                                            Type
                                                                        </th>
                                                                        <th width="1%">
                                                                            Request
                                                                            date
                                                                        </th>
                                                                        <th>
                                                                            ABC
                                                                            name
                                                                        </th>
                                                                        <th>
                                                                            ABC
                                                                            answer
                                                                        </th>
                                                                        <th width="1%">
                                                                            Livestock
                                                                        </th>
                                                                        <th width="1%">
                                                                            Equine
                                                                        </th>
                                                                    </tr>
                                                                ) : (
                                                                    <tr>
                                                                        <th>
                                                                            Digital
                                                                            editions
                                                                        </th>
                                                                        <th width="1%">
                                                                            Start
                                                                            Date
                                                                        </th>
                                                                        <th width="1%">
                                                                            End
                                                                            date
                                                                        </th>
                                                                        <th>
                                                                            Cost
                                                                        </th>
                                                                        {/* <th>ABC answer</th> */}
                                                                        <th width="1%">
                                                                            Livestock
                                                                        </th>
                                                                        <th width="1%">
                                                                            Equine
                                                                        </th>
                                                                    </tr>
                                                                )}
                                                            </thead>
                                                            {personInput
                                                                .practice_data
                                                                .office &&
                                                            personInput.practice_data.office.split(
                                                                " "
                                                            )[0] !==
                                                                "Industry" ? (
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="publications-col">
                                                                            <div className="d-flex align-items-center justify-content-between">
                                                                                <span className="white-space-pre me-3">
                                                                                    Vet
                                                                                    Times
                                                                                    Digi
                                                                                </span>
                                                                                <Form.Control
                                                                                    name="v_svt_digi"
                                                                                    as="select"
                                                                                    className="form-select"
                                                                                    value={
                                                                                        personInput.v_svt_digi
                                                                                    }
                                                                                    onChange={
                                                                                        handlePersonChange
                                                                                    }
                                                                                >
                                                                                    <option value="Yes">
                                                                                        Yes
                                                                                    </option>
                                                                                    <option value="No">
                                                                                        No
                                                                                    </option>
                                                                                </Form.Control>
                                                                            </div>
                                                                        </td>
                                                                        <td className="request-type-col">
                                                                            <Form.Control
                                                                                name="v_svt_digi_request"
                                                                                as="select"
                                                                                className="form-select"
                                                                                value={
                                                                                    personInput.v_svt_digi_request
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            >
                                                                                <option
                                                                                    value="Phone"
                                                                                    defaultValue=""
                                                                                >
                                                                                    Phone
                                                                                </option>
                                                                                <option value="Written">
                                                                                    Written
                                                                                </option>
                                                                                <option value="Electronic">
                                                                                    Electronic
                                                                                </option>
                                                                                <option value="Website">
                                                                                    Website
                                                                                </option>
                                                                                <option value="C Phone">
                                                                                    C Phone
                                                                                </option>
                                                                                <option value="C Written">
                                                                                    C Written
                                                                                </option>
                                                                                <option value="C Electronic">
                                                                                    C Electronic
                                                                                </option>
                                                                            </Form.Control>
                                                                        </td>
                                                                        <td>
                                                                            <Form.Control
                                                                                name="v_svt_digi_request_date"
                                                                                placeholderText="dd/mm/yyyy"
                                                                                type="date"
                                                                                max="2999-12-31"
                                                                                value={
                                                                                    personInput.v_svt_digi_request_date
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            ></Form.Control>
                                                                        </td>
                                                                        <td>
                                                                            <Form.Control
                                                                                name="v_svt_digi_abc_name"
                                                                                type="text"
                                                                                value={
                                                                                    personInput.v_svt_digi_abc_name
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <Form.Control
                                                                                name="v_svt_digi_abc_answer"
                                                                                type="text"
                                                                                value={
                                                                                    personInput.v_svt_digi_abc_answer
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            />
                                                                        </td>
                                                                        <td className="livestock-col">
                                                                            <Form.Control
                                                                                name="v_svtl_digi"
                                                                                as="select"
                                                                                className="form-select"
                                                                                value={
                                                                                    personInput.v_svtl_digi
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                                disabled={
                                                                                    personInput.v_svt_digi ===
                                                                                    "No"
                                                                                }
                                                                            >
                                                                                <option value="Yes">
                                                                                    Yes
                                                                                </option>
                                                                                <option
                                                                                    value="No"
                                                                                    defaultValue=""
                                                                                >
                                                                                    No
                                                                                </option>
                                                                            </Form.Control>
                                                                        </td>
                                                                        <td className="equine-col">
                                                                            <Form.Control
                                                                                name="v_svte_digi"
                                                                                as="select"
                                                                                className="form-select"
                                                                                value={
                                                                                    personInput.v_svte_digi
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                                disabled={
                                                                                    personInput.v_svt_digi ===
                                                                                    "No"
                                                                                }
                                                                            >
                                                                                <option value="Yes">
                                                                                    Yes
                                                                                </option>
                                                                                <option
                                                                                    value="No"
                                                                                    defaultValue=""
                                                                                >
                                                                                    No
                                                                                </option>
                                                                            </Form.Control>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="publications-col">
                                                                            <div className="d-flex align-items-center  justify-content-between">
                                                                                <span className="white-space-pre me-3">
                                                                                    VN
                                                                                    Times
                                                                                    Digi
                                                                                </span>

                                                                                <Form.Control
                                                                                    name="v_svnt_digi"
                                                                                    as="select"
                                                                                    className="form-select"
                                                                                    value={
                                                                                        personInput.v_svnt_digi
                                                                                    }
                                                                                    onChange={
                                                                                        handlePersonChange
                                                                                    }
                                                                                >
                                                                                    <option value="Yes">
                                                                                        Yes
                                                                                    </option>
                                                                                    <option
                                                                                        value="No"
                                                                                        defaultValue=""
                                                                                    >
                                                                                        No
                                                                                    </option>
                                                                                </Form.Control>
                                                                            </div>
                                                                        </td>
                                                                        <td className="request-type-col">
                                                                            <Form.Control
                                                                                name="v_svnt_digi_request"
                                                                                as="select"
                                                                                className="form-select"
                                                                                value={
                                                                                    personInput.v_svnt_digi_request
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            >
                                                                                <option value="Phone">
                                                                                    Phone
                                                                                </option>
                                                                                <option value="Written">
                                                                                    Written
                                                                                </option>
                                                                                <option value="Electronic">
                                                                                    Electronic
                                                                                </option>
                                                                                <option value="Website">
                                                                                    Website
                                                                                </option>
                                                                                <option value="C Phone">
                                                                                    C
                                                                                    Phone
                                                                                </option>
                                                                                <option value="C Written">
                                                                                    C
                                                                                    Written
                                                                                </option>
                                                                                <option value="C Electronic">
                                                                                    C
                                                                                    Electronic
                                                                                </option>
                                                                                <option value="C Website">
                                                                                    C
                                                                                    Website
                                                                                </option>
                                                                            </Form.Control>
                                                                        </td>
                                                                        <td>
                                                                            <Form.Control
                                                                                name="v_svnt_digi_request_date"
                                                                                placeholderText="dd/mm/yyyy"
                                                                                type="date"
                                                                                max="2999-12-31"
                                                                                value={
                                                                                    personInput.v_svnt_digi_request_date
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            ></Form.Control>
                                                                        </td>
                                                                        <td>
                                                                            <Form.Control
                                                                                name="v_svnt_digi_abc_name"
                                                                                type="text"
                                                                                value={
                                                                                    personInput.v_svnt_digi_abc_name
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <Form.Control
                                                                                name="v_svnt_digi_abc_answer"
                                                                                type="text"
                                                                                value={
                                                                                    personInput.v_svnt_digi_abc_answer
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            />
                                                                        </td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="publications-col">
                                                                            <div className="d-flex align-items-center justify-content-between">
                                                                                <span className="white-space-pre me-3">
                                                                                    VBJ
                                                                                    Digi
                                                                                </span>
                                                                                <Form.Control
                                                                                    name="v_svbj_digi"
                                                                                    as="select"
                                                                                    className="form-select"
                                                                                    value={
                                                                                        personInput.v_svbj_digi
                                                                                    }
                                                                                    onChange={
                                                                                        handlePersonChange
                                                                                    }
                                                                                >
                                                                                    <option value="Yes">
                                                                                        Yes
                                                                                    </option>
                                                                                    <option
                                                                                        value="No"
                                                                                        defaultValue=""
                                                                                    >
                                                                                        No
                                                                                    </option>
                                                                                </Form.Control>
                                                                            </div>
                                                                        </td>
                                                                        <td className="request-type-col">
                                                                            <Form.Control
                                                                                name="v_svbj_digi_request"
                                                                                as="select"
                                                                                className="form-select"
                                                                                value={
                                                                                    personInput.v_svbj_digi_request
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            >
                                                                                <option
                                                                                    value="Phone"
                                                                                    defaultValue=""
                                                                                >
                                                                                    Phone
                                                                                </option>
                                                                                <option value="Written">
                                                                                    Written
                                                                                </option>
                                                                                <option value="Electronic">
                                                                                    Electronic
                                                                                </option>
                                                                                <option value="Website">
                                                                                    Website
                                                                                </option>
                                                                                <option value="C Phone">
                                                                                    C Phone
                                                                                </option>
                                                                                <option value="C Written">
                                                                                    C Written
                                                                                </option>
                                                                                <option value="C Electronic">
                                                                                    C Electronic
                                                                                </option>
                                                                            </Form.Control>
                                                                        </td>
                                                                        <td>
                                                                            <Form.Control
                                                                                name="v_svbj_digi_request_date"
                                                                                placeholderText="dd/mm/yyyy"
                                                                                type="date"
                                                                                max="2999-12-31"
                                                                                value={
                                                                                    personInput.v_svbj_digi_request_date
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            ></Form.Control>
                                                                        </td>
                                                                        <td>
                                                                            <Form.Control
                                                                                name="v_svbj_digi_abc_name"
                                                                                type="text"
                                                                                value={
                                                                                    personInput.v_svbj_digi_abc_name
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <Form.Control
                                                                                name="v_svbj_digi_abc_answer"
                                                                                type="text"
                                                                                value={
                                                                                    personInput.v_svbj_digi_abc_answer
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            />
                                                                        </td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                </tbody>
                                                            ) : (
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="publications-col">
                                                                            <div className="d-flex align-items-center justify-content-between">
                                                                                <span className="white-space-pre me-3">
                                                                                    Vet
                                                                                    Times
                                                                                    Digi
                                                                                </span>
                                                                                <Form.Control
                                                                                    name="v_svt_digi"
                                                                                    as="select"
                                                                                    className="form-select"
                                                                                    value={
                                                                                        personInput.v_svt_digi
                                                                                    }
                                                                                    onChange={
                                                                                        handlePersonChange
                                                                                    }
                                                                                >
                                                                                    <option value="Yes">
                                                                                        Yes
                                                                                    </option>
                                                                                    <option
                                                                                        value="No"
                                                                                        defaultValue=""
                                                                                    >
                                                                                        No
                                                                                    </option>
                                                                                </Form.Control>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <Form.Control
                                                                                name="ind_vt_digi_start"
                                                                                placeholderText="dd/mm/yyyy"
                                                                                type="date"
                                                                                max="2999-12-31"
                                                                                value={
                                                                                    personInput.ind_vt_digi_start
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            ></Form.Control>
                                                                        </td>
                                                                        <td>
                                                                            <Form.Control
                                                                                name="ind_vt_digi_end"
                                                                                placeholderText="dd/mm/yyyy"
                                                                                type="date"
                                                                                max="2999-12-31"
                                                                                value={
                                                                                    personInput.ind_vt_digi_end
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            ></Form.Control>
                                                                        </td>
                                                                        <td>
                                                                            <Form.Control
                                                                                name="ind_vt_digi_cost"
                                                                                type="number"
                                                                                value={
                                                                                    personInput.ind_vt_digi_cost
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            />
                                                                        </td>

                                                                        <td className="livestock-col">
                                                                            <Form.Control
                                                                                name="v_svtl_digi"
                                                                                as="select"
                                                                                className="form-select"
                                                                                value={
                                                                                    personInput.v_svtl_digi
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            >
                                                                                <option value="Yes">
                                                                                    Yes
                                                                                </option>
                                                                                <option
                                                                                    value="No"
                                                                                    defaultValue=""
                                                                                >
                                                                                    No
                                                                                </option>
                                                                            </Form.Control>
                                                                        </td>
                                                                        <td className="equine-col">
                                                                            <Form.Control
                                                                                name="v_svte_digi"
                                                                                as="select"
                                                                                className="form-select"
                                                                                value={
                                                                                    personInput.v_svte_digi
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            >
                                                                                <option value="Yes">
                                                                                    Yes
                                                                                </option>
                                                                                <option
                                                                                    value="No"
                                                                                    defaultValue=""
                                                                                >
                                                                                    No
                                                                                </option>
                                                                            </Form.Control>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="publications-col">
                                                                            <div className="d-flex align-items-center  justify-content-between">
                                                                                <span className="white-space-pre me-3">
                                                                                    VN
                                                                                    Times
                                                                                    Digi
                                                                                </span>

                                                                                <Form.Control
                                                                                    name="v_svnt_digi"
                                                                                    as="select"
                                                                                    className="form-select"
                                                                                    value={
                                                                                        personInput.v_svnt_digi
                                                                                    }
                                                                                    onChange={
                                                                                        handlePersonChange
                                                                                    }
                                                                                >
                                                                                    <option value="Yes">
                                                                                        Yes
                                                                                    </option>
                                                                                    <option
                                                                                        value="No"
                                                                                        defaultValue=""
                                                                                    >
                                                                                        No
                                                                                    </option>
                                                                                </Form.Control>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <Form.Control
                                                                                name="ind_vn_digi_start"
                                                                                placeholderText="dd/mm/yyyy"
                                                                                type="date"
                                                                                max="2999-12-31"
                                                                                value={
                                                                                    personInput.ind_vn_digi_start
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            ></Form.Control>
                                                                        </td>
                                                                        <td>
                                                                            <Form.Control
                                                                                name="ind_vn_digi_end"
                                                                                placeholderText="dd/mm/yyyy"
                                                                                type="date"
                                                                                max="2999-12-31"
                                                                                value={
                                                                                    personInput.ind_vn_digi_end
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            ></Form.Control>
                                                                        </td>
                                                                        <td>
                                                                            <Form.Control
                                                                                name="ind_vn_digi_cost"
                                                                                type="number"
                                                                                value={
                                                                                    personInput.ind_vn_digi_cost
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            />
                                                                        </td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="publications-col">
                                                                            <div className="d-flex align-items-center justify-content-between">
                                                                                <span className="white-space-pre me-3">
                                                                                    VBJ
                                                                                    Digi
                                                                                </span>
                                                                                <Form.Control
                                                                                    name="v_svbj_digi"
                                                                                    as="select"
                                                                                    className="form-select"
                                                                                    value={
                                                                                        personInput.v_svbj_digi
                                                                                    }
                                                                                    onChange={
                                                                                        handlePersonChange
                                                                                    }
                                                                                >
                                                                                    <option value="Yes">
                                                                                        Yes
                                                                                    </option>
                                                                                    <option
                                                                                        value="No"
                                                                                        defaultValue=""
                                                                                    >
                                                                                        No
                                                                                    </option>
                                                                                </Form.Control>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <Form.Control
                                                                                name="ind_vbj_digi_start"
                                                                                placeholderText="dd/mm/yyyy"
                                                                                type="date"
                                                                                max="2999-12-31"
                                                                                value={
                                                                                    personInput.ind_vbj_digi_start
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            ></Form.Control>
                                                                        </td>
                                                                        <td>
                                                                            <Form.Control
                                                                                name="ind_vbj_digi_end"
                                                                                placeholderText="dd/mm/yyyy"
                                                                                type="date"
                                                                                max="2999-12-31"
                                                                                value={
                                                                                    personInput.ind_vbj_digi_end
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            ></Form.Control>
                                                                        </td>
                                                                        <td>
                                                                            <Form.Control
                                                                                name="ind_vbj_digi_cost"
                                                                                type="number"
                                                                                value={
                                                                                    personInput.ind_vbj_digi_cost
                                                                                }
                                                                                onChange={
                                                                                    handlePersonChange
                                                                                }
                                                                            />
                                                                        </td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                </tbody>
                                                            )}
                                                        </Table>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {/*Digital editions*/}

                                            <Row>
                                                <Col lg={12}>
                                                    {/* <div>
                                                <Form.Label>SSO</Form.Label>
                                                <Form.Control
                                                    name="v_sso"
                                                    as="select"
                                                    className="form-select"
                                                >
                                                    <option value="Yes"></option>
                                                    <option value="No">No</option>
                                                    <option value="Duplicate">Duplicate</option>
                                                </Form.Control>
                                                <Form.Label>Last Logged in</Form.Label>
                                                <Form.Control
                                                    // name="v_sso"
                                                    type="text"
                                                    disabled
                                               />
                                            </div> */}

                                                    <div className="sso_Last-Logged">
                                                        {/* <div className="sso me-3 d-flex align-items-center">
                                                            <Form.Label >SMS</Form.Label>
                                                            <Form.Control  onChange={handlePersonChange} value={personInput.v_sms}  name="v_sms" as="select" className="form-select mb-0">
                                                                <option defaultValue value={personInput.v_sms} style={{ display: 'none' }}> {personInput.v_sms == '' ? 'No' : personInput.v_sms} </option>

                                                                <option value="Yes">Yes</option>
                                                                <option value="No">No</option>

                                                            </Form.Control>
                                                        </div>
                                                        <div className="sso me-3 d-flex align-items-center">
                                                            <Form.Label >SSO</Form.Label>
                                                            <Form.Control disabled={true} name="v_sso" as="select" className="form-select mb-0">
                                                                <option defaultValue value={personInput.v_sso} style={{ display: 'none' }}> {personInput.v_sso == '' ? 'No' : personInput.v_sso} </option>

                                                                <option value="Yes">Yes</option>
                                                                <option value="No">No</option>
                                                                <option value="Duplicate">Duplicate</option>
                                                            </Form.Control>
                                                        </div> */}
                                                        <div className="Last-Logged-in d-flex align-items-center">
                                                            <Form.Label>
                                                                Last Logged in
                                                            </Form.Label>
                                                            {/* <Form.Control  type="date" disabled value={personInput.login_at !== '' ? dayjs(personInput.login_at).format('DD/MM/YYYY') : ''} /> */}

                                                            <Form.Control
                                                                disabled={true}
                                                                type="date"
                                                                className="mb-0"
                                                                placeholderText="dd/mm/yyyy"
                                                                value={
                                                                    personInput.login_at !==
                                                                        "" &&
                                                                    personInput.login_at !==
                                                                        "0000-00-00"
                                                                        ? dayjs(
                                                                              personInput.login_at
                                                                          ).format(
                                                                              "DD/MM/YYYY"
                                                                          )
                                                                        : null
                                                                }
                                                            ></Form.Control>
                                                        </div>
                                                    </div>
                                                    <Row className="mt-3 gx-1">
                                                        <Col lg={6}>
                                                            <div className="custom-table">
                                                                <Table className="table-curved">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>
                                                                                Online
                                                                            </th>
                                                                            <th>
                                                                                Request
                                                                                Type
                                                                            </th>
                                                                            <th width="1%">
                                                                                Request
                                                                                date
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className="online-col">
                                                                                <div className="d-flex align-items-center justify-content-between">
                                                                                    <span className="white-space-pre me-3">
                                                                                        {" "}
                                                                                        VBD
                                                                                        e-mail
                                                                                    </span>
                                                                                    <Form.Control
                                                                                        name="v_sevbd"
                                                                                        as="select"
                                                                                        className="form-select"
                                                                                        value={
                                                                                            personInput.v_sevbd
                                                                                        }
                                                                                        onChange={
                                                                                            handlePersonChange
                                                                                        }
                                                                                        disabled={
                                                                                            !re.test(
                                                                                                personInput.v_email
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <option value="Yes">
                                                                                            Yes
                                                                                        </option>
                                                                                        <option value="No">
                                                                                            No
                                                                                        </option>
                                                                                        <option value="CPB">
                                                                                            CPB
                                                                                        </option>
                                                                                    </Form.Control>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    name="v_sevbd_request"
                                                                                    as="select"
                                                                                    className="form-select"
                                                                                    value={
                                                                                        personInput.v_sevbd_request
                                                                                    }
                                                                                    onChange={
                                                                                        handlePersonChange
                                                                                    }
                                                                                >
                                                                                    <option value="Phone">
                                                                                        Phone
                                                                                    </option>
                                                                                    <option value="Written">
                                                                                        Written
                                                                                    </option>
                                                                                    <option value="Electronic">
                                                                                        Electronic
                                                                                    </option>
                                                                                    <option value="Website">
                                                                                        Website
                                                                                    </option>
                                                                                    <option value="C Phone">
                                                                                        C
                                                                                        Phone
                                                                                    </option>
                                                                                    <option value="C Written">
                                                                                        C
                                                                                        Written
                                                                                    </option>
                                                                                    <option value="C Electronic">
                                                                                        C
                                                                                        Electronic
                                                                                    </option>
                                                                                    <option value="C Website">
                                                                                        C
                                                                                        Website
                                                                                    </option>
                                                                                </Form.Control>
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    name="v_sevbd_request_date"
                                                                                    type="date"
                                                                                    placeholderText="dd/mm/yyyy"
                                                                                    max="2999-12-31"
                                                                                    value={
                                                                                        personInput.v_sevbd_request_date
                                                                                    }
                                                                                    onChange={
                                                                                        handlePersonChange
                                                                                    }
                                                                                ></Form.Control>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="online-col">
                                                                                <div className="d-flex align-items-center justify-content-between">
                                                                                    <span className="white-space-pre me-3">
                                                                                        Vettimes
                                                                                        (d)
                                                                                    </span>

                                                                                    <Form.Control
                                                                                        name="v_vnews_d"
                                                                                        as="select"
                                                                                        className="form-select"
                                                                                        value={
                                                                                            personInput.v_vnews_d
                                                                                        }
                                                                                        onChange={
                                                                                            handlePersonChange
                                                                                        }
                                                                                        disabled={
                                                                                            !re.test(
                                                                                                personInput.v_email
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <option value="Yes">
                                                                                            Yes
                                                                                        </option>
                                                                                        <option value="No">
                                                                                            No
                                                                                        </option>
                                                                                        <option value="CPB">
                                                                                            CPB
                                                                                        </option>
                                                                                    </Form.Control>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    name="v_vnews_d_request"
                                                                                    as="select"
                                                                                    className="form-select"
                                                                                    value={
                                                                                        personInput.v_vnews_d_request
                                                                                    }
                                                                                    onChange={
                                                                                        handlePersonChange
                                                                                    }
                                                                                >
                                                                                    <option value="Phone">
                                                                                        Phone
                                                                                    </option>
                                                                                    <option value="Written">
                                                                                        Written
                                                                                    </option>
                                                                                    <option value="Electronic">
                                                                                        Electronic
                                                                                    </option>
                                                                                    <option value="Website">
                                                                                        Website
                                                                                    </option>
                                                                                    <option value="C Phone">
                                                                                        C
                                                                                        Phone
                                                                                    </option>
                                                                                    <option value="C Written">
                                                                                        C
                                                                                        Written
                                                                                    </option>
                                                                                    <option value="C Electronic">
                                                                                        C
                                                                                        Electronic
                                                                                    </option>
                                                                                    <option value="C Website">
                                                                                        C
                                                                                        Website
                                                                                    </option>
                                                                                </Form.Control>
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    name="v_vnews_d_request_date"
                                                                                    placeholderText="dd/mm/yyyy"
                                                                                    type="date"
                                                                                    max="2999-12-31"
                                                                                    value={
                                                                                        personInput.v_vnews_d_request_date
                                                                                    }
                                                                                    onChange={
                                                                                        handlePersonChange
                                                                                    }
                                                                                ></Form.Control>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="online-col">
                                                                                <div className="d-flex align-items-center justify-content-between">
                                                                                    <span className="white-space-pre me-3">
                                                                                        Vet
                                                                                        Times
                                                                                        Jobs
                                                                                        (w)
                                                                                    </span>

                                                                                    <Form.Control
                                                                                        name="v_vjnw"
                                                                                        as="select"
                                                                                        className="form-select"
                                                                                        value={
                                                                                            personInput.v_vjnw
                                                                                        }
                                                                                        onChange={
                                                                                            handlePersonChange
                                                                                        }
                                                                                        disabled={
                                                                                            !re.test(
                                                                                                personInput.v_email
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <option value="Yes">
                                                                                            Yes
                                                                                        </option>
                                                                                        <option value="No">
                                                                                            No
                                                                                        </option>
                                                                                        <option value="CPB">
                                                                                            CPB
                                                                                        </option>
                                                                                    </Form.Control>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    name="v_vjnw_request"
                                                                                    as="select"
                                                                                    className="form-select"
                                                                                    value={
                                                                                        personInput.v_vjnw_request
                                                                                    }
                                                                                    onChange={
                                                                                        handlePersonChange
                                                                                    }
                                                                                >
                                                                                    <option value="Phone">
                                                                                        Phone
                                                                                    </option>
                                                                                    <option value="Written">
                                                                                        Written
                                                                                    </option>
                                                                                    <option value="Electronic">
                                                                                        Electronic
                                                                                    </option>
                                                                                    <option value="Website">
                                                                                        Website
                                                                                    </option>
                                                                                    <option value="C Phone">
                                                                                        C
                                                                                        Phone
                                                                                    </option>
                                                                                    <option value="C Written">
                                                                                        C
                                                                                        Written
                                                                                    </option>
                                                                                    <option value="C Electronic">
                                                                                        C
                                                                                        Electronic
                                                                                    </option>
                                                                                    <option value="C Website">
                                                                                        C
                                                                                        Website
                                                                                    </option>
                                                                                </Form.Control>
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    name="v_vjnw_request_date"
                                                                                    placeholderText="dd/mm/yyyy"
                                                                                    type="date"
                                                                                    max="2999-12-31"
                                                                                    value={
                                                                                        personInput.v_vjnw_request_date
                                                                                    }
                                                                                    onChange={
                                                                                        handlePersonChange
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="online-col">
                                                                                <div className="d-flex align-items-center justify-content-between">
                                                                                    <span className="white-space-pre me-3">
                                                                                        {" "}
                                                                                        VT
                                                                                        Jobs
                                                                                        Marketing
                                                                                    </span>
                                                                                    <Form.Control
                                                                                        name="v_vt_jobs_marketing"
                                                                                        as="select"
                                                                                        className="form-select"
                                                                                        value={
                                                                                            personInput.v_vt_jobs_marketing
                                                                                        }
                                                                                        onChange={
                                                                                            handlePersonChange
                                                                                        }
                                                                                        disabled={
                                                                                            !re.test(
                                                                                                personInput.v_email
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <option value="Yes">
                                                                                            Yes
                                                                                        </option>
                                                                                        <option value="No">
                                                                                            No
                                                                                        </option>
                                                                                        <option value="CPB">
                                                                                            CPB
                                                                                        </option>
                                                                                    </Form.Control>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    name="v_vt_jobs_marketing_request"
                                                                                    as="select"
                                                                                    className="form-select"
                                                                                    value={
                                                                                        personInput.v_vt_jobs_marketing_request
                                                                                    }
                                                                                    onChange={
                                                                                        handlePersonChange
                                                                                    }
                                                                                >
                                                                                    <option value="Phone">
                                                                                        Phone
                                                                                    </option>
                                                                                    <option value="Written">
                                                                                        Written
                                                                                    </option>
                                                                                    <option value="Electronic">
                                                                                        Electronic
                                                                                    </option>
                                                                                    <option value="Website">
                                                                                        Website
                                                                                    </option>
                                                                                    <option value="C Phone">
                                                                                        C
                                                                                        Phone
                                                                                    </option>
                                                                                    <option value="C Written">
                                                                                        C
                                                                                        Written
                                                                                    </option>
                                                                                    <option value="C Electronic">
                                                                                        C
                                                                                        Electronic
                                                                                    </option>
                                                                                    <option value="C Website">
                                                                                        C
                                                                                        Website
                                                                                    </option>
                                                                                </Form.Control>
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    name="v_vt_jobs_marketing_request_date"
                                                                                    placeholderText="dd/mm/yyyy"
                                                                                    type="date"
                                                                                    max="2999-12-31"
                                                                                    value={
                                                                                        personInput.v_vt_jobs_marketing_request_date
                                                                                    }
                                                                                    onChange={
                                                                                        handlePersonChange
                                                                                    }
                                                                                ></Form.Control>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </Col>
                                                        <Col lg={6}>
                                                            <div className="custom-table">
                                                                <Table className="table-curved">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>
                                                                                Online
                                                                            </th>
                                                                            <th>
                                                                                Request
                                                                                Type
                                                                            </th>
                                                                            <th width="1%">
                                                                                Request
                                                                                date
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className="online-col">
                                                                                <div className="d-flex align-items-center justify-content-between">
                                                                                    <span className="white-space-pre me-3">
                                                                                        {" "}
                                                                                        3rd
                                                                                        party
                                                                                        e-mails
                                                                                    </span>

                                                                                    <Form.Control
                                                                                        name="v_s3rd"
                                                                                        as="select"
                                                                                        className="form-select"
                                                                                        value={
                                                                                            personInput.v_s3rd
                                                                                        }
                                                                                        onChange={
                                                                                            handlePersonChange
                                                                                        }
                                                                                        disabled={
                                                                                            !re.test(
                                                                                                personInput.v_email
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <option value="Yes">
                                                                                            Yes
                                                                                        </option>
                                                                                        <option value="No">
                                                                                            No
                                                                                        </option>
                                                                                        <option value="CPB">
                                                                                            CPB
                                                                                        </option>
                                                                                    </Form.Control>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    name="v_s3rd_request"
                                                                                    as="select"
                                                                                    className="form-select"
                                                                                    value={
                                                                                        personInput.v_s3rd_request
                                                                                    }
                                                                                    onChange={
                                                                                        handlePersonChange
                                                                                    }
                                                                                >
                                                                                    <option value="Phone">
                                                                                        Phone
                                                                                    </option>
                                                                                    <option value="Written">
                                                                                        Written
                                                                                    </option>
                                                                                    <option value="Electronic">
                                                                                        Electronic
                                                                                    </option>
                                                                                    <option value="Website">
                                                                                        Website
                                                                                    </option>
                                                                                    <option value="C Phone">
                                                                                        C
                                                                                        Phone
                                                                                    </option>
                                                                                    <option value="C Written">
                                                                                        C
                                                                                        Written
                                                                                    </option>
                                                                                    <option value="C Electronic">
                                                                                        C
                                                                                        Electronic
                                                                                    </option>
                                                                                    <option value="C Website">
                                                                                        C
                                                                                        Website
                                                                                    </option>
                                                                                </Form.Control>
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    name="v_s3rd_request_date"
                                                                                    max="2999-12-31"
                                                                                    placeholderText="dd/mm/yyyy"
                                                                                    value={
                                                                                        personInput.v_s3rd_request_date
                                                                                    }
                                                                                    onChange={
                                                                                        handlePersonChange
                                                                                    }
                                                                                ></Form.Control>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="online-col">
                                                                                <div className="d-flex align-items-center justify-content-between">
                                                                                    <span className="white-space-pre me-3">
                                                                                        Vettimes
                                                                                        (w)
                                                                                    </span>

                                                                                    <Form.Control
                                                                                        name="v_vnews"
                                                                                        as="select"
                                                                                        className="form-select"
                                                                                        value={
                                                                                            personInput.v_vnews
                                                                                        }
                                                                                        onChange={
                                                                                            handlePersonChange
                                                                                        }
                                                                                        disabled={
                                                                                            !re.test(
                                                                                                personInput.v_email
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <option value="Yes">
                                                                                            Yes
                                                                                        </option>
                                                                                        <option value="No">
                                                                                            No
                                                                                        </option>
                                                                                        <option value="CPB">
                                                                                            CPB
                                                                                        </option>
                                                                                    </Form.Control>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    name="v_vnews_request"
                                                                                    as="select"
                                                                                    className="form-select"
                                                                                    value={
                                                                                        personInput.v_vnews_request
                                                                                    }
                                                                                    onChange={
                                                                                        handlePersonChange
                                                                                    }
                                                                                >
                                                                                    <option value="Phone">
                                                                                        Phone
                                                                                    </option>
                                                                                    <option value="Written">
                                                                                        Written
                                                                                    </option>
                                                                                    <option value="Electronic">
                                                                                        Electronic
                                                                                    </option>
                                                                                    <option value="Website">
                                                                                        Website
                                                                                    </option>
                                                                                    <option value="C Phone">
                                                                                        C
                                                                                        Phone
                                                                                    </option>
                                                                                    <option value="C Written">
                                                                                        C
                                                                                        Written
                                                                                    </option>
                                                                                    <option value="C Electronic">
                                                                                        C
                                                                                        Electronic
                                                                                    </option>
                                                                                    <option value="C Website">
                                                                                        C
                                                                                        Website
                                                                                    </option>
                                                                                </Form.Control>
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    name="v_vnews_request_date"
                                                                                    placeholderText="dd/mm/yyyy"
                                                                                    type="date"
                                                                                    max="2999-12-31"
                                                                                    value={
                                                                                        personInput.v_vnews_request_date
                                                                                    }
                                                                                    onChange={
                                                                                        handlePersonChange
                                                                                    }
                                                                                ></Form.Control>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="online-col">
                                                                                <div className="d-flex align-items-center justify-content-between">
                                                                                    <span className="white-space-pre me-3">
                                                                                        Vet
                                                                                        Times
                                                                                        Jobs
                                                                                        (m)
                                                                                    </span>

                                                                                    <Form.Control
                                                                                        name="v_vjnm"
                                                                                        as="select"
                                                                                        className="form-select"
                                                                                        value={
                                                                                            personInput.v_vjnm
                                                                                        }
                                                                                        onChange={
                                                                                            handlePersonChange
                                                                                        }
                                                                                        disabled={
                                                                                            !re.test(
                                                                                                personInput.v_email
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <option value="Yes">
                                                                                            Yes
                                                                                        </option>
                                                                                        <option value="No">
                                                                                            No
                                                                                        </option>
                                                                                        <option value="CPB">
                                                                                            CPB
                                                                                        </option>
                                                                                    </Form.Control>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    name="v_vjnm_request"
                                                                                    as="select"
                                                                                    className="form-select"
                                                                                    value={
                                                                                        personInput.v_vjnm_request
                                                                                    }
                                                                                    onChange={
                                                                                        handlePersonChange
                                                                                    }
                                                                                >
                                                                                    <option value="Phone">
                                                                                        Phone
                                                                                    </option>
                                                                                    <option value="Written">
                                                                                        Written
                                                                                    </option>
                                                                                    <option value="Electronic">
                                                                                        Electronic
                                                                                    </option>
                                                                                    <option value="Website">
                                                                                        Website
                                                                                    </option>
                                                                                    <option value="C Phone">
                                                                                        C
                                                                                        Phone
                                                                                    </option>
                                                                                    <option value="C Written">
                                                                                        C
                                                                                        Written
                                                                                    </option>
                                                                                    <option value="C Electronic">
                                                                                        C
                                                                                        Electronic
                                                                                    </option>
                                                                                    <option value="C Website">
                                                                                        C
                                                                                        Website
                                                                                    </option>
                                                                                </Form.Control>
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    name="v_vjnm_request_date"
                                                                                    placeholderText="dd/mm/yyyy"
                                                                                    type="date"
                                                                                    max="2999-12-31"
                                                                                    value={
                                                                                        personInput.v_vjnm_request_date
                                                                                    }
                                                                                    onChange={
                                                                                        handlePersonChange
                                                                                    }
                                                                                ></Form.Control>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="online-col">
                                                                                <div className="d-flex align-items-center justify-content-between">
                                                                                    <span className="white-space-pre me-3">
                                                                                        VN
                                                                                        Newsletter
                                                                                        (m)
                                                                                    </span>

                                                                                    <Form.Control
                                                                                        name="vn_monthly_newsletter"
                                                                                        as="select"
                                                                                        className="form-select"
                                                                                        value={
                                                                                            personInput.vn_monthly_newsletter
                                                                                        }
                                                                                        onChange={
                                                                                            handlePersonChange
                                                                                        }
                                                                                        disabled={
                                                                                            !re.test(
                                                                                                personInput.v_email
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <option value="Yes">
                                                                                            Yes
                                                                                        </option>
                                                                                        <option value="No">
                                                                                            No
                                                                                        </option>
                                                                                        <option value="CPB">
                                                                                            CPB
                                                                                        </option>
                                                                                    </Form.Control>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    name="vn_monthly_newsletter_request"
                                                                                    as="select"
                                                                                    className="form-select"
                                                                                    value={
                                                                                        personInput.vn_monthly_newsletter_request
                                                                                    }
                                                                                    onChange={
                                                                                        handlePersonChange
                                                                                    }
                                                                                >
                                                                                    <option value="Phone">
                                                                                        Phone
                                                                                    </option>
                                                                                    <option value="Written">
                                                                                        Written
                                                                                    </option>
                                                                                    <option value="Electronic">
                                                                                        Electronic
                                                                                    </option>
                                                                                    <option value="Website">
                                                                                        Website
                                                                                    </option>
                                                                                    <option value="C Phone">
                                                                                        C
                                                                                        Phone
                                                                                    </option>
                                                                                    <option value="C Written">
                                                                                        C
                                                                                        Written
                                                                                    </option>
                                                                                    <option value="C Electronic">
                                                                                        C
                                                                                        Electronic
                                                                                    </option>
                                                                                    <option value="C Website">
                                                                                        C
                                                                                        Website
                                                                                    </option>
                                                                                </Form.Control>
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    name="vn_monthly_newsletter_request_date"
                                                                                    placeholderText="dd/mm/yyyy"
                                                                                    type="date"
                                                                                    value={
                                                                                        personInput.vn_monthly_newsletter_request_date
                                                                                    }
                                                                                    onChange={
                                                                                        handlePersonChange
                                                                                    }
                                                                                ></Form.Control>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Tab>
                                        <Tab
                                            eventKey="sso"
                                            title="Single Sign On"
                                            className="row-px-2"
                                        >
                                            <div className="custom-table">
                                                <Table className="table-curved">
                                                    <thead>
                                                        <tr>
                                                            <th>SSO</th>
                                                            <th>Logged in</th>
                                                            <th>Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>VT app</td>
                                                            <td>
                                                                <Form.Control
                                                                    name="vtapp_sso"
                                                                    as="select"
                                                                    className="form-select"
                                                                    value={
                                                                        personInput.vtapp_sso
                                                                    }
                                                                    onChange={
                                                                        handlePersonChange
                                                                    }
                                                                    disabled
                                                                >
                                                                    <option value="Yes">
                                                                        Yes
                                                                    </option>
                                                                    <option value="No">
                                                                        No
                                                                    </option>
                                                                </Form.Control>
                                                            </td>
                                                            <td>
                                                                <Form.Control
                                                                    name="vtapp_date"
                                                                    placeholderText="dd/mm/yyyy"
                                                                    type="date"
                                                                    max="2999-12-31"
                                                                    value={
                                                                        personInput.vtapp_date
                                                                    }
                                                                    onChange={
                                                                        handlePersonChange
                                                                    }
                                                                    disabled
                                                                ></Form.Control>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                vettimes.co.uk
                                                            </td>
                                                            <td>
                                                                <Form.Control
                                                                    name="vt_sso"
                                                                    as="select"
                                                                    className="form-select"
                                                                    value={
                                                                        personInput.vt_sso
                                                                    }
                                                                    onChange={
                                                                        handlePersonChange
                                                                    }
                                                                    disabled
                                                                >
                                                                    <option value="Yes">
                                                                        Yes
                                                                    </option>
                                                                    <option value="No">
                                                                        No
                                                                    </option>
                                                                </Form.Control>
                                                            </td>
                                                            <td>
                                                                <Form.Control
                                                                    name="vt_date"
                                                                    placeholderText="dd/mm/yyyy"
                                                                    type="date"
                                                                    max="2999-12-31"
                                                                    value={
                                                                        personInput.vt_date
                                                                    }
                                                                    onChange={
                                                                        handlePersonChange
                                                                    }
                                                                    disabled
                                                                ></Form.Control>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Profile updated
                                                            </td>
                                                            <td>
                                                                <Form.Control
                                                                    name="p_updated"
                                                                    as="select"
                                                                    className="form-select"
                                                                    value={
                                                                        personInput.p_updated
                                                                    }
                                                                    onChange={
                                                                        handlePersonChange
                                                                    }
                                                                    disabled
                                                                >
                                                                    <option value="Yes">
                                                                        Yes
                                                                    </option>
                                                                    <option value="No">
                                                                        No
                                                                    </option>
                                                                </Form.Control>
                                                            </td>
                                                            <td>
                                                                <Form.Control
                                                                    name="p_date"
                                                                    placeholderText="dd/mm/yyyy"
                                                                    type="date"
                                                                    max="2999-12-31"
                                                                    value={
                                                                        personInput.p_date
                                                                    }
                                                                    onChange={
                                                                        handlePersonChange
                                                                    }
                                                                    disabled
                                                                ></Form.Control>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>SM Date</td>
                                                            <td></td>
                                                            <td>
                                                                <Form.Control
                                                                    name="vtapp_date"
                                                                    placeholderText="dd/mm/yyyy"
                                                                    type="date"
                                                                    max="2999-12-31"
                                                                    value={
                                                                        personInput.sm_date
                                                                    }
                                                                    onChange={
                                                                        handlePersonChange
                                                                    }
                                                                    disabled
                                                                ></Form.Control>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>CPD</td>
                                                            <td>
                                                                <Form.Control
                                                                    name="cpd_sso"
                                                                    as="select"
                                                                    className="form-select"
                                                                    value={
                                                                        personInput.cpd_sso
                                                                    }
                                                                    onChange={
                                                                        handlePersonChange
                                                                    }
                                                                    disabled
                                                                >
                                                                    <option value="Yes">
                                                                        Yes
                                                                    </option>
                                                                    <option value="No">
                                                                        No
                                                                    </option>
                                                                </Form.Control>
                                                            </td>
                                                            <td>
                                                                <Form.Control
                                                                    name="cpd_date"
                                                                    placeholderText="dd/mm/yyyy"
                                                                    type="date"
                                                                    max="2999-12-31"
                                                                    value={
                                                                        personInput.cpd_date
                                                                    }
                                                                    onChange={
                                                                        handlePersonChange
                                                                    }
                                                                    disabled
                                                                ></Form.Control>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>VT jobs</td>
                                                            <td>
                                                                <Form.Control
                                                                    name="vtjobs_sso"
                                                                    as="select"
                                                                    className="form-select"
                                                                    value={
                                                                        personInput.vtjobs_sso
                                                                    }
                                                                    onChange={
                                                                        handlePersonChange
                                                                    }
                                                                    disabled
                                                                >
                                                                    <option value="Yes">
                                                                        Yes
                                                                    </option>
                                                                    <option value="No">
                                                                        No
                                                                    </option>
                                                                </Form.Control>
                                                            </td>
                                                            <td>
                                                                <Form.Control
                                                                    name="vtjobs_date"
                                                                    placeholderText="dd/mm/yyyy"
                                                                    type="date"
                                                                    max="2999-12-31"
                                                                    value={
                                                                        personInput.vtjobs_date
                                                                    }
                                                                    onChange={
                                                                        handlePersonChange
                                                                    }
                                                                    disabled
                                                                ></Form.Control>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>VT academy</td>
                                                            <td>
                                                                <Form.Control
                                                                    name="academy_sso"
                                                                    as="select"
                                                                    className="form-select"
                                                                    value={
                                                                        personInput.vtapp_sso
                                                                    }
                                                                    onChange={
                                                                        handlePersonChange
                                                                    }
                                                                    disabled
                                                                >
                                                                    <option value="Yes">
                                                                        Yes
                                                                    </option>
                                                                    <option value="No">
                                                                        No
                                                                    </option>
                                                                </Form.Control>
                                                            </td>
                                                            <td>
                                                                <Form.Control
                                                                    name="academy_date"
                                                                    placeholderText="dd/mm/yyyy"
                                                                    type="date"
                                                                    max="2999-12-31"
                                                                    value={
                                                                        personInput.academy_date
                                                                    }
                                                                    onChange={
                                                                        handlePersonChange
                                                                    }
                                                                    disabled
                                                                ></Form.Control>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                VT Marketplace
                                                            </td>
                                                            <td>
                                                                <Form.Control
                                                                    name="marketplace_sso"
                                                                    as="select"
                                                                    className="form-select"
                                                                    value={
                                                                        personInput.marketplace_sso
                                                                    }
                                                                    onChange={
                                                                        handlePersonChange
                                                                    }
                                                                    disabled
                                                                >
                                                                    <option value="Yes">
                                                                        Yes
                                                                    </option>
                                                                    <option value="No">
                                                                        No
                                                                    </option>
                                                                </Form.Control>
                                                            </td>
                                                            <td>
                                                                <Form.Control
                                                                    name="marketplace_date"
                                                                    placeholderText="dd/mm/yyyy"
                                                                    type="date"
                                                                    max="2999-12-31"
                                                                    value={
                                                                        personInput.marketplace_date
                                                                    }
                                                                    onChange={
                                                                        handlePersonChange
                                                                    }
                                                                    disabled
                                                                ></Form.Control>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Tab>

                                        <Tab
                                            eventKey="SSO"
                                            title="SSO Interest"
                                            className="row-px-2 sso-interest"
                                        >
                                            <Row className="gx-2">
                                                <Col>
                                                    <strong className="mt-0">
                                                        Beef
                                                    </strong>
                                                    {beefCattle.map((x, i) => (
                                                        <Form.Group
                                                            controlId={x.value}
                                                        >
                                                            <Form.Check
                                                                name="v_interests"
                                                                type="checkbox"
                                                                label={`${x.value}  ${x.label}`}
                                                                checked={
                                                                    personInput.v_interests &&
                                                                    personInput.v_interests
                                                                        .split(
                                                                            ","
                                                                        )
                                                                        .find(
                                                                            (
                                                                                curr
                                                                            ) => {
                                                                                return (
                                                                                    curr ===
                                                                                    x.value
                                                                                );
                                                                            }
                                                                        )
                                                                }
                                                                value={x.value}
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Form.Group>
                                                    ))}

                                                    <strong>Birds</strong>
                                                    {bird.map((x, i) => (
                                                        <Form.Group
                                                            controlId={x.value}
                                                        >
                                                            <Form.Check
                                                                name="v_interests"
                                                                type="checkbox"
                                                                label={`${x.value}  ${x.label}`}
                                                                checked={
                                                                    personInput.v_interests
                                                                        .split(
                                                                            ","
                                                                        )
                                                                        .indexOf(
                                                                            x.value
                                                                        ) !== -1
                                                                }
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                                value={x.value}
                                                            />
                                                        </Form.Group>
                                                    ))}

                                                    <strong>Cats</strong>
                                                    {cats.map((x, i) => (
                                                        <Form.Group
                                                            controlId={x.value}
                                                        >
                                                            <Form.Check
                                                                name="v_interests"
                                                                type="checkbox"
                                                                label={`${x.value}  ${x.label}`}
                                                                checked={
                                                                    personInput.v_interests
                                                                        .split(
                                                                            ","
                                                                        )
                                                                        .indexOf(
                                                                            x.value
                                                                        ) !== -1
                                                                }
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                                value={x.value}
                                                            />
                                                        </Form.Group>
                                                    ))}

                                                    <strong>Cattle</strong>
                                                    {cattle.map((x, i) => (
                                                        <Form.Group
                                                            controlId={x.value}
                                                        >
                                                            <Form.Check
                                                                name="v_interests"
                                                                type="checkbox"
                                                                label={`${x.value}  ${x.label}`}
                                                                checked={
                                                                    personInput.v_interests
                                                                        .split(
                                                                            ","
                                                                        )
                                                                        .indexOf(
                                                                            x.value
                                                                        ) !== -1
                                                                }
                                                                value={x.value}
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Form.Group>
                                                    ))}
                                                    <strong>
                                                        Dairy cattle
                                                    </strong>
                                                    {dairyCattle.map((x, i) => (
                                                        <Form.Group
                                                            controlId={x.value}
                                                        >
                                                            <Form.Check
                                                                name="v_interests"
                                                                type="checkbox"
                                                                label={`${x.value}  ${x.label}`}
                                                                checked={
                                                                    personInput.v_interests
                                                                        .split(
                                                                            ","
                                                                        )
                                                                        .indexOf(
                                                                            x.value
                                                                        ) !== -1
                                                                }
                                                                value={x.value}
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Form.Group>
                                                    ))}

                                                    <strong>Dogs </strong>
                                                    {dogs.map((x, i) => (
                                                        <Form.Group
                                                            controlId={x.value}
                                                        >
                                                            <Form.Check
                                                                name="v_interests"
                                                                type="checkbox"
                                                                label={`${x.value}  ${x.label}`}
                                                                checked={
                                                                    personInput.v_interests
                                                                        .split(
                                                                            ","
                                                                        )
                                                                        .indexOf(
                                                                            x.value
                                                                        ) !== -1
                                                                }
                                                                value={x.value}
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Form.Group>
                                                    ))}
                                                </Col>

                                                <Col>
                                                    {dogs2.map((x, i) => (
                                                        <Form.Group
                                                            controlId={x.value}
                                                        >
                                                            <Form.Check
                                                                name="v_interests"
                                                                type="checkbox"
                                                                label={`${x.value}  ${x.label}`}
                                                                checked={
                                                                    personInput.v_interests
                                                                        .split(
                                                                            ","
                                                                        )
                                                                        .indexOf(
                                                                            x.value
                                                                        ) !== -1
                                                                }
                                                                value={x.value}
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Form.Group>
                                                    ))}
                                                    <strong>General</strong>
                                                    {general.map((x, i) => (
                                                        <Form.Group
                                                            controlId={x.value}
                                                        >
                                                            <Form.Check
                                                                name="v_interests"
                                                                type="checkbox"
                                                                label={`${x.value}  ${x.label}`}
                                                                checked={personInput.v_interests
                                                                    .split(",")
                                                                    .find(
                                                                        (
                                                                            curr
                                                                        ) => {
                                                                            return (
                                                                                curr ===
                                                                                x.value
                                                                            );
                                                                        }
                                                                    )}
                                                                value={x.value}
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Form.Group>
                                                    ))}
                                                    <strong>Horses</strong>
                                                    {horses.map((x, i) => (
                                                        <Form.Group
                                                            controlId={x.value}
                                                        >
                                                            <Form.Check
                                                                name="v_interests"
                                                                type="checkbox"
                                                                label={`${x.value}  ${x.label}`}
                                                                checked={personInput.v_interests
                                                                    .split(",")
                                                                    .find(
                                                                        (
                                                                            curr
                                                                        ) => {
                                                                            return (
                                                                                curr ===
                                                                                x.value
                                                                            );
                                                                        }
                                                                    )}
                                                                value={x.value}
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Form.Group>
                                                    ))}
                                                    <strong>
                                                        {" "}
                                                        Laws & Info
                                                    </strong>

                                                    {lawInfo.map((x, i) => (
                                                        <Form.Group
                                                            controlId={x.value}
                                                        >
                                                            <Form.Check
                                                                name="v_interests"
                                                                type="checkbox"
                                                                label={`${x.value}  ${x.label}`}
                                                                checked={personInput.v_interests
                                                                    .split(",")
                                                                    .find(
                                                                        (
                                                                            curr
                                                                        ) => {
                                                                            return (
                                                                                curr ===
                                                                                x.value
                                                                            );
                                                                        }
                                                                    )}
                                                                value={x.value}
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Form.Group>
                                                    ))}
                                                </Col>

                                                <Col>
                                                    <strong className="mt-0">
                                                        Others
                                                    </strong>
                                                    {others.map((x, i) => (
                                                        <Form.Group
                                                            controlId={x.value}
                                                        >
                                                            <Form.Check
                                                                name="v_interests"
                                                                type="checkbox"
                                                                label={`${x.value}  ${x.label}`}
                                                                checked={personInput.v_interests
                                                                    .split(",")
                                                                    .find(
                                                                        (
                                                                            curr
                                                                        ) => {
                                                                            return (
                                                                                curr ===
                                                                                x.value
                                                                            );
                                                                        }
                                                                    )}
                                                                value={x.value}
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Form.Group>
                                                    ))}
                                                    <strong>Pig</strong>
                                                    {pig.map((x, i) => (
                                                        <Form.Group
                                                            controlId={x.value}
                                                        >
                                                            <Form.Check
                                                                name="v_interests"
                                                                type="checkbox"
                                                                label={`${x.value}  ${x.label}`}
                                                                checked={personInput.v_interests
                                                                    .split(",")
                                                                    .find(
                                                                        (
                                                                            curr
                                                                        ) => {
                                                                            return (
                                                                                curr ===
                                                                                x.value
                                                                            );
                                                                        }
                                                                    )}
                                                                value={x.value}
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Form.Group>
                                                    ))}
                                                    <strong>Poultry</strong>
                                                    {poultry.map((x, i) => (
                                                        <Form.Group
                                                            controlId={x.value}
                                                        >
                                                            <Form.Check
                                                                name="v_interests"
                                                                type="checkbox"
                                                                label={`${x.value}  ${x.label}`}
                                                                checked={personInput.v_interests
                                                                    .split(",")
                                                                    .find(
                                                                        (
                                                                            curr
                                                                        ) => {
                                                                            return (
                                                                                curr ===
                                                                                x.value
                                                                            );
                                                                        }
                                                                    )}
                                                                value={x.value}
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Form.Group>
                                                    ))}

                                                    <strong>
                                                        Practice Management
                                                    </strong>
                                                    {practiceManagement.map(
                                                        (x, i) => (
                                                            <Form.Group
                                                                controlId={
                                                                    x.value
                                                                }
                                                            >
                                                                <Form.Check
                                                                    name="v_interests"
                                                                    type="checkbox"
                                                                    label={`${x.value}  ${x.label}`}
                                                                    checked={personInput.v_interests
                                                                        .split(
                                                                            ","
                                                                        )
                                                                        .find(
                                                                            (
                                                                                curr
                                                                            ) => {
                                                                                return (
                                                                                    curr ===
                                                                                    x.value
                                                                                );
                                                                            }
                                                                        )}
                                                                    value={
                                                                        x.value
                                                                    }
                                                                    onChange={
                                                                        handlePersonChange
                                                                    }
                                                                />
                                                            </Form.Group>
                                                        )
                                                    )}

                                                    <strong>Rabbits</strong>
                                                    {rabbits.map((x, i) => (
                                                        <Form.Group
                                                            controlId={x.value}
                                                        >
                                                            <Form.Check
                                                                name="v_interests"
                                                                type="checkbox"
                                                                label={`${x.value}  ${x.label}`}
                                                                checked={personInput.v_interests
                                                                    .split(",")
                                                                    .find(
                                                                        (
                                                                            curr
                                                                        ) => {
                                                                            return (
                                                                                curr ===
                                                                                x.value
                                                                            );
                                                                        }
                                                                    )}
                                                                value={x.value}
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Form.Group>
                                                    ))}
                                                    <strong>Reptiles</strong>
                                                    {reptiles.map((x, i) => (
                                                        <Form.Group
                                                            controlId={x.value}
                                                        >
                                                            <Form.Check
                                                                name="v_interests"
                                                                type="checkbox"
                                                                label={`${x.value}  ${x.label}`}
                                                                checked={personInput.v_interests
                                                                    .split(",")
                                                                    .find(
                                                                        (
                                                                            curr
                                                                        ) => {
                                                                            return (
                                                                                curr ===
                                                                                x.value
                                                                            );
                                                                        }
                                                                    )}
                                                                value={x.value}
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Form.Group>
                                                    ))}
                                                </Col>
                                                <Col>
                                                    <strong className="mt-0">
                                                        Rodents
                                                    </strong>
                                                    {rodents.map((x, i) => (
                                                        <Form.Group
                                                            controlId={x.value}
                                                        >
                                                            <Form.Check
                                                                name="v_interests"
                                                                type="checkbox"
                                                                label={`${x.value}  ${x.label}`}
                                                                checked={personInput.v_interests
                                                                    .split(",")
                                                                    .find(
                                                                        (
                                                                            curr
                                                                        ) => {
                                                                            return (
                                                                                curr ===
                                                                                x.value
                                                                            );
                                                                        }
                                                                    )}
                                                                value={x.value}
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Form.Group>
                                                    ))}

                                                    <strong>Specialisms</strong>
                                                    {specialisms.map((x, i) => (
                                                        <Form.Group
                                                            controlId={x.value}
                                                        >
                                                            <Form.Check
                                                                name="v_interests"
                                                                type="checkbox"
                                                                label={`${x.value}  ${x.label}`}
                                                                checked={personInput.v_interests
                                                                    .split(",")
                                                                    .find(
                                                                        (
                                                                            curr
                                                                        ) => {
                                                                            return (
                                                                                curr ===
                                                                                x.value
                                                                            );
                                                                        }
                                                                    )}
                                                                value={x.value}
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Form.Group>
                                                    ))}
                                                </Col>
                                                <Col>
                                                    {specialisms2.map(
                                                        (x, i) => (
                                                            <Form.Group
                                                                controlId={
                                                                    x.value
                                                                }
                                                            >
                                                                <Form.Check
                                                                    name="v_interests"
                                                                    type="checkbox"
                                                                    label={`${x.value}  ${x.label}`}
                                                                    checked={personInput.v_interests
                                                                        .split(
                                                                            ","
                                                                        )
                                                                        .find(
                                                                            (
                                                                                curr
                                                                            ) => {
                                                                                return (
                                                                                    curr ===
                                                                                    x.value
                                                                                );
                                                                            }
                                                                        )}
                                                                    value={
                                                                        x.value
                                                                    }
                                                                    onChange={
                                                                        handlePersonChange
                                                                    }
                                                                />
                                                            </Form.Group>
                                                        )
                                                    )}

                                                    <strong>Sheep</strong>
                                                    {sheep.map((x, i) => (
                                                        <Form.Group
                                                            controlId={x.value}
                                                        >
                                                            <Form.Check
                                                                name="v_interests"
                                                                type="checkbox"
                                                                label={`${x.value}  ${x.label}`}
                                                                checked={personInput.v_interests
                                                                    .split(",")
                                                                    .find(
                                                                        (
                                                                            curr
                                                                        ) => {
                                                                            return (
                                                                                curr ===
                                                                                x.value
                                                                            );
                                                                        }
                                                                    )}
                                                                value={x.value}
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Form.Group>
                                                    ))}
                                                    <strong>
                                                        Zoo, Wildlife, Fish
                                                    </strong>
                                                    {zooWildlifeFish.map(
                                                        (x, i) => (
                                                            <Form.Group
                                                                controlId={
                                                                    x.value
                                                                }
                                                            >
                                                                <Form.Check
                                                                    name="v_interests"
                                                                    type="checkbox"
                                                                    label={`${x.value}  ${x.label}`}
                                                                    checked={personInput.v_interests
                                                                        .split(
                                                                            ","
                                                                        )
                                                                        .find(
                                                                            (
                                                                                curr
                                                                            ) => {
                                                                                return (
                                                                                    curr ===
                                                                                    x.value
                                                                                );
                                                                            }
                                                                        )}
                                                                    value={
                                                                        x.value
                                                                    }
                                                                    onChange={
                                                                        handlePersonChange
                                                                    }
                                                                />
                                                            </Form.Group>
                                                        )
                                                    )}
                                                </Col>
                                            </Row>
                                        </Tab>

                                        {/* {personInput.practice_data.office
                                            && personInput.practice_data.office.split(" ")[0] === "Industry"
                                            && <Tab eventKey="address" title="Home address" className="row-px-2">
                                                <Row className="gx-2">
                                                    <Col lg={3}>
                                                        <Form.Label>Find your address</Form.Label>
                                                        <div className="find-address">
                                                        <span className="address-icon" onClick={handlePostcodeSubmit} ><Image src={addressIcon} />search</span>                                                            <Form.Control
                                                                name="postcode"
                                                                type="text"
                                                                size="sm"
                                                                placeholder="Enter your Postcode"
                                                                autoComplete="nope"
                                                                onChange={handlePostcodeChange}
                                                            />
                                                            {postcodeMenuShow && data.hasOwnProperty("arr") && Array.from(data.arr).length > 0 &&
                                                                <div ref={ref} className="address-dropdown">
                                                                    <PerfectScrollbar>
                                                                        {Array.from(data.arr).map((curr, index) =>
                                                                            <p key={index} onClick={() => { handleSetAddress(curr) }}>{curr.display}</p>
                                                                        )}
                                                                    </PerfectScrollbar>
                                                                </div>
                                                            }
                                                        </div>
                                                    </Col>
                                                    <Col lg={3}>
                                                        <Form.Label>Address line 1</Form.Label>
                                                        <Form.Control
                                                            name="v_address_1"
                                                            type="text"
                                                            value={personInput.v_address_1}
                                                            onChange={handlePersonChange}
                                                        />
                                                    </Col>
                                                    <Col lg={3}>
                                                        <Form.Label>Address line 2</Form.Label>
                                                        <Form.Control
                                                            name="v_address_2"
                                                            type="text"
                                                            value={personInput.v_address_2}
                                                            onChange={handlePersonChange}
                                                        />
                                                    </Col>

                                                    <Col lg={3}>
                                                        <Form.Label>Address line 3</Form.Label>
                                                        <Form.Control
                                                            name="v_address_3"
                                                            type="text"
                                                            value={personInput.v_address_3}
                                                            onChange={handlePersonChange}
                                                        />
                                                    </Col>

                                                    <Col lg={3}>
                                                        <Form.Label>Town / City</Form.Label>
                                                        <Form.Control
                                                            name="v_town"
                                                            type="text"
                                                            value={personInput.v_town}
                                                            onChange={handlePersonChange}
                                                        />
                                                    </Col>

                                                    <Col lg={3}>
                                                        <Form.Label>County</Form.Label>
                                                        <Form.Control
                                                            name="v_county"
                                                            type="text"
                                                            value={personInput.v_county}
                                                            onChange={handlePersonChange}
                                                        />
                                                    </Col>


                                                    <Col lg={3}>
                                                        <Form.Label>Postcode</Form.Label>
                                                        <Form.Control
                                                            name="v_postcode"
                                                            type="text"
                                                            value={personInput.v_postcode}
                                                            onChange={handlePersonChange}
                                                        />
                                                    </Col>

                                                    <Col lg={3}>
                                                        <Form.Label>Country</Form.Label>
                                                        <Form.Control
                                                            name="v_country"
                                                            type="text"
                                                            value={personInput.v_country}
                                                            onChange={handlePersonChange}
                                                        />
                                                    </Col>

                                                    <Col lg={3}>
                                                        <Form.Label>Primary email</Form.Label>
                                                        <Form.Control
                                                            name="v_email"
                                                            type="text"
                                                            value={personInput.v_email}
                                                            onChange={handlePersonChange}
                                                        />
                                                    </Col>

                                                    <Col lg={3}>
                                                        <Form.Label>Secondary email</Form.Label>
                                                        <Form.Control
                                                            name="v_work_email"
                                                            type="text"
                                                            value={personInput.v_work_email}
                                                            onChange={handlePersonChange}
                                                        />
                                                    </Col>

                                                    <Col lg={3}>
                                                        <Form.Label>SSO email</Form.Label>
                                                        <Form.Control
                                                            disabled
                                                            value={personInput.sso_email}
                                                        />
                                                    </Col>

                                                    <Col lg={3}>
                                                        <Form.Label>Tel.</Form.Label>
                                                        <Form.Control
                                                            name="v_tel"
                                                            type="text"
                                                            value={personInput.v_tel}
                                                            onChange={handlePersonChange}
                                                        />
                                                    </Col>
                                                </Row>

                                            </Tab>} */}
                                    </Tabs>
                                </Tab>
                                {/*<Tab eventKey="skills" title="SKILL MATCH">*/}
                                {/*    <Tabs className="secondary-tabs row-px-2">*/}
                                {/*        <Tab eventKey="pinfo" title="Personal Info" className="row-px-2">*/}
                                {/*            <Form>*/}
                                {/*                <Row className="align-items">*/}
                                {/*                    <Col lg={4}>*/}
                                {/*                        <Form.Label>Primary Email</Form.Label>*/}
                                {/*                        <Form.Control*/}
                                {/*                            // name=""*/}
                                {/*                            readOnly={true}*/}
                                {/*                            type="text"*/}
                                {/*                            value={personInput.v_email}*/}
                                {/*                        />*/}
                                {/*                        <Form.Label>Contact time</Form.Label>*/}
                                {/*                        <Form.Control*/}
                                {/*                            name="contact_time"*/}
                                {/*                            type="text"*/}
                                {/*                            value={personInput.contact_time}*/}
                                {/*                            onChange={handlePersonChange}*/}
                                {/*                        />*/}
                                {/*                        <Form.Label>Right to work in the UK?</Form.Label>*/}
                                {/*                        /!* <Form.Control*/}
                                {/*                    name="rtw"*/}
                                {/*                    type="text"*/}
                                {/*                    value={personInput.rtw}*/}
                                {/*                    onChange={handlePersonChange}*/}
                                {/*                /> *!/*/}
                                {/*                        <div className="d-flex">*/}
                                {/*                            <Form.Group controlID="rtw_Yes">*/}
                                {/*                                <Form.Check*/}
                                {/*                                    inline*/}
                                {/*                                    id="rtw_Yes"*/}
                                {/*                                    name="rtw"*/}
                                {/*                                    type="radio"*/}
                                {/*                                    label="Yes"*/}
                                {/*                                    value={"Yes"}*/}
                                {/*                                    onChange={handlePersonChange}*/}
                                {/*                                    checked={personInput.rtw == "Yes"}*/}
                                {/*                                />*/}
                                {/*                            </Form.Group>*/}
                                {/*                            <Form.Group controlID="rtw_No">*/}
                                {/*                                <Form.Check*/}
                                {/*                                    inline*/}
                                {/*                                    id="rtw_No"*/}
                                {/*                                    name="rtw"*/}
                                {/*                                    type="radio"*/}
                                {/*                                    label="No"*/}
                                {/*                                    value={"No"}*/}
                                {/*                                    onChange={handlePersonChange}*/}
                                {/*                                    checked={personInput.rtw == "No"}*/}
                                {/*                                />*/}
                                {/*                            </Form.Group>*/}
                                {/*                        </div>*/}
                                {/*                        <Form.Label>RCVS number</Form.Label>*/}
                                {/*                        <Form.Control*/}
                                {/*                            name="rcvs_2"*/}
                                {/*                            type="email"*/}
                                {/*                            value={personInput.rcvs_2}*/}
                                {/*                            onChange={handlePersonChange}*/}
                                {/*                        />*/}
                                {/*                        <Form.Label>County</Form.Label>*/}
                                {/*                        <Form.Control*/}
                                {/*                            name="sm_county"*/}
                                {/*                            type="text"*/}
                                {/*                            value={personInput.sm_county}*/}
                                {/*                            onChange={handlePersonChange}*/}
                                {/*                        />*/}
                                {/*                        <Form.Label>Relocate</Form.Label>*/}
                                {/*                        <div className="d-flex">*/}
                                {/*                            <Form.Group controlID="relocate_Yes">*/}
                                {/*                                <Form.Check*/}
                                {/*                                    inline*/}
                                {/*                                    id="relocate_Yes"*/}
                                {/*                                    name="relocate"*/}
                                {/*                                    type="radio"*/}
                                {/*                                    label="Yes"*/}
                                {/*                                    value={"Yes"}*/}
                                {/*                                    onChange={handlePersonChange}*/}
                                {/*                                    checked={personInput.relocate == "Yes"}*/}
                                {/*                                />*/}
                                {/*                            </Form.Group>*/}
                                {/*                            <Form.Group controlID="relocate_No">*/}
                                {/*                                <Form.Check*/}
                                {/*                                    inline*/}
                                {/*                                    id="relocate_No"*/}
                                {/*                                    name="relocate"*/}
                                {/*                                    type="radio"*/}
                                {/*                                    label="No"*/}
                                {/*                                    value={"No"}*/}
                                {/*                                    onChange={handlePersonChange}*/}
                                {/*                                    checked={personInput.relocate == "No"}*/}
                                {/*                                />*/}
                                {/*                            </Form.Group>*/}
                                {/*                        </div>*/}
                                {/*                    </Col>*/}
                                {/*                    <Col lg={4}>*/}
                                {/*                        <Form.Label>Work Email</Form.Label>*/}
                                {/*                        <Form.Control*/}
                                {/*                            name="sm_work_email"*/}
                                {/*                            type="email"*/}
                                {/*                            value={personInput.sm_work_email}*/}
                                {/*                            onChange={handlePersonChange}*/}
                                {/*                        />*/}
                                {/*                        <Form.Label>Preferred method of contact</Form.Label>*/}
                                {/*                        <div className="d-flex mb-2">*/}
                                {/*                            <Form.Group controlID="contact_method_Email">*/}
                                {/*                                <Form.Check*/}
                                {/*                                    id="contact_method_Email"*/}
                                {/*                                    name="contact_method"*/}
                                {/*                                    label="Email"*/}
                                {/*                                    value="Email"*/}
                                {/*                                    className="me-3"*/}
                                {/*                                    checked={personInput.contact_method &&*/}
                                {/*                                    personInput.contact_method.split(",").indexOf("Email") !== -1}*/}
                                {/*                                    onChange={handlePersonChange}*/}
                                {/*                                />*/}
                                {/*                            </Form.Group>*/}
                                {/*                            <Form.Group controlID="contact_method_Phone">*/}
                                {/*                                <Form.Check*/}
                                {/*                                    id="contact_method_Phone"*/}
                                {/*                                    name="contact_method"*/}
                                {/*                                    label="Phone"*/}
                                {/*                                    value="Phone"*/}
                                {/*                                    className="me-3"*/}
                                {/*                                    checked={personInput.contact_method &&*/}
                                {/*                                    personInput.contact_method.split(",").indexOf("Phone") !== -1}*/}
                                {/*                                    onChange={handlePersonChange}*/}
                                {/*                                />*/}
                                {/*                            </Form.Group>*/}
                                {/*                            <Form.Group controlID="contact_method_Both">*/}
                                {/*                                <Form.Check*/}
                                {/*                                    id="contact_method_Both"*/}
                                {/*                                    name="contact_method"*/}
                                {/*                                    label="Both"*/}
                                {/*                                    value="Both"*/}
                                {/*                                    checked={personInput.contact_method &&*/}
                                {/*                                    personInput.contact_method.split(",").indexOf("Both") !== -1}*/}
                                {/*                                    onChange={handlePersonChange}*/}
                                {/*                                />*/}
                                {/*                            </Form.Group>*/}
                                {/*                        </div>*/}
                                {/*                        <Form.Label>Salary band</Form.Label>*/}
                                {/*                        <Select*/}
                                {/*                            isMulti*/}
                                {/*                            className="basic-single"*/}
                                {/*                            classNamePrefix="select"*/}
                                {/*                            name="sm_salary_band"*/}
                                {/*                            styles={selectStyle}*/}
                                {/*                            options={salaryBand}*/}
                                {/*                            value={selectedSalaryBand}*/}
                                {/*                            onChange={setSalaryBand}*/}
                                {/*                        />*/}
                                {/*                        <Form.Label>Address</Form.Label>*/}
                                {/*                        <Form.Control*/}
                                {/*                            name="sm_address_1"*/}
                                {/*                            type="text"*/}
                                {/*                            value={personInput.sm_address_1}*/}
                                {/*                            onChange={handlePersonChange}*/}
                                {/*                        />*/}
                                {/*                        <Form.Label>Home postcode</Form.Label>*/}
                                {/*                        <Form.Control*/}
                                {/*                            name="s_postcode"*/}
                                {/*                            type="text"*/}
                                {/*                            value={personInput.s_postcode}*/}
                                {/*                            onChange={handlePersonChange}*/}
                                {/*                        />*/}
                                {/*                        <Form.Label>Select location</Form.Label>*/}
                                {/*                        <Select*/}
                                {/*                            isMulti*/}
                                {/*                            className="basic-single"*/}
                                {/*                            classNamePrefix="select"*/}
                                {/*                            name="areas"*/}
                                {/*                            styles={selectStyle}*/}
                                {/*                            options={areas}*/}
                                {/*                            value={selectedLocation}*/}
                                {/*                            onChange={setLocation}*/}
                                {/*                        />*/}
                                {/*                    </Col>*/}
                                {/*                    <Col lg={4}>*/}
                                {/*                        <Form.Label>Phone number</Form.Label>*/}
                                {/*                        <Form.Control*/}
                                {/*                            name="phone_number"*/}
                                {/*                            type="text"*/}
                                {/*                            value={personInput.phone_number}*/}
                                {/*                            onChange={handlePersonChange}*/}
                                {/*                        />*/}
                                {/*                        <Form.Label>Do you hold a UK valid driving</Form.Label>*/}
                                {/*                        <div className="d-flex">*/}
                                {/*                            <Form.Group controlID="drlc_Yes">*/}
                                {/*                                <Form.Check*/}
                                {/*                                    inline*/}
                                {/*                                    id="drlc_Yes"*/}
                                {/*                                    name="drlc"*/}
                                {/*                                    type="radio"*/}
                                {/*                                    label="Yes"*/}
                                {/*                                    value={"Yes"}*/}
                                {/*                                    checked={personInput.drlc == "Yes"}*/}
                                {/*                                    onChange={handlePersonChange}*/}
                                {/*                                />*/}
                                {/*                            </Form.Group>*/}
                                {/*                            <Form.Group controlID="drlc_No">*/}
                                {/*                                <Form.Check*/}
                                {/*                                    inline*/}
                                {/*                                    id="drlc_No"*/}
                                {/*                                    name="drlc"*/}
                                {/*                                    type="radio"*/}
                                {/*                                    label="No"*/}
                                {/*                                    value={"No"}*/}
                                {/*                                    checked={personInput.drlc == "No"}*/}
                                {/*                                    onChange={handlePersonChange}*/}
                                {/*                                />*/}
                                {/*                            </Form.Group>*/}
                                {/*                        </div>*/}
                                {/*                        <Form.Label>Preferred next role</Form.Label>*/}
                                {/*                        <Form.Control*/}
                                {/*                            name="sm_next_role"*/}
                                {/*                            type="text"*/}
                                {/*                            value={personInput.sm_next_role}*/}
                                {/*                            onChange={handlePersonChange}*/}
                                {/*                        />*/}
                                {/*                        <Form.Label>Town</Form.Label>*/}
                                {/*                        <Form.Control*/}
                                {/*                            name="sm_town"*/}
                                {/*                            type="text"*/}
                                {/*                            value={personInput.sm_town}*/}
                                {/*                            onChange={handlePersonChange}*/}
                                {/*                        />*/}
                                {/*                        <Form.Label>Travel (miles)</Form.Label>*/}
                                {/*                        <Form.Control*/}
                                {/*                            name="distance"*/}
                                {/*                            type="text"*/}
                                {/*                            value={personInput.distance}*/}
                                {/*                            onChange={handlePersonChange}*/}
                                {/*                        />*/}
                                {/*                    </Col>*/}
                                {/*                </Row>*/}
                                {/*            </Form>*/}

                                {/*        </Tab>*/}
                                {/*        <Tab eventKey="profile" title="Profile">*/}
                                {/*            <div>*/}
                                {/*                <Row className="px-card">*/}
                                {/*                    <Col>*/}

                                {/*                        <Form.Label>Job Type</Form.Label>*/}

                                {/*                        <div className="d-flex">*/}
                                {/*                            <Form.Group controlID="prof_Vet">*/}
                                {/*                                <Form.Check*/}
                                {/*                                    inline*/}
                                {/*                                    id="prof_Vet"*/}
                                {/*                                    name="prof"*/}
                                {/*                                    type="radio"*/}
                                {/*                                    label="Vet"*/}
                                {/*                                    value="vet"*/}
                                {/*                                    checked={personInput.prof === "vet"}*/}
                                {/*                                    onChange={handlePersonChange}*/}
                                {/*                                />*/}
                                {/*                            </Form.Group>*/}
                                {/*                            <Form.Group controlID="prof_Nurse">*/}
                                {/*                                <Form.Check*/}
                                {/*                                    inline*/}
                                {/*                                    id="prof_Nurse"*/}
                                {/*                                    name="prof"*/}
                                {/*                                    type="radio"*/}
                                {/*                                    label="Nurse"*/}
                                {/*                                    value="nurse"*/}
                                {/*                                    checked={personInput.prof === "nurse"}*/}
                                {/*                                    onChange={handlePersonChange}*/}
                                {/*                                />*/}
                                {/*                            </Form.Group>*/}
                                {/*                        </div>*/}
                                {/*                    </Col>*/}
                                {/*                    <Col>*/}
                                {/*                        <Form.Label>Position Type</Form.Label>*/}
                                {/*                        <Select*/}
                                {/*                            isMulti*/}
                                {/*                            className="basic-single"*/}
                                {/*                            classNamePrefix="select"*/}
                                {/*                            name="position"*/}
                                {/*                            styles={selectStyle}*/}
                                {/*                            options={positions}*/}
                                {/*                            value={positionType}*/}
                                {/*                            onChange={setPositionType}*/}
                                {/*                        />*/}

                                {/*                    </Col>*/}
                                {/*                    <Col>*/}
                                {/*                        <Form.Label>Species Type</Form.Label>*/}
                                {/*                        <Select*/}
                                {/*                            isMulti*/}
                                {/*                            className="basic-single"*/}
                                {/*                            classNamePrefix="select"*/}
                                {/*                            name="species_type"*/}
                                {/*                            options={species}*/}
                                {/*                            styles={selectStyle}*/}
                                {/*                            value={speciesType}*/}
                                {/*                            onChange={setSpeciesType}*/}
                                {/*                        />*/}
                                {/*                    </Col>*/}
                                {/*                </Row>*/}
                                {/*                <Accordion defaultActiveKey="0">*/}
                                {/*                    <Card>*/}
                                {/*                        <Card.Header>*/}
                                {/*                            <Accordion.Toggle as={"div"} eventKey="0">*/}
                                {/*                                Small Animal*/}
                                {/*                            </Accordion.Toggle>*/}
                                {/*                        </Card.Header>*/}
                                {/*                        <Accordion.Collapse eventKey="0">*/}
                                {/*                            <Card.Body>*/}
                                {/*                                <div className="custom-table">*/}
                                {/*                                    <Table className="table-curved">*/}
                                {/*                                        <thead>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <th>Small Animal</th>*/}
                                {/*                                            <th className="text-center">Very confident</th>*/}
                                {/*                                            <th className="text-center">Confident</th>*/}
                                {/*                                            <th className="text-center">Limited Experience</th>*/}
                                {/*                                            <th className="text-center">None</th>*/}
                                {/*                                        </tr>*/}
                                {/*                                        </thead>*/}
                                {/*                                        <tbody>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <td>General consultations</td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="consultation_general"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="1"*/}
                                {/*                                                    checked={String(personInput.consultation_general) === "1"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="consultation_general"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="2"*/}
                                {/*                                                    checked={String(personInput.consultation_general) === "2"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="consultation_general"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="3"*/}
                                {/*                                                    checked={String(personInput.consultation_general) === "3"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="consultation_general"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="4"*/}
                                {/*                                                    checked={String(personInput.consultation_general) === "4"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                        </tr>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <td>Are you confident doing 10-minute consultations</td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="consultation_10min"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="1"*/}
                                {/*                                                    checked={String(personInput.consultation_10min) === "1"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="consultation_10min"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="2"*/}
                                {/*                                                    checked={String(personInput.consultation_10min) === "2"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="consultation_10min"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="3"*/}
                                {/*                                                    checked={String(personInput.consultation_10min) === "3"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="consultation_10min"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="4"*/}
                                {/*                                                    checked={String(personInput.consultation_10min) === "4"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                        </tr>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <td>Dog / cat castration</td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="castration_dog_cat"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="1"*/}
                                {/*                                                    checked={String(personInput.castration_dog_cat) === "1"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="castration_dog_cat"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="2"*/}
                                {/*                                                    checked={String(personInput.castration_dog_cat) === "2"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="castration_dog_cat"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="3"*/}
                                {/*                                                    checked={String(personInput.castration_dog_cat) === "3"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="castration_dog_cat"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="4"*/}
                                {/*                                                    checked={String(personInput.castration_dog_cat) === "4"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                        </tr>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <td>Rabbit castration</td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="castration_rabbit"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="1"*/}
                                {/*                                                    checked={String(personInput.castration_rabbit) === "1"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="castration_rabbit"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="2"*/}
                                {/*                                                    checked={String(personInput.castration_rabbit) === "2"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="castration_rabbit"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="3"*/}
                                {/*                                                    checked={String(personInput.castration_rabbit) === "3"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="castration_rabbit"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="4"*/}
                                {/*                                                    checked={String(personInput.castration_rabbit) === "4"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                        </tr>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <td>Dog / cat spay</td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="spay_dog_cat"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="1"*/}
                                {/*                                                    checked={String(personInput.spay_dog_cat) === "1"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="spay_dog_cat"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="2"*/}
                                {/*                                                    checked={String(personInput.spay_dog_cat) === "2"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="spay_dog_cat"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="3"*/}
                                {/*                                                    checked={String(personInput.spay_dog_cat) === "3"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="spay_dog_cat"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="4"*/}
                                {/*                                                    checked={String(personInput.spay_dog_cat) === "4"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                        </tr>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <td>Rabbit spay</td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="spay_rabbit"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="1"*/}
                                {/*                                                    checked={String(personInput.spay_rabbit) === "1"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="spay_rabbit"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="2"*/}
                                {/*                                                    checked={String(personInput.spay_rabbit) === "2"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="spay_rabbit"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="3"*/}
                                {/*                                                    checked={String(personInput.spay_rabbit) === "3"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="spay_rabbit"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="4"*/}
                                {/*                                                    checked={String(personInput.spay_rabbit) === "4"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                        </tr>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <td>Caesarean section</td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="c_section"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="1"*/}
                                {/*                                                    checked={String(personInput.c_section) === "1"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="c_section"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="2"*/}
                                {/*                                                    checked={String(personInput.c_section) === "2"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="c_section"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="3"*/}
                                {/*                                                    checked={String(personInput.c_section) === "3"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="c_section"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="4"*/}
                                {/*                                                    checked={String(personInput.c_section) === "4"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                        </tr>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <td>Dental extractions</td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="extractions_dental"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="1"*/}
                                {/*                                                    checked={String(personInput.extractions_dental) === "1"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="extractions_dental"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="2"*/}
                                {/*                                                    checked={String(personInput.extractions_dental) === "2"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="extractions_dental"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="3"*/}
                                {/*                                                    checked={String(personInput.extractions_dental) === "3"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="extractions_dental"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="4"*/}
                                {/*                                                    checked={String(personInput.extractions_dental) === "4"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                        </tr>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <td>Ex Lap.</td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="ex_lap"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="1"*/}
                                {/*                                                    checked={String(personInput.ex_lap) === "1"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="ex_lap"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="2"*/}
                                {/*                                                    checked={String(personInput.ex_lap) === "2"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="ex_lap"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="3"*/}
                                {/*                                                    checked={String(personInput.ex_lap) === "3"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="ex_lap"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="4"*/}
                                {/*                                                    checked={String(personInput.ex_lap) === "4"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                        </tr>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <td>Limb amputation</td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="amputation_limb"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="1"*/}
                                {/*                                                    checked={String(personInput.amputation_limb) === "1"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="amputation_limb"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="2"*/}
                                {/*                                                    checked={String(personInput.amputation_limb) === "2"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="amputation_limb"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="3"*/}
                                {/*                                                    checked={String(personInput.amputation_limb) === "3"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="amputation_limb"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="4"*/}
                                {/*                                                    checked={String(personInput.amputation_limb) === "4"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                        </tr>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <td>Lump removals</td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="removal_lump"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="1"*/}
                                {/*                                                    checked={String(personInput.removal_lump) === "1"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="removal_lump"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="2"*/}
                                {/*                                                    checked={String(personInput.removal_lump) === "2"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="removal_lump"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="3"*/}
                                {/*                                                    checked={String(personInput.removal_lump) === "3"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="removal_lump"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="4"*/}
                                {/*                                                    checked={String(personInput.removal_lump) === "4"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                        </tr>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <td>Radiograph interpretation</td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="radiograph_interpretation"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="1"*/}
                                {/*                                                    checked={String(personInput.radiograph_interpretation) === "1"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="radiograph_interpretation"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="2"*/}
                                {/*                                                    checked={String(personInput.radiograph_interpretation) === "2"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="radiograph_interpretation"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="3"*/}
                                {/*                                                    checked={String(personInput.radiograph_interpretation) === "3"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="radiograph_interpretation"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="4"*/}
                                {/*                                                    checked={String(personInput.radiograph_interpretation) === "4"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                        </tr>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <td>Taking radiographs</td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="radiograph_taking"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="1"*/}
                                {/*                                                    checked={String(personInput.radiograph_taking) === "1"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="radiograph_taking"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="2"*/}
                                {/*                                                    checked={String(personInput.radiograph_taking) === "2"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="radiograph_taking"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="3"*/}
                                {/*                                                    checked={String(personInput.radiograph_taking) === "3"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="radiograph_taking"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="4"*/}
                                {/*                                                    checked={String(personInput.radiograph_taking) === "4"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                        </tr>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <td>Interpreting laboratory results</td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="lab_interpreting_results"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="1"*/}
                                {/*                                                    checked={String(personInput.lab_interpreting_results) === "1"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="lab_interpreting_results"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="2"*/}
                                {/*                                                    checked={String(personInput.lab_interpreting_results) === "2"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="lab_interpreting_results"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="3"*/}
                                {/*                                                    checked={String(personInput.lab_interpreting_results) === "3"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="lab_interpreting_results"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="4"*/}
                                {/*                                                    checked={String(personInput.lab_interpreting_results) === "4"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                        </tr>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <td>Emergency medicine and critical care</td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="emergency_medicine"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="1"*/}
                                {/*                                                    checked={String(personInput.emergency_medicine) === "1"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="emergency_medicine"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="2"*/}
                                {/*                                                    checked={String(personInput.emergency_medicine) === "2"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="emergency_medicine"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="3"*/}
                                {/*                                                    checked={String(personInput.emergency_medicine) === "3"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="emergency_medicine"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="4"*/}
                                {/*                                                    checked={String(personInput.emergency_medicine) === "4"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                        </tr>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <td>Ultrasonography</td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="ultrasonography"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="1"*/}
                                {/*                                                    checked={String(personInput.ultrasonography) === "1"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="ultrasonography"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="2"*/}
                                {/*                                                    checked={String(personInput.ultrasonography) === "2"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="ultrasonography"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="3"*/}
                                {/*                                                    checked={String(personInput.ultrasonography) === "3"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="ultrasonography"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="4"*/}
                                {/*                                                    checked={String(personInput.ultrasonography) === "4"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                        </tr>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <td>Eutdanasia consultations</td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="consultation_euthanasia"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="1"*/}
                                {/*                                                    checked={String(personInput.consultation_euthanasia) === "1"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="consultation_euthanasia"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="2"*/}
                                {/*                                                    checked={String(personInput.consultation_euthanasia) === "2"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="consultation_euthanasia"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="3"*/}
                                {/*                                                    checked={String(personInput.consultation_euthanasia) === "3"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="consultation_euthanasia"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="4"*/}
                                {/*                                                    checked={String(personInput.consultation_euthanasia) === "4"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                        </tr>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <td>Orthopaedic surgery</td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="surgery_orthaepedic"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="1"*/}
                                {/*                                                    checked={String(personInput.surgery_orthaepedic) === "1"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="surgery_orthaepedic"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="2"*/}
                                {/*                                                    checked={String(personInput.surgery_orthaepedic) === "2"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="surgery_orthaepedic"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="3"*/}
                                {/*                                                    checked={String(personInput.surgery_orthaepedic) === "3"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="surgery_orthaepedic"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="4"*/}
                                {/*                                                    checked={String(personInput.surgery_orthaepedic) === "4"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                        </tr>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <td>Dermatology investigation</td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="investigation_dermatology"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="1"*/}
                                {/*                                                    checked={String(personInput.investigation_dermatology) === "1"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="investigation_dermatology"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="2"*/}
                                {/*                                                    checked={String(personInput.investigation_dermatology) === "2"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="investigation_dermatology"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="3"*/}
                                {/*                                                    checked={String(personInput.investigation_dermatology) === "3"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="investigation_dermatology"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="4"*/}
                                {/*                                                    checked={String(personInput.investigation_dermatology) === "4"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                        </tr>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <td>Ophthalmology</td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="ophthalmology"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="1"*/}
                                {/*                                                    checked={String(personInput.ophthalmology) === "1"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="ophthalmology"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="2"*/}
                                {/*                                                    checked={String(personInput.ophthalmology) === "2"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="ophthalmology"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="3"*/}
                                {/*                                                    checked={String(personInput.ophthalmology) === "3"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="ophthalmology"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="4"*/}
                                {/*                                                    checked={String(personInput.ophthalmology) === "4"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                        </tr>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <td>Cardiology investigation</td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="investigation_cardiology"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="1"*/}
                                {/*                                                    checked={String(personInput.investigation_cardiology) === "1"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="investigation_cardiology"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="2"*/}
                                {/*                                                    checked={String(personInput.investigation_cardiology) === "2"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="investigation_cardiology"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="3"*/}
                                {/*                                                    checked={String(personInput.investigation_cardiology) === "3"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="investigation_cardiology"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="4"*/}
                                {/*                                                    checked={String(personInput.investigation_cardiology) === "4"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                        </tr>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <td>Neurology investigation</td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="investigation_neurology"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="1"*/}
                                {/*                                                    checked={String(personInput.investigation_neurology) === "1"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="investigation_neurology"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="2"*/}
                                {/*                                                    checked={String(personInput.investigation_neurology) === "2"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="investigation_neurology"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="3"*/}
                                {/*                                                    checked={String(personInput.investigation_neurology) === "3"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="investigation_neurology"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="4"*/}
                                {/*                                                    checked={String(personInput.investigation_neurology) === "4"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                        </tr>*/}

                                {/*                                        </tbody>*/}

                                {/*                                    </Table>*/}
                                {/*                                </div>*/}
                                {/*                                <Form.Label className="mt-3">Cat spay in minutes</Form.Label>*/}
                                {/*                                <Form.Control*/}
                                {/*                                    name="cat_spay"*/}
                                {/*                                    type="text"*/}
                                {/*                                    placeholder="Cat spay in minutes"*/}
                                {/*                                    value={personInput.cat_spay}*/}
                                {/*                                    onChange={handlePersonChange}*/}
                                {/*                                />*/}

                                {/*                                <Form.Label>20kg bitch spay in minutes</Form.Label>*/}
                                {/*                                <Form.Control*/}
                                {/*                                    name="bitch_spay"*/}
                                {/*                                    type="text"*/}
                                {/*                                    placeholder="20kg bitch spay in minutes "*/}
                                {/*                                    value={personInput.bitch_spay}*/}
                                {/*                                    onChange={handlePersonChange}/>*/}
                                {/*                            </Card.Body>*/}

                                {/*                        </Accordion.Collapse>*/}
                                {/*                    </Card>*/}
                                {/*                </Accordion>*/}
                                {/*                <Accordion defaultActiveKey="1">*/}
                                {/*                    <Card>*/}
                                {/*                        <Card.Header>*/}
                                {/*                            <Accordion.Toggle as={"div"} eventKey="1">*/}
                                {/*                                Farm Animal*/}
                                {/*                            </Accordion.Toggle>*/}
                                {/*                        </Card.Header>*/}
                                {/*                        <Accordion.Collapse eventKey="1">*/}
                                {/*                            <Card.Body>*/}
                                {/*                                <div className="custom-table">*/}
                                {/*                                    <Table className="table-curved">*/}
                                {/*                                        <thead>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <th>Farm Animal</th>*/}
                                {/*                                            <th className="text-center">Very Confident</th>*/}
                                {/*                                            <th className="text-center">Very Confident</th>*/}
                                {/*                                            <th className="text-center">Limited Experience</th>*/}
                                {/*                                            <th className="text-center">None</th>*/}
                                {/*                                        </tr>*/}
                                {/*                                        </thead>*/}
                                {/*                                        <tbody>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <td>Clinical examination and diagnosis</td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="clinical_examination"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="1"*/}
                                {/*                                                    checked={String(personInput.clinical_examination) === "1"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="clinical_examination"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="2"*/}
                                {/*                                                    checked={String(personInput.clinical_examination) === "2"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="clinical_examination"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="3"*/}
                                {/*                                                    checked={String(personInput.clinical_examination) === "3"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="clinical_examination"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="4"*/}
                                {/*                                                    checked={String(personInput.clinical_examination) === "4"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                        </tr>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <td>Fertility work</td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="fertility_work"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="1"*/}
                                {/*                                                    checked={String(personInput.fertility_work) === "1"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="fertility_work"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="2"*/}
                                {/*                                                    checked={String(personInput.fertility_work) === "2"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="fertility_work"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="3"*/}
                                {/*                                                    checked={String(personInput.fertility_work) === "3"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="fertility_work"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="4"*/}
                                {/*                                                    checked={String(personInput.fertility_work) === "4"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                        </tr>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <td>LDA surgery</td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="lda_surgery"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="1"*/}
                                {/*                                                    checked={String(personInput.lda_surgery) === "1"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="lda_surgery"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="2"*/}
                                {/*                                                    checked={String(personInput.lda_surgery) === "2"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="lda_surgery"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="3"*/}
                                {/*                                                    checked={String(personInput.lda_surgery) === "3"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="lda_surgery"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="4"*/}
                                {/*                                                    checked={String(personInput.lda_surgery) === "4"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                        </tr>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <td>Caesareans</td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="caesareans"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="1"*/}
                                {/*                                                    checked={String(personInput.caesareans) === "1"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="caesareans"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="2"*/}
                                {/*                                                    checked={String(personInput.caesareans) === "2"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="caesareans"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="3"*/}
                                {/*                                                    checked={String(personInput.caesareans) === "3"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="caesareans"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="4"*/}
                                {/*                                                    checked={String(personInput.caesareans) === "4"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                        </tr>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <td>Blood sampling</td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="blood_sampling"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="1"*/}
                                {/*                                                    checked={String(personInput.blood_sampling) === "1"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="blood_sampling"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="2"*/}
                                {/*                                                    checked={String(personInput.blood_sampling) === "2"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="blood_sampling"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="3"*/}
                                {/*                                                    checked={String(personInput.blood_sampling) === "3"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="blood_sampling"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="4"*/}
                                {/*                                                    checked={String(personInput.blood_sampling) === "4"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                        </tr>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <td>TB Testing</td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="tb_testing"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="1"*/}
                                {/*                                                    checked={String(personInput.tb_testing) === "1"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="tb_testing"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="2"*/}
                                {/*                                                    checked={String(personInput.tb_testing) === "2"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="tb_testing"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="3"*/}
                                {/*                                                    checked={String(personInput.tb_testing) === "3"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="tb_testing"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="4"*/}
                                {/*                                                    checked={String(personInput.tb_testing) === "4"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                        </tr>*/}
                                {/*                                        </tbody>*/}

                                {/*                                    </Table>*/}
                                {/*                                </div>*/}
                                {/*                                <Form.Label className="mt-3">TB tests per day</Form.Label>*/}
                                {/*                                <Form.Control*/}
                                {/*                                    name="tb_per_day"*/}
                                {/*                                    type="text"*/}
                                {/*                                    placeholder="TB tests per day"*/}
                                {/*                                    value={personInput.tb_per_day}*/}
                                {/*                                    onChange={handlePersonChange}*/}
                                {/*                                />*/}

                                {/*                                <Form.Label>Years of farm experience </Form.Label>*/}
                                {/*                                <Form.Control*/}
                                {/*                                    name="farm_experience"*/}
                                {/*                                    type="text"*/}
                                {/*                                    placeholder="Years of farm experience "*/}
                                {/*                                    value={personInput.farm_experience}*/}
                                {/*                                    onChange={handlePersonChange}/>*/}
                                {/*                            </Card.Body>*/}
                                {/*                        </Accordion.Collapse>*/}
                                {/*                    </Card>*/}
                                {/*                </Accordion>*/}
                                {/*                <Accordion defaultActiveKey="2">*/}
                                {/*                    <Card>*/}
                                {/*                        <Card.Header>*/}
                                {/*                            <Accordion.Toggle as={"div"} eventKey="2">*/}
                                {/*                                Equine*/}
                                {/*                            </Accordion.Toggle>*/}
                                {/*                        </Card.Header>*/}
                                {/*                        <Accordion.Collapse eventKey="2">*/}
                                {/*                            <Card.Body>*/}
                                {/*                                <div className="custom-table">*/}
                                {/*                                    <Table className="table-curved">*/}
                                {/*                                        <thead>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <th>Equine</th>*/}
                                {/*                                            <th className="text-center">Very Confident</th>*/}
                                {/*                                            <th className="text-center">Confident</th>*/}
                                {/*                                            <th className="text-center">Limited Experience</th>*/}
                                {/*                                            <th className="text-center">None</th>*/}
                                {/*                                        </tr>*/}
                                {/*                                        </thead>*/}
                                {/*                                        <tbody>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <td>Lameness investigations</td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="investigation_lameness"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="1"*/}
                                {/*                                                    checked={String(personInput.investigation_lameness) === "1"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="investigation_lameness"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="2"*/}
                                {/*                                                    checked={String(personInput.investigation_lameness) === "2"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="investigation_lameness"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="3"*/}
                                {/*                                                    checked={String(personInput.investigation_lameness) === "3"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="investigation_lameness"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="4"*/}
                                {/*                                                    checked={String(personInput.investigation_lameness) === "4"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                        </tr>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <td>Dentistry</td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="dentistry"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="1"*/}
                                {/*                                                    checked={String(personInput.dentistry) === "1"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="dentistry"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="2"*/}
                                {/*                                                    checked={String(personInput.dentistry) === "2"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="dentistry"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="3"*/}
                                {/*                                                    checked={String(personInput.dentistry) === "3"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="dentistry"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="4"*/}
                                {/*                                                    checked={String(personInput.dentistry) === "4"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                        </tr>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <td>Pre-purchase investigations</td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="investigation_pre_purchase"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="1"*/}
                                {/*                                                    checked={String(personInput.investigation_pre_purchase) === "1"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="investigation_pre_purchase"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="2"*/}
                                {/*                                                    checked={String(personInput.investigation_pre_purchase) === "2"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="investigation_pre_purchase"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="3"*/}
                                {/*                                                    checked={String(personInput.investigation_pre_purchase) === "3"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="investigation_pre_purchase"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="4"*/}
                                {/*                                                    checked={String(personInput.investigation_pre_purchase) === "4"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                        </tr>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <td>Stud work</td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="stud_work"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="1"*/}
                                {/*                                                    checked={String(personInput.stud_work) === "1"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="stud_work"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="2"*/}
                                {/*                                                    checked={String(personInput.stud_work) === "2"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="stud_work"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="3"*/}
                                {/*                                                    checked={String(personInput.stud_work) === "3"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="stud_work"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="4"*/}
                                {/*                                                    checked={String(personInput.stud_work) === "4"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                        </tr>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <td>Al work</td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="al_work"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="1"*/}
                                {/*                                                    checked={String(personInput.al_work) === "1"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="al_work"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="2"*/}
                                {/*                                                    checked={String(personInput.al_work) === "2"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="al_work"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="3"*/}
                                {/*                                                    checked={String(personInput.al_work) === "3"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="al_work"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="4"*/}
                                {/*                                                    checked={String(personInput.al_work) === "4"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                        </tr>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <td>Anaesthesia</td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="anaesthesia"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="1"*/}
                                {/*                                                    checked={String(personInput.anaesthesia) === "1"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="anaesthesia"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="2"*/}
                                {/*                                                    checked={String(personInput.anaesthesia) === "2"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="anaesthesia"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="3"*/}
                                {/*                                                    checked={String(personInput.anaesthesia) === "3"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="anaesthesia"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="4"*/}
                                {/*                                                    checked={String(personInput.anaesthesia) === "4"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                        </tr>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <td>Radiology</td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="radiology"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="1"*/}
                                {/*                                                    checked={String(personInput.radiology) === "1"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="radiology"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="2"*/}
                                {/*                                                    checked={String(personInput.radiology) === "2"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="radiology"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="3"*/}
                                {/*                                                    checked={String(personInput.radiology) === "3"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="radiology"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="4"*/}
                                {/*                                                    checked={String(personInput.radiology) === "4"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                        </tr>*/}
                                {/*                                        </tbody>*/}

                                {/*                                    </Table>*/}

                                {/*                                </div>*/}
                                {/*                                <Select*/}
                                {/*                                    isMulti*/}
                                {/*                                    className="basic-single mt-2"*/}
                                {/*                                    classNamePrefix="select"*/}
                                {/*                                    name="main_experience"*/}
                                {/*                                    styles={selectStyle}*/}
                                {/*                                    options={expHorses}*/}
                                {/*                                    value={seletedEquineExperience}*/}
                                {/*                                    onChange={setSeletedEquineExperience}*/}
                                {/*                                />*/}
                                {/*                            </Card.Body>*/}
                                {/*                        </Accordion.Collapse>*/}
                                {/*                    </Card>*/}
                                {/*                </Accordion>*/}
                                {/*                <Accordion defaultActiveKey="3">*/}
                                {/*                    <Card>*/}
                                {/*                        <Card.Header>*/}
                                {/*                            <Accordion.Toggle as={"div"} eventKey="3">*/}
                                {/*                                VN Skills Match*/}
                                {/*                            </Accordion.Toggle>*/}
                                {/*                        </Card.Header>*/}
                                {/*                        <Accordion.Collapse eventKey="3">*/}
                                {/*                            <Card.Body>*/}
                                {/*                                <div className="custom-table">*/}
                                {/*                                    <Table className="table-curved">*/}
                                {/*                                        <thead>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <th>VN Skills Match</th>*/}
                                {/*                                            <th className="text-center">Very Confident</th>*/}
                                {/*                                            <th className="text-center">Confident</th>*/}
                                {/*                                            <th className="text-center">Limited Experience</th>*/}
                                {/*                                            <th className="text-center">None</th>*/}
                                {/*                                        </tr>*/}
                                {/*                                        </thead>*/}
                                {/*                                        <tbody>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <td>Nurse consultations</td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="consultation_nurse"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="1"*/}
                                {/*                                                    checked={String(personInput.consultation_nurse) === "1"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="consultation_nurse"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="2"*/}
                                {/*                                                    checked={String(personInput.consultation_nurse) === "2"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="consultation_nurse"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="3"*/}
                                {/*                                                    checked={String(personInput.consultation_nurse) === "3"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="consultation_nurse"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="4"*/}
                                {/*                                                    checked={String(personInput.consultation_nurse) === "4"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                        </tr>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <td>Taking blood samples</td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="blood_samples_taking"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="1"*/}
                                {/*                                                    checked={String(personInput.blood_samples_taking) === "1"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="blood_samples_taking"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="2"*/}
                                {/*                                                    checked={String(personInput.blood_samples_taking) === "2"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="blood_samples_taking"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="3"*/}
                                {/*                                                    checked={String(personInput.blood_samples_taking) === "3"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="blood_samples_taking"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="4"*/}
                                {/*                                                    checked={String(personInput.blood_samples_taking) === "4"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                        </tr>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <td>Catheter insertion</td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="catheter_insertion"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="1"*/}
                                {/*                                                    checked={String(personInput.catheter_insertion) === "1"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="catheter_insertion"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="2"*/}
                                {/*                                                    checked={String(personInput.catheter_insertion) === "2"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="catheter_insertion"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="3"*/}
                                {/*                                                    checked={String(personInput.catheter_insertion) === "3"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="catheter_insertion"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="4"*/}
                                {/*                                                    checked={String(personInput.catheter_insertion) === "4"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                        </tr>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <td>Anaesthetics monitoring</td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="anaesthetics_monitoring"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="1"*/}
                                {/*                                                    checked={String(personInput.anaesthetics_monitoring) === "1"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="anaesthetics_monitoring"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="2"*/}
                                {/*                                                    checked={String(personInput.anaesthetics_monitoring) === "2"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="anaesthetics_monitoring"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="3"*/}
                                {/*                                                    checked={String(personInput.anaesthetics_monitoring) === "3"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="anaesthetics_monitoring"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="4"*/}
                                {/*                                                    checked={String(personInput.anaesthetics_monitoring) === "4"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                        </tr>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <td>Animal restraint</td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="animal_restraint"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="1"*/}
                                {/*                                                    checked={String(personInput.animal_restraint) === "1"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="animal_restraint"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="2"*/}
                                {/*                                                    checked={String(personInput.animal_restraint) === "2"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="animal_restraint"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="3"*/}
                                {/*                                                    checked={String(personInput.animal_restraint) === "3"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="animal_restraint"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="4"*/}
                                {/*                                                    checked={String(personInput.animal_restraint) === "4"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                        </tr>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <td>Reception skills</td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="reception_skills"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="1"*/}
                                {/*                                                    checked={String(personInput.reception_skills) === "1"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="reception_skills"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="2"*/}
                                {/*                                                    checked={String(personInput.reception_skills) === "2"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="reception_skills"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="3"*/}
                                {/*                                                    checked={String(personInput.reception_skills) === "3"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="reception_skills"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="4"*/}
                                {/*                                                    checked={String(personInput.reception_skills) === "4"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                        </tr>*/}
                                {/*                                        <tr>*/}
                                {/*                                            <td>Schedule 3 procedures</td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="schedule_3_procedures"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="1"*/}
                                {/*                                                    checked={String(personInput.schedule_3_procedures) === "1"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="schedule_3_procedures"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="2"*/}
                                {/*                                                    checked={String(personInput.schedule_3_procedures) === "2"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="schedule_3_procedures"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="3"*/}
                                {/*                                                    checked={String(personInput.schedule_3_procedures) === "3"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                            <td>*/}
                                {/*                                                <Form.Check*/}
                                {/*                                                    name="schedule_3_procedures"*/}
                                {/*                                                    type="radio"*/}
                                {/*                                                    value="4"*/}
                                {/*                                                    checked={String(personInput.schedule_3_procedures) === "4"}*/}
                                {/*                                                    onChange={handlePersonChange}*/}
                                {/*                                                />*/}
                                {/*                                            </td>*/}
                                {/*                                        </tr>*/}
                                {/*                                        </tbody>*/}
                                {/*                                    </Table>*/}
                                {/*                                </div>*/}
                                {/*                            </Card.Body>*/}
                                {/*                        </Accordion.Collapse>*/}
                                {/*                    </Card>*/}
                                {/*                </Accordion>*/}
                                {/*            </div>*/}
                                {/*        </Tab>*/}
                                {/*        <Tab eventKey="pms_cv" title="PMS+CV">*/}
                                {/*            <div>*/}
                                {/*                <Form>*/}
                                {/*                    <Row className="px-card pms-tab">*/}
                                {/*                        <Col>*/}
                                {/*                            <ListGroup className="">*/}
                                {/*                                <span className="list-group-header">What PMS systems are you confident in?</span>*/}
                                {/*                                <Form>*/}
                                {/*                                    <Row className="gx-0">*/}
                                {/*                                        <Col md={3}>*/}
                                {/*                                            <ListGroup.Item>*/}
                                {/*                                                <Form.Group controlID="pms_Animana">*/}
                                {/*                                                    <Form.Check*/}
                                {/*                                                        as="input"*/}
                                {/*                                                        id="pms_Animana"*/}
                                {/*                                                        name="pms"*/}
                                {/*                                                        type="checkbox"*/}
                                {/*                                                        label="Animana"*/}
                                {/*                                                        value="animana"*/}
                                {/*                                                        checked={*/}
                                {/*                                                            personInput.pms.indexOf("animana") != -1}*/}
                                {/*                                                        onChange={handlePersonChange}*/}
                                {/*                                                    />*/}
                                {/*                                                </Form.Group>*/}
                                {/*                                            </ListGroup.Item>*/}
                                {/*                                        </Col>*/}
                                {/*                                        <Col md={3}>*/}
                                {/*                                            <ListGroup.Item>*/}
                                {/*                                                <Form.Group controlID="pms_Ezvetpro">*/}
                                {/*                                                    <Form.Check*/}
                                {/*                                                        id="pms_Ezvetpro"*/}
                                {/*                                                        name="pms"*/}
                                {/*                                                        type="checkbox"*/}
                                {/*                                                        label="Ezvetpro"*/}
                                {/*                                                        value="ezvetpro"*/}
                                {/*                                                        checked={personInput.pms &&*/}
                                {/*                                                        personInput.pms.indexOf("ezvetpro") != -1}*/}
                                {/*                                                        onChange={handlePersonChange}*/}
                                {/*                                                    />*/}
                                {/*                                                </Form.Group>*/}
                                {/*                                            </ListGroup.Item>*/}
                                {/*                                        </Col>*/}
                                {/*                                        <Col md={3}>*/}
                                {/*                                            <ListGroup.Item>*/}
                                {/*                                                <Form.Group controlID="pms_Freedom">*/}
                                {/*                                                    <Form.Check*/}
                                {/*                                                        id="pms_Freedom"*/}
                                {/*                                                        name="pms"*/}
                                {/*                                                        type="checkbox"*/}
                                {/*                                                        label="Freedom"*/}
                                {/*                                                        value="freedom"*/}
                                {/*                                                        checked={personInput.pms &&*/}
                                {/*                                                        personInput.pms.indexOf("freedom") != -1}*/}
                                {/*                                                        onChange={handlePersonChange}*/}
                                {/*                                                    />*/}
                                {/*                                                </Form.Group>*/}
                                {/*                                            </ListGroup.Item>*/}
                                {/*                                        </Col>*/}
                                {/*                                        <Col md={3}>*/}
                                {/*                                            <ListGroup.Item>*/}
                                {/*                                                <Form.Group controlID="pms_RoboVet">*/}
                                {/*                                                    <Form.Check*/}
                                {/*                                                        id="pms_RoboVet"*/}
                                {/*                                                        name="pms"*/}
                                {/*                                                        type="checkbox"*/}
                                {/*                                                        label="RoboVet"*/}
                                {/*                                                        value="roboVet"*/}
                                {/*                                                        checked={personInput.pms &&*/}
                                {/*                                                        personInput.pms.indexOf("roboVet") != -1}*/}
                                {/*                                                        onChange={handlePersonChange}*/}
                                {/*                                                    />*/}
                                {/*                                                </Form.Group>*/}
                                {/*                                            </ListGroup.Item>*/}
                                {/*                                        </Col>*/}
                                {/*                                    </Row>*/}
                                {/*                                    <Row className="gx-0">*/}
                                {/*                                        <Col md={3}>*/}
                                {/*                                            <ListGroup.Item>*/}
                                {/*                                                <Form.Group controlID="pms_RxWorks">*/}
                                {/*                                                    <Form.Check*/}
                                {/*                                                        id="pms_RxWorks"*/}
                                {/*                                                        name="pms"*/}
                                {/*                                                        type="checkbox"*/}
                                {/*                                                        label="RxWorks"*/}
                                {/*                                                        value="rxWorks"*/}
                                {/*                                                        checked={personInput.pms &&*/}
                                {/*                                                        personInput.pms.indexOf("rxWorks") != -1}*/}
                                {/*                                                        onChange={handlePersonChange}*/}
                                {/*                                                    />*/}
                                {/*                                                </Form.Group>*/}
                                {/*                                            </ListGroup.Item>*/}
                                {/*                                        </Col>*/}
                                {/*                                        <Col md={3}>*/}
                                {/*                                            <ListGroup.Item>*/}
                                {/*                                                <Form.Group controlID="pms_Animana">*/}
                                {/*                                                    <Form.Check*/}
                                {/*                                                        id="pms_Spectrum_AT_Veterinary_Systems"*/}
                                {/*                                                        name="pms"*/}
                                {/*                                                        type="checkbox"*/}
                                {/*                                                        label="Spectrum (AT Veterinary Systems)"*/}
                                {/*                                                        value="spectrum"*/}
                                {/*                                                        checked={personInput.pms &&*/}
                                {/*                                                        personInput.pms.indexOf("spectrum") != -1}*/}
                                {/*                                                        onChange={handlePersonChange}*/}
                                {/*                                                    />*/}
                                {/*                                                </Form.Group>*/}
                                {/*                                            </ListGroup.Item>*/}
                                {/*                                        </Col>*/}
                                {/*                                        <Col md={3}>*/}
                                {/*                                            <ListGroup.Item>*/}
                                {/*                                                <Form.Group controlID="pms_Teleos">*/}
                                {/*                                                    <Form.Check*/}
                                {/*                                                        id="pms_Teleos"*/}
                                {/*                                                        name="pms"*/}
                                {/*                                                        type="checkbox"*/}
                                {/*                                                        label="Teleos"*/}
                                {/*                                                        value="teleos"*/}
                                {/*                                                        checked={personInput.pms &&*/}
                                {/*                                                        personInput.pms.indexOf("teleos") != -1}*/}
                                {/*                                                        onChange={handlePersonChange}*/}
                                {/*                                                    />*/}
                                {/*                                                </Form.Group>*/}
                                {/*                                            </ListGroup.Item>*/}
                                {/*                                        </Col>*/}
                                {/*                                        <Col md={3}>*/}
                                {/*                                            <ListGroup.Item>*/}
                                {/*                                                <Form.Group controlID="pms_VetSpace">*/}
                                {/*                                                    <Form.Check*/}
                                {/*                                                        id="pms_VetSpace"*/}
                                {/*                                                        name="pms"*/}
                                {/*                                                        type="checkbox"*/}
                                {/*                                                        label="VetSpace"*/}
                                {/*                                                        value="vetspace"*/}
                                {/*                                                        checked={personInput.pms &&*/}
                                {/*                                                        personInput.pms.indexOf("vetspace") != -1}*/}
                                {/*                                                        onChange={handlePersonChange}*/}
                                {/*                                                    />*/}
                                {/*                                                </Form.Group>*/}
                                {/*                                            </ListGroup.Item>*/}
                                {/*                                        </Col>*/}
                                {/*                                    </Row>*/}
                                {/*                                    <Row className="gx-0">*/}
                                {/*                                        <Col md={3}>*/}

                                {/*                                            <ListGroup.Item>*/}
                                {/*                                                <Form.Group controlID="pms_Animana">*/}
                                {/*                                                    <Form.Check*/}
                                {/*                                                        id="pms_Voyager_Jupiter"*/}
                                {/*                                                        name="pms"*/}
                                {/*                                                        type="checkbox"*/}
                                {/*                                                        label="Voyager (Jupiter)"*/}
                                {/*                                                        value="voyager"*/}
                                {/*                                                        checked={personInput.pms &&*/}
                                {/*                                                        personInput.pms.indexOf("voyager") != -1}*/}
                                {/*                                                        onChange={handlePersonChange}*/}
                                {/*                                                    />*/}
                                {/*                                                </Form.Group>*/}
                                {/*                                            </ListGroup.Item>*/}
                                {/*                                        </Col>*/}
                                {/*                                        <Col md={3}>*/}
                                {/*                                            <ListGroup.Item>*/}
                                {/*                                                <Form.Group controlID="pms_Zeus">*/}
                                {/*                                                    <Form.Check*/}
                                {/*                                                        id="pms_Zeus"*/}
                                {/*                                                        name="pms"*/}
                                {/*                                                        type="checkbox"*/}
                                {/*                                                        label="Zeus"*/}
                                {/*                                                        value="zeus"*/}
                                {/*                                                        checked={personInput.pms &&*/}
                                {/*                                                        personInput.pms.indexOf("zeus") != -1}*/}
                                {/*                                                        onChange={handlePersonChange}*/}
                                {/*                                                    />*/}
                                {/*                                                </Form.Group>*/}
                                {/*                                            </ListGroup.Item>*/}

                                {/*                                        </Col>*/}
                                {/*                                    </Row>*/}

                                {/*                                </Form>*/}
                                {/*                            </ListGroup>*/}
                                {/*                            <div className="d-flex justify-content-between align-items-center mt-3">*/}
                                {/*                                <p className="m-0">Please click the link below to download CV.</p>*/}
                                {/*                                <Button className="btn theme-btn-primary">Download CV</Button>*/}
                                {/*                            </div>*/}
                                {/*                        </Col>*/}
                                {/*                    </Row>*/}
                                {/*                </Form>*/}
                                {/*            </div>*/}
                                {/*        </Tab>*/}
                                {/*    </Tabs>*/}
                                {/*</Tab>*/}
                                <Tab
                                    eventKey="people-match"
                                    title="PEOPLE MATCH"
                                >
                                    <Tabs className="secondary-tabs row-px-2">
                                        <Tab
                                            eventKey="personal-details"
                                            title="Personal Details"
                                            className="row-px-2"
                                        >
                                            <Row className="align-items">
                                                <Col lg={4}>
                                                    <Form.Label>
                                                        Are you a:
                                                    </Form.Label>
                                                    <div className="">
                                                        <Form.Group controlID="veterinary_surgeon">
                                                            <Form.Check
                                                                inline
                                                                id="veterinary_surgeon"
                                                                name="pm_job_type"
                                                                type="radio"
                                                                label="Veterinary Surgeon"
                                                                value={
                                                                    "Veterinary Surgeon"
                                                                }
                                                                checked={
                                                                    personInput.pm_job_type ==
                                                                    "Veterinary Surgeon"
                                                                }
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Form.Group>
                                                        <Form.Group controlID="registered_veterinary_nurse">
                                                            <Form.Check
                                                                inline
                                                                id="registered_veterinary_nurse"
                                                                name="pm_job_type"
                                                                type="radio"
                                                                label="Registered Veterinary Nurse"
                                                                value={
                                                                    "Registered Veterinary Nurse"
                                                                }
                                                                checked={
                                                                    personInput.pm_job_type ==
                                                                    "Registered Veterinary Nurse"
                                                                }
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Form.Group>
                                                        <Form.Group controlID="final_year_veterinary_student">
                                                            <Form.Check
                                                                inline
                                                                id="final_year_veterinary_student"
                                                                name="pm_job_type"
                                                                type="radio"
                                                                label="Final Year Veterinary Student"
                                                                value={
                                                                    "Final Year Veterinary Student"
                                                                }
                                                                checked={
                                                                    personInput.pm_job_type ==
                                                                    "Final Year Veterinary Student"
                                                                }
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Form.Group>
                                                        <Form.Group controlID="student_registered_veterinary_nurse">
                                                            <Form.Check
                                                                inline
                                                                id="student_registered_veterinary_nurse"
                                                                name="pm_job_type"
                                                                type="radio"
                                                                label="Student Veterinary Nurse"
                                                                value={
                                                                    "Student Veterinary Nurse"
                                                                }
                                                                checked={
                                                                    personInput.pm_job_type ==
                                                                    "Student Veterinary Nurse"
                                                                }
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="preferred-next-role mt-2">
                                                        <Form.Label className="mb-1 w-100">
                                                            Type of preferred
                                                            next role?
                                                        </Form.Label>
                                                        <Form.Group controlID="small-animal">
                                                            <Form.Check
                                                                id="small-animal"
                                                                name="pm_next_role"
                                                                type="checkbox"
                                                                checked={personInput.pm_next_role.includes(
                                                                    "Small animal"
                                                                )}
                                                                label="Small animal"
                                                                value="Small animal"
                                                                className="form-label"
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Form.Group>
                                                        <Form.Group controlID="equine">
                                                            <Form.Check
                                                                id="equine"
                                                                name="pm_next_role"
                                                                type="checkbox"
                                                                checked={personInput.pm_next_role.includes(
                                                                    "Equine"
                                                                )}
                                                                label="Equine"
                                                                value="Equine"
                                                                className="form-label"
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Form.Group>
                                                        <Form.Group controlID="large-animal">
                                                            <Form.Check
                                                                id="large-animal"
                                                                name="pm_next_role"
                                                                type="checkbox"
                                                                checked={personInput.pm_next_role.includes(
                                                                    "Large animal"
                                                                )}
                                                                label="Large animal"
                                                                value="Large animal"
                                                                className="form-label"
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Form.Group>
                                                        <Form.Group controlID="pm_exotics">
                                                            <Form.Check
                                                                id="pm_exotics"
                                                                name="pm_next_role"
                                                                type="checkbox"
                                                                checked={personInput.pm_next_role.includes(
                                                                    "Exotics"
                                                                )}
                                                                label="Exotics"
                                                                value="Exotics"
                                                                className="form-label"
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Form.Group>
                                                        <Form.Group controlID="mixed-practice">
                                                            <Form.Check
                                                                id="mixed-practice"
                                                                name="pm_next_role"
                                                                type="checkbox"
                                                                checked={personInput.pm_next_role.includes(
                                                                    "Mixed practice"
                                                                )}
                                                                label="Mixed practice"
                                                                value="Mixed practice"
                                                                className="form-label"
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Form.Group>
                                                        <Form.Group controlID="emergency-critical-care">
                                                            <Form.Check
                                                                id="emergency-critical-care"
                                                                name="pm_next_role"
                                                                type="checkbox"
                                                                checked={personInput.pm_next_role.includes(
                                                                    "Emergency and critical care night vet"
                                                                )}
                                                                label="ECC/night vet"
                                                                value="Emergency and critical care night vet"
                                                                className="form-label"
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Form.Group>
                                                        <Form.Group controlID="Commercial">
                                                            <Form.Check
                                                                id="Commercial"
                                                                name="pm_next_role"
                                                                type="checkbox"
                                                                checked={personInput.pm_next_role.includes(
                                                                    "Commercial"
                                                                )}
                                                                label="Commercial"
                                                                value="Commercial"
                                                                className="form-label"
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Form.Group>
                                                        <Form.Group controlID="Academia">
                                                            <Form.Check
                                                                id="Academia"
                                                                name="pm_next_role"
                                                                type="checkbox"
                                                                checked={personInput.pm_next_role.includes(
                                                                    "Academia"
                                                                )}
                                                                label="Academia"
                                                                value="Academia"
                                                                className="form-label"
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <Form.Label>
                                                        PM soft opt-in
                                                    </Form.Label>
                                                    <Form.Control
                                                        disabled={true}
                                                        name="pm_soft_opt_in"
                                                        as="select"
                                                        className="form-select mb-0"
                                                        value={
                                                            personInput.pm_soft_opt_in
                                                        }
                                                        onChange={
                                                            handlePersonChange
                                                        }
                                                    >
                                                        <option value="Yes">
                                                            Yes
                                                        </option>
                                                        <option value="No">
                                                            No
                                                        </option>
                                                    </Form.Control>
                                                    <Form.Label>
                                                        PM soft opt-in date
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="pm_soft_opt_in_date"
                                                        placeholderText="dd/mm/yyyy"
                                                        type="date"
                                                        max="2999-12-31"
                                                        value={
                                                            personInput.pm_soft_opt_in_date
                                                        }
                                                        onChange={
                                                            handlePersonChange
                                                        }
                                                        disabled
                                                    ></Form.Control>
                                                    <Form.Label>
                                                        Name
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="pm_name"
                                                        type="text"
                                                        value={
                                                            personInput.pm_name
                                                        }
                                                        onChange={
                                                            handlePersonChange
                                                        }
                                                    />
                                                    <Form.Label>
                                                        Email
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="pm_email"
                                                        type="text"
                                                        value={
                                                            personInput.pm_email
                                                        }
                                                        onChange={
                                                            handlePersonChange
                                                        }
                                                    />
                                                    {personInput.pm_job_type ==
                                                        "Registered Veterinary Nurse" ||
                                                    personInput.pm_job_type ==
                                                        "Student Veterinary Nurse" ? (
                                                        <div>
                                                            <Form.Label>
                                                                Year registered
                                                                with the RCVS?
                                                            </Form.Label>
                                                            <Form.Control
                                                                name="pm_year_rcvs"
                                                                type="text"
                                                                value={
                                                                    personInput.pm_year_rcvs
                                                                }
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {personInput.pm_job_type ==
                                                        "Veterinary Surgeon" ||
                                                    personInput.pm_job_type ==
                                                        "Final Year Veterinary Student" ? (
                                                        <div>
                                                            <Form.Label>
                                                                University
                                                            </Form.Label>
                                                            <Form.Control
                                                                name="pm_university"
                                                                type="text"
                                                                value={
                                                                    personInput.pm_university
                                                                }
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                            <Form.Label>
                                                                Year of
                                                                qualification
                                                            </Form.Label>
                                                            <Form.Control
                                                                name="pm_year_qualification"
                                                                as="select"
                                                                className="form-select"
                                                                value={
                                                                    personInput.pm_year_qualification
                                                                }
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            >
                                                                <option value="">
                                                                    Select year
                                                                </option>
                                                                {Array.from(
                                                                    {
                                                                        length: 91,
                                                                    },
                                                                    (_, i) =>
                                                                        2030 - i
                                                                ).map(
                                                                    (
                                                                        curr,
                                                                        ind
                                                                    ) => (
                                                                        <option
                                                                            key={
                                                                                ind
                                                                            }
                                                                            value={
                                                                                curr
                                                                            }
                                                                        >
                                                                            {
                                                                                curr
                                                                            }
                                                                        </option>
                                                                    )
                                                                )}
                                                            </Form.Control>
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </Col>
                                                <Col lg={4}>
                                                    <Form.Label>
                                                        Phone
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="pm_phone"
                                                        type="text"
                                                        value={
                                                            personInput.pm_phone
                                                        }
                                                        onChange={
                                                            handlePersonChange
                                                        }
                                                    />
                                                    <Form.Label>
                                                        PM Interested
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="pm_interested"
                                                        as="select"
                                                        className="form-select"
                                                        value={
                                                            personInput.pm_interested
                                                        }
                                                        onChange={
                                                            handlePersonChange
                                                        }
                                                    >
                                                        <option value="Yes">
                                                            Yes
                                                        </option>
                                                        <option value="No">
                                                            No
                                                        </option>
                                                    </Form.Control>
                                                    <Form.Label>
                                                        PM Interested Date
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="pm_interest_date"
                                                        placeholderText="dd/mm/yyyy"
                                                        type="date"
                                                        max="2999-12-31"
                                                        value={
                                                            personInput.pm_interest_date
                                                        }
                                                        onChange={
                                                            handlePersonChange
                                                        }
                                                        disabled
                                                    ></Form.Control>
                                                    <Form.Label>
                                                        Tell us more about your
                                                        ideal role
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="pm_ideal_role"
                                                        as="textarea"
                                                        className="form-control normal-text-area"
                                                        value={
                                                            personInput.pm_ideal_role
                                                        }
                                                        onChange={
                                                            handlePersonChange
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        </Tab>
                                        <Tab
                                            eventKey="location-preferences"
                                            title="Location Preferences"
                                            className="row-px-2"
                                        >
                                            <Row className="align-items">
                                                <Col lg={4}>
                                                    <Form.Label>
                                                        Do you have the right to
                                                        work in the UK?
                                                    </Form.Label>
                                                    <div className="d-flex">
                                                        <Form.Group controlID="pm_work_in_uk_yes">
                                                            <Form.Check
                                                                inline
                                                                id="pm_work_in_uk_yes"
                                                                name="pm_work_in_uk"
                                                                type="radio"
                                                                label="Yes"
                                                                value={"Yes"}
                                                                checked={
                                                                    personInput.pm_work_in_uk ==
                                                                    "Yes"
                                                                }
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Form.Group>
                                                        <Form.Group controlID="pm_work_in_uk_no">
                                                            <Form.Check
                                                                inline
                                                                id="pm_work_in_uk_no"
                                                                name="pm_work_in_uk"
                                                                type="radio"
                                                                label="No"
                                                                value={"No"}
                                                                checked={
                                                                    personInput.pm_work_in_uk ==
                                                                    "No"
                                                                }
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <Form.Label>
                                                        Are you willing to
                                                        relocate?
                                                    </Form.Label>
                                                    <div className="d-flex">
                                                        <Form.Group controlID="pm_relocate_yes">
                                                            <Form.Check
                                                                id="pm_relocate_yes"
                                                                name="pm_relocate"
                                                                type="radio"
                                                                label="Yes"
                                                                value={"Yes"}
                                                                checked={
                                                                    personInput.pm_relocate ==
                                                                    "Yes"
                                                                }
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Form.Group>
                                                        <Form.Group controlID="pm_relocate_no">
                                                            <Form.Check
                                                                inline
                                                                id="pm_relocate_no"
                                                                name="pm_relocate"
                                                                type="radio"
                                                                label="No"
                                                                value={"No"}
                                                                checked={
                                                                    personInput.pm_relocate ==
                                                                    "No"
                                                                }
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <Form.Label>
                                                        How far are you willing
                                                        to travel in miles?
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="pm_distance"
                                                        type="text"
                                                        value={
                                                            personInput.pm_distance
                                                        }
                                                        onChange={
                                                            handlePersonChange
                                                        }
                                                    />
                                                    {personInput.pm_relocate ==
                                                        "Yes" && (
                                                        <div>
                                                            <Form.Label>
                                                                Select location
                                                            </Form.Label>
                                                            <Select
                                                                isMulti
                                                                className="basic-single"
                                                                classNamePrefix="select"
                                                                name="pm_areas"
                                                                styles={
                                                                    selectStyle
                                                                }
                                                                options={areas}
                                                                value={
                                                                    selectedPmLocation
                                                                }
                                                                onChange={
                                                                    setPmLocation
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                    <Form.Label>
                                                        Would you consider
                                                        working outside the UK?
                                                    </Form.Label>
                                                    <div className="d-flex">
                                                        <Form.Group controlID="pm_working_outside_yes">
                                                            <Form.Check
                                                                id="pm_working_outside_yes"
                                                                name="pm_working_outside"
                                                                type="radio"
                                                                label="Yes"
                                                                value={"Yes"}
                                                                checked={
                                                                    personInput.pm_working_outside ==
                                                                    "Yes"
                                                                }
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Form.Group>
                                                        <Form.Group controlID="pm_working_outside_no">
                                                            <Form.Check
                                                                inline
                                                                id="pm_working_outside_no"
                                                                name="pm_working_outside"
                                                                type="radio"
                                                                label="No"
                                                                value={"No"}
                                                                checked={
                                                                    personInput.pm_working_outside ==
                                                                    "No"
                                                                }
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    {personInput.pm_working_outside ==
                                                        "Yes" && (
                                                        <div>
                                                            <Form.Label>
                                                                Select location
                                                            </Form.Label>
                                                            <Select
                                                                isMulti
                                                                className="basic-single"
                                                                classNamePrefix="select"
                                                                name="pm_outside_areas"
                                                                styles={
                                                                    selectStyle
                                                                }
                                                                options={areas2}
                                                                value={
                                                                    selectedPmOutside
                                                                }
                                                                onChange={
                                                                    setPmOutside
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                </Col>
                                                <Col lg={4}>
                                                    <Form.Label>
                                                        Address line 1
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="pm_address_1"
                                                        type="text"
                                                        value={
                                                            personInput.pm_address_1
                                                        }
                                                        onChange={
                                                            handlePersonChange
                                                        }
                                                    />
                                                    <Form.Label>
                                                        Address line 2
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="pm_address_2"
                                                        type="text"
                                                        value={
                                                            personInput.pm_address_2
                                                        }
                                                        onChange={
                                                            handlePersonChange
                                                        }
                                                    />
                                                    <Form.Label>
                                                        Address line 3
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="pm_address_3"
                                                        type="text"
                                                        value={
                                                            personInput.pm_address_3
                                                        }
                                                        onChange={
                                                            handlePersonChange
                                                        }
                                                    />
                                                    <Form.Label>
                                                        Town
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="pm_town"
                                                        type="text"
                                                        value={
                                                            personInput.pm_town
                                                        }
                                                        onChange={
                                                            handlePersonChange
                                                        }
                                                    />
                                                </Col>
                                                <Col lg={4}>
                                                    <Form.Label>
                                                        County
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="pm_county"
                                                        type="text"
                                                        value={
                                                            personInput.pm_county
                                                        }
                                                        onChange={
                                                            handlePersonChange
                                                        }
                                                    />

                                                    <Form.Label>
                                                        Postcode
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="pm_postcode"
                                                        type="text"
                                                        value={
                                                            personInput.pm_postcode
                                                        }
                                                        onChange={
                                                            handlePersonChange
                                                        }
                                                    />

                                                    <Form.Label>
                                                        Country
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="pm_country"
                                                        type="text"
                                                        value={
                                                            personInput.pm_country
                                                        }
                                                        onChange={
                                                            handlePersonChange
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        </Tab>
                                        <Tab
                                            eventKey="job-preferences"
                                            title="Job Preferences"
                                            className="row-px-2"
                                        >
                                            <Row className="align-items">
                                                <Col
                                                    lg={4}
                                                    className="salary-range"
                                                >
                                                    <Form.Label>
                                                        What is your desired
                                                        salary range in your
                                                        next role?
                                                    </Form.Label>
                                                    <Form.Group
                                                        className="w-100"
                                                        controlID="up to 10000"
                                                    >
                                                        <Form.Check
                                                            inline
                                                            id="up to 10000"
                                                            name="pm_salary_band"
                                                            type="checkbox"
                                                            label="Up to £10,000"
                                                            value="up to 10000"
                                                            onChange={
                                                                handlePersonChange
                                                            }
                                                            checked={personInput.pm_salary_band.includes(
                                                                "up to 10000"
                                                            )}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group controlID="10000 - 14999">
                                                        <Form.Check
                                                            inline
                                                            id="10000 - 14999"
                                                            name="pm_salary_band"
                                                            type="checkbox"
                                                            label="£10,000 - £14,999"
                                                            value="10000 - 14999"
                                                            onChange={
                                                                handlePersonChange
                                                            }
                                                            checked={personInput.pm_salary_band.includes(
                                                                "10000 - 14999"
                                                            )}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group controlID="50000 - 59999">
                                                        <Form.Check
                                                            inline
                                                            id="50000 - 59999"
                                                            name="pm_salary_band"
                                                            type="checkbox"
                                                            label="£50,000 - £59,999"
                                                            value="50000 - 59999"
                                                            onChange={
                                                                handlePersonChange
                                                            }
                                                            checked={personInput.pm_salary_band.includes(
                                                                "50000 - 59999"
                                                            )}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group controlID="15000 - 19999">
                                                        <Form.Check
                                                            inline
                                                            id="15000 - 19999"
                                                            name="pm_salary_band"
                                                            type="checkbox"
                                                            label="£15,000 - £19,999"
                                                            value="15000 - 19999"
                                                            onChange={
                                                                handlePersonChange
                                                            }
                                                            checked={personInput.pm_salary_band.includes(
                                                                "15000 - 19999"
                                                            )}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group controlID="60000 - 69999">
                                                        <Form.Check
                                                            inline
                                                            id="60000 - 69999"
                                                            name="pm_salary_band"
                                                            type="checkbox"
                                                            label="£60,000 - £69,999"
                                                            value="60000 - 69999"
                                                            onChange={
                                                                handlePersonChange
                                                            }
                                                            checked={personInput.pm_salary_band.includes(
                                                                "60000 - 69999"
                                                            )}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group controlID="20000 - 24999">
                                                        <Form.Check
                                                            inline
                                                            id="20000 - 24999"
                                                            name="pm_salary_band"
                                                            type="checkbox"
                                                            label="£20,000 - £24,999"
                                                            value="20000 - 24999"
                                                            onChange={
                                                                handlePersonChange
                                                            }
                                                            checked={personInput.pm_salary_band.includes(
                                                                "20000 - 24999"
                                                            )}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group controlID="70000 - 79999">
                                                        <Form.Check
                                                            inline
                                                            id="70000 - 79999"
                                                            name="pm_salary_band"
                                                            type="checkbox"
                                                            label="£70,000 - £79,999"
                                                            value="70000 - 79999"
                                                            onChange={
                                                                handlePersonChange
                                                            }
                                                            checked={personInput.pm_salary_band.includes(
                                                                "70000 - 79999"
                                                            )}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group controlID="25000 - 29999">
                                                        <Form.Check
                                                            inline
                                                            id="25000 - 29999"
                                                            name="pm_salary_band"
                                                            type="checkbox"
                                                            label="£25,000 - £29,999"
                                                            value="25000 - 29999"
                                                            onChange={
                                                                handlePersonChange
                                                            }
                                                            checked={personInput.pm_salary_band.includes(
                                                                "25000 - 29999"
                                                            )}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group controlID="80000 - 89999">
                                                        <Form.Check
                                                            inline
                                                            id="80000 - 89999"
                                                            name="pm_salary_band"
                                                            type="checkbox"
                                                            label="£80,000 - £89,999"
                                                            value="80000 - 89999"
                                                            onChange={
                                                                handlePersonChange
                                                            }
                                                            checked={personInput.pm_salary_band.includes(
                                                                "80000 - 89999"
                                                            )}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group controlID="30000 - 39999">
                                                        <Form.Check
                                                            inline
                                                            id="30000 - 39999"
                                                            name="pm_salary_band"
                                                            type="checkbox"
                                                            label="£30,000 - £39,999"
                                                            value="30000 - 39999"
                                                            onChange={
                                                                handlePersonChange
                                                            }
                                                            checked={personInput.pm_salary_band.includes(
                                                                "30000 - 39999"
                                                            )}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group controlID="90000 - 99999">
                                                        <Form.Check
                                                            inline
                                                            id="90000 - 99999"
                                                            name="pm_salary_band"
                                                            type="checkbox"
                                                            label="£90,000 - £99,999"
                                                            value="90000 - 99999"
                                                            onChange={
                                                                handlePersonChange
                                                            }
                                                            checked={personInput.pm_salary_band.includes(
                                                                "90000 - 99999"
                                                            )}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group controlID="40000 - 49999">
                                                        <Form.Check
                                                            inline
                                                            id="40000 - 49999"
                                                            name="pm_salary_band"
                                                            type="checkbox"
                                                            label="£40,000 - £49,999"
                                                            value="40000 - 49999"
                                                            onChange={
                                                                handlePersonChange
                                                            }
                                                            checked={personInput.pm_salary_band.includes(
                                                                "40000 - 49999"
                                                            )}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group controlID="100000 or more">
                                                        <Form.Check
                                                            inline
                                                            id="100000 or more"
                                                            name="pm_salary_band"
                                                            type="checkbox"
                                                            label="£100,000 or more"
                                                            value="100000 or more"
                                                            onChange={
                                                                handlePersonChange
                                                            }
                                                            checked={personInput.pm_salary_band.includes(
                                                                "100000 or more"
                                                            )}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={4}>
                                                    <Form.Label>
                                                        Are you looking for
                                                        full-time or part-time
                                                        work?
                                                    </Form.Label>
                                                    <div className="d-flex">
                                                        <Form.Group controlID="Part-time">
                                                            <Form.Check
                                                                inline
                                                                id="Part-time"
                                                                name="pm_looking_for_work"
                                                                type="checkbox"
                                                                label="Part-time"
                                                                value="Part-time"
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                                checked={personInput.pm_looking_for_work.includes(
                                                                    "Part-time"
                                                                )}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group controlID="Full-time">
                                                            <Form.Check
                                                                inline
                                                                id="Full-time"
                                                                name="pm_looking_for_work"
                                                                type="checkbox"
                                                                label="Full-time"
                                                                value="Full-time"
                                                                onChange={
                                                                    handlePersonChange
                                                                }
                                                                checked={personInput.pm_looking_for_work.includes(
                                                                    "Full-time"
                                                                )}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <Form.Label>
                                                        What is important to you
                                                        in your next role?
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="pm_important_next_role"
                                                        type="text"
                                                        value={
                                                            personInput.pm_important_next_role
                                                        }
                                                        onChange={
                                                            handlePersonChange
                                                        }
                                                    />
                                                    <Form.Label>
                                                        What are you clinical
                                                        interests?
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="pm_clinical_interest"
                                                        type="text"
                                                        value={
                                                            personInput.pm_clinical_interest
                                                        }
                                                        onChange={
                                                            handlePersonChange
                                                        }
                                                    />
                                                </Col>
                                                <Col lg={4}>
                                                    <Form.Label>
                                                        What is your ideal rota?
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="pm_ideal_rota"
                                                        type="text"
                                                        value={
                                                            personInput.pm_ideal_rota
                                                        }
                                                        onChange={
                                                            handlePersonChange
                                                        }
                                                    />
                                                    <Form.Label>
                                                        What skills would you
                                                        like to develop in your
                                                        next role?
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="pm_skill_develop"
                                                        type="text"
                                                        value={
                                                            personInput.pm_skill_develop
                                                        }
                                                        onChange={
                                                            handlePersonChange
                                                        }
                                                    />
                                                    {personInput.pm_cv.trim() !=
                                                    "" ? (
                                                        <a
                                                            target="_blank"
                                                            href={
                                                                personInput.pm_cv
                                                            }
                                                            className="mt-4 d-flex"
                                                        >
                                                            View CV
                                                        </a>
                                                    ) : (
                                                        <p>No CV attached</p>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Tab>
                                        {personInput.pm_profile == "Yes" && (
                                            <Tab
                                                eventKey="remove-pm"
                                                title="Delete PM data"
                                                className="row-px-2"
                                            >
                                                <Button
                                                    className="btn btn-danger btn-sm"
                                                    id="button-addon2"
                                                    onClick={() => {
                                                        setDeletePm();
                                                    }}
                                                >
                                                    Delete PM data
                                                </Button>
                                            </Tab>
                                        )}
                                    </Tabs>
                                </Tab>
                                <Tab eventKey="cpd" title="CPD PACKAGES">
                                    <Row className="row-px-2">
                                        <Col lg={12} className="p-0">
                                            <Accordion defaultActiveKey="0">
                                                <Card>
                                                    <Accordion.Toggle
                                                        as={"div"}
                                                        eventKey="0"
                                                    >
                                                        <Card.Header className="d-flex justify-content-between">
                                                            Companion Animal{" "}
                                                            <span className="grey-text">
                                                                {
                                                                    personInput.companion_animal_subs
                                                                }
                                                            </span>
                                                        </Card.Header>
                                                    </Accordion.Toggle>

                                                    <Accordion.Collapse eventKey="0">
                                                        <Card.Body>
                                                            <div className="custom-table">
                                                                <Table className="table-curved">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>
                                                                                Companion
                                                                                Animal
                                                                            </th>
                                                                            <th>
                                                                                1st
                                                                                Viewed
                                                                            </th>
                                                                            <th>
                                                                                Last
                                                                                Viewed
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                Parasitology
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.para_date1
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.para_date2
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Pain
                                                                                Management
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.pman_date1
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.pman_date2
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Dermatology
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.derma_date1
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.derma_date2
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Oncology
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.onco_date1
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.onco_date2
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Senior
                                                                                Pets
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.spets_date1
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.spets_date2
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Gastrointestine
                                                                                Disorders
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.gastro_date1
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.gastro_date2
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Cardiology
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.cardio_date1
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.cardio_date2
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Nutrition
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.nutrition_date1
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.nutrition_date2
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Emergency
                                                                                and
                                                                                critical
                                                                                care
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.ecc_date1
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.ecc_date2
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Neurology
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.neurology_date1
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.neurology_date2
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Urology
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.urology_date1
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.urology_date2
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                FIP
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.fip_date1
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.fip_date2
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Alabama
                                                                                rot
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.alabama_rot_date1
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.alabama_rot_date2
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Endocrinology
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.endocrinology_date1
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.endocrinology_date2
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Anaesthesia
                                                                                and
                                                                                analgesia
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.anaesthesia_date1
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.anaesthesia_date2
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Ophthalmology
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.ophthalmology_date1
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.ophthalmology_date2
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Oral
                                                                                care
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.oral_care_date1
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.oral_care_date2
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Wound
                                                                                management
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.wound_management_date1
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.wound_management_date2
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                                <Card>
                                                    <Accordion.Toggle
                                                        as={"div"}
                                                        eventKey="1"
                                                    >
                                                        <Card.Header className="d-flex justify-content-between">
                                                            Exotics{" "}
                                                            <span className="grey-text">
                                                                {
                                                                    personInput.exotics_subs
                                                                }
                                                            </span>
                                                        </Card.Header>
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body>
                                                            <div className="custom-table">
                                                                <Table className="table-curved">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>
                                                                                Exotics
                                                                            </th>
                                                                            <th>
                                                                                1st
                                                                                Viewed
                                                                            </th>
                                                                            <th>
                                                                                Last
                                                                                Viewed
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                Rabbits
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.rabbits_date1
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.rabbits_date2
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Rodents
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.rodents_date1
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.rodents_date2
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Guinea
                                                                                pigs
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.guinea_p_date1
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.guinea_p_date2
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Reptiles
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.reptiles_date1
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.reptiles_date2
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Birds
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.birds_date1
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.birds_date2
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                                <Card>
                                                    <Accordion.Toggle
                                                        as={"div"}
                                                        eventKey="2"
                                                    >
                                                        <Card.Header className="d-flex justify-content-between">
                                                            Equine{" "}
                                                            <span className="grey-text">
                                                                {
                                                                    personInput.equine_subs
                                                                }
                                                            </span>
                                                        </Card.Header>
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="2">
                                                        <Card.Body>
                                                            <div className="custom-table">
                                                                <Table className="table-curved">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>
                                                                                Equine
                                                                            </th>
                                                                            <th>
                                                                                1st
                                                                                Viewed
                                                                            </th>
                                                                            <th>
                                                                                Last
                                                                                Viewed
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                Lameness
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.lameness_date1
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.lameness_date2
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Respiratory
                                                                                diseases
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.equine_respiratory_date1
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.equine_respiratory_date2
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Preventive
                                                                                Health
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.phealth_date1
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.phealth_date2
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Obesity
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.obesity_date1
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.obesity_date2
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Imaging
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.imaging_date1
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.imaging_date2
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                                <Card>
                                                    <Accordion.Toggle
                                                        as={"div"}
                                                        eventKey="3"
                                                    >
                                                        <Card.Header className="d-flex justify-content-between">
                                                            Livestock{" "}
                                                            <span className="grey-text">
                                                                {
                                                                    personInput.livestock_subs
                                                                }
                                                            </span>
                                                        </Card.Header>
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="3">
                                                        <Card.Body>
                                                            <div className="custom-table">
                                                                <Table className="table-curved">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>
                                                                                Livestock
                                                                            </th>
                                                                            <th>
                                                                                1st
                                                                                Viewed
                                                                            </th>
                                                                            <th>
                                                                                Last
                                                                                Viewed
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                Calf
                                                                                health
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.calf_health_date1
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.calf_health_date2
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Mastitis
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.mastitis_date1
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.mastitis_date2
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Respiratory
                                                                                diseases
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.livestock_respiratory_date1
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.livestock_respiratory_date1
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Parasites
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.parasites_date1
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.parasites_date2
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Reproduction
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.reproduction_date1
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.reproduction_date2
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Bovine
                                                                                viral
                                                                                diarrhoea
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.bvd_date1
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.bvd_date2
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Lamb
                                                                                health
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.lamb_date1
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.lamb_date2
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                                <Card>
                                                    <Accordion.Toggle
                                                        as={"div"}
                                                        eventKey="4"
                                                    >
                                                        <Card.Header className="d-flex justify-content-between">
                                                            Business{" "}
                                                            <span className="grey-text">
                                                                {
                                                                    personInput.business_subs
                                                                }
                                                            </span>
                                                        </Card.Header>
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="4">
                                                        <Card.Body>
                                                            <div className="custom-table">
                                                                <Table className="table-curved">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>
                                                                                Business
                                                                            </th>
                                                                            <th>
                                                                                1st
                                                                                Viewed
                                                                            </th>
                                                                            <th>
                                                                                Last
                                                                                Viewed
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                Finance
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.finance_date1
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.finance_date2
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Client
                                                                                care
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.client_care_date1
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.client_care_date2
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Technology
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.technology_date1
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.technology_date2
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Training
                                                                                and
                                                                                development
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.train_and_develop_date1
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.train_and_develop_date2
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                                <Card>
                                                    <Accordion.Toggle
                                                        as={"div"}
                                                        eventKey="5"
                                                    >
                                                        <Card.Header className="d-flex justify-content-between">
                                                            Well-being{" "}
                                                            <span className="grey-text">
                                                                {
                                                                    personInput.well_being_subs
                                                                }
                                                            </span>
                                                        </Card.Header>
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="5">
                                                        <Card.Body>
                                                            <div className="custom-table">
                                                                <Table className="table-curved">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>
                                                                                Well-being
                                                                            </th>
                                                                            <th>
                                                                                1st
                                                                                Viewed
                                                                            </th>
                                                                            <th>
                                                                                Last
                                                                                Viewed
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                Well-being
                                                                                for
                                                                                your
                                                                                team
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.wb_teams_date1
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.wb_teams_date2
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                well-being
                                                                                for
                                                                                you
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.wb_you_date1
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    disabled
                                                                                    value={
                                                                                        personInput.wb_you_date2
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab eventKey="events" title="EVENTS">
                                    <Row className="row-px-2 mt-5 gx-3">
                                        <Form>
                                            <Row className="align-items">
                                                <Col lg={4}>
                                                    <Form.Label>
                                                        Congresses
                                                    </Form.Label>
                                                    <Select
                                                        menuPlacement="top"
                                                        isMulti
                                                        closeMenuOnSelect={
                                                            false
                                                        }
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        name="v_congresses"
                                                        styles={selectStyle}
                                                        menuPortalTarget={
                                                            document.body
                                                        }
                                                        options={
                                                            dropDownEventsCongresses
                                                        }
                                                        value={
                                                            selectedEventsCongresses
                                                        }
                                                        onChange={
                                                            setEventsCongresses
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Row>
                                    <Row className="row-px-2 mt-5 gx-3">
                                        <h5>VN Happy Hour</h5>
                                        <Form>
                                            <Row className="align-items">
                                                <Col lg={4}>
                                                    <Form.Label>
                                                        Registration Email
                                                        Address
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="v_registration_email"
                                                        type="text"
                                                        onChange={
                                                            handlePersonChange
                                                        }
                                                        value={
                                                            personInput.v_registration_email
                                                        }
                                                    />
                                                </Col>
                                                <Col lg={4}>
                                                    <Form.Label>
                                                        Registration
                                                    </Form.Label>
                                                    <Select
                                                        menuPlacement="top"
                                                        isMulti
                                                        closeMenuOnSelect={
                                                            false
                                                        }
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        name="v_registration"
                                                        menuPortalTarget={
                                                            document.body
                                                        }
                                                        styles={selectStyle}
                                                        options={
                                                            dropDownEventsRegistration
                                                        }
                                                        value={
                                                            selectedEventsRegistration
                                                        }
                                                        onChange={
                                                            setEventsRegistration
                                                        }
                                                    />
                                                </Col>
                                                <Col lg={4}>
                                                    <Form.Label>
                                                        Attended
                                                    </Form.Label>
                                                    <Select
                                                        menuPlacement="top"
                                                        isMulti
                                                        closeMenuOnSelect={
                                                            false
                                                        }
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        name="v_attended"
                                                        styles={selectStyle}
                                                        menuPortalTarget={
                                                            document.body
                                                        }
                                                        options={
                                                            dropDownEventsRegistration
                                                        }
                                                        value={
                                                            selectedEventsAttended
                                                        }
                                                        onChange={
                                                            setEventsAttended
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Row>
                                </Tab>
                                <Tab
                                    eventKey="notes-history"
                                    title="NOTES/HISTORY"
                                >
                                    <Row className="row-px-2 mt-5 gx-3">
                                        <Col lg={6}>
                                            <h5>Notes</h5>

                                            <Card>
                                                <Card.Body>
                                                    <div className="notes">
                                                        {/* <Button onClick={() => { handlePlaceholder('curr_date') }} className="btn theme-btn-outline-primary me-2 mb-2 btn btn-primary">{placeValue.curr_date}</Button> */}

                                                        <FormControl
                                                            as="textarea"
                                                            name="v_notes"
                                                            value={
                                                                personInput.v_notes
                                                            }
                                                            onChange={
                                                                handlePersonChange
                                                            }
                                                            className="form-control normal-text-area"
                                                        />
                                                        {/* <p>{formatString(personInput.v_notes)} <span></span></p> */}
                                                    </div>
                                                    <div className="add-note">
                                                        <button
                                                            className="btn theme-btn-primary"
                                                            onClick={
                                                                handleUpdateNotes
                                                            }
                                                        >
                                                            <Image
                                                                src={addIcon}
                                                            />
                                                            Update note
                                                        </button>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col lg={6} className="history-box">
                                            <h5>History</h5>
                                            <Card>
                                                <Card.Body className="p-0">
                                                    <PerfectScrollbar>
                                                        {personInput.history_data ==
                                                        [] ? (
                                                            <div className="history">
                                                                <p className="history-date">
                                                                    No data
                                                                </p>
                                                            </div>
                                                        ) : (
                                                            Object.values(
                                                                personInput.history
                                                            ).map(
                                                                (curr, ind) => {
                                                                    return (
                                                                        <div
                                                                            key={
                                                                                ind
                                                                            }
                                                                            className="history"
                                                                        >
                                                                            {curr.d !=
                                                                            undefined ? (
                                                                                <p className="history-date">
                                                                                    {dayjs(
                                                                                        curr.d
                                                                                    ).format(
                                                                                        "DD/MM/YYYY HH:mm:ss"
                                                                                    )}
                                                                                </p>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                            <p className="history-title">
                                                                                {
                                                                                    curr.u
                                                                                }
                                                                            </p>
                                                                            <p className="history-content">
                                                                                {
                                                                                    curr.f
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    );
                                                                }
                                                            )
                                                        )}
                                                    </PerfectScrollbar>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab
                                    eventKey="branches"
                                    title="BRANCHES"
                                    className="row-px-2"
                                >
                                    <Form inline>
                                        <div className="position-relative advance-search w-100">
                                            <span
                                                className="search-icon"
                                                onClick={() => {
                                                    openBar(true);
                                                }}
                                            >
                                                <Image src={searchIcon} />
                                                <span className="search-arrow"></span>
                                            </span>
                                            <span
                                                className="cancel-icon"
                                                onClick={() => {
                                                    openBar(false);
                                                    setKeyword("");
                                                }}
                                            >
                                                <Image src={cancelIcon} />
                                            </span>
                                            <FormControl
                                                type="text"
                                                placeholder="Move this individual to other practice..."
                                                className="mr-sm-2 search-bar"
                                                onChange={handleKeywordChange}
                                                value={Keyword}
                                            />

                                            {bar && (
                                                <div className="advance-search-dropdown">
                                                    <Row>
                                                        <Col lg={12}>
                                                            {data2.hasOwnProperty(
                                                                "practices"
                                                            ) &&
                                                                data2.practices.map(
                                                                    (
                                                                        curr,
                                                                        index
                                                                    ) => (
                                                                        <div
                                                                            key={
                                                                                index
                                                                            }
                                                                            className="search-results position-relative"
                                                                            onClick={() => {
                                                                                handleSetPracticeId(
                                                                                    curr.id,
                                                                                    curr.office
                                                                                );
                                                                            }}
                                                                        >
                                                                            <div
                                                                                className={
                                                                                    curr.office.split(
                                                                                        " "
                                                                                    )[0] ===
                                                                                    "Industry"
                                                                                        ? "search-result mx-2  practice-industry-paw"
                                                                                        : "search-result mx-2"
                                                                                }
                                                                            >
                                                                                <p>
                                                                                    {
                                                                                        curr.name
                                                                                    }
                                                                                </p>
                                                                                <span>
                                                                                    {
                                                                                        curr.address_1
                                                                                    }
                                                                                    ,{" "}
                                                                                    {
                                                                                        curr.postcode
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                            <div className="search-divider"></div>
                                                                        </div>
                                                                    )
                                                                )}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )}
                                        </div>
                                    </Form>
                                    <div className="custom-table">
                                        <Table className="table-curved">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Address line 1</th>
                                                    <th>Town</th>
                                                    <th>Postcode</th>
                                                    <th>Primary</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    onClick={() =>
                                                        dispatch(
                                                            openPractice(
                                                                personInput
                                                                    .practice_data
                                                                    .id
                                                            )
                                                        )
                                                    }
                                                >
                                                    <td>
                                                        {
                                                            personInput
                                                                .practice_data
                                                                .name
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            personInput
                                                                .practice_data
                                                                .address_1
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            personInput
                                                                .practice_data
                                                                .town
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            personInput
                                                                .practice_data
                                                                .postcode
                                                        }
                                                    </td>
                                                    <td>{personInput.pri}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </div>
    );
};

export default Person;
