let personModel = {
    abcanswer: "",
    abcname: "",
    academy_date: "",
    academy_sso: "",
    // al_work: "", //Integer
    // amputation_limb: "", //Integer
    // anaesthesia: "", //Integer
    // anaesthetics_monitoring: "", //Integer
    // animal_restraint: "", //Integer
    anywhere: "",
    // areas: "",
    // bitch_spay: "",
    // blood_samples_taking: "", //Integer
    // blood_sampling: "", //Integer
    business_date: "",
    business_subs: "",
    // c_section: "", //Integer
    // caesareans: "", //Integer
    calf_health: "",
    calf_health_date1: "",
    calf_health_date2: "",
    cardio: "",
    cardio_date1: "",
    cardio_date2: "",
    // castration_dog_cat: "", //Integer
    // castration_rabbit: "", //Integer
    // cat_spay: "",
    // catheter_insertion: "", //Integer
    client_care: "",
    client_care_date1: "",
    client_care_date2: "",
    // clinical_examination: "", //Integer
    companion_animal_date: "",
    companion_animal_subs: "",
    // consultation_10min: "", //Integer
    // consultation_euthanasia: "", //Integer
    // consultation_general: "", //Integer
    // consultation_nurse: "", //Integer
    // contact_method: "",
    contact_time: "",
    cpd_date: "",
    cpd_sso: "",
    marketplace_date: "",
    marketplace_sso: "",
    //created_at: "",
    // cv: "",
    date: "",
    // dentistry: "", //Integer
    derma: "",
    derma_date1: "",
    derma_date2: "",
    // distance: "",
    drlc: "",
    // emergency_medicine: "", //Integer
    equine_date: "",
    equine_respiratory: "",
    equine_respiratory_date1: "",
    equine_respiratory_date2: "",
    equine_subs: "",
    // ex_lap: "", //Integer
    exotics_date: "",
    exotics_subs: "",
    // extractions_dental: "", //Integer
    // farm_experience: "",
    // fertility_work: "", //Integer
    finance: "",
    finance_date1: "",
    finance_date2: "",
    forename: "",
    guinea_p_date1:'',
    guinea_p_date2:'',
    reptiles_date1:'',
    reptiles_date2:'',
    ecc_date1:'',
    ecc_date2:'',
    eq_git_dis_date1:'',
    eq_git_dis_date2:'',
    neurology_date1:'',
    neurology_date2:'',
    urology_date1:'',
    urology_date2:'',
    alabama_rot_date1:'',
    alabama_rot_date2:'',
    wb_teams_date1:'',
    wb_teams_date2:'',
    wb_you_date1:'',
    wb_you_date2:'',
    fip_date1:'',
    fip_date2:'',
    gastro: "",
    gastro_date1: "",
    gastro_date2: "",
    gender: "",
    id: "", // Integer
    ind_agent: "",
    ind_type: "",
    ind_vbj_cost: "",
    ind_vbj_end: "",
    ind_vbj_start: "",
    ind_vn_cost: "",
    ind_vn_end: "",
    ind_vn_start: "",
    ind_vt_cost: "",
    ind_vt_end: "",
    ind_vt_start: "",
    initials: "",
    // investigation_cardiology: "", //Integer
    // investigation_dermatology: "", //Integer
    // investigation_lameness: "", //Integer
    // investigation_neurology: "", //Integer
    // investigation_pre_purchase: "", //Integer
    // lab_interpreting_results: "", //Integer
    lameness: "",
    lameness_date1: "",
    lameness_date2: "",
    lastreq: "",
    // lda_surgery: "", //Integer
    livestock_date: "",
    livestock_respiratory: "",
    livestock_respiratory_date1: "",
    livestock_respiratory_date2: "",
    livestock_subs: "",
    login_at: "",
    lvf: "",
    lvf_email: "",
    maiden: "",
    // main_experience: "",
    mastitis: "",
    mastitis_date1: "",
    mastitis_date2: "",
    middlenames: "", //null
    nutrition: "",
    nutrition_date1: "",
    nutrition_date2: "",
    onco: "",
    onco_date1: "",
    onco_date2: "",
    // ophthalmology: "", //Integer
    p_date: "",
    p_updated: "",
    para: "",
    para_date1: "",
    para_date2: "",
    parasites: "",
    parasites_date1: "",
    parasites_date2: "",
    phealth: "",
    phealth_date1: "",
    phealth_date2: "",
    // phone_number: "",
    pman: "",
    pman_date1: "",
    pman_date2: "",
    // pms: "",
    // pmsother: "",
    // position: "",
    practice_id: "", //Integer
    practice_data: {},
    history: {},
    vet: {},
    history_data: [],
    pri: "",
    // prof: "",
    rabbits: "",
    rabbits_date1: "",
    rabbits_date2: "",
    // radiograph_interpretation: "", //Integer
    // radiograph_taking: "", //Integer
    // radiology: "", //Integer
    // rcvs_2: "",
    // reception_skills: "", //Integer
    // relocate: "",
    // removal_lump: "", //Integer
    residence: "",
    rodents: "",
    rodents_date1: "",
    rodents_date2: "",
    // rtw: "",
    // s_postcode: "",
    // schedule_3_procedures: "", //Integer
    // sm_address_1: "",
    // sm_address_2: "",
    // sm_address_3: "",
    // sm_county: "",
    // sm_date: "",
    // sm_lat: "",
    // sm_lng: "",
    // sm_next_role: "",
    // sm_profile: "",
    // sm_salary_band: "",
    // sm_town: "",
    // sm_work_email: "",
    soft_reg: "",
    // spay_dog_cat: "", //Integer
    // spay_rabbit: "", //Integer
    // species_type: "",
    spets: "",
    spets_date1: "",
    spets_date2: "",
    sso_email: "",
    // stud_work: "", //Integer
    // surgery_orthaepedic: "", //Integer
    surname: "",
    // tb_per_day: "",
    // tb_testing: "", //Integer
    technology: "",
    technology_date1: "",
    technology_date2: "",
    title: "",
    train_and_develop: "",
    train_and_develop_date1: "",
    train_and_develop_date2: "",
    u_cert: "",
    u_country: "",
    u_suffix: "",
    u_uni: "",
    u_year: "", //Integer
    // ultrasonography: "", //Integer
    // updated_at: "",
    // updatedby: "",
    update_vet:"",
    v_address_1: "",
    v_address_2: "",
    v_address_3: "",
    v_bcva: "",
    v_beva: "",
    v_bsava: "",
    v_bva: "",
    v_bvna: "",
    v_country: "",
    v_county: "",
    v_email: "",
    v_history: "",
    v_interests: "",
    v_job_types: "",
    v_job_types_o: "",
    v_lat: "",
    v_lng: "",
    v_notes: "",
    v_paid: "",
    v_pma: "",
    v_postcode: "",
    v_preferences: "",
    v_rcvsnum: "",
    v_sms:"",
    v_s3rd: "",
    v_s3rd_request: "",
    v_s3rd_request_date: "",
    v_sdm: "",
    v_sevbd: "",
    v_sevbd_request: "",
    v_sevbd_request_date: "",
    v_species: "",
    v_species_o: "",
    v_spvs: "",
    v_sso: "",
    v_svbj: "",
    v_svbj_abc_answer: "",
    v_svbj_abc_name: "",
    v_svbj_request: "",
    v_svbj_request_date: "",
    v_svnt: "",
    v_svnt_abc_answer: "",
    v_svnt_abc_name: "",
    v_svnt_request: "",
    v_svnt_request_date: "",
    v_svt: "",
    v_svt_abc_answer: "",
    v_svt_abc_name: "",
    v_svt_request: "",
    v_svt_request_date: "",
    v_svte: "",
    v_svtl: "",
    v_tel: "",
    v_town: "",
    v_vjnm: "",
    v_vjnm_request: "",
    v_vjnm_request_date: "",
    v_vjnw: "",
    v_vjnw_request: "",
    v_vjnw_request_date: "",
    v_vnews: "",
    v_vnews_d: "",
    v_vnews_d_request: "",
    v_vnews_d_request_date: "",
    v_vnews_request: "",
    v_vnews_request_date: "",
    v_vpma: "",
    v_vt_jobs_marketing: "",
    v_vt_jobs_marketing_request: "",
    v_vt_jobs_marketing_request_date: "",
    v_work_email: "",
    vn_monthly_newsletter: "",
    vn_monthly_newsletter_request: "",
    vn_monthly_newsletter_request_date: "",
    vnwbe: "",
    vnwreq: "",
    vt_date: "",
    vt_sso: "",
    vtapp_date: "",
    vtapp_sso: "",
    vtjobs_date: "",
    vtjobs_sso: "",
    vtwbe: "",
    vtwreq: "",
    well_being_date: "",
    well_being_subs: "",
    working_hours: "",
    v_registration_email: "",
    v_registration: "",
    v_attended: "",
    v_congresses: "",
    v_vet_panel: "",
    v_vn_panel: "",
    v_practice_manager_panel: "",
    v_email_opt_in: "",
    primary_pma : "",
    secondary_pma : "",
    pm_name : "",
    pm_email : "",
    pm_phone : "",
    pm_university : "",
    pm_next_role : "",
    pm_postcode : "",
    pm_address_1 : "",
    pm_address_2 : "",
    pm_address_3 : "",
    pm_distance : "",
    pm_town : "",
    pm_county : "",
    pm_country : "",
    pm_relocate : "",
    pm_working_outside : "",
    pm_looking_for_work : "",
    pm_ideal_rota : "",
    pm_clinical_interest : "",
    pm_important_next_role : "",
    pm_skill_develop : "",
    pm_salary_band : "",
    pm_work_in_uk : "",
    pm_cv : "",
    pm_year_rcvs : "",
    pm_areas : "",
    pm_outside_areas : "",
    pm_profile : "",
    pm_profile_created_date : "",
    pm_profile_modified : "",
    pm_job_type : "",
    pm_year_qualification : "",
    pm_ideal_role : "",
    pm_interested : "",
    pm_interest_date : "",
    pm_soft_opt_in : "",
    pm_soft_opt_in_date : "",
    record_created : "",
    endocrinology_date1 : "",
    endocrinology_date2 : "",
    anaesthesia_date1 : "",
    anaesthesia_date2 : "",
    ophthalmology_date1 : "",
    ophthalmology_date2 : "",
    oral_care_date1 : "",
    oral_care_date2 : "",
    wound_management_date1 : "",
    wound_management_date2 : "",
    birds_date1 : "",
    birds_date2 : "",
    obesity_date1 : "",
    obesity_date2 : "",
    imaging_date1 : "",
    imaging_date2 : "",
    reproduction_date1 : "",
    reproduction_date2 : "",
    bvd_date1 : "",
    bvd_date2 : "",
    lamb_date1 : "",
    lamb_date2 : "",

    v_svtl_digi:"",
    v_svte_digi:"",

    v_svt_digi: "",
    v_svt_digi_abc_answer: "",
    v_svt_digi_abc_name: "",
    v_svt_digi_request: "",
    v_svt_digi_request_date: "",

    v_svnt_digi : "",
    v_svnt_digi_request : "",
    v_svnt_digi_request_date : "",
    v_svnt_digi_abc_name : "",
    v_svnt_digi_abc_answer : "",

    v_svbj_digi : "",
    v_svbj_digi_request : "",
    v_svbj_digi_request_date : "",
    v_svbj_digi_abc_name : "",
    v_svbj_digi_abc_answer : "",

    ind_vt_digi_start:"",
    ind_vt_digi_end:"",
    ind_vt_digi_cost:"",

    ind_vn_digi_start:"",
    ind_vn_digi_end:"",
    ind_vn_digi_cost:"",

    ind_vbj_digi_start:"",
    ind_vbj_digi_end:"",
    ind_vbj_digi_cost:"",

}

export default personModel
