import React, { useState, useEffect, useReducer } from 'react';
import { Button, Col, Row, Card, Table, Collapse, Image, Form } from 'react-bootstrap';
import { practice_vet } from '../constants/advanceQueryBuilder'
//Icons
import arrowDown from '../assets/icons/arrow-down.svg';
import arrowUp from '../assets/icons/arrow-up.svg';
//perfect-scrollbar
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import QueriesInput from './QueriesInput';
import QueriesInputOr from './QueriesInputOr';
import RadiusSearchADQ from "./RadiusSearchADQ"
//react-redux
import { useDispatch, useSelector } from 'react-redux';
import { openPractice, openPerson } from '../actions/userActions';
import GlobalLoader from './GlobalLoader';
import MassMove from './MassMove';
import ExportBuilderSidebar from './ExportBuilderSidebar';
import MassUpdateSidebar from './MassUpdateSidebar';
import useOnclickOutside from 'react-cool-onclickoutside';
import Axios from '../adapters/xhr';
import { confirmationAlert, swAlert } from '../helpers/alert';
import ReactPaginate from 'react-paginate';
import '../App.css'
import swal from "sweetalert";
import {save_without_audit} from "../helpers/validation";
import Swal from "sweetalert2";

// REDUCER FUNCTION
function reducer(state, action) {
    switch (action.type) {
        case 'ADD INPUT FIELD':
            return [...state, { and: { from: 'VetFields', where: 'title', wheretext: 'Title', param: 'LIKE', val: '' }, or: [] }]

        case 'ADD INPUT FIELD OR':
            state[action.index].or.push({ from: state[action.index].and.from, where: state[action.index].and.where, wheretext: state[action.index].and.wheretext, param: state[action.index].and.param, val: state[action.index].and.val, val2:state[action.index].and.val2 })
            return [...state]

        case 'DELETE INPUT FIELD':
            return [...state.filter((curr, i) => { return i !== action.index })]

        case 'DELETE INPUT FIELD OR':
            state[action.index].or.splice(action.ind, 1)
            return [...state]

        case 'ENTITY VALUE':
            state[action.index].and.from = action.entityValue
            if (action.entityValue === 'practice_fields') {
                state[action.index].and.where = 'office'
                state[action.index].and.wheretext = 'Office'
            } else {
                state[action.index].and.where = 'title'
                state[action.index].and.wheretext = 'TITLE'
            }
            return [...state]
        case 'ENTITY VALUE OR':
            state[action.index].or[action.ind].from = action.entityValue
            if (action.entityValue === 'practice_fields') {
                state[action.index].or[action.ind].where = 'office'
                state[action.index].or[action.ind].wheretext = 'Office'
            } else {
                state[action.index].or[action.ind].where = 'title'
                state[action.index].or[action.ind].wheretext = 'TITLE'
            }
            return [...state]

        case 'WHERE FIELD':
            state[action.index].and.where = action.where
            state[action.index].and.wheretext = action.wheretext

            return [...state]

        case 'WHERE FIELD OR':
            state[action.index].or[action.ind].where = action.where
            state[action.index].or[action.ind].wheretext = action.wheretext
            return [...state]

        case 'OPERATOR FIELD':
            state[action.index].and.param = action.param
            return [...state]
        case 'OPERATOR FIELD OR':
            state[action.index].or[action.ind].param = action.param
            return [...state]

        case 'VALUE_FIELD':
            state[action.index].and.val = action.val
            state[action.index].and.val2 = action.val2
            return [...state]

        case 'VALUE_FIELD_OR':
            state[action.index].or[action.ind].val2 = action.val2
            state[action.index].or[action.ind].val = action.val
            return [...state]

        case 'RESET VALUE VAL2':
            // state[action.index].and.val2 = []
            // state[action.index].and.val = ''
            return [...state]

        case 'RESET VALUE VAL2 MUST':
             state[action.index].and.val2 = []
             state[action.index].and.val = ''
            return [...state]

        case 'RESET VALUE VAL2 OR':
            // state[action.index].or[action.ind].val2 = []
            // state[action.index].or[action.ind].val = ''
            return [...state]

        case 'RESET_QUERIES':
            return [{ and: { from: 'VetFields', where: 'title', wheretext: 'Title', param: 'LIKE', val: '', val2: [] }, or: [] }]

        case 'REPORT BUILDER EDIT QUERY':
            return [...action.initialState]

        default:
            return [...state]
    }
}

// REDUCER FUCNTION END
const AdvanceQueryBuilder = (props) => {

    let [queries, dispatched] = useReducer(reducer, [{ and: { from: 'VetFields', where: 'title', wheretext: 'Title', param: 'LIKE', val: '', val2: [] }, or: [] }])

    const [loading, setLoading] = useState(false)
    const [showPagination, setShowPagination] = useState(false)
    const [respData, setRespData] = useState({});
    const [queryResult, setQueryResult] = useState([])
    const [resultCount, setResultCount] = useState()
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState()
    const [open, setOpen] = useState(false);
    const [mass, setMass] = useState(false);
    const [massMove, setMassMove] = useState(false);
    const [showRadius, setShowRadius] = useState(false);
    const [radiusSearchReq, setRadiusSearchReq] = useState({ driving: null, radius_miles: null, radius_postcode: null })
    const [resultOrder, setResultOrder] = useState({ order: null })
    const [exportBuilderInitial, setExportBuilderInitial] = useState()
    const { reportList } = useSelector(state => state.getReportListReducer)
    const [reports, setReports] = useState([]);
    const dispatch = useDispatch()

    useEffect(() => {
        if (resultOrder.order !== null) showResult()
    }, [resultOrder])

    const handleShowResult = (e) => {
        const currPage = e ? e.selected : 0
        showResult(currPage + 1)
    }

    useEffect(() => {
        if (reportList) {
            setReports(reportList.data)
            //dispatch({type:'REPORT BUILDER EDIT QUERY',initialState:reportList.data.query})
        }
    }, [reportList])

    const showResult = async (currPage) => {

        let payload = { query: queries, ...radiusSearchReq, ...resultOrder };
        let { data } = await Axios.post(`/query-bulder?page=${currPage}`, payload);
        let tempObj = {}
        let exportArr = []
        for (const [key, value] of Object.entries(data.fields)) {
            tempObj = {
                value: key,
                label: value
            }
            exportArr.push(tempObj)
        }
        setExportBuilderInitial(exportArr)
        setShowPagination(true)
        setRespData(data);
        setQueryResult(data.results.data)
        setResultCount(data.count)
        setLastPage(data.results.last_page)
        setCurrentPage(data.results.current_page ?? 1)
        if(document.getElementsByClassName('advance-query-builder-table').length > 0){
            document.getElementsByClassName('advance-query-builder-table')[0].getElementsByTagName('tbody')[0].scrollTop = 0
        }
    };

    const resetHandler = () => {
        setShowPagination(false)
        setShowRadius(false)
        setResultCount()
        setRadiusSearchReq({ driving: null, radius_miles: null, radius_postcode: null })
        setRespData({});
        setQueryResult([]);
        dispatched({ type: 'RESET_QUERIES' })
    };

    const handleSendPracticeId = (dataId) => {
        dispatch(openPractice(dataId));
    };

    const handleSendPersonId = (dataId) => {
        dispatch(openPerson(dataId));
    };

    const massDelete = () => {

        swal("Are you sure to perform this action?", {
            buttons: {
                notSave: {
                    text: "Delete from Vetfile",
                    className: "theme-btn-secondary min-w-175 my-2",
                    value: "delete from vetfile",
                },
                save: {
                    text: "Delete from SSO",
                    className: "theme-btn-outline-secondary min-w-175 my-2",
                    value: "delete from sso"
                }
            },
        }).then((value) => {
            let errorMesg = ''
            switch (value) {

                case "delete from vetfile":
                    Axios.delete(`/vet/mass/remove/practice`, {
                        headers: { Authorization: `Bearer ${localStorage.token}` }
                    }).then(res => {
                        if (res.data.status) {
                            swAlert(`${res.data.message}`, 'success', true)
                        }
                    }).catch((err) => {
                        swAlert(`${err.message}`, true)
                    })
                    break;
                case "delete from sso":
                    Axios.delete(`/vet/mass/remove/sso`, {
                        headers: { Authorization: `Bearer ${localStorage.token}` }
                    }).then(res => {
                        if (res.data.status) {
                            swAlert(`${res.data.message}`, 'success', true)
                        }
                    }).catch((err) => {
                        swAlert(`${err.message}`, true)
                    })
                    break;
            }
        });


        // confirmationAlert('Are you sure to perform this action?', 'question').then(async (result) => {
        //     if (result.isConfirmed) {
        //         await Axios.delete(`/vet/mass/remove/practice`, {
        //             headers: { Authorization: `Bearer ${localStorage.token}` }
        //         }).then(res => {
        //             if (res.data.status) {
        //                 swAlert(`${res.data.message}`, 'success', true)
        //             }
        //         }).catch((err) => {
        //             swAlert(`${err.message}`, true)
        //         })
        //     }
        // });
    }

    const ref = useOnclickOutside(() => {
        setOpen(false);
        setMassMove(false)
        setMass(false)
    });

    const showExportSidebar = () => {
        setOpen(true);
    }

    const showMassSidebar = () => {
        setMass(true);
    }

    const showMassMoveSidebar = () => {
        setMassMove(true);
    }
    const removeRadiusSearch = () => {
        setRadiusSearchReq({ driving: null, radius_miles: null, radius_postcode: null })
        setShowRadius(false)
    }
    const handleResultOrder = (name) => {
        if (resultOrder.order === null) {
            setResultOrder({ order: `${name} DESC` })
        } else if (Object.values(resultOrder).toString().includes("DESC")) {
            setResultOrder({ order: `${name} ASC` })
        } else if (Object.values(resultOrder).toString().includes("ASC")) {
            setResultOrder({ order: `${name} DESC` })
        }
    }

    const addClass = () => {
        if (props.cls == '') {
            props.setClass("hideShow")

        } else {
            props.setClass("")
        }
    }

    const handleOnClickTable = (forename, office, title, id, VID, item_id, vet_id) => {

        if (localStorage.getItem('user_role') != 5 && props.userMe_role != 5 && props.showPracticeMessage && title) {
            personMessageOnClick()
            return;
        }

        if (localStorage.getItem("user_role") != 5 && props.userMe_role != 5 && props.showPersonMessage && !title) {
            practiceMessageOnClick()
            return;
        }

        if (item_id && (vet_id || VID)) {
            if (vet_id) {
                handleSendPersonId(vet_id)
                return
            } else {
                handleSendPersonId(VID)
                return
            }
        }
        if (item_id && !title) {
            handleSendPracticeId(item_id)
            return
        }
        if (item_id) {
            handleSendPersonId(item_id)
            return
        }
        if (VID) {
            handleSendPersonId(VID)
            return
        }
        if (vet_id) {
            handleSendPersonId(vet_id)
            return
        }
        if (title || forename) {
            handleSendPersonId(id)
            return
        } else {
            handleSendPracticeId(id)
        }
    }

    //Methods for getting table header value
    const handleTableHeaderValue = (value) => {
        for (let i = 0; i < practice_vet.length; i++) {
            if (value === practice_vet[i].value) {
                return practice_vet[i].label
            }
        }
        return value
    }

    const handleGetReport = async (e) => {
        let id = e.target.value;
        let report = await Axios.get(`/report/builder/${id}`)
        if(report.data.data.radius.driving || report.data.data.radius.radius_miles || report.data.data.radius.radius_postcode ){
            setShowRadius(true)
        }else{
            setShowRadius(false)
        }
        dispatched({ type: 'REPORT BUILDER EDIT QUERY', initialState: report.data.data.query })
        setRadiusSearchReq({...report.data.data.radius})
    }

    const closeMassUpdateSidebar = () => {
        setMass(false)
    }

    const personMessageOnClick = () => {
        Swal.fire({
            title: 'Message',
            text: 'Changes has been made on vet window. Dont forget to save changes if needed.',
            confirmButtonText: 'Understood'
        })
        props.practiceMessageFunc(false)
    }

    const practiceMessageOnClick = () => {
        Swal.fire({
            title: "Message",
            text: "Changes has been made on practice window. Dont forget to save changes if needed.",
            confirmButtonText: "Understood",
        });
        props.showPersonMsgFunc(false);
    }

    return (
        <>
            {massMove && <div ref={ref} id="addPractice"> ? (
                <Collapse in={massMove}>
                    <div id="addPractice">
                        {<MassMove/>}
                    </div>
                </Collapse>
                ) </div>}

            {open && <div ref={ref} id="addPractice"> ? (
                <Collapse in={open}>
                    <div id="addPractice">
                        <ExportBuilderSidebar values={exportBuilderInitial}/>
                    </div>
                </Collapse>

                )</div>}

            {mass && <div ref={ref} id="addPractice"> ? (
                <Collapse in={mass}>
                    <div id="addPractice">
                        <MassUpdateSidebar closeMassUpdateSidebar={closeMassUpdateSidebar}
                                           entity={queries[0].and.from ?? ''} handleShowResult={handleShowResult}/>

                    </div>

                </Collapse>

                )</div>}
            {/* <div className="advance-query-builder-toggle"><a className="toggle-btn" onClick={addClass} ><span className="toggle-arrow"></span></a></div> */}

            <div className="AQB-minimize justify-content-between align-items-center">
                <h3 className="mb-3">Advance Query Builder</h3>
                {queryResult.length > 0 &&
                    <h4 className="mb-2">Total results found: <span className="theme-blue-text">{respData.count}</span>
                    </h4>
                }
            </div>

            <Row className="advance-query-builder gx-3">

                {/* <GlobalLoader/> */}

                <Col lg={6}>
                    <Card className="mt-4">
                        <Card.Body>
                            <Row>
                                <Col lg={12}>

                                    <div className="d-flex mb-3 align-items-center justify-content-between">
                                        <h3 className="mb-0">Advance Query Builder</h3>
                                        <Form.Control name="saved-reports" onChange={handleGetReport} className="form-select m-w-150 mb-0" as="select" placeholder="Choose a preset">
                                            <option disabled selected>Choose a preset</option>
                                            {
                                                reports && reports.map((curr, ind) => (
                                                    <option key={ind} value={curr.id}>{curr.title}</option>
                                                ))
                                            }
                                        </Form.Control>
                                    </div>

                                    {queries.map((item, index) => {

                                        return (
                                            <>
                                                <QueriesInput item={item} type='AND' dispatched={dispatched} index={index} />
                                                {item.or.map((curr, ind) => { return (<QueriesInputOr curr={curr} type='OR' dispatched={dispatched} index={index} ind={ind} />) })}
                                            </>
                                        )
                                    })}

                                </Col>
                                <Col>
                                    {showRadius && (
                                        <RadiusSearchADQ radiusSearchReq={radiusSearchReq} setRadiusReq={setRadiusSearchReq} removeRadiusSearch={removeRadiusSearch} />)}
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div className="d-flex mt-3">
                                        {/* <div className="me-2">
                                            <Button
                                                className="btn theme-btn-primary"
                                                onClick={addInputsHandler}
                                            >
                                                And
                                            </Button>
                                        </div> */}
                                        <div className="me-2">
                                            <Button onClick={() => { setShowRadius(true) }} className="btn theme-btn-primary">
                                                Perform Radius Search
                                            </Button>
                                        </div>
                                        <div className="me-2">
                                            <Button
                                                className="btn theme-btn-outline-primary"
                                                onClick={resetHandler}
                                            >
                                                Reset Builder
                                            </Button>
                                        </div>
                                        <div className="">
                                            <Button
                                                className="btn theme-btn-secondary"
                                                onClick={(e) => { handleShowResult(e) }}
                                            >
                                                Show Results
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>

                {queryResult.length > 0 &&
                    <Col lg={6}>
                        <Card className="mt-4 custom-card">
                            <Card.Body>
                                <div className="d-flex justify-content-between mb-3 align-items-center">
                                    {queryResult.length > 0 &&
                                        <h4 className="mb-0">Total results found: <span className="theme-blue-text">{respData.count}</span></h4>
                                    }
                                    {
                                        queryResult.length > 0 &&
                                        <div className="button-group">
                                            {localStorage.getItem('user_role') == 1 && <Button onClick={showMassSidebar} className="btn theme-btn-outline-primary ms-2">Mass update</Button>}
                                            {localStorage.getItem('user_role') == 1 && <Button onClick={massDelete} className="btn theme-btn-outline-primary ms-2">Mass Delete</Button>}
                                            {localStorage.getItem('user_role') == 1 && <Button onClick={showMassMoveSidebar} className="btn theme-btn-outline-primary ms-2">Mass move</Button>}
                                            {(localStorage.getItem('user_role') == 1 || localStorage.getItem('user_role') == 2 || localStorage.getItem('user_role') == 3 || localStorage.getItem('user_role') == 4 || localStorage.getItem('user_role') == 6) && <Button onClick={showExportSidebar} className="btn theme-btn-outline-primary ms-2">Export Builder</Button>}
                                        </div>
                                    }

                                </div>

                                {loading == true ? <GlobalLoader /> : null}

                                {/* <PerfectScrollbar className="custom-table advanced-query-builder-custom-table">
                                    <Table hover className="table-fixed">
                                        <thead>
                                            <tr>
                                                {queryResult.length > 0 &&
                                                    (Object.keys(
                                                        queryResult[0]
                                                    ).map((f) => <th className="position-relative">{handleTableHeaderValue(f)} <Button onClick={() => { handleResultOrder(f) }} className="p-0 table-arrow-up"> <Image src={arrowUp} />  </Button> <Button onClick={() => { handleResultOrder(f) }} className="p-0 table-arrow-down"> <Image src={arrowDown} />  </Button></th>))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {queryResult.map((f) => (
                                                <tr onClick={() => { handleOnClickTable(f.office,f.title,f.id,f.vid,f.item_id);}}>{Object.values(f).map(x => <td>{x}</td>)}</tr>
                                            ))}
                                        </tbody>

                                    </Table>
                                </PerfectScrollbar> */}

                                <div className="table-responsive">
                                    <table className="width-table-query fixed-header-table advance-query-builder-table">
                                        <thead >
                                            <tr>
                                                {queryResult.length > 0 &&
                                                    (Object.keys(
                                                        queryResult[0]
                                                    ).map((f) => <th className="position-relative">{handleTableHeaderValue(f)} <Button onClick={() => { handleResultOrder(f) }} className="p-0 table-arrow-up"> <Image src={arrowUp} />  </Button> <Button onClick={() => { handleResultOrder(f) }} className="p-0 table-arrow-down"> <Image src={arrowDown} />  </Button></th>))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {queryResult.map((f) => (
                                                <tr onClick={() => { handleOnClickTable(f.forename,f.office, f.title, f.id, f.VID, f.item_id, f.vet_id); }}>{Object.values(f).map(x => <td>{x}</td>)}</tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                                {showPagination &&
                                    (<ReactPaginate
                                        previousLabel={"<"}
                                        nextLabel={">"}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={lastPage}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        forcePage={currentPage-1}
                                        onPageChange={handleShowResult}
                                        containerClassName={"pagination mt-5"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"} />)}
                            </Card.Body>


                        </Card>
                    </Col>}
                {resultCount === 0 &&
                    <Col lg={6}>
                        <div>
                            <h4 className="mb-0"><span className="theme-blue-text">No result found</span></h4>
                        </div>

                    </Col>
                }
            </Row>
        </>
    );
};

export default AdvanceQueryBuilder;
