import React, { useEffect, useState } from 'react'
//react-redux
import { useSelector, useDispatch } from 'react-redux'
import { updatePractice, getPracticeById, openPractice, openPerson, search, lastSearchedResult, pmsDropdown } from '../actions/userActions'
//react-bootstrap
import { Button, Col, Card, Form, FormControl, ListGroup, Row, Tab, Table, Tabs, Image, Collapse, Container, Nav, FormGroup } from 'react-bootstrap'
//assets
import deleteIcon from "../assets/icons/delete.svg";
import editIcon from "../assets/icons/edit.svg";
//practice model
import practiceModel from '../constants/practiceModel';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//react-spinner
import BeatLoader from "react-spinners/BeatLoader";
//search-icon
import addressIcon from "../assets/icons/map-pin.svg";
import searchIcon from "../assets/icons/search-icon.svg";
import cancelIcon from "../assets/icons/cancel-icon.svg";
import Swal from "sweetalert2";
import PerfectScrollbar from "react-perfect-scrollbar";
import useOnclickOutside from "react-cool-onclickoutside";
import Axios from '../adapters/xhr';
import dayjs from 'dayjs';
import MaskedInput from 'react-text-mask';
import Select from 'react-select';
import { confirmationAlert, swAlert } from '../helpers/alert';

import axios from "axios";
import {yesAndNo, animalType, industryType, oralCareKitOptions, corporate_fields,buying_fields} from '../constants/constants';
import { practice } from '../constants/advanceQueryBuilder';
//Validation
import { practiceBeforeSaveUpdateValidation } from "../helpers/validation";

import { selectStyle } from '../styling/select-style'

const Practice = (props) => {

  const [open, setOpen] = useState(true)
  const [value, setValue] = useState('');

  const [openMenu, setOpenMenu] = useState(false);
  const [switchmain, setSwitchmain] = useState(false);
  const [chooseMain, setChooseMain] = useState(false);
  const [key, setKey] = useState('home');
  const [practiceInput, setPracticeInput] = useState(practiceModel)
  const [bar, openBar] = useState(false)
  const [Keyword, setKeyword] = useState('')
  const [action, setActionData] = useState('practiceAsMain');
  const [data, setData] = useState({ practices: [] })
  const [id, setId] = useState([])
  const [oralCareOptions, setOralCareOptions] = useState([])
  // multi select
  const [directMail, setDirectMail] = useState(null);
  const [sendThirdParty, setSendThirdParty] = useState(null);
  const [noStaffPrac, setNoStaffPrac] = useState(null);
  const [type, setType] = useState(null);

  //use-state-hooks
  //const [practiceInput, setPracticeInput] = useState(practiceModel)
  const [error, setError] = useState({})
  const [postcode, setPostcode] = useState('')
  const [postcodeMenuShow, setPostCodeMenuShow] = useState(false)
  const [address, setAddress] = useState({})
  // const [addressData, addressSetData] = useState({})
  //react-redux
  const dispatch = useDispatch()
  const { loadingUpdatePractice, practiceUpdate, errorUpdatePractice } = useSelector(state => state.updatePracticeReducer)
  const { loadingGetPractice, practiceGet, errorGetPractice } = useSelector(state => state.getPracticeReducer)
  const { lastSearchResult } = useSelector(state => state.lastSearchedResultReducer)
  var { practiceId } = useSelector(state => state.openPracticeReducer)
  const { searchPracticeResult } = useSelector(state => state.searchReducer)
  const [date, setDate] = useState(new Date())

  // pms dropdown
  const [pmsDropDown, setPMSDropDown] = useState([])
  const [selectedPMS, setSelectedPMS] = useState([])
  const [disableTypes, setDisableTypes] = useState(false);
  const [customizeInput, setCustomizeInput] = useState({ new_value: '', set_value: '' })
  const { pms_dropdown } = useSelector(state => state.pmsDropDownReducer)

  //use-state-hooks
  const [openn, setOpenn] = useState({
    switchmain: false,
    chooseMain: false,
    lastViewedOpen: false,
    exportBuilder: false

  });

  const ref = useOnclickOutside(() => {
    setOpenMenu(false);
    setPostCodeMenuShow(false)
  });

  const handleClickBtn = () => {
    setOpenMenu(!openMenu);
  };

  const deletePractice = (practice) => {

    let data = practiceGet ? practiceGet.data : lastSearchResult.data.practice;

    if (practice === data.main && data.branch_data.length > 1) {
      swAlert('You are unable to delete this main practice. Please select a new main before deleting.', 'error', true)
    } else {
      confirmationAlert('Are you sure to perform this action?', 'question').then(async (result) => {
        if (result.isConfirmed) {
          await Axios.delete(`/practice/${practice}`, {
            headers: {
              Authorization: `Bearer ${localStorage.token}`
            }
          }).then(res => {
            if (res.data.status) swAlert(`${res.data.message}`, 'success', true)
          }).catch((err) => {
            swAlert(`${err.message}`, true)
          })

          dispatch(lastSearchedResult())
        }
      })
    }
  }

  useEffect(() => {
    if (pms_dropdown) {
      let pms_select = [];
      pms_dropdown.data && pms_dropdown.data.forEach(el => {
        pms_select.push({ 'label': el.value, 'value': el.id })
      });
      setPMSDropDown(pms_select);
    }
  }, [pms_dropdown])


    useEffect(() => {

        setDisableTypes(false)

        let type = practiceInput.type.length > 0 ? practiceInput.type.split(",") : [];
        let vetfile_type = type.includes('Vetfile');


        if(vetfile_type){
                setDisableTypes(true)
                setPracticeInput((prev) => ({...prev, type: 'Vetfile'}))
        } else {
            setDisableTypes(false)
        }

    }, [practiceInput.type])

  //useEffect

  //   useEffect(() => {
  //     if (openn.chooseMain===true || openn.switchmain===true || openn.lastViewedOpen===true || openn.exportBuilder === true) {
  //          setOpenn({ coll: props.open, switchmain: false, chooseMain: false, lastViewedOpen: false,exportBuilder: false })
  //     }
  //     if(bar == true){
  //         openBar(false);
  //     }
  // }, [props.open,props.searchOpen]);

  useEffect(() => {
    if (practiceGet) populatePractice(practiceGet.data)
      if(document.getElementsByClassName('people-table-scroll').length > 0){
          document.getElementsByClassName('people-table-scroll')[0].getElementsByTagName('tbody')[0].scrollTop = 0
      }
  }, [practiceGet])

  useEffect(() => {
    practiceId && dispatch(getPracticeById(practiceId))
  }, [dispatch, practiceId])

  useEffect(() => {
    if (lastSearchResult) populatePractice(lastSearchResult.data.practice)
  }, [lastSearchResult])

  useEffect(() => {
    if (searchPracticeResult) {
      setData({
        practices: searchPracticeResult ? searchPracticeResult.data.practices : [],
      })
      openBar(true)
    }
  }, [searchPracticeResult])

  useEffect(() => {
    if (Keyword.length >= 3) {
      dispatch(search(Keyword, "prac"))
    } else {
      openBar(false)
    }
  }, [dispatch, Keyword])

  useEffect(() => {
    practiceUpdate && setPracticeInput(practiceUpdate.data)
  }, [practiceUpdate])

  useEffect(() => {
    let temp = []
    oralCareOptions.forEach((x) => {
      temp.push(x.label)
    })
    let str = temp.join(",")
    setPracticeInput((prev) => ({ ...prev, oralcare: str }))
    console.log('practiceInput oralcare', practiceInput.oralcare)
  }, [oralCareOptions, oralCareKitOptions])

  useEffect(() => {
    let tempValue = []
    if(practiceInput.oralcare !== null){
      practiceInput.oralcare.split(",").forEach((x) => {
        oralCareKitOptions.forEach((y) => {
          if (y.value === x) {
            tempValue.push({ label: y.label, value: y.value, fieldName1: y.fieldName1, fieldName2: y.fieldName2, f1Value: y.f1Value, f2Value: y.f2Value })
          }
        })
      })
    }
    setOralCareOptions(tempValue)
  }, [practiceGet, dispatch, practiceInput.id, oralCareKitOptions])

  // populate practice form
  const populatePractice = (practice) => {
    resetPostCodeLookUp();
    let temp = {}
    Object.entries(practice).forEach(([key, value]) => {
      if (value === null) temp[key] = ''
      else if (key === 'branch_data') {
        value.sort((x, y) => { return x.id === x.main ? -1 : 0; });
        temp[key] = value;
      } else temp[key] = value
    });
    setPracticeInput(temp)
    setSelectedPMS({ label: temp.pramansys })
    setDirectMail({ label: temp.direct_mail })
    setSendThirdParty({ label: temp.send_third })
    setNoStaffPrac({ label: temp.no_staff_pra })
    setType({ label: temp.type })
    /* setDirectMail(yesAndNo.find((row) => { return row.value == temp.direct_mail; }))
    setSendThirdParty(yesAndNo.find((row) => { return row.value == temp.send_third; }))
    setNoStaffPrac(yesAndNo.find((row) => { return row.value == temp.no_staff_pra; }))
    setType(animalType.find((row) => { return row.value == temp.type; })) */
  }

  //search stuff
  const handleKeywordChange = (e) => {
    e.preventDefault()
    const { value } = e.target

    setKeyword(value)
  }

  const handleSetPracticeId = (id, office1) => {
    // setPersonInput(prevState => ({
    //   ...prevState,
    //   practice_id: String(id)
    // }))
      if(office1 == 'Practice Main'){
      office1 = 'Practice Branch';
    }else if(office1 == 'Industry Main'){
      office1 = 'Industry Branch';
    }else{
      //
    }
    dispatch(updatePractice(id, { main: practiceInput.main, office: office1 }))
      dispatch(openPractice(practiceInput.id))
  }

  const handleOralCareOptions = (e) => {
    setOralCareOptions(e)
  }

  //address finder
  const handlePostcodeChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    // setPostCodeMenuShow(true)

    setPostcode(value)
  }

  const getOralCareKitMultiValues = () => {
    let tempValue = []
    practiceInput.oralcare.split(",").forEach((x) => {
      oralCareKitOptions.forEach((y) => {
        if (y.value === x) {
          tempValue.push({ label: y.label, value: y.value, fieldName1: y.fieldName1, fieldName2: y.fieldName2 })
        }
      })
    })
    return tempValue
  }

  const handlePostcodeSubmit = async () => {
    const api_key = 'htcb_PTDuEiH1jU-6sz0vA24263'
    const { data } = await axios.get(
      `https://api.getAddress.io/find/${postcode}?api-key=${api_key}&expand=true`
    );

    const arr = []
    data.addresses.forEach((curr, ind) => {
      arr.push({
        display: curr.formatted_address.join(" "),
        address1: curr.line_1,
        address2: (curr.line_2 === '' && curr.country !== 'Isle of Man' && curr.country !== 'Channel Islands') ? curr.locality : curr.line_2,
        address3: curr.line_3,
        town: (curr.country === 'Isle of Man' || curr.country === 'Channel Islands') ? curr.locality : curr.town_or_city,
        county: (curr.town_or_city === 'Isle of Man' || curr.country === 'Channel Islands') ? curr.town_or_city :  curr.county,
        country: curr.country === 'Isle of Man' ? 'England' : curr.country,
        postcode: data.postcode
      })
    })
    setData({ arr });
    setPostCodeMenuShow(true)
  }

  const handleSetAddress = (data) => {
    setPracticeInput(prevState => ({
      ...prevState,
      address_1: data.address1,
      address_2: data.address2,
      address_3: data.address3,
      town: data.town,
      county: data.county,
      country: data.country,
      postcode: data.postcode
    }))
    setData({})
  }

  //practice stuff
  const handlePracticeChange = (e) => {
      if (!props.showPersonMessage) props.showPersonMsgFunc(true)
        const { name, value } = e.target

        setPracticeInput(prevState => ({...prevState, [name]: value }))

        practiceModel[name] = value

      if(corporate_fields.includes(name)){
          setPracticeInput(prevState => ({ ...prevState, corporate: 'No' }))
          let corporate = [];
          corporate_fields.map((val,i)=>{
              corporate[i]= practiceModel[val];
          });
          if (corporate.includes('Yes')) {
              setPracticeInput(prevState => ({ ...prevState, corporate: 'Yes' }))
          }
      }
      if(buying_fields.includes(name)){
          setPracticeInput(prevState => ({ ...prevState, buying: 'No' }))
          let buying = [];
          buying_fields.map((val,i)=>{
              buying[i]= practiceModel[val];
          });
          // if(practiceModel['vetswest'] === 'Yes'){
          //     setPracticeInput(prevState => ({ ...prevState, mwi_group: 'Yes' }))
          // }
          if (buying.includes('Yes')) {
              setPracticeInput(prevState => ({ ...prevState, buying: 'Yes' }))
          }
      }
      if (name === "type") {
          let type = practiceInput.type.length > 0 ? practiceInput.type.split(",") : []

          if (type.find(curr => curr === value)) {
              let str = type.filter(curr => curr != value).join()

              setPracticeInput(prevState => ({
                  ...prevState,
                  type: str
              }))
          } else {
              type.push(value)
              let str = type.length > 0 ? type.join() : ""
              setPracticeInput(prevState => ({
                  ...prevState,
                  type: str
              }))
          }
      }
  }

  const handleMultiSelectPracticeChange = (e, name, field) => {
      if (!props.showPersonMessage) props.showPersonMsgFunc(true)
    field(e)
    setPracticeInput(prevState => ({
      ...prevState,
      [name]: e.label
    }))
  }
  // const ockReqChange=()=>{
  //   debugger
  //   oralCareOptions.forEach((item)=>{
  //     if(practiceInput.oralcare.split(',').find( i => i===item.value)){

  //     }else{
  //       practiceInput[`${item.f1Value}`] = null
  //       practiceInput[`${item.f2Value}`] = null
  //       setPracticeInput(...practiceInput)
  //     }
  //     })
  // }

  const handleCheckPracticeChange = (e) => {
    const { name, value } = e.target
    let temp = !practiceInput[name] ? [] : practiceInput[name].split(",");
    e.target.checked ? temp.push(value) : temp.splice(temp.indexOf(value), 1);
    temp = temp.join(",");
    setPracticeInput(prevState => ({
      ...prevState,
      [name]: temp
    }))
  }
  const handlePracticeSubmit = (e) => {
    e.preventDefault();
    props.resetState();

    let perror = practiceBeforeSaveUpdateValidation(practiceInput);
    setError(perror)
    let errorInPractice = Object.keys(perror).length > 0 ? 1 : 0
    if (errorInPractice) {
      swAlert('Please fill the required fields', 'error')
    } else {

      if(practiceInput.address_2 === ''){
        practiceInput.address_2 = null
      }
      if(practiceInput.address_3 === ''){
        practiceInput.address_3 = null
      }
      if(practiceInput.fax === ''){
        practiceInput.fax = null
      }
      if(practiceInput.website === ''){
        practiceInput.website = null
      }
      if(practiceInput.wholesaler === ''){
        practiceInput.wholesaler = null
      }
      if(practiceInput.pramansys === ''){
        practiceInput.pramansys = null
      }
      if(practiceInput.training === ''){
        practiceInput.training = null
      }
      if(practiceInput.oralcare === ''){
        practiceInput.oralcare = null
      }
      if(practiceInput.ock_req_name === ''){
        practiceInput.ock_req_name = null
      }
      if(practiceInput.ock_req_email === ''){
        practiceInput.ock_req_email = null
      }
      if(practiceInput.ock_req_name_2016 === ''){
        practiceInput.ock_req_name_2016 = null
      }
      if(practiceInput.ock_req_name_2017 === ''){
        practiceInput.ock_req_name_2017 = null
      }
      if(practiceInput.ock_req_name_2018 === ''){
        practiceInput.ock_req_name_2018 = null
      }
      if(practiceInput.ock_req_name_2019 === ''){
        practiceInput.ock_req_name_2019 = null
      }
      if(practiceInput.ock_req_name_2020 === ''){
        practiceInput.ock_req_name_2020 = null
      }
      if(practiceInput.ock_req_name_2021 === ''){
        practiceInput.ock_req_name_2021 = null
      }
      if(practiceInput.ock_req_name_2022 === ''){
        practiceInput.ock_req_name_2022 = null
      }
      if(practiceInput.ock_req_name_2023 === ''){
        practiceInput.ock_req_name_2023 = null
      }
      if(practiceInput.ock_req_email_2016 === ''){
        practiceInput.ock_req_email_2016 = null
      }
      if(practiceInput.ock_req_email_2017 === ''){
        practiceInput.ock_req_email_2017 = null
      }
      if(practiceInput.ock_req_email_2018 === ''){
        practiceInput.ock_req_email_2018 = null
      }
      if(practiceInput.ock_req_email_2019 === ''){
        practiceInput.ock_req_email_2019 = null
      }
      if(practiceInput.ock_req_email_2020 === ''){
        practiceInput.ock_req_email_2020 = null
      }
      if(practiceInput.ock_req_email_2021 === ''){
        practiceInput.ock_req_email_2021 = null
      }
        if(practiceInput.ock_req_email_2022 === ''){
            practiceInput.ock_req_email_2022 = null
        }
        if(practiceInput.ock_req_email_2023 === ''){
            practiceInput.ock_req_email_2023 = null
        }
      if(practiceInput.ock_request_date === ''){
        practiceInput.ock_request_date = null
      }
      if(practiceInput.oralcare_restock_1 === ''){
        practiceInput.oralcare_restock_1 = null
      }
      if(practiceInput.oralcare_restock_2 === ''){
        practiceInput.oralcare_restock_2 = null
      }
      if(practiceInput.oralcare_restock_3 === ''){
        practiceInput.oralcare_restock_3 = null
      }
      if(practiceInput.abc_name === ''){
        practiceInput.abc_name = null
      }
      if(practiceInput.abc_answer === ''){
        practiceInput.abc_answer = null
      }
      if(practiceInput.request_type === ''){
        practiceInput.request_type = null
      }
      if(practiceInput.request_date === ''){
        practiceInput.request_date = null
      }
      if(practiceInput.vs_date === ''){
        practiceInput.vs_date = null
      }
      if(practiceInput.tel === ''){
        practiceInput.tel = null
      }

      practiceId
        ? dispatch(updatePractice(practiceInput.id, practiceInput))
        : dispatch(updatePractice(practiceInput.id, practiceInput))
      props.showPersonMsgFunc(false)
    }

    resetPostCodeLookUp();
  }

  const resetPostCodeLookUp = () => {
    setPostcode('');
  }

  const showSideBar = (event, index) => {
    setValue(event.target.value)
    practiceInput.branch_data[index].office = event.target.value;
    let getId = practiceInput.branch_data[index].id;

    setId(getId)

    if (event.target.value == 'Practice Main' || event.target.value == 'Industry Main') {
      setChooseMain(true);
      setSwitchmain(false);
    } else {
      setChooseMain(false);
      setSwitchmain(true);
    }
    setOpenMenu(!openMenu);

    // setOpenn({chooseMain: !openn.chooseMain, switchmain: false});
    // setOpenn({chooseMain: false, switchmain: !openn.switchmain})
    // setOpenn({chooseMain: false, lastViewedOpen: !openn.lastViewedOpen})
  }

  const sendAction = async (event, type) => {
    let payload = {
      action: type
    }
    try {
      let response = await Axios.post(`/switch/practice/branch/${id}`, payload)
      Swal.fire({
        position: "center",
        icon: "success",
        title: `${response.data.message}`,
        showConfirmButton: false,
        timer: 3000,
      }).then(() => { })
      dispatch(lastSearchedResult())
    } catch (err) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: `${err.response.data.message}`,
        showConfirmButton: false,
        timer: 3000,
      })
    }
  }

  const sendActions = async (event, type) => {

    let data = practiceGet ? practiceGet.data : lastSearchResult.data.practice;

    if (type === id) dispatch(lastSearchedResult())
    else {
      let payload = {
        action: action
      }
      try {
        let response = await Axios.post(`/switch/practice/branch/${type}`, payload)
        Swal.fire({
          position: "center",
          icon: "success",
          title: `${response.data.message}`,
          showConfirmButton: false,
          timer: 3000,
        }).then(() => { })
        dispatch(lastSearchedResult())
      } catch (err) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: `${err.response.data.message}`,
          showConfirmButton: false,
          timer: 3000,
        })
      }
    }
  }

  const messageOnClick = () => {
    if (localStorage.getItem('user_role') != 5 && props.userMe_role != 5 && props.showPracticeMessage) {
      Swal.fire({ title: 'Message', text: 'Changes has been made on vet window. Dont forget to save changes if needed.', confirmButtonText: 'Understood' })
      props.practiceMessageFunc(false)
    }
  }

  return (
    <Card className={(practiceInput.office === "Industry Main" || practiceInput.office === "Industry Branch") ? 'indus-practice custom-card practice-card' : "custom-card practice-card"} onClick={messageOnClick}>
      {openMenu && <div ref={ref} id="addPractice">
        <Collapse in={switchmain || chooseMain}   >
          <div id="addPractice" onClick={messageOnClick}>
            <Container className="p-0" onClick={messageOnClick}>
              {/* Practice slider */}
              {switchmain !== true ?
                <PerfectScrollbar>
                  <Form>

                    <h4 className="my-3 px-4">
                      <span>Switch to main or create a standalone practice</span>
                    </h4>


                    <div className="list-view-export ">
                      <p className="d-flex justify-content-between my-2 me-3 align-items-center">
                        Switch to main
                                <span className="p-0">
                          <Button className="btn-sm theme-btn-primary " onClick={(event) => sendAction(event, 'switchToMain')}>
                            Select
                                    </Button></span></p>
                      <p className="d-flex justify-content-between my-2 me-3 align-items-center">
                        Create standalone practice
                                <span className="p-0">
                          <Button className="btn-sm theme-btn-primary" onClick={(event) => sendAction(event, 'switchPracticeAsStandalone')}>
                            Select
                                    </Button></span></p>
                      <p className="d-flex justify-content-between my-2 me-3 align-items-center">
                        Switch to main and set old main as standalone
                                <span className="p-0">
                          <Button className="btn-sm theme-btn-primary" onClick={(event) => sendAction(event, 'switchToMainAndStandalone')}>
                            Select
                                    </Button></span></p>
                    </div>

                  </Form>
                </PerfectScrollbar> : <PerfectScrollbar>
                  <Form>

                    <h4 className="my-3 px-4">
                      <span>Choose which practice will be the new main</span>
                    </h4>

                    <div className="list-view-export ">
                      {practiceInput.branch_data && practiceInput.branch_data.map((curr, ind) => {
                        return (
                          <p key={ind} className="d-flex justify-content-between my-2 me-3 align-items-center">
                            {curr.name}, {curr.postcode}, {curr.id}

                            <span>
                              <Button className="btn-sm theme-btn-primary" onClick={(event) => sendActions(event, curr.id)}>Select</Button></span></p>
                        )
                      })}

                    </div>

                  </Form>
                </PerfectScrollbar>}

              {/* {openn.lastViewedOpen && (<ChooseMain />)} */}
              {/* temporary export Builder*/}
              {/* {openn.exportBuilder && (<SwitchMain />)} */}

            </Container>
          </div>
        </Collapse>
      </div>}

      <Card.Header className="d-flex justify-content-between align-items-center min-h-50">
        {practiceGet
          ? <span className="card-header-title">{practiceInput.id} | {practiceInput.office} | {practiceInput.name} | {practiceInput.updated_by}  |  {dayjs(practiceInput.updated_date).format('DD/MM/YYYY')} </span>
          : lastSearchResult
            ? <span className="card-header-title">{practiceInput.id} | {practiceInput.office} | {practiceInput.name} | {practiceInput.updated_by}  | {dayjs(practiceInput.updated_date).format('DD/MM/YYYY')}</span>
            : ""}
        {(props.userMe_role == 1 || props.userMe_role == 2 || props.userMe_role == 3) && <Button
          onClick={handlePracticeSubmit}
          size="sm"
          className="theme-btn-white"
        >
          Save Changes <BeatLoader color="#ffffff" loading={loadingUpdatePractice === true} css="" size={5} />
        </Button>}
      </Card.Header>
      <Card.Body className="pt-0" onClick={messageOnClick}>
        <Row className="alice-blue-bg pb-2">
          <Collapse in={open}>
            <Form>
              <Row className="light-grey mb-3">
                <Col lg={12}>
                  <Row className="py-2 justify-content-between gx-2">
                    <Col lg={5} className="">
                      <div className="find-address practice-find-address">
                        <span className="address-icon" onClick={handlePostcodeSubmit} ><Image src={addressIcon} />search</span>
                        <Form.Control
                          name=""
                          type="text"
                          className="mb-0"
                          placeholder="Enter your Postcode"
                          autoComplete="nope"
                          value={postcode}
                          onChange={handlePostcodeChange}
                        />
                        {postcodeMenuShow && data.hasOwnProperty("arr") && Array.from(data.arr).length > 0 &&
                          <div ref={ref} className="address-dropdown">
                            <PerfectScrollbar>
                              {Array.from(data.arr).map((curr, index) =>
                                <p key={index} onClick={() => { handleSetAddress(curr) }}>{curr.display}</p>
                              )}
                            </PerfectScrollbar>
                          </div>
                        }
                      </div>
                    </Col>
                    <Col lg={7} className="">
                      {(practiceInput.office === "Practice Main" || practiceInput.office === "Practice Branch") &&
                        <Col lg={12}>
                          <div className="">
                            <ListGroup horizontal>
                              <ListGroup.Item className="d-flex justify-content-between">VS<span>{practiceInput.vs}</span> </ListGroup.Item>
                              <ListGroup.Item className="d-flex justify-content-between">Branch VS<span> {practiceInput.branch_vs}</span> </ListGroup.Item>
                              <ListGroup.Item className="d-flex justify-content-between">VN<span> {practiceInput.vn}</span> </ListGroup.Item>
                              <ListGroup.Item className="d-flex justify-content-between">Branch VN <span>{practiceInput.branch_vn}</span> </ListGroup.Item>
                            </ListGroup>
                          </div>
                        </Col>}
                    </Col>
                  </Row>

                </Col>

              </Row>
              <Row className="align-items gx-2">
                {(practiceInput.office === "Practice Main" || practiceInput.office === "Practice Branch") &&
                  <>
                    <Col lg={3}>
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        name="name"
                        type="text"
                        value={practiceInput.name}
                        onChange={handlePracticeChange}
                        className={(!practiceInput.name && error.pname) ? 'invalid' : ''}
                      />
                      <Form.Label>Address 1</Form.Label>
                      <Form.Control
                        name="address_1"
                        type="text"
                        value={practiceInput.address_1}
                        onChange={handlePracticeChange}
                        className={(!practiceInput.address_1 && error.paddress1) ? 'invalid' : ''}
                      />
                      <Form.Label>Address 2</Form.Label>
                      <Form.Control
                        name="address_2"
                        type="text"
                        value={practiceInput.address_2}
                        onChange={handlePracticeChange}
                      />
                      <Form.Label>Address 3</Form.Label>
                      <Form.Control
                        name="address_3"
                        type="text"
                        value={practiceInput.address_3}
                        onChange={handlePracticeChange}
                      />
                      <Form.Label>Town</Form.Label>
                      <Form.Control
                        name="town"
                        type="text"
                        value={practiceInput.town ? practiceInput.town.toUpperCase() : ''}
                        onChange={handlePracticeChange}
                        className={(!practiceInput.town && error.ptown) ? 'invalid' : ''}
                      />
                      <Form.Label>County</Form.Label>
                      <Form.Control
                        name="county"
                        type="text"
                        value={practiceInput.county}
                        onChange={handlePracticeChange}
                        className={(!practiceInput.county && error.pcounty) ? 'invalid' : ''}
                      />
                      <Form.Label>Postcode</Form.Label>
                      <Form.Control
                        name="postcode"
                        value={practiceInput.postcode.toUpperCase()}
                        type="text"
                        onChange={handlePracticeChange}
                        className={(!practiceInput.postcode && error.ppostcode) ? 'invalid' : ''}
                      />
                      <Form.Label>Country</Form.Label>
                      <Form.Control name="country" type="text" value={practiceInput.country} onChange={handlePracticeChange} />

                    </Col>
                    <Col lg={3}>
                      <Form.Label>Brick</Form.Label>
                      <Form.Control
                        name="brick_data"
                        type="text"
                        value={practiceInput.brick_data && practiceInput.brick_data.brick}
                        onChange={handlePracticeChange}
                        readOnly={true}
                      />
                      <Form.Label>Tel.</Form.Label>
                      {/* <Form.Control
                                            name="tel"
                                            type="number"
                                            value={practiceInput.tel}
                                            onChange={handlePracticeChange}
                                        /> */}


                      <MaskedInput
                        mask={[/[0-9]/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ']}
                        guide={false}
                        value={practiceInput.tel}
                        name="tel"
                        className="form-control"
                        onChange={handlePracticeChange}
                      //className={(!practiceInput.tel && error.ptel) ? 'invalid' :''}
                      />

                      <Form.Label>Fax</Form.Label>
                      <Form.Control
                        name="fax"
                        type="text"
                        value={practiceInput.fax}
                        onChange={handlePracticeChange}
                      />

                      {/*<Form.Label>Type</Form.Label>*/}
                      {/*<Select*/}
                      {/*  className="basic-single"*/}
                      {/*  classNamePrefix="select"*/}
                      {/*  name="type"*/}
                      {/*  styles={selectStyle}*/}
                      {/*  options={animalType}*/}
                      {/*  value={*/}
                      {/*    practiceInput.type == 'Industry' ? industryType : type*/}
                      {/*  }*/}
                      {/*  // onChange={setType}*/}
                      {/*  onChange={(e) => { handleMultiSelectPracticeChange(e, 'type', setType) }}*/}
                      {/*/>*/}

                        <Form.Label>Type</Form.Label>
                        <Form.Group controlID="small-animal">
                            <Form.Check
                                id="small-animal"
                                name="type"
                                type="checkbox"
                                checked={practiceInput.type.includes('Small Animals')}
                                label="Small Animals"
                                value="Small Animals"
                                className="form-label"
                                onChange={handlePracticeChange}
                                disabled={disableTypes}
                            />
                        </Form.Group>
                        {/*<Form.Group controlID="mixed-animal">*/}
                        {/*    <Form.Check*/}
                        {/*        id="mixed-animal"*/}
                        {/*        name="type"*/}
                        {/*        type="checkbox"*/}
                        {/*        checked={practiceInput.type.includes('Mixed Animals')}*/}
                        {/*        label="Mixed Animals"*/}
                        {/*        value="Mixed Animals"*/}
                        {/*        className="form-label"*/}
                        {/*        onChange={handlePracticeChange}*/}
                        {/*        disabled={disableTypes}*/}
                        {/*    />*/}
                        {/*</Form.Group>*/}
                        <Form.Group controlID="equine-type">
                            <Form.Check
                                id="equine-type"
                                name="type"
                                type="checkbox"
                                checked={practiceInput.type.includes('Equine')}
                                label="Equine"
                                value="Equine"
                                className="form-label"
                                onChange={handlePracticeChange}
                                disabled={disableTypes}
                            />
                        </Form.Group>
                        <Form.Group controlID="farm-animal">
                            <Form.Check
                                id="farm-animal"
                                name="type"
                                type="checkbox"
                                checked={practiceInput.type.includes('Farm Animals')}
                                label="Farm Animals"
                                value="Farm Animals"
                                className="form-label"
                                onChange={handlePracticeChange}
                                disabled={disableTypes}
                            />
                        </Form.Group>
                        {/*<Form.Group controlID="companion-animal">*/}
                        {/*    <Form.Check*/}
                        {/*        id="companion-animal"*/}
                        {/*        name="type"*/}
                        {/*        type="checkbox"*/}
                        {/*        checked={practiceInput.type.includes('Companion Animal')}*/}
                        {/*        label="Companion Animal"*/}
                        {/*        value="Companion Animal"*/}
                        {/*        className="form-label"*/}
                        {/*        onChange={handlePracticeChange}*/}
                        {/*        disabled={disableTypes}*/}
                        {/*    />*/}
                        {/*</Form.Group>*/}
                        <Form.Group controlID="university-type">
                            <Form.Check
                                id="university-type"
                                name="type"
                                type="checkbox"
                                checked={practiceInput.type.includes('University/College')}
                                label="University/College"
                                value="University/College"
                                className="form-label"
                                onChange={handlePracticeChange}
                                disabled={disableTypes}
                            />
                        </Form.Group>
                        <Form.Group controlID="zoo-type">
                            <Form.Check
                                id="zoo-type"
                                name="type"
                                type="checkbox"
                                checked={practiceInput.type.includes('Zoo/Wildlife')}
                                label="Zoo/Wildlife"
                                value="Zoo/Wildlife"
                                className="form-label"
                                onChange={handlePracticeChange}
                                disabled={disableTypes}
                            />
                        </Form.Group>
                        <Form.Group controlID="overseas-type">
                            <Form.Check
                                id="overseas-type"
                                name="type"
                                type="checkbox"
                                checked={practiceInput.type.includes('Overseas')}
                                label="Overseas"
                                value="Overseas"
                                className="form-label"
                                onChange={handlePracticeChange}
                                disabled={disableTypes}
                            />
                        </Form.Group>
                        <Form.Group controlID="vetfile-type">
                            <Form.Check
                                id="vetfile-type"
                                name="type"
                                type="checkbox"
                                checked={practiceInput.type.includes('Vetfile')}
                                label="Vetfile"
                                value="Vetfile"
                                className="form-label"
                                onChange={handlePracticeChange}
                            />
                        </Form.Group>

                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        name="email"
                        type="email"
                        value={practiceInput.email}
                        onChange={handlePracticeChange}
                      />
                      <Form.Label>Web</Form.Label>
                      <Form.Control
                        name="website"
                        type="text"
                        value={practiceInput.website}
                        onChange={handlePracticeChange}
                      />
                    </Col>

                    <Col lg={3}>
                      <Form.Label>Wholesaler</Form.Label>
                      <Form.Control
                        name="wholesaler"
                        value={practiceInput.wholesaler}
                        type="text"
                        onChange={handlePracticeChange}
                      />

                      <Form.Label>Pra. Man. Sys.</Form.Label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        name="pramansys"
                        styles={selectStyle}
                        options={pmsDropDown}
                        value={selectedPMS}
                        onChange={(e) => { handleMultiSelectPracticeChange(e, 'pramansys', setSelectedPMS) }}
                      />

                      {/* <Form.Control
                        name="pramansys"
                        className="form-select"
                        as="select"
                        value={practiceInput.pramansys}
                        onChange={handlePracticeChange}
                      >
                        <option value=""></option>
                        <option value="None">None</option>
                        <option value="Animana">Animana</option>
                        <option value="Assisi">Assisi</option>
                        <option value="AWD Live">AWD Live</option>
                        <option value="Bespoke System">Bespoke System</option>
                        <option value="Black Dog">Black Dog</option>
                        <option value="Cornerstone">Cornerstone</option>
                        <option value="Clinic Office">Clinic Office</option>
                        <option value="DataVet">DataVet</option>
                        <option value="DotVet">DotVet</option>
                        <option value="DuroVet">DuroVet</option>
                        <option value="Eclipse">Eclipse</option>
                        <option value="Enterprise (Jupiter)">Enterprise (Jupiter)</option>
                        <option value="Evolution (Verifac)">Evolution (Verifac)</option>
                        <option value="Ezvetpro (EzOfficeSystems)">Ezvetpro (EzOfficeSystems)</option>
                        <option value="Ezyvet (Cloud)">Ezyvet (Cloud)</option>
                        <option value="Felix">Felix</option>
                        <option value="Freedom">Freedom</option>
                        <option value="Helix">Helix</option>
                        <option value="Jupiter">Jupiter</option>
                        <option value="Merlin">Merlin</option>
                        <option value="OMNIvet">OMNIvet</option>
                        <option value="Paper Diary">Paper Diary</option>
                        <option value="Premvet (Vetsolutions)">Premvet (Vetsolutions)</option>
                        <option value="ProVet Cloud">ProVet Cloud</option>
                        <option value="Refused">Refused</option>
                        <option value="Robovet (Vetsolutions)">Robovet (Vetsolutions)</option>
                        <option value="RxWorks (RxWorks)">RxWorks (RxWorks)</option>
                        <option value="Sean Test">Sean Test</option>
                        <option value="Spectrum (AT Vet Systems)">Spectrum (AT Vet Systems)</option>
                        <option value="Teleos (Teleos Systems)">Teleos (Teleos Systems)</option>
                        <option value="TesterSean">TesterSean</option>
                        <option value="Ventana (Vetsolutions)">Ventana (Vetsolutions)</option>
                        <option value="Vet-One (Vet-One)">Vet-One (Vet-One)</option>
                        <option value="VetCloud (VetCloud)">VetCloud (VetCloud)</option>
                        <option value="VetCom">VetCom</option>
                        <option value="Vet Works">Vet Works</option>
                        <option value="VetSpace (VetSpace was Jupiter)">VetSpace (VetSpace was Jupiter)</option>
                        <option value="Vetter">Vetter</option>
                        <option value="VetIT (2sys)">VetIT (2sys)</option>
                        <option value="Voyager (Jupiter)">Voyager (Jupiter)</option>
                        <option value="Vetlink">Vetlink</option>
                        <option value="Vetsys">Vetsys</option>
                        <option value="Midshires">Midshires</option>
                        <option value="Zeus">Zeus</option>
                        <option value="ZIMS">ZIMS</option>
                      </Form.Control> */}

                      <Form.Label>Direct Mail</Form.Label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        name="direct_mail"
                        options={yesAndNo}
                        styles={selectStyle}
                        value={directMail}
                        // onChange={setDirectMail}
                        onChange={(e) => { handleMultiSelectPracticeChange(e, 'direct_mail', setDirectMail) }}
                      />

                      <div className='customSelect'>
                        <Form.Label>Send 3rd party</Form.Label>
                        <Select
                          // isMulti
                          className="basic-single"
                          classNamePrefix="select"
                          name="send_third"
                          options={yesAndNo}
                          styles={selectStyle}
                          value={sendThirdParty}
                          // onChange={setSendThirdParty}
                          onChange={(e) => { handleMultiSelectPracticeChange(e, 'send_third', setSendThirdParty) }}
                        />
                      </div>

                      <Form.Label>No Staff Prac</Form.Label>
                      <Select
                        // isMulti
                        className="basic-single"
                        classNamePrefix="select"
                        name="no_staff_pra"
                        options={yesAndNo}
                        styles={selectStyle}
                        value={noStaffPrac}
                        // onChange={setNoStaffPrac}
                        onChange={(e) => { handleMultiSelectPracticeChange(e, 'no_staff_pra', setNoStaffPrac) }}
                      />
                      <Form.Label>Corporate</Form.Label>
                      <Form.Control
                        name="corporate"
                        type="text"
                        value={practiceInput.corporate}
                        onChange={handlePracticeChange}
                        readOnly={true}
                      />
                        <Form.Label>OOH</Form.Label>
                        <Form.Control name="ooh" className="form-select" as="select" value={practiceInput.ooh} onChange={handlePracticeChange}>
                            <option>Yes</option>
                            <option>No</option>
                        </Form.Control>
                      <Form.Label>Buying</Form.Label>
                      <Form.Control
            name="buying"
            type="text"
            value={practiceInput.buying}
            onChange={handlePracticeChange}
            readOnly={true}
            />
                    </Col>

                    <Col lg={3}>

                      <Form.Label>Referral</Form.Label>
                      <Form.Control
                        name="referral"
                        className="form-select"
                        as="select"
                        value={practiceInput.referral}
                        onChange={handlePracticeChange}
                      >
                        <option>Yes</option>
                        <option>No</option>
                      </Form.Control>
                      <Form.Label>RCVS Accred.</Form.Label>
                      <Form.Control
                        name="rcvs_acc"
                        className="form-select"
                        as="select"
                        value={practiceInput.rcvs_acc}
                        onChange={handlePracticeChange}
                      >
                        <option>Yes</option>
                        <option>No</option>
                      </Form.Control>
                      <Form.Label>ABC name</Form.Label>
                      <Form.Control
                        name="abc_name"
                        type="text"
                        value={practiceInput.abc_name}
                        onChange={handlePracticeChange}
                      />
                      <Form.Label>ABC answer</Form.Label>
                      <Form.Control
                        name="abc_answer"
                        type="text"
                        value={practiceInput.abc_answer}
                        onChange={handlePracticeChange}
                      />
                      <Form.Label>Request type</Form.Label>
                      <Form.Control
                        name="request_type"
                        className="form-select"
                        as="select"
                        value={practiceInput.request_type}
                        onChange={handlePracticeChange}
                      >
                        <option value="">Select request type</option>
                        <option value="Phone">Phone</option>
                        <option value="Written">Written</option>
                        <option value="Electronic">Electronic</option>
                      </Form.Control>
                      <Form.Label className="d-block">Request date</Form.Label>
                      {/* <DatePicker
                          name="request_date"
                          placeholderText="dd/mm/yyyy"
                          className="form-control w-100"
                          value={!!(new Date(practiceInput.request_date).toString() == 'Invalid Date') ? 'dd/mm/yyyy' : dayjs(new Date(practiceInput.request_date)).format('DD/MM/YYYY')}
                          onChange={request_date => handlePracticeChange({ target: { value: request_date, name: 'request_date' } })}
                        /> */}

                      <Form.Control
                        name="request_date"
                        className="w-100"
                        type="date"
                        value={practiceInput.request_date}
                        onChange={handlePracticeChange}
                        max='2999-12-31'
                      ></Form.Control>


                    </Col>
                  </>
                }


                {(practiceInput.office === "Industry Main" || practiceInput.office === "Industry Branch") &&

                  <>
                    <Col lg={3}>
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        name="name"
                        type="text"
                        value={practiceInput.name}
                        onChange={handlePracticeChange}
                        className={(!practiceInput.name && error.pname) ? 'invalid' : ''}
                      />

                      <Form.Label>Town</Form.Label>
                      <Form.Control
                        name="town"
                        type="text"
                        value={practiceInput.town ? practiceInput.town.toUpperCase() : ''}
                        onChange={handlePracticeChange}
                        className={(!practiceInput.town && error.ptown) ? 'invalid' : ''}
                      />

                      <Form.Label>Tel.</Form.Label>
                      {/* <Form.Control
                                            name="tel"
                                            type="number"
                                            value={practiceInput.tel}
                                            onChange={handlePracticeChange}
                                          /> */}

                      <MaskedInput
                        mask={[/[0-9]/, /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, " "]}
                        guide={false}
                        value={practiceInput.tel}
                        name="tel"
                        className="form-control"
                        onChange={handlePracticeChange}
                      />
                      <Form.Label>Web</Form.Label>
                      <Form.Control name="website" type="text" value={practiceInput.website} onChange={handlePracticeChange} />
                    </Col>

                    <Col lg={3}>
                      <Form.Label>Address 1</Form.Label>
                      <Form.Control
                        name="address_1"
                        type="text"
                        value={practiceInput.address_1}
                        onChange={handlePracticeChange}
                        className={(!practiceInput.address_1 && error.paddress1) ? 'invalid' : ''}
                      />
                      <Form.Label>County</Form.Label>
                      <Form.Control
                        name="county"
                        type="text"
                        value={practiceInput.county}
                        onChange={handlePracticeChange}
                        className={(!practiceInput.county && error.pcounty) ? 'invalid' : ''}
                      />

                      <Form.Label>Fax</Form.Label>
                      <Form.Control name="fax" type="text" value={practiceInput.fax} onChange={handlePracticeChange} />
                    </Col>

                    <Col lg={3}>
                      <Form.Label>Address 2</Form.Label>
                      <Form.Control name="address_2" type="text" value={practiceInput.address_2} onChange={handlePracticeChange} />


                        <Form.Label>Country</Form.Label>
                        <Form.Control
                            name="country"
                            type="text"
                            value={practiceInput.country.charAt(0).toUpperCase() + practiceInput.country.slice(1)}
                            onChange={handlePracticeChange}
                            className={(!practiceInput.country && error.pcountry) ? 'invalid' : ''}
                        />


                      <Form.Label>Type</Form.Label>

                      <Form.Control
                        name="type"
                        className="form-select"
                        as="select"
                        value={practiceInput.type}
                        onChange={handlePracticeChange}
                      >
                        {practiceInput.type === "Industry" ? (
                          <>
                            <option value="Industry">Industry</option>
                          </>
                        ) : (
                          <>
                            <option value="">Select type</option>
                            <option value="Small Animals">Small Animals</option>
                            <option value="Mixed Animals">Mixed Animals</option>
                            <option value="Equine">Equine</option>
                            <option value="Farm Animals">Farm Animals</option>
                            <option value="Companion Animal">Companion Animal</option>
                            <option value="University/College">University/College</option>
                            <option value="Zoo/Wildlife">Zoo/Wildlife</option>
                            <option value="Vetfile">Vetfile</option>
                          </>
                        )}
                      </Form.Control>
                    </Col>

                    <Col lg={3}>
                      <Form.Label>Address 3</Form.Label>
                      <Form.Control name="address_3" type="text" value={practiceInput.address_3} onChange={handlePracticeChange} />
                      <Form.Label>Postcode</Form.Label>
                      <Form.Control name="postcode" value={practiceInput.postcode.toUpperCase()} type="text" onChange={handlePracticeChange} />
                      <Form.Label>Email</Form.Label>
                      <Form.Control name="email" type="email" value={practiceInput.email} onChange={handlePracticeChange} />
                    </Col>
                  </>
                }
              </Row>
            </Form>
          </Collapse>
          <div className="hide d-flex justify-content-center mt-2">
            <a onClick={() => { setOpen(!open) }} className="hide-btn"><span className={open === true ? "arrow hide" : "arrow show"}></span>{open ? "Hide" : "Show"}</a>
          </div>
        </Row>
        <Row>

          <Col lg={12} className="p-0">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              className="row-px-2"
              onSelect={(k) => setKey(k)}
            >
              <Tab eventKey="home" title={`BRANCHES (${practiceInput.branch_data && practiceInput.branch_data.length})`} className="row-px-2">
                {/* <span><small>Start typing in the text field below to associate a new branch to this practice</small></span> */}
                <Form inline>
                  <div className="position-relative advance-search w-100">
                    <span className="search-icon" onClick={() => { openBar(true) }}><Image src={searchIcon} /><span className="search-arrow"></span></span>
                    <span className="cancel-icon" onClick={() => { openBar(false); setKeyword('') }}><Image src={cancelIcon} /></span>
                    <FormControl
                      type="text"
                      placeholder="Search"
                      className="mr-sm-2 search-bar"
                      placeholder="Start typing in the text field below to associate a new branch to this practice"
                      onChange={handleKeywordChange}
                      value={Keyword}
                    />
                    {bar &&
                      <div className="advance-search-dropdown">
                        <Row>
                          <Col lg={12}>
                            {data.hasOwnProperty("practices") && data.practices.map((curr, index) =>
                              <div
                                key={index}
                                className="search-results position-relative"
                                onClick={() => { handleSetPracticeId(curr.id, curr.office); openBar(false); setKeyword("") }}
                              >
                                <div className="search-result mx-2">
                                  <p>{curr.name}</p>
                                  <span>{curr.address_1}, {curr.postcode}</span>
                                </div>
                                <div className="search-divider"></div>
                              </div>
                            )}
                          </Col>
                        </Row>
                      </div>
                    }
                  </div>


                </Form>
                <div className="table-wrapper">
                  <table className="fixed-header-table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Address 1</th>
                        <th>Town</th>
                        <th>Postcode</th>
                        <th>Office</th>
                        <th className="text-center">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {practiceInput.branch_data && practiceInput.branch_data.map((curr, ind) => (
                        <tr className={curr.id == practiceInput.id && 'active-practice-branch'} key={ind}>
                          <td onClick={() => dispatch(getPracticeById(curr.id))} >{curr.name}</td>
                          <td onClick={() => dispatch(getPracticeById(curr.id))}>{curr.address_1}</td>
                          <td onClick={() => dispatch(getPracticeById(curr.id))}>{curr.town}</td>
                          <td onClick={() => dispatch(getPracticeById(curr.id))}>{curr.postcode}</td>
                          {/* <td className="office-select">{curr.office}</td> */}
                          {practiceInput.type === "Industry" ? (
                            <td className="select-office">
                              <Form className="form-select" name="title"
                                as="select" onChange={($event) => showSideBar($event, ind)} value={curr.office} >

                                <option defaultValue value="" style={{ display: 'none' }}></option>
                                <option value="Industry Branch">I.Branch</option>
                                <option value="Industry Main">I.Main</option>
                              </Form>
                            </td>

                          ) :
                            <td className="select-office">
                              <Form className="form-select" name="title"
                                as="select" onChange={($event) => showSideBar($event, ind)} value={curr.office} >

                                <option defaultValue value="" style={{ display: 'none' }}></option>
                                <option value="Practice Branch">P.Branch</option>
                                <option value="Practice Main">P.Main</option>
                              </Form>
                            </td>}

                          <td>
                            <div className="action-icons">
                              {/* <span className="edit-icon" onClick={() => dispatch(openPractice(curr.id))}><Image src={editIcon} /></span> */}
                              <span className="delete-icon" onClick={() => deletePractice(curr.id)}><Image src={deleteIcon} /></span>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Tab>

              {(practiceInput.office === "Practice Main" ||
                practiceInput.office === "Practice Branch") ?
                <Tab eventKey="profile" title={`PEOPLE (${practiceInput.vet_data.length})`} className="row-px-2">
                  <div className="people-tab">
                    <div className="table-wrapper">
                      <table className="fixed-header-table people-table-scroll">
                        <thead>
                          <tr >
                            <th>Title</th>
                            <th>Forename</th>
                            <th>Surname</th>
                            <th>Job types</th>
                            <th>Other Job Types</th>
                            <th>Species</th>
                          </tr>
                        </thead>
                        <tbody>
                          {practiceInput.vet_data.map((curr, ind) => (
                            <tr key={ind} onClick={() => dispatch(openPerson(curr.id))}>
                              <td>{curr.title}</td>
                              <td>{curr.forename}</td>
                              <td>{curr.surname}</td>
                              <td>{curr.v_job_types}</td>
                              <td>{curr.v_job_types_o}</td>
                              <td>{curr.v_species}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Tab>
                :
                <Tab eventKey="profile" title={`PEOPLE (${practiceInput.vet_data.length})`} className="row-px-2">
                  <div className="people-tab">
                  <div className="table-wrapper">
                  <table className="fixed-header-table people-table-scroll">
                          <thead>
                            <tr >
                              <th>Title</th>
                              <th>Forename</th>
                              <th>Surname</th>
                              <th>Other Job Types</th>
                            </tr>
                          </thead>
                          <tbody>
                            {practiceInput.vet_data.map((curr, ind) => (
                              <tr key={ind} onClick={() => dispatch(openPerson(curr.id))}>
                                <td>{curr.title}</td>
                                <td>{curr.forename}</td>
                                <td>{curr.surname}</td>
                                <td>{curr.v_job_types_o}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                  </div>
                </Tab>}

              {(practiceInput.office === "Practice Main" ||
                practiceInput.office === "Practice Branch") &&
                <Tab eventKey="contact" title="DETAILS (1)">
                  <Tabs className="secondary-tabs row-px-2">
                    <Tab
                      eventKey="details-subscriptions"
                      title="Subscriptions"
                      className="row-px-2 practice-tab-select"
                    >
                      <Form>
                        <div className="row gx-2">
                          <div className="col-lg-4">
                            <Card className="card-even-odd h-100">
                              <Card.Body className="p-0">
                                <div className="d-flex align-items-center justify-content-between p-2">
                                  <Form.Label>Practice VT</Form.Label>
                                  <Form.Control
                                    name="sendvt"
                                    className="form-select  max-w-110 mb-0"
                                    as="select"
                                    value={practiceInput.sendvt}
                                    onChange={handlePracticeChange}
                                  >
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </Form.Control>
                                </div>
                                <div className="d-flex align-items-center justify-content-between p-2">
                                  <Form.Label>Practice VNT</Form.Label>
                                  <Form.Control
                                    name="sendvnt"
                                    className="form-select  max-w-110 mb-0"
                                    as="select"
                                    value={practiceInput.sendvnt}
                                    onChange={handlePracticeChange}
                                  >
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </Form.Control>
                                </div>
                                <div className="d-flex align-items-center justify-content-between p-2">
                                  <Form.Label>Practice VBJ</Form.Label>
                                  <Form.Control
                                    name="sendvbj"
                                    className="form-select  max-w-110 mb-0"
                                    as="select"
                                    value={practiceInput.sendvbj}
                                    onChange={handlePracticeChange}
                                  >
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </Form.Control>
                                </div>
                                <div className="d-flex align-items-center justify-content-between p-2">
                                  <Form.Label>Practice Livestock</Form.Label>
                                  <Form.Control
                                    name="sendlivestock"
                                    className="form-select  max-w-110 mb-0"
                                    as="select"
                                    value={practiceInput.sendlivestock}
                                    onChange={handlePracticeChange}
                                  >
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </Form.Control>
                                </div>
                                <div className="d-flex align-items-center justify-content-between p-2">
                                  <Form.Label>Practice Equine</Form.Label>
                                  <Form.Control
                                    name="sendequine"
                                    className="form-select  max-w-110 mb-0"
                                    as="select"
                                    value={practiceInput.sendequine}
                                    onChange={handlePracticeChange}
                                  >
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </Form.Control>
                                </div>
                              </Card.Body>
                            </Card>
                          </div>



                          <div className="col-lg-8">

                            <Card className="card-even-odd">

                              <Card.Body className="p-2">
                                <Form.Label>Training</Form.Label>
                                <div className="d-flex" style={{ background: '#fff' }}>
                                  <Form.Group controlId="ems-training">
                                    <Form.Check
                                      name="training"
                                      label="EMS-Extra Mural Studies"
                                      value="EMS-Extra Mural Studies"
                                      checked={
                                        practiceInput.training &&
                                        practiceInput.training
                                          .split(",")
                                          .indexOf("EMS-Extra Mural Studies") != -1
                                      }
                                      onChange={handleCheckPracticeChange}
                                      disabled={practiceInput.training && practiceInput.training.split(',').indexOf('None') != -1}
                                      style={{ marginRight: '15px', alignItems: 'center' }}
                                    />
                                  </Form.Group>

                                  <Form.Group controlId="vn-training">
                                    <Form.Check
                                      name="training"
                                      label="VN Training"
                                      value="VN Training"
                                      checked={
                                        practiceInput.training &&
                                        practiceInput.training
                                          .split(",")
                                          .indexOf("VN Training") != -1
                                      }
                                      onChange={handleCheckPracticeChange}
                                      disabled={practiceInput.training && practiceInput.training.split(',').indexOf('None') != -1}
                                      style={{ marginRight: '15px', alignItems: 'center' }}
                                    />
                                  </Form.Group>

                                  <Form.Group controlId="none">
                                    <Form.Check
                                      name="training"
                                      label="None"
                                      value="None"
                                      checked={
                                        practiceInput.training &&
                                        practiceInput.training
                                          .split(",")
                                          .indexOf("None") != -1
                                      }
                                      onChange={handleCheckPracticeChange}
                                      disabled={practiceInput.training && (practiceInput.training.split(',').indexOf('EMS-Extra Mural Studies') != -1 || practiceInput.training.split(',').indexOf('VN Training') != -1)}
                                      style={{ marginRight: '15px', alignItems: 'center' }}
                                    />
                                  </Form.Group>
                                </div>

                              </Card.Body>
                              <Card.Body className="p-2">

                                <div className="row">
                                  <Form.Label>Oral Care Kits</Form.Label>
                                  <div className="col-md-6">
                                    <Select
                                      // defaultValue={}
                                      isMulti
                                      name="oralcare"
                                      styles={selectStyle}
                                      options={oralCareKitOptions}
                                      className="basic-single"
                                      classNamePrefix="select"
                                      value={oralCareOptions}
                                      onChange={handleOralCareOptions}
                                    />
                                  </div>
                                  <div className="col-md-6">
                                    <input name="ock_request_date" className='form-control' type='date' value={practiceInput.ock_request_date && (practiceInput.ock_request_date !== null ? practiceInput.ock_request_date : null)} onChange={handlePracticeChange} />
                                  </div>
                                </div>

                                <div className="row">
                                  {
                                    oralCareOptions.length > 0 && oralCareOptions.map((x, i) => (
                                      <>
                                        <div className="col-md-6">
                                          <Form.Label>{x.fieldName1}</Form.Label>
                                          <Form.Control
                                            name={x.f1Value}
                                            value={practiceInput[`${x.f1Value}`]}
                                            onChange={handlePracticeChange}
                                          />
                                        </div>
                                        <div className="col-md-6">
                                          <Form.Label>{x.fieldName2}</Form.Label>
                                          <Form.Control
                                            name={x.f2Value}
                                            value={practiceInput[`${x.f2Value}`]}
                                            onChange={handlePracticeChange}
                                          />
                                        </div>
                                      </>
                                    ))
                                  }
                                </div>

                                {/* <div className="row">
                                  <div className="col-md-6">
                                    <Form.Label>OCK Req Name</Form.Label>
                                    <Form.Control
                                      name="ock_req_name"
                                      type="date"
                                      max='2999-12-31'
                                      value={practiceInput.ock_req_name}
                                      onChange={handlePracticeChange}
                                    />
                                  </div>
                                  <div className="col-md-6">
                                    <Form.Label>OCK Req Email</Form.Label>
                                    <Form.Control
                                      name="ock_req_email"
                                      type="email"
                                      value={practiceInput.ock_req_email}
                                      onChange={handlePracticeChange}
                                    />
                                  </div>
                                </div> */}

                                <div className="row">
                                  <div className="col-md-6">
                                    <Form.Label>Oral Care Restock 1</Form.Label>
                                    <input name="oralcare_restock_1" className='form-control' type='date' value={practiceInput.oralcare_restock_1 && (practiceInput.oralcare_restock_1 !== null ? practiceInput.oralcare_restock_1 : null)} max='2999-12-31' onChange={handlePracticeChange} />
                                  </div>
                                  <div className="col-md-6">
                                    <Form.Label>Oral Care Restock 2</Form.Label>
                                    <input name="oralcare_restock_2" className='form-control' type='date' value={practiceInput.oralcare_restock_2 && (practiceInput.oralcare_restock_2 !== null ? practiceInput.oralcare_restock_2 : null)} max='2999-12-31' onChange={handlePracticeChange} />
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-6">
                                    <Form.Label>Mars POS</Form.Label>
                                    <input name="oralcare_restock_3" className='form-control' type='date' value={practiceInput.oralcare_restock_3 && (practiceInput.oralcare_restock_3 !== null ? practiceInput.oralcare_restock_3 : null)} max='2999-12-31' onChange={handlePracticeChange} />
                                  </div>
                                </div>

                              </Card.Body>

                            </Card>

                          </div>



                        </div>
                      </Form>
                    </Tab>
                    <Tab
                      eventKey="details-corporate-buyings"
                      title="Corporate/Buyings"
                      className="row-px-2 practice-tab-select"
                    >
                      <div className="row gx-2">
                        <div className="col-lg-4">
                          <Card className="card-even-odd h-100">
                            <Card.Body className="p-0">
                              <Form>
                                <div className="d-flex align-items-center justify-content-between p-2">
                                  <Form.Label>Pets at Home</Form.Label>
                                  <Form.Control
                                    name="pethome"
                                    className="form-select max-w-110 mb-0 corporate"
                                    as="select"
                                    value={practiceInput.pethome}
                                    onChange={handlePracticeChange}
                                  >
                                    <option>Yes</option>
                                    <option>No</option>
                                  </Form.Control>
                                </div>
                                <div className="d-flex align-items-center justify-content-between p-2">
                                  <Form.Label>Vets Now</Form.Label>
                                  <Form.Control
                                    name="vetsnow"
                                    className="form-select  max-w-110 mb-0 corporate"
                                    as="select"
                                    value={practiceInput.vetsnow}
                                    onChange={handlePracticeChange}
                                  >
                                    <option>Yes</option>
                                    <option>No</option>
                                  </Form.Control>
                                </div>
                              </Form>
                            </Card.Body>
                          </Card>
                        </div>
                        <div className="col-lg-4">
                          <Card className="card-even-odd h-100">
                            <Card.Body className="p-0">
                              <Form>
                                <div className="d-flex align-items-center justify-content-between p-2">
                                  <Form.Label>CVS</Form.Label>
                                  <Form.Control
                                    name="cvs"
                                    className="form-select max-w-110 mb-0"
                                    value={practiceInput.cvs} as="select"
                                    onChange={handlePracticeChange}
                                  >
                                    <option>Yes</option>
                                    <option>No</option>
                                  </Form.Control>
                                </div>
                                <div className="d-flex align-items-center justify-content-between p-2">
                                  <Form.Label>IVC</Form.Label>
                                  <Form.Control
                                    name="ivc"
                                    className="form-select max-w-110 mb-0"
                                    as="select"
                                    value={practiceInput.ivc}
                                    onChange={handlePracticeChange}
                                  >
                                    <option>Yes</option>
                                    <option>No</option>
                                  </Form.Control>
                                </div>
                                <div className="d-flex align-items-center justify-content-between p-2">
                                  <Form.Label>Linnaeus</Form.Label>
                                  <Form.Control
                                    className="form-select max-w-110 mb-0"
                                    name="linnaeus"
                                    as="select"
                                    value={practiceInput.linnaeus}
                                    onChange={handlePracticeChange}
                                  >
                                    <option>Yes</option>
                                    <option>No</option>
                                  </Form.Control>
                                </div>
                                <div className="d-flex align-items-center justify-content-between p-2">
                                  <Form.Label>Medivet</Form.Label>
                                  <Form.Control
                                    name="medivet"
                                    className="form-select max-w-110 mb-0"
                                    as="select"
                                    value={practiceInput.medivet}
                                    onChange={handlePracticeChange}
                                  >
                                    <option>Yes</option>
                                    <option>No</option>
                                  </Form.Control>
                                </div>
                                <div className="d-flex align-items-center justify-content-between p-2">
                                  <Form.Label>Vet Partners</Form.Label>
                                  <Form.Control
                                    name="vetpartners"
                                    className="form-select max-w-110 mb-0"
                                    as="select"
                                    value={practiceInput.vetpartners}
                                    onChange={handlePracticeChange}
                                  >
                                    <option>Yes</option>
                                    <option>No</option>
                                  </Form.Control>
                                </div>
                              </Form>
                            </Card.Body>
                          </Card>
                        </div>
                        <div className="col-lg-4">
                          <Card className="card-even-odd h-100">
                            <Card.Body className="p-0">
                              <Form>
                                <div className="d-flex align-items-center justify-content-between p-2">
                                  <Form.Label>MWI Group</Form.Label>
                                  <Form.Control
                                    name="mwi_group"
                                    className="form-select max-w-110 mb-0"
                                    as="select"
                                    value={practiceInput.mwi_group}
                                    onChange={handlePracticeChange}
                                  >
                                    <option>Yes</option>
                                    <option>No</option>
                                  </Form.Control>
                                </div>
                                <div className="d-flex align-items-center justify-content-between p-2">
                                  <Form.Label>Vetcel</Form.Label>
                                  <Form.Control
                                    name="vetcel"
                                    className="form-select max-w-110 mb-0"
                                    as="select"
                                    value={practiceInput.vetcel}
                                    onChange={handlePracticeChange}
                                  >
                                    <option>Yes</option>
                                    <option>No</option>
                                  </Form.Control>
                                </div>
                                <div className="d-flex align-items-center justify-content-between p-2">
                                  <Form.Label>VetDynamics</Form.Label>
                                  <Form.Control
                                    name="vetdynamics"
                                    className="form-select max-w-110 mb-0"
                                    as="select"
                                    value={practiceInput.vetdynamics}
                                    onChange={handlePracticeChange}
                                  >
                                    <option>Yes</option>
                                    <option>No</option>
                                  </Form.Control>
                                </div>
                                <div className="d-flex align-items-center justify-content-between p-2">
                                  <Form.Label>VetShare</Form.Label>
                                  <Form.Control
                                    name="vetshare"
                                    className="form-select max-w-110 mb-0"
                                    as="select"
                                    value={practiceInput.vetshare}
                                    onChange={handlePracticeChange}
                                  >
                                    <option>Yes</option>
                                    <option>No</option>
                                  </Form.Control>
                                </div>
                                <div className="d-flex align-items-center justify-content-between p-2">
                                  <Form.Label>Vetsure</Form.Label>
                                  <Form.Control
                                    name="vetsure"
                                    className="form-select max-w-110 mb-0"
                                    as="select"
                                    value={practiceInput.vetsure}
                                    onChange={handlePracticeChange}
                                  >
                                    <option>Yes</option>
                                    <option>No</option>
                                  </Form.Control>
                                </div>

                                <div className="d-flex align-items-center justify-content-between p-2">
                                  <Form.Label>XL Vets</Form.Label>
                                  <Form.Control
                                    name="xlvets"
                                    className="form-select max-w-110 mb-0"
                                    as="select"
                                    value={practiceInput.xlvets}
                                    onChange={handlePracticeChange}
                                  >
                                    <option>Yes</option>
                                    <option>No</option>
                                  </Form.Control>
                                </div>
                              </Form>
                            </Card.Body>
                          </Card>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </Tab>}
            </Tabs>
          </Col>
        </Row>
      </Card.Body>

    </Card>
  )
}

export default Practice
