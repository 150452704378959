import { React, useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { Col, Container, Row } from "react-bootstrap";
//components
import Header from "../components/Header";
import Person from "../components/Person";
import Practice from "../components/Practice";
import Sidebar from "../components/Sidebar";
import personModel from "../constants/personModel";
import practiceModel from "../constants/practiceModel";
import AdvanceQuery from "../components/AdvanceQuery";
import IdleTimerContainer from "../components/IdleTimerContainer"
import AdvanceQueryBuilder from '../components/AdvanceQueryBuilder'
//actions
import { me } from '../actions/userActions'
import ModifyUser from "../components/ModifyUser";
import ReportBuilder from "../components/ReportBuilder";
import MassUpload from "../components/MassUpload.js";

const Dashboard = () => {
    const [open, setOpen] = useState(false);
    const [personInput, setPersonInput] = useState(personModel);
    const [practiceInput, setPracticeInput] = useState(practiceModel);
    const [showPersonMessage, setShowPersonMessage] = useState(false);
    const [showPracticeMessage, setShowPracticeMessage] = useState(false);
    const [modifyUserState, setModifyUserState] = useState(false)
    const [reportBuilderState, setReportBuilderState] = useState(false)
    const { userMe } = useSelector(state => state.userMeReducer)
    const [userMe_role, setuserMe_role] = useState(localStorage.getItem('user_role'))
    const dispatch = useDispatch()
    const [massUploadState, setMassUploadState] = useState(false)

    /* useEffect(() => {w
        (async () => {
            await dispatch(me())
        })()
    }, []) */

    /* useEffect(() => {
        console.log('me:  ', userMe)
        if (userMe) {
            localStorage.setItem('user_role', userMe.role)
            setuserMe_role(userMe.role)
        }
    }, [userMe]) */

    useEffect(() => {
        dispatch(me())
    }, [])

    useEffect(() => {
        if (userMe) setuserMe_role(localStorage.getItem('user_role'))
    }, [userMe])

    const resetState = () => {
        setPersonInput(personModel)
        setPracticeInput(practiceModel)
    }

    return (
        <>
            <IdleTimerContainer></IdleTimerContainer>

            <Header userMe_role={userMe_role} open={open} personInput={personInput} setPersonInput={setPersonInput} practiceInput={practiceInput} setPracticeInput={setPracticeInput} reset={resetState} showPersonMessage={showPersonMessage} showPersonMsgFunc={setShowPersonMessage} showPracticeMessage={showPracticeMessage} practiceMessageFunc={setShowPracticeMessage} setModifyUserState={setModifyUserState} setReportBuilderState={setReportBuilderState} />

            <Sidebar userMe_role={userMe_role} pres={modifyUserState === true ? 'modify' : reportBuilderState === true ? 'report' : 'home'} setModifyUserState={setModifyUserState} setReportBuilderState={setReportBuilderState} showPersonMessage={showPersonMessage} showPersonMsgFunc={setShowPersonMessage} showPracticeMessage={showPracticeMessage} practiceMessageFunc={setShowPracticeMessage} setMassUploadState={setMassUploadState} />

            <div
                onClick={() => {

                    setOpen(!open);
                }}
            >

                <div className="page-wrapper">
                    <Container fluid className="my-4 ">

                        {(modifyUserState === false && reportBuilderState === false && massUploadState === false) &&
                            <Row className="gx-3">
                                {/* <Col lg={2}>
                                <Sidebar />
                        </Col> */}
                                <Col lg={5}>
                                    <Practice userMe_role={userMe_role} resetState={resetState} showPersonMessage={showPersonMessage} showPersonMsgFunc={setShowPersonMessage} showPracticeMessage={showPracticeMessage} practiceMessageFunc={setShowPracticeMessage} />
                                </Col>
                                <Col lg={7}>
                                    <Person userMe_role={userMe_role} resetState={resetState} showPersonMessage={showPersonMessage} showPersonMsgFunc={setShowPersonMessage} showPracticeMessage={showPracticeMessage} practiceMessageFunc={setShowPracticeMessage} />
                                </Col>
                                <Col>
                                    <AdvanceQueryBuilder userMe_role={userMe_role} showPersonMessage={showPersonMessage} showPersonMsgFunc={setShowPersonMessage} showPracticeMessage={showPracticeMessage} practiceMessageFunc={setShowPracticeMessage} />
                                </Col>
                            </Row>}

                        <Row>
                            {modifyUserState &&
                                <Col lg={12}>
                                    <ModifyUser userMe_role={userMe_role} />
                                </Col>}
                        </Row>

                        <Row>
                            {reportBuilderState &&
                                <Col lg={12}>
                                    <ReportBuilder />
                                </Col>}
                        </Row>

                        <Row>
                            {massUploadState &&
                            <Col lg={12}>
                                <MassUpload />
                            </Col>}
                        </Row>

                    </Container>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
